import cookies from "js-cookie";
import { DEFAULT_COOKIE_OPTIONS } from "../consts";
import getCookieName from "./getCookieName";

export default function setupAuthForLocalhost() {
  let hasUrlChanged = false;
  const parsedURL = new URL(window.location.href);
  const params = new URLSearchParams(parsedURL.search);
  const token = params.get("tk");
  if (token) {
    cookies.set(
      getCookieName({ name: "tk", env: "workspace", isDevelopment: true }),
      token,
      DEFAULT_COOKIE_OPTIONS
    );
    params.delete("tk");
    hasUrlChanged = true;
  }

  const impersonatedUserId = params.get("impersonatedUserId");
  if (impersonatedUserId) {
    cookies.set(
      getCookieName({ name: "impersonatedUserId", env: "workspace", isDevelopment: true }),
      impersonatedUserId,
      DEFAULT_COOKIE_OPTIONS
    );
    params.delete("impersonatedUserId");
    hasUrlChanged = true;
  }
  if (!hasUrlChanged) {
    return;
  }
  parsedURL.search = `?${params.toString()}`;
  window.location.replace(parsedURL.toString());
}
