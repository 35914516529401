import React, { MouseEvent, ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import get from "lodash/get";
import map from "lodash/map";
import uniqBy from "lodash/uniqBy";
import {
  GridCellParams,
  GridColumnVisibilityModel,
  GridFilterModel,
  GridPaginationModel,
  GridPinnedColumns,
  GridRowModel,
  GridRowIdGetter,
  GridRowParams,
  GridSortModel,
  MuiEvent
} from "@mui/x-data-grid-pro";
import qs from "query-string";
import { useLocation, Location, useNavigate } from "react-router-dom";
import { attempt, filter, first, values } from "lodash";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { LazyQueryHookOptions, LazyQueryResultTuple, OperationVariables } from "@apollo/client";
import DataGrid, {
  DataGridFilterModel,
  ListCustomOptions,
  ListSettings,
  adjustFilterModel,
  setListConfiguration
} from "../DataGrid";
import {
  ListColumn,
  ListFilter,
  useCreateListPresetMutation,
  useUpdateListPresetMutation,
  useGetListQuery,
  useDeleteListPresetMutation,
  useGetAgentsLazyQuery,
  UserCompanyRole
} from "../../generated/graphql";
import adjustPresetColumns from "./adjustPresetColumns";
import getGraphQLErrorsAsString from "../../libs/graphql/getGraphQLErrorsAsString";
import adjustPresetFilters from "./adjustPresetFilters";
import getColumnFormats from "./getColumnFormats";
import { useAuth } from "../../contexts/auth";
import PageLoader from "../PageLoader/PageLoader";
import downloadFile from "../../libs/formatter/downloadFile";

export default function ListingDataGrid({
  listId,
  useGetRowsLazyQuery,
  useExportRowsMutation,
  lazyQueryVariables,
  renderCellDataProps,
  getAgentsVariables,
  useGetAgentsLazyquery: initialUseGetAgentsLazyquery,
  query,
  onRowClick,
  onCellClick,
  backgroundColor,
  triggerRefetch,
  isEditable = true,
  getRowId,
  isMultiRowSelectionAllowed = false,
  toolbarButtons,
  onRowSelectedChange,
  getAgentQueryName = "getAgents"
}: {
  listId: string;
  isEditable?: boolean | null | undefined;
  useGetRowsLazyQuery: (
    baseOptions?: LazyQueryHookOptions<any, any>
  ) => LazyQueryResultTuple<any, any>;
  query: string;
  backgroundColor?: string | undefined;
  lazyQueryVariables?: OperationVariables;
  useExportRowsMutation?: any;
  renderCellDataProps?: OperationVariables;
  getAgentsVariables?: OperationVariables;
  useGetAgentsLazyquery?: (
    baseOptions?: LazyQueryHookOptions<any, any>
  ) => LazyQueryResultTuple<any, any>;
  triggerRefetch?: boolean;
  onRowClick?: ({
    rowId,
    row,
    event
  }: {
    rowId: string;
    row: GridRowModel;
    event: MuiEvent<MouseEvent>;
  }) => void;
  onCellClick?: ({
    rowId,
    row,
    event,
    field
  }: {
    rowId: string;
    row: GridRowModel;
    event: MuiEvent<MouseEvent>;
    field: string;
  }) => void;
  getRowId?: GridRowIdGetter<any>;
  isMultiRowSelectionAllowed?: boolean;
  toolbarButtons?: ReactNode;
  onRowSelectedChange?: any;
  getAgentQueryName?: string;
}) {
  const location: Location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isLoaded, setLoaded] = useState(false);

  const { config, userCompanyRole } = useAuth();

  const searchParamsQuery = qs.parse(location.search, {
    parseNumbers: true
  });

  const [listFilterModel, setListFilterModel] = useState<DataGridFilterModel>(
    attempt(() =>
      searchParamsQuery.filters ? JSON.parse(window.atob(searchParamsQuery.filters as string)) : {}
    )
  );

  const [pagination, setPagination] = useState<GridPaginationModel>(
    attempt(() =>
      searchParamsQuery.pagination
        ? JSON.parse(window.atob(searchParamsQuery.pagination as string))
        : { page: 0, pageSize: 20 }
    )
  );

  const [sortModelSettings, setSortModelSettings] = useState<GridSortModel>(
    attempt(() =>
      searchParamsQuery.sort ? JSON.parse(window.atob(searchParamsQuery.sort as string)) : {}
    )
  );

  const [currentPresetId, setCurrentPresetId] = useState<number | undefined>(
    (searchParamsQuery.presetId as number) || undefined
  );

  const {
    data: listData,
    loading: listLoading,
    refetch
  } = useGetListQuery({
    fetchPolicy: "network-only",
    errorPolicy: "all",
    variables: { listId }
  });

  const [getAgents] = initialUseGetAgentsLazyquery
    ? initialUseGetAgentsLazyquery()
    : useGetAgentsLazyQuery();

  const [getRows, { data, loading: dataLoading, error: getRowsError }] = useGetRowsLazyQuery({
    fetchPolicy: "network-only",
    errorPolicy: "all",
    variables: {
      filters: adjustFilterModel({ filterModel: listFilterModel }),
      page: pagination.page,
      rowsPerPage: pagination.pageSize,
      order: first(sortModelSettings)?.sort,
      orderBy: first(sortModelSettings)?.field,
      ...lazyQueryVariables
    }
  });

  const [exportRows, { loading: isExporting }] = useExportRowsMutation
    ? useExportRowsMutation()
    : [null, { loading: false }];
  const [createListPreset] = useCreateListPresetMutation();
  const [updateListPreset] = useUpdateListPresetMutation();
  const [deleteListPreset] = useDeleteListPresetMutation();

  const handleGetAgents = async ({ query: initQuery }: { query: string }) => {
    const result = await getAgents({
      variables: { ...getAgentsVariables, order: "asc", orderBy: "name", query: initQuery }
    });
    const agentResults = getAgentQueryName
      ? uniqBy(
          map(get(result, ["data", getAgentQueryName, "rows"]), field => field.agent),
          "id"
        )
      : get(result, "data.getAgents.rows");

    if (agentResults) {
      return agentResults as UserCompanyRole[];
    }
    return [];
  };

  const { baseListSettings, rows, rowCount, settings } = useMemo(() => {
    if (!listData) {
      return {
        baseListSettings: {} as ListSettings,
        rows: [],
        rowCount: 0,
        settings: {} as ListCustomOptions
      };
    }
    const listSettingData = listData?.getList as ListSettings;
    const rowsData = get(data, [query, "rows"]);
    const totalRowCount = get(data, [query, "count"]) as number;
    const listActions = isEditable ? listSettingData?.listActions : [];
    const columnData = listData?.getList?.columns as ListColumn[];
    const filterData = listData?.getList?.filters as ListFilter[];

    let columnsWithFormatter;
    if (config && userCompanyRole && renderCellDataProps) {
      columnsWithFormatter = getColumnFormats({
        columns: columnData,
        filters: filterData,
        renderCellDataProps,
        currentUser: userCompanyRole,
        config
      });
    } else if (config && userCompanyRole && !renderCellDataProps) {
      columnsWithFormatter = getColumnFormats({
        columns: columnData,
        filters: filterData,
        renderCellDataProps: [],
        currentUser: userCompanyRole,
        config
      });
    }

    const baseListSettingsValue = {
      ...listSettingData,
      listActions,
      columns: columnsWithFormatter,
      filters: filterData
    } as ListSettings;

    const columnVisibilityModel = attempt(() =>
      searchParamsQuery.columnVisibilityModel
        ? JSON.parse(window.atob(searchParamsQuery.columnVisibilityModel as string))
        : {}
    );
    const pinnedColumns = attempt(() =>
      searchParamsQuery.pinnedColumns
        ? JSON.parse(window.atob(searchParamsQuery.pinnedColumns as string))
        : {}
    );

    const columnOrders = attempt(() =>
      searchParamsQuery.columnVisibilityModel
        ? JSON.parse(window.atob(searchParamsQuery.columnOrders as string))
        : []
    );

    const filters = attempt(() =>
      searchParamsQuery.filters ? JSON.parse(window.atob(searchParamsQuery.filters as string)) : {}
    );

    const paginationSettings = attempt(() =>
      searchParamsQuery.pagination
        ? JSON.parse(window.atob(searchParamsQuery.pagination as string))
        : {}
    );

    const sortSettings = attempt(() =>
      searchParamsQuery.sort ? JSON.parse(window.atob(searchParamsQuery.sort as string)) : {}
    ) as GridSortModel;

    const { presetId } = searchParamsQuery;

    const {
      columnVisibilityModel: initialColumnVisibilityModel,
      pinnedColumns: initialPinnedColumns,
      currentPresetId: initialPresetId,
      filterModel: filterModelSettings,
      paginationModel: initialPaginationModel,
      columnOrders: initialColumnOrders,
      sortModel: initialSortModel
    } = setListConfiguration({
      listSettings: baseListSettingsValue,
      settings: {
        columnVisibilityModel,
        pinnedColumns,
        columnOrders,
        filterModel: filters,
        sortModel: sortSettings,
        paginationModel: paginationSettings,
        presetId: presetId as number
      },
      selectedPresetId: currentPresetId
    });

    setCurrentPresetId(initialPresetId);
    setPagination(initialPaginationModel);
    setSortModelSettings(initialSortModel);
    setListFilterModel(filterModelSettings);
    setLoaded(true);

    return {
      baseListSettings: baseListSettingsValue,
      settings: {
        columnVisibilityModel: initialColumnVisibilityModel,
        pinnedColumns: initialPinnedColumns,
        filterModel: filterModelSettings,
        paginationModel: initialPaginationModel,
        presetId: initialPresetId as number,
        columnOrders: initialColumnOrders,
        sortModel: initialSortModel
      },
      rows: rowsData,
      rowCount: totalRowCount
    };
  }, [
    data,
    listData,
    searchParamsQuery.pinnedColumns,
    searchParamsQuery.columnOrders,
    searchParamsQuery.columnVisibilityModel,
    searchParamsQuery.presetId,
    searchParamsQuery.filters,
    searchParamsQuery.pagination,
    searchParamsQuery.sort
  ]);

  const handleRowClick = ({ id, row }: GridRowParams, event: MuiEvent<MouseEvent>) => {
    if (onRowClick) {
      onRowClick({ rowId: id as string, row, event });
    }
  };

  const handleCellClick = ({ id, row, field }: GridCellParams, event: MuiEvent<MouseEvent>) => {
    if (onCellClick) {
      onCellClick({ rowId: id as string, row, field, event });
    }
  };

  const onSettingsChange = useCallback(
    ({
      filterModel,
      pinnedColumns: updatedPinnedColumns,
      columnVisibilityModel: updatedColumnVisibilityModel,
      columnOrders: updatedColumnOrders,
      paginationModel: updatedPaginationModel,
      sortModel: updatedSortModel,
      selectedPresetId
    }: {
      filterModel: DataGridFilterModel;
      pinnedColumns: GridPinnedColumns;
      columnVisibilityModel: GridColumnVisibilityModel;
      paginationModel: GridPaginationModel;
      sortModel: GridSortModel;
      selectedPresetId: number;
      columnOrders: string[];
    }) => {
      setCurrentPresetId(selectedPresetId);
      setPagination(updatedPaginationModel);
      setSortModelSettings(updatedSortModel);
      setListFilterModel(filterModel);
      setLoaded(false);

      const search = {
        presetId: selectedPresetId || null,
        pinnedColumns: attempt(() => window.btoa(JSON.stringify(updatedPinnedColumns))),
        columnVisibilityModel: attempt(() =>
          window.btoa(JSON.stringify(updatedColumnVisibilityModel))
        ),
        columnOrders: attempt(() => window.btoa(JSON.stringify(updatedColumnOrders))),
        filters: attempt(() => window.btoa(JSON.stringify(filterModel))),
        pagination: attempt(() => window.btoa(JSON.stringify(updatedPaginationModel))),
        sort: attempt(() => window.btoa(JSON.stringify(updatedSortModel)))
      };

      setLoaded(true);
      navigate({ search: qs.stringify(search, { arrayFormat: "comma" }) }, { replace: true });
    },
    [searchParamsQuery, navigate]
  );

  const onUpdatePreset = ({
    presetId,
    presetName,
    filterModel,
    columnOrders,
    columnVisibilityModel,
    paginationModel,
    pinnedColumns,
    sortModel
  }: {
    presetId: string;
    presetName: string;
    filterModel: GridFilterModel;
    columnOrders: string[];
    columnVisibilityModel: GridColumnVisibilityModel;
    paginationModel: GridPaginationModel;
    pinnedColumns: GridPinnedColumns;
    sortModel: GridSortModel;
  }) => {
    const presetColumns = adjustPresetColumns({
      columnVisibilityModel,
      pinnedColumns,
      columnOrders
    });

    const presetFilters = adjustPresetFilters({
      filterModel
    });

    return updateListPreset({
      variables: {
        presetId,
        presetColumns,
        presetFilters,
        pageSize: paginationModel.pageSize,
        presetName,
        listId,
        order: first(sortModel)?.sort,
        orderBy: first(sortModel)?.field
      }
    })
      .then(response => {
        const updatedPresetId = response.data?.updateListPreset;

        enqueueSnackbar(t(`The list preset has been updated successfully`), {
          variant: "success"
        });

        const search = { presetId: updatedPresetId };
        navigate({ search: qs.stringify(search, { arrayFormat: "comma" }) }, { replace: true });
        refetch();
      })
      .catch(error => {
        const errorAsString = getGraphQLErrorsAsString(error);
        const translatedError = t("Unable to update list preset because {{error}}", {
          error: errorAsString
        });
        enqueueSnackbar(translatedError, {
          variant: "error"
        });
      });
  };

  const onDeletePreset = ({ presetId }) =>
    deleteListPreset({
      variables: {
        presetId
      }
    })
      .then(response => {
        const deletedPresetId = response.data?.deleteListPreset;

        enqueueSnackbar(t(`The list preset has been deleted successfully`), {
          variant: "success"
        });
        if (deletedPresetId === Number(settings?.presetId)) {
          navigate({ search: "" }, { replace: true });
        }

        refetch();
      })
      .catch(error => {
        const errorAsString = getGraphQLErrorsAsString(error);
        const translatedError = t("Unable to delete list preset because {{error}}", {
          error: errorAsString
        });
        enqueueSnackbar(translatedError, {
          variant: "error"
        });
      });

  const onCreateNewPreset = ({
    presetName,
    filterModel,
    columnOrders,
    columnVisibilityModel,
    paginationModel,
    pinnedColumns,
    sortModel
  }: {
    presetName?: string;
    filterModel: GridFilterModel;
    columnOrders: string[];
    columnVisibilityModel: GridColumnVisibilityModel;
    paginationModel: GridPaginationModel;
    pinnedColumns: GridPinnedColumns;
    sortModel: GridSortModel;
  }) => {
    const presetColumns = adjustPresetColumns({
      columnVisibilityModel,
      pinnedColumns,
      columnOrders
    });

    const presetFilters = adjustPresetFilters({
      filterModel
    });

    return createListPreset({
      variables: {
        presetColumns,
        presetFilters,
        pageSize: paginationModel.pageSize,
        presetName,
        listId,
        order: first(sortModel)?.sort,
        orderBy: first(sortModel)?.field
      }
    })
      .then(response => {
        const presetId = response.data?.createListPreset;

        enqueueSnackbar(t(`The list preset has been created successfully`), {
          variant: "success"
        });

        const search = { presetId };
        navigate({ search: qs.stringify(search, { arrayFormat: "comma" }) }, { replace: true });
        refetch();
      })
      .catch(error => {
        const errorAsString = getGraphQLErrorsAsString(error);
        const translatedError = t("Unable to create list preset because {{error}}", {
          error: errorAsString
        });
        enqueueSnackbar(translatedError, {
          variant: "error"
        });
      });
  };

  const handleExportClick = async () => {
    if (exportRows) {
      try {
        const response = await exportRows({
          variables: {
            listId,
            filters: adjustFilterModel({ filterModel: listFilterModel }),
            order: first(sortModelSettings)?.sort,
            orderBy: first(sortModelSettings)?.field,
            visibleColumnOrders: filter(
              settings.columnOrders,
              column => settings.columnVisibilityModel[column] === true
            ),
            ...lazyQueryVariables
          }
        });

        enqueueSnackbar(t("CSV file has been generated, the download should start shortly"), {
          variant: "success"
        });

        const downloadUrl = values(response.data)[0];

        if (!downloadUrl) {
          throw new Error(t("Unable to get the download URL"));
        }

        return await Promise.resolve(downloadFile({ url: downloadUrl })).catch(downloadError => {
          enqueueSnackbar(t("Unable to download file: {{error}}", { error: downloadError.code }), {
            variant: "error"
          });
        });
      } catch (errorExport) {
        const errorAsString = getGraphQLErrorsAsString(errorExport);
        const translatedError = t("Unable to export because {{error}}", { error: errorAsString });
        enqueueSnackbar(translatedError, {
          variant: "error"
        });
      }
    }

    return false;
  };

  useEffect(() => {
    if (isLoaded) {
      getRows();
    }
  }, [isLoaded, triggerRefetch]);

  if (getRowsError) {
    enqueueSnackbar(getRowsError.message, { variant: "error", preventDuplicate: true });

    return null;
  }

  if (listLoading) {
    return <PageLoader />;
  }

  return (
    <DataGrid
      onUpdatePreset={onUpdatePreset}
      onCreateNewPreset={onCreateNewPreset}
      onDeletePreset={onDeletePreset}
      settings={settings}
      loading={listLoading}
      dataLoading={dataLoading}
      rows={rows}
      getRowId={getRowId}
      listSettings={baseListSettings}
      onRowClick={handleRowClick}
      onCellClick={handleCellClick}
      onSettingsChange={onSettingsChange}
      totalRowCount={rowCount}
      handleGetAgents={handleGetAgents}
      disableRowSelectionOnClick
      backgroundColor={backgroundColor}
      isMultiRowSelectionAllowed={isMultiRowSelectionAllowed}
      renderCellDataProps={renderCellDataProps}
      onExportClick={handleExportClick}
      isExporting={isExporting}
      toolbarButtons={toolbarButtons}
      onRowSelectedChange={onRowSelectedChange}
    />
  );
}
