import React, { useState, ElementType } from "react";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import isFloat from "validator/lib/isFloat";
import isNumneric from "validator/lib/isNumeric";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { InputBaseComponentProps } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import { OverridableTextFieldProps } from "../../../types/mui";
import NumberFormat from "../../NumberFormat/NumberFormat";

export default function Step2Form({
  personalPointValue,
  personalRecruit,
  teamRecruit: initTeamRecruit,
  teamPointValue: initTeamPointValue,
  acknowledgeChecked: initAcknowledgeChecked,
  onChange
}: {
  personalPointValue: number;
  personalRecruit: number;
  teamRecruit?: number;
  teamPointValue?: number;
  acknowledgeChecked?: boolean;
  onChange: ({
    values: { teamPointValue, teamRecruit, acknowledgeChecked },
    hasError
  }: {
    values: { teamPointValue: number; teamRecruit: number; acknowledgeChecked: boolean };
    hasError: boolean;
  }) => void;
}) {
  const theme = useTheme();
  const { t } = useTranslation();

  const [teamPointValue, setTeamPointValue] = useState<OverridableTextFieldProps>({
    value: initTeamPointValue || null,
    helperText: t(
      "How many point value that you and the people under your hierarchy will write in 2024? (Life policy: target premium, annuity: approximately 6% of premium)"
    )
  });
  const [teamRecruit, setTeamRecruit] = useState<OverridableTextFieldProps>({
    value: initTeamRecruit || null,
    helperText: t(
      "How many new agents will you and people under your hierarchy successfully recruit in 2024?"
    )
  });
  const [acknowledgeChecked, setAcknowledgeChecked] = useState(initAcknowledgeChecked || false);

  const isTeamPointValueValid = (newTeamPointValue, newTeamRecruitValue) =>
    (isEmpty(newTeamRecruitValue) && isEmpty(newTeamPointValue)) ||
    (newTeamPointValue &&
      parseInt(newTeamPointValue, 10) >= personalPointValue &&
      isFloat(newTeamPointValue) &&
      /^[+]?\d+([.]\d+)?$/.test(newTeamPointValue));

  const isTeamRecruitValid = (newTeamPointValue, newTeamRecruitValue) =>
    (isEmpty(newTeamRecruitValue) && isEmpty(newTeamPointValue)) ||
    (newTeamRecruitValue &&
      parseInt(newTeamRecruitValue, 10) >= personalRecruit &&
      isNumneric(newTeamRecruitValue) &&
      /^[0-9]*$/.test(newTeamRecruitValue));

  const triggerChange = changes => {
    const values = {
      teamPointValue: teamPointValue.value,
      teamRecruit: teamRecruit.value,
      acknowledgeChecked,
      ...changes
    };
    const isTeamPointValueHasError = !isTeamPointValueValid(
      values.teamPointValue,
      values.teamRecruit
    );
    setTeamPointValue({
      value: values.teamPointValue,
      error: isTeamPointValueHasError,
      helperText: isTeamPointValueHasError
        ? t(
            "Invalid Team point value. It must be a positive number and greater than or equal to Personal point value ({{personalPointValue}})",
            { personalPointValue }
          )
        : t(
            "How many point value that you and the people under your hierarchy will write in 2024? (Life policy: target premium, annuity: approximately 6% of premium)"
          )
    });
    const isTeamRecruitHasError = !isTeamRecruitValid(values.teamPointValue, values.teamRecruit);
    setTeamRecruit({
      value: values.teamRecruit,
      error: isTeamRecruitHasError,
      helperText: isTeamRecruitHasError
        ? t(
            "Invalid Team Recruit. It must be a positive number and greater than or equal to Personal recruit ({{personalRecruit}})",
            { personalRecruit }
          )
        : t(
            "How many new agents will you and people under your hierarchy successfully recruit in 2024?"
          )
    });

    onChange({
      values,
      hasError: isTeamPointValueHasError || isTeamRecruitHasError || !values.acknowledgeChecked
    });
  };

  const onTeamPointValueChange = event => {
    triggerChange({ teamPointValue: event.target.value });
  };

  const onTeamRecruitChange = event => {
    triggerChange({ teamRecruit: event.target.value });
  };
  const onCheckboxChange = event => {
    setAcknowledgeChecked(event.target.checked);
    triggerChange({ acknowledgeChecked: event.target.checked });
  };

  return (
    <>
      <TextField
        id="teamPointValue"
        margin="dense"
        variant="outlined"
        label={t("Team Paid Point Value")}
        fullWidth
        required
        onChange={onTeamPointValueChange}
        InputProps={{
          inputProps: { decimalScale: 0 },
          inputComponent: NumberFormat as ElementType<InputBaseComponentProps>
        }}
        sx={{ marginTop: theme.spacing(2.5) }}
        {...teamPointValue}
        autoFocus
      />

      <TextField
        id="teamRecruit"
        margin="dense"
        variant="outlined"
        label={t("Team Successful Recruit")}
        fullWidth
        required
        onChange={onTeamRecruitChange}
        InputProps={{
          inputProps: { decimalScale: 0 },
          inputComponent: NumberFormat as ElementType<InputBaseComponentProps>
        }}
        sx={{ marginTop: theme.spacing(2.5) }}
        {...teamRecruit}
      />

      <FormControlLabel
        sx={{
          marginTop: theme.spacing(2),
          marginLeft: theme.spacing(0.5),
          paddingRight: theme.spacing(0.5)
        }}
        control={
          <Checkbox checked={acknowledgeChecked} onChange={onCheckboxChange} color="primary" />
        }
        label={
          <Typography fontSize={theme.spacing(1.7)} sx={{ color: theme.palette.grey[750] }}>
            {t("I acknowledge that commitments can not be changed after submitting.")}
          </Typography>
        }
      />
    </>
  );
}
