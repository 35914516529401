import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import { IconButton, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import ClearIcon from "@mui/icons-material/Clear";
import AgentSelectForDialog from "../../../../../../components/AgentSelect/AgentSelectForDialog";
import { UserCompanyRole, useGetAgentsByIdsLazyQuery } from "../../../../../../generated/graphql";
import AgentInfo from "./AgentInfo";

export default function TrainerAndAssistantForm({
  instructorIds,
  onAddInstructorId,
  onRemoveInstructorId,
  assistantIds,
  onAddAssistantId,
  onRemoveAssistantId,
  isTrainingManager
}: {
  instructorIds: string[] | [];
  onAddInstructorId: (instructorId: string) => void;
  onRemoveInstructorId: (instructorId: string) => void;
  assistantIds: string[] | [];
  onAddAssistantId: (assistantId: string) => void;
  onRemoveAssistantId: (assistantId: string) => void;
  isTrainingManager: boolean;
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [getInstructorAgents, { data: instructorAgentData }] = useGetAgentsByIdsLazyQuery();
  const [getAssistantAgents, { data: assistantAgentData }] = useGetAgentsByIdsLazyQuery();

  const instructors = useMemo(() => {
    if (!isEmpty(instructorIds)) {
      getInstructorAgents({
        variables: {
          ids: instructorIds
        }
      });
    }
    return instructorAgentData?.getAgentsByIds as UserCompanyRole[];
  }, [instructorIds, instructorAgentData]);

  const assistants = useMemo(() => {
    if (!isEmpty(assistantIds)) {
      getAssistantAgents({
        variables: {
          ids: assistantIds
        }
      });
    }
    return assistantAgentData?.getAgentsByIds as UserCompanyRole[];
  }, [assistantIds, assistantAgentData]);

  return (
    <Grid container spacing={1}>
      {isTrainingManager && (
        <Grid item xs={12} md={6}>
          <Typography variant="h7">{t("Trainers")}</Typography>
          <AgentSelectForDialog
            sx={{
              backgroundColor: theme.palette.common.white,
              marginBottom: theme.spacing(1),
              marginTop: theme.spacing(2)
            }}
            isActive
            isBaseshopRequired={false}
            onAction={instructorId => onAddInstructorId(instructorId)}
            hasAction
            isTakingActionOnRowClick
            excludeUserCompanyRoleIds={instructorIds.concat(assistantIds as [])}
            label={t("Select trainer")}
          />
          <Box sx={{ marginTop: theme.spacing(1) }}>
            {!isEmpty(instructorIds) &&
              map(instructors, instructor => (
                <Box key={instructor?.id} sx={{ display: "flex", alignItems: "center" }}>
                  <AgentInfo agent={instructor} />
                  <IconButton
                    onClick={() => onRemoveInstructorId(instructor?.id)}
                    sx={{ marginLeft: "auto" }}
                  >
                    <ClearIcon
                      sx={{
                        color: theme.palette.error.main,
                        [theme.breakpoints.down("sm")]: {
                          height: theme.spacing(3),
                          width: theme.spacing(3)
                        }
                      }}
                    />
                  </IconButton>
                </Box>
              ))}
          </Box>
        </Grid>
      )}
      <Grid item xs={12} md={isTrainingManager ? 6 : 12}>
        <Typography variant="h7">{t("Assistants")}</Typography>
        <AgentSelectForDialog
          sx={{
            backgroundColor: theme.palette.common.white,
            marginBottom: theme.spacing(1),
            marginTop: theme.spacing(2)
          }}
          isActive
          isBaseshopRequired={false}
          onAction={assistantId => onAddAssistantId(assistantId)}
          hasAction
          isTakingActionOnRowClick
          excludeUserCompanyRoleIds={assistantIds.concat(instructorIds as [])}
          label={t("Select assistant")}
        />
        <Box sx={{ marginTop: theme.spacing(1) }}>
          {!isEmpty(assistantIds) &&
            map(assistants, assistant => (
              <Box key={assistant?.id} sx={{ display: "flex", alignItems: "center" }}>
                <AgentInfo agent={assistant} />
                <IconButton
                  onClick={() => onRemoveAssistantId(assistant.id)}
                  sx={{ marginLeft: "auto" }}
                >
                  <ClearIcon
                    sx={{
                      color: theme.palette.error.main,
                      [theme.breakpoints.down("sm")]: {
                        height: theme.spacing(3),
                        width: theme.spacing(3)
                      }
                    }}
                  />
                </IconButton>
              </Box>
            ))}
        </Box>
      </Grid>
    </Grid>
  );
}
