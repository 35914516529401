import {
  GridColumnsPanelProps,
  GridPanel,
  GridPanelWrapper,
  useGridRootProps
} from "@mui/x-data-grid-pro";
import React from "react";
import DataGridColumnsManagement from "./DataGridColumnsManagement";

interface CustomGridColumnsPanelProps extends GridColumnsPanelProps {
  anchorEl: HTMLElement | null;
}

export default function DataGridColumnsPanel({ anchorEl, ...props }: CustomGridColumnsPanelProps) {
  const rootProps = useGridRootProps();

  return (
    <GridPanel
      sx={{ ".MuiPaper-root": { borderRadius: 5 } }}
      anchorEl={anchorEl}
      placement="bottom-start"
      open
    >
      <GridPanelWrapper {...props}>
        <DataGridColumnsManagement {...rootProps.slotProps?.columnsManagement} />
      </GridPanelWrapper>
    </GridPanel>
  );
}
