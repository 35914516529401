import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import qs from "query-string";
import React, { useState } from "react";
import SupportIcon from "@mui/icons-material/Support";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Avatar, Typography, useTheme } from "@mui/material";
import IssueReportModal from "../IssueReportModal/IssueReportModal";
import { useAuth } from "../../contexts/auth";

export default function Header() {
  const { t } = useTranslation();
  const location = useLocation();
  const theme = useTheme();
  const auth = useAuth();
  const [openNeedHelp, setOpenNeedHelp] = useState(false);
  const searchParams = qs.parse(location.search, {
    parseNumbers: true
  });

  const onBackButtonClick = () => {
    if (window.history.length > 1 || searchParams.from === "ams") {
      return window.history.back();
    }
    return window.close();
  };

  let text = "Close";
  let Icon = CloseIcon;

  if (window.history.length > 1) {
    text = "Back";
    Icon = ArrowBackIcon;
  } else if (searchParams.from === "ams") {
    text = "Back to AMS";
    Icon = ArrowBackIcon;
  }
  const onNeedHelpOpen = () => {
    setOpenNeedHelp(true);
  };

  const onNeedHelpClose = () => {
    setOpenNeedHelp(false);
  };

  return (
    <Box
      sx={{
        height: theme.spacing(10),
        width: "100%",
        backgroundColor: theme.palette.common.white
      }}
    >
      <Box
        sx={{
          display: "flex",
          height: "100%",
          alignItems: "center",
          maxWidth: {
            xl: `calc(100% - ${theme.spacing(10)})`,
            lg: "lg",
            md: "md",
            sm: "sm"
          },
          margin: "0 auto",
          justifyContent: "space-between"
        }}
      >
        <Box>
          <Button
            startIcon={<Icon />}
            sx={{
              color: theme.palette.common.black
            }}
            onClick={onBackButtonClick}
          >
            {t(text)}
          </Button>
        </Box>
        <Box sx={{ display: "flex", gap: theme.spacing(2.5) }}>
          <Button
            color="primary"
            variant="contained"
            startIcon={<SupportIcon />}
            onClick={onNeedHelpOpen}
          >
            {t("Need Help?")}
          </Button>
          <Box sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}>
            <Avatar
              alt={`${auth.userCompanyRole?.name} avatar`}
              src={auth.userCompanyRole?.avatarUrl as string}
              sx={{
                height: theme.spacing(6),
                width: theme.spacing(6),
                border: `2px solid ${theme.palette.orange[90]}`
              }}
            />
            <Typography variant="h7">{auth?.userCompanyRole?.name}</Typography>
          </Box>
        </Box>
      </Box>
      {openNeedHelp && <IssueReportModal open={openNeedHelp} onClose={onNeedHelpClose} />}
    </Box>
  );
}
