import React from "react";
import { Grid, GridProps, useTheme } from "@mui/material";

export default function CenteredLayout({ children, ...props }: GridProps) {
  const theme = useTheme();
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "100vh", backgroundColor: theme.palette.grey[100] }}
      {...props}
    >
      <Grid item>{children}</Grid>
    </Grid>
  );
}
