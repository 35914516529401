import { Box, useTheme } from "@mui/material";
import React from "react";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";

interface StatusWithIconProps {
  status: string;
  text?: string;
  showText?: boolean;
  message?: string;
  showMessageInline?: boolean;
}

export default function StatusWithIcon({
  status,
  text,
  showText = true,
  message,
  showMessageInline = false
}: StatusWithIconProps) {
  const statusText = text || status;
  const theme = useTheme();
  const STATUS_ICON = {
    error: (
      <ErrorOutlineOutlinedIcon
        fontSize="small"
        sx={{ display: "inline", height: theme.spacing(1.75) }}
      />
    ),
    warning: (
      <WarningAmberOutlinedIcon
        fontSize="small"
        sx={{ display: "inline", height: theme.spacing(1.75) }}
      />
    ),
    success: (
      <CheckCircleOutlineOutlinedIcon
        fontSize="small"
        sx={{ display: "inline", height: theme.spacing(1.75) }}
      />
    )
  };
  const foundStatus = STATUS_ICON[status];

  return (
    <Box
      sx={{
        whiteSpace: "nowrap",
        "&.warning": {
          color: theme.palette.warning.main
        },
        "&.onHold": {
          color: theme.palette.warning.main
        },
        "&.error": {
          color: theme.palette.error.main
        },
        "&.success": {
          color: theme.palette.success.dark
        }
      }}
      title={!showMessageInline ? message : undefined}
      component="span"
    >
      {foundStatus}
      {showText && (
        <span
          style={{
            display: "inline-block",
            marginRight: theme.spacing(1),
            textTransform: "capitalize",
            paddingLeft: theme.spacing(0.5)
          }}
        >
          {statusText}
        </span>
      )}
      {showMessageInline && message && <span style={{ whiteSpace: "initial" }}>{message}</span>}
    </Box>
  );
}
