import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import React from "react";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import truncate from "lodash/truncate";
import { useTranslation } from "react-i18next";
import { find } from "lodash";
import { AgentLevel, UserCompanyRole } from "../../../../../../generated/graphql";
import { useAuth } from "../../../../../../contexts/auth";

export default function AgentInfo({ agent }: { agent?: UserCompanyRole | undefined }) {
  const theme = useTheme();
  const { config } = useAuth();
  const { t } = useTranslation();
  const agentLevels = config?.company?.agentLevels;
  const agentLevel = find(agentLevels, { id: agent?.agentLevelId }) as AgentLevel;

  const isXlAndDown = useMediaQuery(theme.breakpoints.down("xl"));
  const isXsScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        flexBasis: 1,
        padding: theme.spacing(1, 0)
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center"
        }}
      >
        {agent?.avatarUrl && (
          <Avatar
            src={agent?.avatarUrl}
            sx={{
              height: theme.spacing(4),
              width: theme.spacing(4),
              marginRight: theme.spacing(1)
            }}
          />
        )}
        <Typography
          variant="h7"
          sx={{
            color: theme.palette.grey[50],
            marginRight: theme.spacing(isXlAndDown ? 1.25 : 2.5),
            fontWeight: 500
          }}
        >
          {agent?.name && truncate(agent?.name, { length: isXsScreen ? 10 : 18 })}
        </Typography>
        <Typography variant="body5" sx={{ color: theme.palette.grey[70] }}>
          {t(`${agent?.agentCode} - ${agentLevel?.level}`)}
        </Typography>
      </Box>
    </Box>
  );
}
