import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import Box, { BoxProps } from "@mui/material/Box";
import SearchIcon from "@mui/icons-material/Search";
import keycode from "keycode";
import get from "lodash/get";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useTranslation } from "react-i18next";
import { FilterQueryInput } from "../../generated/graphql";
import OrderPopup from "./OrderPopup";

interface SearchInputProps extends BoxProps {
  onSortChange?: (p: { orderBy: string; order: string }) => void;
  onFilterValueChange: (p: { text?: FilterQueryInput }) => void;
  filterValues: { text: FilterQueryInput } | null;
  isSortable?: boolean;
  placeholder?: string;
  isSmallSearch?: boolean;
}

export default function SearchInput({
  onSortChange,
  onFilterValueChange,
  filterValues,
  isSortable = true,
  placeholder,
  isSmallSearch = false,
  ...props
}: SearchInputProps) {
  const { t } = useTranslation();
  const [text, setText] = useState<string>(get(filterValues, "text.query", ""));
  const onSearchChange = event => {
    setText(event.target.value);
  };
  const onSearchEnter = event => {
    if (keycode.isEventKey(event, "enter")) {
      onFilterValueChange({
        text: {
          operatorTypeId: "contains",
          query: text
        }
      });
    }
  };

  const onSearchClick = () => {
    onFilterValueChange({
      text: {
        operatorTypeId: "contains",
        query: text
      }
    });
  };
  const onClearClick = () => {
    setText("");
    onFilterValueChange({});
  };

  return (
    <Box
      sx={{
        display: "flex",
        width: () => ({
          xs: "100%",
          md: "auto"
        }),
        ...props.sx
      }}
      {...props}
    >
      <Box
        className="searchBox-wrapper"
        sx={{
          p: theme => (isSmallSearch ? 0 : theme.spacing(1.25, 0, 1, 0)),
          height: isSmallSearch ? "80%" : "100%",
          display: "flex",
          alignItems: "center",
          width: theme => ({
            xs: "100%",
            md: isSmallSearch ? theme.spacing(35) : theme.spacing(45)
          }),
          borderRadius: theme => theme.spacing(1),
          border: theme => `1px solid ${theme.palette.grey[isSmallSearch ? 90 : 60]}`,
          textAlign: "center"
        }}
      >
        <IconButton
          type="button"
          sx={{
            p: theme => theme.spacing(1.25)
          }}
          aria-label={t("search")}
          onClick={onSearchClick}
        >
          <SearchIcon sx={{ color: theme => theme.palette.common.black }} />
        </IconButton>
        <InputBase
          sx={{
            letterSpacing: theme => theme.spacing(0.0625),
            ".MuiInputBase-input": {
              color: theme => theme.palette.grey[10]
            },
            fontFamily: "Montserrat",
            fontSize: theme => theme.typography.pxToRem(14),
            fontWeight: 400,
            lineHeight: "160%",
            color: theme => theme.palette.grey[10]
          }}
          placeholder={placeholder || t("Search")}
          inputProps={{ "aria-label": t("Search") }}
          onChange={onSearchChange}
          onKeyUp={onSearchEnter}
          value={text}
          fullWidth
        />
        {text !== "" && (
          <IconButton
            type="button"
            aria-label={t("clear")}
            sx={{
              p: theme => theme.spacing(1.25)
            }}
            onClick={onClearClick}
          >
            <HighlightOffIcon sx={{ color: theme => theme.palette.common.black }} />
          </IconButton>
        )}
      </Box>
      {isSortable && onSortChange && <OrderPopup onSortChange={onSortChange} />}
    </Box>
  );
}
