import percentageValueFormatter from "./formatters/percentage";
import dateValueFormatter from "./formatters/date";
import currencyValueFormatter from "./formatters/currency";
import productTypeValueFormatter from "./formatters/productType";
import productValueFormatter from "./formatters/product";
import numberValueFormatter from "./formatters/number";

export default {
  percentage: percentageValueFormatter,
  currency: currencyValueFormatter,
  number: numberValueFormatter,
  date: dateValueFormatter,
  customerName: () => value => value.name,
  productType: productTypeValueFormatter,
  product: productValueFormatter
};
