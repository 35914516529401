import React, { MouseEvent } from "react";
import { MuiEvent } from "@mui/x-data-grid-pro";
import { useGetPoliciesLazyQuery, useExportPoliciesMutation } from "../../../generated/graphql";
import ListingDataGrid from "../../../components/ListingDataGrid/ListingDataGrid";
import FullLayout from "../../../layouts/FullLayout";
import handleAMSLinkClick from "../../../libs/handleAMSLinkClick";

export default function PolicyListingPage() {
  const onRowClick = ({
    rowId: policyId,
    event
  }: {
    rowId: string;
    event: MuiEvent<MouseEvent>;
  }) => {
    handleAMSLinkClick({ url: `/policies/${policyId}/details`, event });
  };

  return (
    <FullLayout
      sx={{
        pl: { xs: 2.5, md: 5 },
        pr: { xs: 0, md: 5 },
        overflow: "clip",
        ".header-container": {
          pr: { xs: 2.5, md: 0 }
        }
      }}
      includePadding={false}
    >
      <ListingDataGrid
        useGetRowsLazyQuery={useGetPoliciesLazyQuery}
        useExportRowsMutation={useExportPoliciesMutation}
        onRowClick={onRowClick}
        query="getPoliciesV2"
        listId="1"
      />
    </FullLayout>
  );
}
