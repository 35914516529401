import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import React from "react";
import { useTheme } from "@mui/material/styles";
import { Button, useMediaQuery } from "@mui/material";
import { find } from "lodash";
import { useTranslation } from "react-i18next";
import truncate from "lodash/truncate";
import { AgentLevel, UserCompanyRole } from "../../generated/graphql";
import { useAuth } from "../../contexts/auth";

export default function UserInfo({
  option,
  hasAction,
  onAction,
  onCloseDropdown,
  actionText,
  startIcon: StartIcon,
  isTakingActionOnRowClick = false,
  ...props
}: {
  option: UserCompanyRole;
  hasAction?: boolean;
  actionText?: string;
  startIcon?: React.ElementType;
  isTakingActionOnRowClick?: boolean;
  onCloseDropdown: () => void;
  onAction?: (agentId: string) => void;
}) {
  const theme = useTheme();
  const { t } = useTranslation();
  const { config } = useAuth();
  const agentLevels = config?.company?.agentLevels;
  const agentLevel = find(agentLevels, { id: option.agentLevelId }) as AgentLevel;
  const onActionClick = () => {
    if (onAction) {
      onAction(option.id);
    }
    return onCloseDropdown();
  };
  const isXlAndDown = useMediaQuery(theme.breakpoints.down("xl"));
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        padding: hasAction ? theme.spacing(1, 2) : theme.spacing(2, 0),
        cursor: "pointer",
        "&:hover": {
          backgroundColor: theme.palette.action.selected
        }
      }}
      {...(hasAction ? { onClick: isTakingActionOnRowClick ? onActionClick : undefined } : props)}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center"
        }}
      >
        {option.avatarUrl && (
          <Avatar
            src={option.avatarUrl}
            sx={{
              height: theme.spacing(4),
              width: theme.spacing(4),
              marginRight: theme.spacing(1)
            }}
          />
        )}
        <Typography
          variant="h7"
          sx={{
            color: theme.palette.grey[50],
            marginRight: theme.spacing(isXlAndDown ? 1.25 : 2.5),
            fontWeight: 500
          }}
        >
          {option?.name && truncate(option?.name, { length: 20 })}
        </Typography>
        <Typography variant="body5" sx={{ color: theme.palette.grey[70] }}>
          {`${option.agentCode}`}
        </Typography>
        {agentLevel?.level && (
          <Typography variant="body5" sx={{ color: theme.palette.grey[70] }}>
            {` - ${agentLevel?.level}`}
          </Typography>
        )}
      </Box>
      {hasAction && !isTakingActionOnRowClick && (
        <Button
          variant="contained"
          disabled={false}
          size="medium"
          sx={{ mt: 1, mb: 1, ml: 3, padding: theme.spacing(0.5, 4) }}
          startIcon={StartIcon && <StartIcon />}
          onClick={onActionClick}
        >
          {t("{{buttonText}}", { buttonText: actionText })}
        </Button>
      )}
    </Box>
  );
}
