import React, { useState, useEffect, ReactNode } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import get from "lodash/get";
import upperCase from "lodash/upperCase";
import map from "lodash/map";
import { SxProps, Theme } from "@mui/material";
import { useAuth } from "../../contexts/auth";
import { SYSTEM_FEATURE_ID_AGENT_LEVELS } from "../../consts";
import {
  AgentLevel,
  Maybe,
  SystemFeature,
  UserCompanyRole,
  WorkflowItemFieldValueAgentPicker,
  useGetUsersLazyQuery
} from "../../generated/graphql";
import UserInfo from "./UserInfo";
import hasSystemFeatures from "../../libs/hasSystemFeatures";

interface AgentSelectProps {
  label: string;
  helperText?: string | ReactNode;
  onChange?: (arg0: UserCompanyRole) => void;
  disabled?: boolean;
  required?: boolean;
  value?: WorkflowItemFieldValueAgentPicker | null;
  error?: boolean;
  excludeUserCompanyRoleIds?: string[];
  isBaseshopRequired?: boolean;
  isOnlyQualifiedCertified?: boolean;
  isActive: boolean;
  hasAction?: boolean;
  onAction?: (agentId: string) => void;
  actionText?: string;
  startIcon?: React.ElementType;
  isTakingActionOnRowClick?: boolean;
  sx: SxProps<Theme>;
}

export default function AgentSelectForDialog({
  label,
  helperText,
  onChange = () => {},
  disabled,
  required,
  value: initialValue,
  error,
  excludeUserCompanyRoleIds,
  isBaseshopRequired,
  isOnlyQualifiedCertified,
  isActive,
  hasAction,
  onAction,
  actionText,
  startIcon: StartIcon,
  isTakingActionOnRowClick,
  sx
}: AgentSelectProps) {
  const { t } = useTranslation();
  const auth = useAuth();

  const agentLevels: Maybe<Maybe<AgentLevel>[]> = auth?.config?.company?.agentLevels ?? [];
  const systemFeatures: Maybe<Maybe<SystemFeature>[]> = auth?.config?.company?.systemFeatures ?? [];

  const [value, setValue] = useState<UserCompanyRole | null>(initialValue as UserCompanyRole);
  const [inputValue, setInputValue] = useState<string>("");
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<UserCompanyRole[]>([]);
  const [getUsers] = useGetUsersLazyQuery({
    variables: {
      page: 0,
      rowsPerPage: 6,
      order: "asc",
      orderBy: "name",
      excludeUserCompanyRoleIds,
      isBaseshopRequired,
      isOnlyQualifiedCertified,
      query: inputValue
    },
    fetchPolicy: "network-only",
    errorPolicy: "all"
  });

  useEffect(() => {
    if (initialValue) {
      setValue(initialValue as UserCompanyRole);
    }
  }, [initialValue]);

  const isAgentLevelsFeatureAllowed: boolean = hasSystemFeatures({
    systemFeatures,
    requiredSystemFeatureIds: [SYSTEM_FEATURE_ID_AGENT_LEVELS]
  });
  useEffect(() => {
    let active = true;
    const getData = async () => {
      const data = await getUsers();
      const results = get(data, "data.getAssociatedUsers.rows", []);

      if (active) {
        let newOptions: UserCompanyRole[] = [];

        if (results) {
          newOptions = [...newOptions, ...results];
        }
        const displayOptions = map(newOptions, option => ({
          id: option.id,
          name: option.name,
          email: option.email,
          phoneNumber: option.phoneNumber || "",
          avatarUrl: option.avatarUrl,
          agentCode: option.agentCode || "",
          agentLevel: option.agentLevelId ? get(agentLevels[option.agentLevelId], "level") : "",
          agentLevelId: option.agentLevelId,
          status: option.status || ""
        }));

        setOptions(displayOptions);
      }
    };
    getData();
    return () => {
      active = false;
    };
  }, [value, inputValue, agentLevels, excludeUserCompanyRoleIds]);

  const getOptionLabel = (option: UserCompanyRole) => {
    if (!option) {
      return "";
    }
    const agentCode = option.agentCode ? `${option.agentCode} - ` : "";
    const agentLevel =
      isAgentLevelsFeatureAllowed && option.agentLevel ? ` - ${option.agentLevel}` : "";
    const phoneNumber = option.phoneNumber ? ` - ${option.phoneNumber}` : "";
    const email = option.email ? ` - ${option.email}` : "";
    const status = option.status ? ` - ${upperCase(option.status)}` : "";

    return typeof option === "string"
      ? option
      : `${agentCode}${option.name}${agentLevel}${phoneNumber}${email}${status}`;
  };
  const onACChange = (event, newValue: UserCompanyRole | null) => {
    if (newValue) {
      const simplifiedValue: UserCompanyRole = {
        id: newValue.id,
        name: newValue.name,
        email: newValue.email,
        phoneNumber: newValue.phoneNumber,
        avatarUrl: newValue.avatarUrl,
        agentCode: newValue.agentCode,
        agentLevel: newValue.agentLevelId
          ? (get(agentLevels[newValue.agentLevelId], "level") as AgentLevel | undefined)
          : null,
        agentLevelId: newValue.agentLevelId,
        status: newValue.status
      };
      setOptions(newValue ? [simplifiedValue, ...options] : options);
      setValue(simplifiedValue as UserCompanyRole);
      onChange(simplifiedValue);
    }
  };
  const onInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };
  const onActionClick = async (agentId: string) => {
    if (onAction) {
      setLoading(true);
      await onAction(agentId);
      setLoading(false);
      setValue(null);
      setInputValue("");
    }
  };
  const onCloseDropdown = () => {
    setOpenDropdown(false);
  };
  const onOpenDropdown = () => setOpenDropdown(true);

  return (
    <Autocomplete
      sx={sx}
      data-testid="agentSelect"
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={(option, initValue) => option.id === initValue.id}
      filterOptions={x => x}
      options={options}
      open={openDropdown}
      onOpen={onOpenDropdown}
      onClose={onCloseDropdown}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      inputValue={inputValue}
      fullWidth
      onChange={onACChange}
      onInputChange={onInputChange}
      disabled={disabled}
      noOptionsText={t("Type something to search")}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          fullWidth
          helperText={helperText}
          value={value}
          required={required}
          error={error}
          autoFocus={isActive}
          disabled={disabled || loading}
        />
      )}
      renderOption={(props, option) =>
        option.name && (
          <UserInfo
            actionText={actionText}
            startIcon={StartIcon}
            hasAction={hasAction}
            onAction={onActionClick}
            onCloseDropdown={onCloseDropdown}
            isTakingActionOnRowClick={isTakingActionOnRowClick}
            option={option}
            {...props}
          />
        )
      }
    />
  );
}
