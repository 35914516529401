import Box from "@mui/material/Box";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import { isEmpty } from "lodash";
import AnnouncementItem from "./AnnouncementItem";
import {
  TrainingClassSessionAnnouncement,
  useGetTrainingClassSessionAnnouncementListQuery
} from "../../../../generated/graphql";

interface AnnouncementListModelProps {
  open: boolean;
  onClose: () => void;
  trainingClassId: string;
  trainingClassSessionId: string;
}

export default function AnnouncementListModel({
  open,
  onClose,
  trainingClassSessionId,
  trainingClassId
}: AnnouncementListModelProps) {
  const theme = useTheme();
  const { t } = useTranslation();
  const { data, refetch, loading } = useGetTrainingClassSessionAnnouncementListQuery({
    variables: { trainingClassSessionId, trainingClassId }
  });

  const announcements = useMemo(
    () =>
      (data?.getTrainingClassSessionAnnouncementList as TrainingClassSessionAnnouncement[]) || [],
    [data]
  );
  useEffect(() => {
    if (open) {
      refetch();
    }
  }, [open]);

  if (loading) {
    return null;
  }

  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: theme.spacing(2.5),
          backgroundColor: theme.palette.backgroundColor.light
        }
      }}
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          backgroundColor: theme.palette.backgroundColor.light,
          padding: theme.spacing(2.5, 2.5, 0, 2.5),
          gap: theme.spacing(1)
        }}
      >
        <Typography variant="h5" sx={{ color: theme.palette.grey[10] }}>
          {t("Announcement(s)")}
        </Typography>
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing(2.5),
          padding: theme.spacing(2.5, "!important"),
          borderRadius: theme.spacing(1.5)
        }}
      >
        <Box
          sx={{
            maxHeight: "90vh",
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(2.5)
          }}
        >
          {isEmpty(announcements) ? (
            <Typography variant="body1">{t("There is no announcement")}</Typography>
          ) : (
            announcements.map(announcement => (
              <AnnouncementItem key={announcement.id} announcement={announcement} />
            ))
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
}
