import keyBy from "lodash/keyBy";
import find from "lodash/find";
import isEmpty from "lodash/isEmpty";
import {
  GridColDef,
  GridColumnVisibilityModel,
  GridPaginationModel,
  GridPinnedColumnFields,
  GridSortModel
} from "@mui/x-data-grid-pro";
import getPresetSettings from "./getPresetSettings";

export interface ListSettings {
  columns: GridColDef[];
  filters: {
    label: string;
    icon: string;
    filterKey: string;
    type: string;
  }[];
  isPresetEnabled?: boolean;
  isExportFunctionEnabled: boolean;
  isDensitySelectionEnabled: boolean;
  listActions: { action: string }[];
  userPresets: {
    id: string;
    name: string;
    pageSize: number;
    order: string;
    orderBy: string;
    isDefault: boolean;
    presetColumns: {
      isPinnedOnLeft: boolean;
      isPinnedOnRight: boolean;
      isVisible: boolean;
      order: number;
      columnId: string;
      column: {
        field: string;
      };
    }[];
    presetFilters: {
      operator: string;
      singleValue?: string;
      multipleValues?: string[];
      isMultipleValues: boolean;
      column: {
        field: string;
      };
    }[];
  }[];
}

export default function setListConfiguration({
  listSettings,
  settings,
  selectedPresetId
}: {
  listSettings: ListSettings;
  settings: {
    presetId: number;
    columnVisibilityModel: GridColumnVisibilityModel;
    pinnedColumns: GridPinnedColumnFields;
    columnOrders: string[];
    paginationModel: GridPaginationModel;
    filterModel: Record<string, { query: string; operator: string }>;
    sortModel: GridSortModel;
  };
  selectedPresetId?: number;
}) {
  const {
    filterModel,
    presetId,
    pinnedColumns,
    columnVisibilityModel,
    columnOrders,
    paginationModel,
    sortModel
  } = settings;

  const { userPresets, columns: availableColumns } = listSettings;
  const defaultPreset = find(userPresets, { isDefault: true });

  const hashedUserPresets = keyBy(userPresets, "id");
  if (
    isEmpty(columnVisibilityModel) &&
    isEmpty(pinnedColumns) &&
    isEmpty(columnOrders) &&
    isEmpty(filterModel) &&
    isEmpty(sortModel)
  ) {
    if (!defaultPreset) {
      return {
        currentPresetId: "",
        paginationModel: {
          page: 0,
          pageSize: 20
        },
        filterModel: {},
        columnOrders: [],
        columnVisibilityModel: {},
        sortModel: [],
        pinnedColumns: { left: [], right: [] }
      };
    }
    return getPresetSettings({
      preset: presetId && hashedUserPresets[presetId] ? hashedUserPresets[presetId] : defaultPreset,
      availableColumns
    });
  }

  if (!presetId || presetId === selectedPresetId) {
    return {
      columnVisibilityModel,
      columnOrders,
      pinnedColumns,
      filterModel,
      paginationModel,
      sortModel,
      currentPresetId: presetId || ""
    };
  }

  return {
    ...getPresetSettings({
      preset: presetId && hashedUserPresets[presetId] ? hashedUserPresets[presetId] : defaultPreset,
      availableColumns
    }),
    paginationModel
  };
}
