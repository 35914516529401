import { Tooltip, alpha, useMediaQuery, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import map from "lodash/map";
import React from "react";
import { t } from "i18next";
import { InfoOutlined } from "@mui/icons-material";
import { ProductionCommitment } from "../../generated/graphql";
import formatNumber from "../../libs/formatter/formatNumber";

export default function ProductionCommitmentSummary({ stats }: { stats: ProductionCommitment[] }) {
  const theme = useTheme();
  const isSMUp = useMediaQuery(theme.breakpoints.up("sm"));

  const categories = {
    personalWrittenPointValue: {
      name: "Total Personal Written PV",
      color: "#C2C600",
      tooltip: t(
        "This shows the total value of all new policies in the Production Breakdowns report, even if they were written before the current reporting period."
      )
    },
    personalPointValue: {
      name: "Total Personal Paid PV",
      color: "#4E9A51",
      tooltip: t(
        `Here are the total points for policies in the "Paid Business" column of the Production Breakdowns report. These points are for policies written between January 1st, 2024, and December 31st, 2024, and they must be issued and paid by February 28th, 2025.`
      )
    },
    personalRecruit: {
      name: "Total Personal Recruit",
      color: "#CF7827"
    },
    teamPointValue: {
      name: "Total Team Paid PV",
      color: "#E92376"
    },
    teamRecruit: {
      name: "Total Team Recruit",
      color: "#1E95D6"
    }
  };

  return (
    <Box>
      <Grid container columnSpacing={2} rowSpacing={1}>
        {map(stats, entry => (
          <Grid key={`${entry.category}-summary`} item xs={12} sm={4} md={2.4}>
            <Box
              sx={{
                display: "flex",
                borderRadius: theme.spacing(2.5),
                boxShadow: `0 0 ${theme.spacing(5)} 0 ${alpha(theme.palette.common.black, 0.04)}`,
                flexDirection: "column",
                p: 2.5,
                gap: 1.5,
                height: "100%"
              }}
            >
              {entry.goal !== 0 && (
                <Box sx={{ display: "flex", alignItems: "center", gap: 1.5 }}>
                  <Typography variant="body2" sx={{ color: categories[entry.category].color }}>
                    {`${Math.round((entry.current / entry.goal) * 100)}%`}
                  </Typography>
                  <LinearProgress
                    sx={{
                      width: "100%",
                      height: theme.spacing(1),
                      borderRadius: theme.spacing(12.5),
                      "&.MuiLinearProgress-root": {
                        backgroundColor: alpha(categories[entry.category].color, 0.24)
                      },
                      "& .MuiLinearProgress-bar": {
                        backgroundColor: categories[entry.category].color,
                        borderRadius: theme.spacing(12.5)
                      }
                    }}
                    variant="determinate"
                    value={entry.goal !== 0 ? (entry.current / entry.goal) * 100 : 0}
                  />
                </Box>
              )}
              <Typography variant="h5" sx={{ color: theme.palette.grey[50] }}>
                {`${formatNumber({ amount: entry.current, format: "0,0", showZero: true })}`}
                {entry.goal !== 0
                  ? ` of ${formatNumber({ amount: entry.goal, format: "0,0", showZero: true })}`
                  : ""}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 1,
                  alignItems: { xs: "left", md: "center" }
                }}
              >
                <Typography variant="body2" sx={{ color: theme.palette.grey[70], fontWeight: 500 }}>
                  {categories[entry.category].name}
                  {categories[entry.category].tooltip ? (
                    <Tooltip
                      enterTouchDelay={0}
                      componentsProps={{
                        tooltip: {
                          sx: {
                            backgroundColor: theme.palette.common.white,
                            color: theme.palette.grey[50],
                            padding: 2.5,
                            borderRadius: 1,
                            boxShadow: `0 0 ${theme.spacing(5)} 0 ${alpha(theme.palette.common.black, 0.04)}`
                          }
                        }
                      }}
                      title={
                        <Typography variant="body3" sx={{ color: theme.palette.grey[50] }}>
                          {categories[entry.category].tooltip}
                        </Typography>
                      }
                      placement={isSMUp ? "right-start" : "bottom"}
                    >
                      <InfoOutlined
                        sx={{
                          width: theme.spacing(2),
                          height: theme.spacing(2),
                          ml: 1,
                          my: "auto",
                          color: theme.palette.grey[50]
                        }}
                      />
                    </Tooltip>
                  ) : null}
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
