import { Theme, ThemeOptions, createTheme } from "@mui/material/styles";
import merge from "lodash/merge";
import { grey, orange } from "./color";

export default function createSharedTheme({
  headingFont = "Arial",
  bodyFont = "sans-serif",
  getOverrideCustomTheme,
  company
}: {
  headingFont?: string;
  bodyFont?: string;
  getOverrideCustomTheme?: (theme: Theme) => object;
  company?: {
    themePalettePrimary: string;
    themePaletteSecondary: string;
    themePaletteError: string;
    themePaletteWarning: string;
    themePaletteInfo: string;
    themePaletteSuccess: string;
    themePaletteTertiary: string;
    themePaletteNeutral: string;
  };
}) {
  const options: ThemeOptions = {
    palette: {
      mode: "light",
      primary: {
        main: company?.themePalettePrimary || orange[50]
      },
      grey,
      orange,
      secondary: {
        main: company?.themePaletteSecondary || "#331200"
      },
      error: {
        main: company?.themePaletteError || "#DC362E"
      },
      warning: {
        main: company?.themePaletteWarning || "#F9FD53"
      },
      info: {
        main: company?.themePaletteInfo || "#1E95D6"
      },
      success: {
        main: company?.themePaletteSuccess || "#4E9A51"
      },
      tertiary: {
        main: company?.themePaletteTertiary || "#FFFFFF"
      },
      neutral: {
        main: company?.themePaletteNeutral || "#323630"
      },
      backgroundColor: {
        main: grey[100],
        dark: grey[50],
        light: "#FBFBFB"
      },
      successBackgroundColor: {
        main: "#4E9A5114",
        light: "#4e9a5114"
      },
      errorBackgroundColor: {
        main: "#DC362E14"
      },
      text: {
        primary: "#331200",
        secondary: "#626760"
      }
    }
  };

  // console.log("🚀 ~ file: createSharedTheme.ts:43 ~ createSharedTheme ~ headingFont:", headingFont)

  let theme = createTheme(options);

  const customTheme = {
    typography: {
      fontFamily: bodyFont,
      lineHeight: "160%",
      h1: {
        fontFamily: headingFont,
        fontWeight: 700,
        fontSize: theme.typography.pxToRem(56),
        lineHeight: "140%",
        [theme.breakpoints.down("sm")]: {
          fontSize: theme.typography.pxToRem(30),
          lineHeight: "160%"
        }
      },
      h2: {
        fontFamily: headingFont,
        fontSize: theme.typography.pxToRem(49),
        lineHeight: theme.typography.pxToRem(72),
        fontWeight: 700
      },
      h3: {
        fontFamily: headingFont,
        fontSize: theme.typography.pxToRem(42),
        lineHeight: theme.typography.pxToRem(42),
        fontWeight: 700,
        [theme.breakpoints.down("sm")]: {
          fontSize: theme.typography.pxToRem(26)
        }
      },
      h4: {
        fontFamily: headingFont,
        fontSize: theme.typography.pxToRem(32),
        fontWeight: 500,
        [theme.breakpoints.down("sm")]: {
          fontSize: theme.typography.pxToRem(24)
        }
      },
      h5: {
        fontFamily: headingFont,
        fontSize: theme.typography.pxToRem(24),
        fontWeight: 500,
        [theme.breakpoints.down("sm")]: {
          fontSize: theme.typography.pxToRem(18)
        }
      },
      h6: {
        fontFamily: headingFont,
        fontSize: theme.typography.pxToRem(21),
        fontWeight: 500,
        [theme.breakpoints.down("sm")]: {
          fontSize: theme.typography.pxToRem(18)
        }
      },
      h7: {
        fontFamily: headingFont,
        fontSize: theme.typography.pxToRem(18),
        fontWeight: 500,
        lineHeight: "160%",
        letterSpacing: theme.typography.pxToRem(0.288),
        [theme.breakpoints.down("sm")]: {
          fontSize: theme.typography.pxToRem(15)
        }
      },
      subtitle3: {
        fontFamily: headingFont,
        fontSize: theme.typography.pxToRem(18),
        fontWeight: 600
      },
      h8: {
        fontFamily: headingFont,
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 500,
        lineHeight: "160%",
        letterSpacing: theme.typography.pxToRem(0.224)
      },
      body1: {
        fontFamily: bodyFont,
        fontSize: theme.typography.pxToRem(21),
        fontWeight: 400,
        [theme.breakpoints.down("sm")]: {
          fontSize: theme.typography.pxToRem(16)
        }
      },
      body2: {
        fontFamily: bodyFont,
        lineHeight: "160%",
        fontSize: theme.typography.pxToRem(18),
        fontWeight: 400,
        [theme.breakpoints.down("sm")]: {
          fontSize: theme.typography.pxToRem(16)
        }
      },
      body3: {
        fontFamily: bodyFont,
        lineHeight: "160%",
        fontSize: theme.typography.pxToRem(16),
        fontWeight: 400,
        [theme.breakpoints.down("sm")]: {
          fontSize: theme.typography.pxToRem(14)
        }
      },
      body4: {
        fontFamily: headingFont,
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 400,
        [theme.breakpoints.down("sm")]: {
          fontSize: theme.typography.pxToRem(12)
        }
      },
      body5: {
        fontFamily: bodyFont,
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 400,
        lineHeight: "160%"
      },
      button: {
        fontFamily: headingFont,
        fontSize: theme.typography.pxToRem(18),
        lineHeight: theme.spacing(3),
        fontWeight: 500,
        [theme.breakpoints.down("sm")]: {
          fontSize: theme.typography.pxToRem(15)
        },
        textTransform: "none"
      },
      state: {
        fontFamily: bodyFont,
        lineHeight: "160%",
        fontSize: theme.typography.pxToRem(21),
        letterSpacing: theme.typography.pxToRem(0.336),
        fontWeight: 400,
        [theme.breakpoints.down("sm")]: {
          letterSpacing: theme.typography.pxToRem(0.208),
          fontSize: theme.typography.pxToRem(13)
        }
      },
      fileContent: {
        fontFamily: bodyFont,
        lineHeight: "160%",
        fontSize: theme.typography.pxToRem(18),
        fontWeight: 400,
        [theme.breakpoints.down("sm")]: {
          letterSpacing: theme.typography.pxToRem(0.348),
          fontSize: theme.typography.pxToRem(14)
        }
      }
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            paddingTop: theme.spacing(1.5),
            paddingBottom: theme.spacing(1.5),
            paddingLeft: theme.spacing(2.5),
            paddingRight: theme.spacing(2.5),
            borderRadius: theme.spacing(12.5),
            [theme.breakpoints.down("sm")]: {
              paddingTop: theme.spacing(1),
              paddingBottom: theme.spacing(1),
              fontSize: theme.typography.pxToRem(13)
            }
          }
        }
      },
      MuiTypography: {
        variants: [
          {
            props: { variant: "body3" }
          },
          {
            props: { variant: "body4" }
          },
          {
            props: { variant: "body5" }
          },
          {
            props: { variant: "h7" }
          },
          {
            props: { variant: "h8" }
          },
          {
            props: { variant: "subtitle3" }
          },
          {
            props: { variant: "state" }
          },
          {
            props: { variant: "fileContent" }
          },
          {
            props: { variant: "dataGridState" }
          }
        ]
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            ".MuiInputBase-root": {
              borderRadius: theme.spacing(1),
              "& fieldset": {
                borderColor: theme.palette.grey[60]
              }
            }
          }
        }
      },
      MuiPickersCalendarHeader: {
        styleOverrides: {
          label: {
            fontSize: theme.typography.pxToRem(18)
          }
        }
      }
    }
  };
  const overrideCustomTheme = getOverrideCustomTheme
    ? merge({}, customTheme, getOverrideCustomTheme(theme))
    : customTheme;

  theme = createTheme(theme, overrideCustomTheme);

  return theme;
}
