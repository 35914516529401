import React from "react";
import Box from "@mui/material/Box";
import { CircularProgress, useTheme } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import FullLayout from "../../layouts/FullLayout";
import PersonalCommitmentList from "../../components/PersonalCommitmentList/PersonalCommitmentList";
import ProductionCommitmentSummary from "../../components/ProductionCommitmentSummary/ProductionCommitmentSummary";
import {
  ProductionCommitment,
  ProductionCommitmentAgentRow,
  useCalculate2024ProductionCommitmentStatsMutation,
  useGetProductionCommitmentStatsQuery,
  useSet2024GoalsMutation
} from "../../generated/graphql";
import ProductionCommitmentDashboardHeader from "../../components/ProductionCommitmentDashboardHeader/ProductionCommitmentDashboardHeader";
import getGraphQLErrorsAsString from "../../libs/graphql/getGraphQLErrorsAsString";

export default function ProductionCommitment2024DashboardPage() {
  const theme = useTheme();
  const { t } = useTranslation();

  const [calculate2024ProductionCommitmentStats, { loading: recalculateLoading }] =
    useCalculate2024ProductionCommitmentStatsMutation();
  const { data, loading, error, refetch } = useGetProductionCommitmentStatsQuery({
    fetchPolicy: "network-only",
    errorPolicy: "all"
  });
  const [set2024Goals, { loading: isUpdating }] = useSet2024GoalsMutation();

  const companyStats = data?.getProductionCommitmentStats?.companyStats as ProductionCommitment[];
  const agentStats = data?.getProductionCommitmentStats
    ?.agentStats as ProductionCommitmentAgentRow[];

  const handleRefreshClick = () =>
    calculate2024ProductionCommitmentStats()
      .then(() => {
        enqueueSnackbar(t(`The list has been refreshed successfully`), {
          variant: "success"
        });

        refetch();
      })
      .catch(err => {
        const errorAsString = getGraphQLErrorsAsString(err);
        const translatedError = t("Unable to refresh list preset because {{error}}", {
          error: errorAsString
        });
        enqueueSnackbar(translatedError, {
          variant: "error"
        });
      });
  const onSetGoalSubmit = async commitment => {
    const response = await set2024Goals({ variables: commitment });
    if (response) {
      enqueueSnackbar("Your 2024 Goals has been added", {
        variant: "success"
      });
      refetch();
    }
  };
  if (error) {
    const errorAsString = getGraphQLErrorsAsString(error);
    enqueueSnackbar(errorAsString, {
      variant: "error"
    });
    return null;
  }
  return (
    <FullLayout includePadding={false}>
      {loading ? (
        <CircularProgress />
      ) : (
        <Box
          sx={{ backgroundColor: theme.palette.backgroundColor.light, px: 4, pt: 5, width: "100%" }}
        >
          <ProductionCommitmentDashboardHeader
            loading={recalculateLoading}
            updatedAt={data?.getProductionCommitmentStats?.updatedAt}
            onRefreshClick={handleRefreshClick}
            isRefreshable={data?.getProductionCommitmentStats?.isRefreshable}
          />
          <ProductionCommitmentSummary stats={companyStats} />
          <PersonalCommitmentList
            onSetGoalSubmit={onSetGoalSubmit}
            loading={isUpdating}
            stats={agentStats}
            refetch={refetch}
          />
        </Box>
      )}
    </FullLayout>
  );
}
