import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Popper from "@mui/material/Popper";
import React, { MouseEvent, useRef, useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import { Box, ButtonProps, FormLabel, MenuItem, MenuList, alpha, useTheme } from "@mui/material";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import map from "lodash/map";
import { useTranslation } from "react-i18next";
import keyBy from "lodash/keyBy";
import sortOptions from "./sortOptions";

const hashedSortOptions = keyBy(sortOptions, "value");

interface ProductionCommitmentSortButtonProps extends ButtonProps {
  onSortChange: ({ value }: { value: string }) => void;
  sortMethod?: string;
}

export default function ProductionCommitmentSortButton({
  onSortChange,
  sx,
  sortMethod,
  ...props
}: ProductionCommitmentSortButtonProps) {
  const theme = useTheme();
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  const handleSortButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClearSortButton = (event: MouseEvent<SVGElement>) => {
    event.stopPropagation();

    setAnchorEl(null);

    onSortChange({ value: "" });
  };

  const handleSortOptionClick =
    ({ value }) =>
    () => {
      setAnchorEl(null);

      onSortChange({ value });
    };

  const isSortActive = !!sortMethod;
  const popoverOpen = Boolean(anchorEl);

  return (
    <>
      <Button
        ref={anchorRef}
        sx={{
          maxWidth: { md: theme.spacing(37.5), lg: "unset" },
          ".MuiButton-startIcon": {
            color: isSortActive ? theme.palette.orange[50] : theme.palette.grey[50]
          },
          borderRadius: theme.spacing(12.5),
          border: `1px solid ${popoverOpen || isSortActive ? theme.palette.orange[50] : theme.palette.grey[90]}`,
          backgroundColor:
            popoverOpen || isSortActive ? theme.palette.orange[90] : theme.palette.grey[100],
          ":hover": {
            backgroundColor:
              popoverOpen || isSortActive ? theme.palette.orange[80] : theme.palette.grey[90],
            border: `1px solid ${popoverOpen || isSortActive ? theme.palette.orange[50] : theme.palette.grey[80]}`
          },
          ...sx
        }}
        startIcon={<UnfoldMoreIcon />}
        onClick={handleSortButtonClick}
        {...props}
      >
        <Typography
          variant="h8"
          sx={{
            textTransform: "capitalize",
            color: isSortActive ? theme.palette.orange[50] : theme.palette.grey[50]
          }}
        >
          {t("Sort{{sortOption}}", {
            sortOption: sortMethod ? `: ${hashedSortOptions[sortMethod].label}` : ""
          })}
        </Typography>
        {isSortActive && (
          <CancelIcon
            component="svg"
            onClick={handleClearSortButton}
            sx={{ ml: 1.25, p: 0, color: theme.palette.error.main }}
          />
        )}
      </Button>
      {popoverOpen && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <Popper open sx={{ zIndex: 99 }} anchorEl={anchorEl} placement="bottom-start">
            <MenuList
              sx={{
                ".Mui-selected > p": { color: theme.palette.orange[50] },
                p: 2.5,
                backgroundColor: theme.palette.grey[100],
                borderRadius: theme.spacing(1),
                boxShadow: `0 ${theme.spacing(0.25)} ${theme.spacing(0.75)} ${theme.spacing(0.25)} ${alpha(theme.palette.common.black, 0.15)}`
              }}
            >
              <FormLabel sx={{ mt: 1 }} id="array-filter-label">
                <Typography variant="body2" sx={{ fontWeight: 500, color: theme.palette.grey[70] }}>
                  {t("Sort")}
                </Typography>
              </FormLabel>
              <Box sx={{ maxHeight: theme.spacing(40), overflowY: "scroll" }}>
                {map(sortOptions, item => (
                  <MenuItem
                    selected={sortMethod === item.value}
                    onClick={handleSortOptionClick({ value: item.value })}
                    sx={{ py: 1, pl: 0 }}
                  >
                    <Typography
                      variant="body2"
                      sx={{ fontWeight: 500, color: theme.palette.grey[50] }}
                    >
                      {item.label}
                    </Typography>
                  </MenuItem>
                ))}
              </Box>
            </MenuList>
          </Popper>
        </ClickAwayListener>
      )}
    </>
  );
}
