import React, { ChangeEvent, ElementType, useMemo, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputBaseComponentProps,
  TextField,
  Toolbar,
  Typography,
  useTheme
} from "@mui/material";
import { useTranslation } from "react-i18next";
import SaveIcon from "@mui/icons-material/Save";
import map from "lodash/map";
import omit from "lodash/omit";
import keyBy from "lodash/keyBy";
import reduce from "lodash/reduce";
import filter from "lodash/filter";
import includes from "lodash/includes";
import some from "lodash/some";
import isEmpty from "lodash/isEmpty";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CustomToggleButton from "../../../../../../components/CustomToggleButton/CustomToggleButton";
import { useAuth } from "../../../../../../contexts/auth";
import NumberFormat from "../../../../../../components/NumberFormat/NumberFormat";
import {
  Carrier,
  TrainingClass,
  TrainingClassSessionRequirement,
  useGetAvailableTrainingClassesQuery
} from "../../../../../../generated/graphql";
import PageLoader from "../../../../../../components/PageLoader/PageLoader";

export default function RequirementsDialog({
  requirements: initialRequirements,
  disabled,
  onClose,
  onRequirementChange
}) {
  const { t } = useTranslation();
  const theme = useTheme();

  const {
    config: {
      company: { universityRequirements, carriers }
    }
  } = useAuth();
  const hashedUniversityRequirements = keyBy(universityRequirements, "code");

  const [requirements, setRequirements] = useState(
    reduce(
      initialRequirements,
      (memo, requirement) => ({
        ...memo,
        [requirement.code]: {
          ...requirement,
          id: hashedUniversityRequirements[requirement.code].id
        }
      }),
      {}
    ) || {}
  );

  const { data, loading } = useGetAvailableTrainingClassesQuery({
    variables: {
      isBootcamp: false,
      sortBy: "id",
      page: 0,
      rowsPerPage: 25
    },
    fetchPolicy: "network-only",
    errorPolicy: "all"
  });

  const trainingClasses = useMemo(() => {
    if (!data) {
      return [];
    }

    return data?.getAvailableTrainingClasses?.rows;
  }, [data]);
  const onSaveButtonClick = () => {
    onRequirementChange(requirements);
  };
  const onRequirementToggleChange =
    ({ code }) =>
    (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      if (checked) {
        const requirement = hashedUniversityRequirements[code];
        setRequirements({
          ...requirements,
          [code]: {
            id: requirement?.id,
            code: requirement?.code,
            name: requirement?.name,
            value: requirement?.defaultValue,
            textValue: requirement?.defaultTextValue ? [requirement.defaultTextValue] : []
          }
        });
      } else {
        setRequirements(omit(requirements, code));
      }
    };
  const onRequirementValueChange =
    ({ code }) =>
    event => {
      const requirement = hashedUniversityRequirements[code];
      setRequirements({
        ...requirements,
        [code]: {
          id: requirement?.id,
          code: requirement?.code,
          name: requirement?.name,
          value: event.target.value
        }
      });
    };
  const onRequirementTextValueChange =
    ({ code, value }) =>
    (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      const requirement = requirements[code];
      let textValue = requirement?.textValue || [];
      if (checked) {
        textValue = [...textValue, value];
      } else {
        textValue = filter(textValue, item => item !== value);
      }

      setRequirements({
        ...requirements,
        [code]: { id: requirement?.id, code: requirement?.code, name: requirement?.name, textValue }
      });
    };

  if (loading) {
    return <PageLoader />;
  }

  return (
    <Dialog
      open
      onClose={onClose}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            background: theme.palette.backgroundColor.light,
            width: "100%",
            maxWidth: theme.spacing(106),
            borderRadius: theme.spacing(2.5)
          }
        }
      }}
    >
      <DialogTitle variant="h5">{t("Requirements Settings")}</DialogTitle>
      <DialogContent sx={{ backgroundColor: theme.spacing(99) }}>
        {map(universityRequirements, requirement => {
          if (requirement.code === "completedClass") {
            return (
              <Box
                sx={{
                  padding: theme.spacing(2),
                  background: theme.palette.grey[98],
                  borderRadius: theme.spacing(1.25),
                  marginBottom: theme.spacing(1)
                }}
              >
                <CustomToggleButton
                  disabled={disabled}
                  checked={requirements[requirement.code]}
                  onChange={onRequirementToggleChange({ code: requirement.code })}
                  label={requirement.name}
                  isMarginLeftAuto
                />
                {requirements[requirement.code] && (
                  <Box
                    sx={{ marginTop: theme.spacing(1), display: "flex", flexDirection: "column" }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        marginTop: "auto",
                        marginBottom: "auto",
                        fontWeight: 400,
                        color: theme.palette.orange[30],
                        marginRight: theme.spacing(3)
                      }}
                    >
                      {requirement.description}
                    </Typography>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      {map(trainingClasses, (trainingClass: TrainingClass) => {
                        const checked = includes(
                          requirements[requirement.code]?.textValue,
                          trainingClass.id
                        );
                        return (
                          <Box>
                            <FormControlLabel
                              sx={{
                                marginLeft: theme.spacing(0.5),
                                paddingRight: theme.spacing(0.5)
                              }}
                              control={
                                <Checkbox
                                  checked={checked}
                                  onChange={onRequirementTextValueChange({
                                    code: requirement.code,
                                    value: trainingClass.id
                                  })}
                                  color="primary"
                                />
                              }
                              label={
                                <Typography
                                  variant="body3"
                                  sx={{
                                    marginTop: "auto",
                                    marginBottom: "auto",
                                    fontWeight: 400,
                                    marginRight: theme.spacing(3)
                                  }}
                                >
                                  {`${trainingClass.id} - ${trainingClass.name}`}
                                </Typography>
                              }
                            />
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>
                )}
              </Box>
            );
          }
          if (requirement.code === "appointed") {
            return (
              <Box
                sx={{
                  padding: theme.spacing(2),
                  background: theme.palette.grey[98],
                  borderRadius: theme.spacing(1.25),
                  marginBottom: theme.spacing(1)
                }}
              >
                <CustomToggleButton
                  disabled={disabled}
                  checked={requirements[requirement.code]}
                  onChange={onRequirementToggleChange({ code: requirement.code })}
                  label={requirement.name}
                  isMarginLeftAuto
                />
                {requirements[requirement.code] &&
                  hashedUniversityRequirements[requirement.code]?.description && (
                    <Box
                      sx={{ marginTop: theme.spacing(1), display: "flex", flexDirection: "column" }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          marginTop: "auto",
                          marginBottom: "auto",
                          fontWeight: 400,
                          color: theme.palette.orange[30],
                          marginRight: theme.spacing(3)
                        }}
                      >
                        {requirement.description}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: theme.spacing(1)
                        }}
                      >
                        {map(carriers, (carrier: Carrier) => {
                          const checked = includes(
                            requirements[requirement.code]?.textValue,
                            carrier.shortName
                          );
                          return (
                            <Box>
                              <FormControlLabel
                                sx={{
                                  marginLeft: theme.spacing(0.5),
                                  paddingRight: theme.spacing(0.5)
                                }}
                                control={
                                  <Checkbox
                                    checked={checked}
                                    onChange={onRequirementTextValueChange({
                                      code: requirement.code,
                                      value: carrier.shortName
                                    })}
                                    color="primary"
                                  />
                                }
                                label={
                                  <Typography
                                    variant="body3"
                                    sx={{
                                      marginTop: "auto",
                                      marginBottom: "auto",
                                      fontWeight: 400,
                                      marginRight: theme.spacing(3)
                                    }}
                                  >
                                    {carrier.shortName}
                                  </Typography>
                                }
                              />
                            </Box>
                          );
                        })}
                      </Box>
                    </Box>
                  )}
              </Box>
            );
          }
          return (
            <Box
              sx={{
                padding: theme.spacing(2),
                background: theme.palette.grey[98],
                borderRadius: theme.spacing(1.25),
                marginBottom: theme.spacing(1)
              }}
            >
              <CustomToggleButton
                disabled={disabled}
                checked={requirements[requirement.code]}
                onChange={onRequirementToggleChange({ code: requirement.code })}
                label={requirement.name}
                isMarginLeftAuto
              />
              {requirements[requirement.code] &&
                hashedUniversityRequirements[requirement.code]?.description && (
                  <Box sx={{ marginTop: theme.spacing(1), display: "flex" }}>
                    <Typography
                      variant="body2"
                      sx={{
                        marginTop: "auto",
                        marginBottom: "auto",
                        fontWeight: 400,
                        color: theme.palette.orange[30],
                        marginRight: theme.spacing(3)
                      }}
                    >
                      {requirement.description}
                    </Typography>
                    <TextField
                      disabled={disabled}
                      margin="dense"
                      variant="outlined"
                      size="small"
                      fullWidth
                      required
                      onChange={onRequirementValueChange({ code: requirement.code })}
                      InputProps={{
                        inputProps: { decimalScale: 0 },
                        inputComponent: NumberFormat as ElementType<InputBaseComponentProps>
                      }}
                      sx={{ marginTop: "auto", marginBottom: "auto", width: theme.spacing(20) }}
                      defaultValue={
                        requirements[requirement.code]?.value ||
                        hashedUniversityRequirements[requirement.code]?.defaultValue
                      }
                    />
                  </Box>
                )}
            </Box>
          );
        })}
      </DialogContent>
      <DialogActions>
        <Toolbar
          disableGutters
          sx={{
            display: { xs: "flex", sm: "block" },
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
            whiteSpace: "nowrap",
            flexWrap: "wrap"
          }}
        >
          <Button
            color="error"
            onClick={onClose}
            sx={{
              width: { xs: "31%", sm: "fit-content" },
              marginRight: theme.spacing(1)
            }}
          >
            {t("Cancel")}
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={onSaveButtonClick}
            startIcon={<SaveIcon />}
            disabled={
              isEmpty(requirements) ||
              (!isEmpty(requirements) &&
                some(
                  requirements,
                  (requirement: TrainingClassSessionRequirement) =>
                    requirement &&
                    !requirement.value &&
                    isEmpty(requirement.value) &&
                    isEmpty(requirement.textValue)
                ))
            }
            sx={{
              width: { xs: "65%", sm: "fit-content" },
              marginRight: { xs: 0, sm: theme.spacing(1) }
            }}
          >
            {t("Save Changes")}
          </Button>
        </Toolbar>
      </DialogActions>
    </Dialog>
  );
}
