import React from "react";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import Typography from "@mui/material/Typography";
import get from "lodash/get";
import keyBy from "lodash/keyBy";

export default function UserRoleCellRenderer({ config }) {
  const {
    company: { roles }
  } = config;

  return function renderCell(props: GridRenderCellParams) {
    const { value } = props;
    const hashedRoles = keyBy(roles, "id");
    return (
      <Typography variant="h7" sx={{ fontWeight: 300 }}>
        {get(hashedRoles[value], "name")}
      </Typography>
    );
  };
}
