/* eslint-disable prefer-destructuring */
import React from "react";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import Typography from "@mui/material/Typography";
import { Box, useTheme } from "@mui/material";
import { get } from "lodash";
import { format } from "date-fns";

export default function TrainingClassSessionText() {
  return function renderCell(props: GridRenderCellParams) {
    const theme = useTheme();
    const { row, field } = props;
    let variant;
    let color;
    let fontWeight;
    let component;
    if (field === "workflowItem.code") {
      variant = "h7";
      color = theme.palette.grey[10];
      fontWeight = 500;
      component = get(row, field, "");
    } else if (["workflowItem.createdBy.name", "workflowItem.updatedBy.name"].includes(field)) {
      variant = "body3";
      color = theme.palette.grey[50];
      fontWeight = 400;
      component = get(row, field, "");
    } else if (["workflowItem.createdAt", "workflowItem.updatedAt"].includes(field)) {
      variant = "body3";
      color = theme.palette.grey[50];
      fontWeight = 400;
      component = get(row, field) && (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="body3" sx={{ color: theme.palette.grey[50] }}>
            {format(get(row, field), "MMMM dd, yyyy")}
          </Typography>

          <Typography variant="body4" sx={{ color: theme.palette.grey[70] }}>
            {format(get(row, field), "h:mm a")}
          </Typography>
        </Box>
      );
    } else if (field === "completedRequirements") {
      variant = "body4";
      color = theme.palette.grey[50];
      fontWeight = 400;
      component = `${get(row, "completedRequirements")}/${get(row, "totalRequirements")}`;
    }
    return (
      <Typography
        variant={variant}
        sx={{
          fontWeight,
          whiteSpace: field === "note" ? "normal" : "unset"
        }}
        color={color}
        fontFamily="Montserrat"
      >
        {component}
      </Typography>
    );
  };
}
