import Box from "@mui/material/Box";
import { isEmpty, keyBy, map, omit } from "lodash";
import React, { useEffect, useState } from "react";
import * as Icons from "@mui/icons-material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import availableFilterInputComponents from "../availableFilterInputComponents";

export default function ActiveFilters({
  availableFilters,
  filterModel,
  onChange,
  onFilterRemove,
  onFilterClearAll,
  handleGetAgents
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSMDown = useMediaQuery(theme.breakpoints.down("sm"));

  const hashedAvailableFilters = keyBy(availableFilters, "filterKey");
  const [showActiveFilters, setShowActiveFilters] = useState<boolean>(true);

  const activeFilters = omit(filterModel, "text");

  useEffect(() => {
    if (!isSMDown) {
      setShowActiveFilters(true);
    }
  }, [isSMDown]);

  const handleFilterChange = ({
    filterKey,
    value
  }: {
    filterKey: string;
    value: { query: string; operatorTypeId: string } | { ids: string[] };
  }) => {
    onChange({ filterKey, value });
  };

  const handleFilterRemove =
    ({ filter }) =>
    () => {
      onFilterRemove({ filterKey: filter.filterKey });
    };

  if (isEmpty(activeFilters)) {
    return null;
  }

  const handleToggleFiltersVisibility = () => {
    setShowActiveFilters(prev => !prev);
  };

  return (
    <Box
      sx={{
        px: 2.5,
        pb: 2.5
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        {isSMDown ? (
          <Button variant="text" onClick={handleToggleFiltersVisibility}>
            <Typography variant="body4" sx={{ textTransform: "none", fontWeight: 400 }}>
              {showActiveFilters ? "Hide" : "Show"}
            </Typography>
          </Button>
        ) : null}
      </Box>
      <Box sx={{ display: showActiveFilters ? "block" : "none" }}>
        <Button
          sx={{ mr: 1.25, mb: 0.5 }}
          variant="outlined"
          startIcon={<Icons.Clear />}
          color="error"
          onClick={onFilterClearAll}
        >
          <Typography variant="h8">{t("Clear All")}</Typography>
        </Button>
        {map(activeFilters, (value, filterKey) => {
          const filter = hashedAvailableFilters[filterKey];
          const FilterSection = availableFilterInputComponents[filter.type];
          const Icon = (filter.icon && Icons[filter.icon]) || Icons.KeyboardArrowRight;
          return (
            <Box sx={{ mr: 1.25, mb: 0.5, display: "inline-block" }}>
              <FilterSection
                key={filterKey}
                values={filterModel[filter.filterKey] || {}}
                items={filter.filterOptionValues}
                label={filter.label}
                dataType={filter.dataType}
                filterKey={filter.filterKey}
                startIcon={<Icon />}
                onChange={handleFilterChange}
                onDelete={handleFilterRemove({ filter })}
                handleGetAgents={handleGetAgents}
              />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}
