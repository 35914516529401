import { DeleteOutlined, EditOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Popover,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { isEmpty } from "lodash";
import keyBy from "lodash/keyBy";
import map from "lodash/map";
import React, { useState, MouseEvent } from "react";
import SavedSearchIcon from "@mui/icons-material/SavedSearch";
import { useTranslation } from "react-i18next";

interface PresetItem {
  id: string;
  name: string;
  isDefault: boolean;
}
interface GridToolbarPresetButtonProps {
  selectedPresetId?: string;
  availablePresets: PresetItem[];
  onPresetChange: ({ selectedPresetId }: { selectedPresetId: string }) => void;
  onCreatePreset: ({ name }: { name: string }) => void;
  onUpdatePreset: ({ name, presetId }: { name: string; presetId: string }) => void;
  onDeletePreset: ({ presetId }: { presetId: string }) => void;
}

export default function GridToolbarPresetButton({
  selectedPresetId = "",
  availablePresets,
  onPresetChange,
  onCreatePreset,
  onUpdatePreset,
  onDeletePreset
}: GridToolbarPresetButtonProps) {
  const theme = useTheme();
  const isMDDown = useMediaQuery(theme.breakpoints.down("md"));
  const isLGDown = useMediaQuery(theme.breakpoints.down("lg"));

  const { t } = useTranslation();
  const [managePresetsOpen, setManagePresetsOpen] = useState(false);
  const [createPresetsOpen, setCreatePresetsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const [presetName, setPresetName] = useState<string>("");
  const [presetToEdit, setPresetToEdit] = useState<PresetItem | null>();

  const hashedPresets = keyBy(availablePresets, "id");

  const handlePresetChange =
    ({ presetId }) =>
    () => {
      onPresetChange({ selectedPresetId: presetId });
      setManagePresetsOpen(false);
    };

  const handleEditPreset =
    ({ presetId }) =>
    (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();

      setPresetToEdit(hashedPresets[presetId]);
      setPresetName(hashedPresets[presetId].name);
      setManagePresetsOpen(false);
      setCreatePresetsOpen(true);
    };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setPresetToEdit(null);
    setPresetName("");
    setManagePresetsOpen(false);
    setCreatePresetsOpen(false);
  };

  const handleCreatePresetClick = () => {
    setCreatePresetsOpen(true);
    setManagePresetsOpen(false);
  };

  const handleSavePresetClick = () => {
    if (presetToEdit) {
      onUpdatePreset({ name: presetName, presetId: presetToEdit.id });
    } else {
      onCreatePreset({ name: presetName });
    }
    setCreatePresetsOpen(false);
  };

  const handleDeletePreset =
    ({ presetId }) =>
    (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();

      onDeletePreset({ presetId });
      setManagePresetsOpen(false);
    };

  const handlePresetButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setManagePresetsOpen(true);
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Button
        size="small"
        startIcon={<SavedSearchIcon />}
        onClick={handlePresetButtonClick}
        variant="text"
      >
        {isLGDown ? "" : t("Preset")}
      </Button>
      <Popover
        sx={{ ".MuiPaper-root": { borderRadius: theme.spacing(1.5) } }}
        open={managePresetsOpen}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
      >
        <List sx={{ minWidth: 400 }}>
          {map(availablePresets, preset => (
            <ListItem disablePadding key={preset.id} value={preset.id}>
              <ListItemButton
                selected={selectedPresetId === preset.id}
                onClick={handlePresetChange({ presetId: preset.id })}
                sx={{ pr: 12 }}
              >
                <ListItemText primary={<Typography variant="body2">{preset.name}</Typography>} />
                {!preset.isDefault ? (
                  <ListItemSecondaryAction>
                    <>
                      <IconButton onClick={handleEditPreset({ presetId: preset.id })}>
                        <EditOutlined />
                      </IconButton>
                      <IconButton onClick={handleDeletePreset({ presetId: preset.id })}>
                        <DeleteOutlined />
                      </IconButton>
                    </>
                  </ListItemSecondaryAction>
                ) : null}
              </ListItemButton>
            </ListItem>
          ))}
          <Divider />
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={handleCreatePresetClick}>Create Preset</Button>
          </Box>
        </List>
      </Popover>
      <Popover
        sx={{ ".MuiPaper-root": { borderRadius: theme.spacing(1.5), py: 1 } }}
        open={createPresetsOpen}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
      >
        <Box sx={{ px: 2.5, pt: 2.5 }}>
          <TextField
            focused
            variant="standard"
            placeholder="Preset Name"
            label="Name Your Preset"
            value={presetName}
            onChange={event => setPresetName(event.target.value)}
          />
        </Box>

        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2.5 }}>
          <Button color="error" onClick={handlePopoverClose}>
            Discard
          </Button>
          <Button disabled={isEmpty(presetName)} onClick={handleSavePresetClick}>
            Save
          </Button>
        </Box>
      </Popover>
      {isMDDown ? null : (
        <Typography variant="body3" color={theme.palette.grey[70]}>
          {!selectedPresetId ? <em>Unsaved Preset</em> : hashedPresets[selectedPresetId].name}
        </Typography>
      )}
    </Box>
  );
}
