import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import { Avatar, Box, Checkbox, useTheme } from "@mui/material";

export default function AgentSelectItem({
  id,
  avatarUrl,
  agentName,
  agentCode,
  agentLevel,
  isSelected,
  onAgentSelected
}: {
  id: number;
  avatarUrl: string;
  agentName: string;
  agentCode: string;
  agentLevel: string;
  isSelected: boolean;
  onAgentSelected: ({ id, isChecked }: { id: number; isChecked: boolean }) => void;
}) {
  const theme = useTheme();
  const [isChecked, setIsChecked] = useState<boolean>(isSelected);
  const onClick = () => {
    setIsChecked(!isChecked);
    onAgentSelected({ id, isChecked: !isChecked });
  };
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "start",
        gap: theme.spacing(1),
        py: 1.5,
        cursor: "pointer"
      }}
      onClick={onClick}
    >
      <Checkbox checked={isChecked} />
      <Avatar src={avatarUrl} sx={{ height: theme.spacing(3), width: theme.spacing(3) }} />
      <Box sx={{ display: "flex", gap: theme.spacing(1.5), alignItems: "center" }}>
        <Typography variant="body3" sx={{ color: theme.palette.grey[10] }}>
          {agentName}
        </Typography>
        <Typography variant="body4" sx={{ color: theme.palette.grey[70], fontWeight: 400 }}>
          {agentCode}
          {agentLevel && ` - ${agentLevel}`}
        </Typography>
      </Box>
    </Box>
  );
}
