import isEmpty from "lodash/isEmpty";
import every from "lodash/every";
import find from "lodash/find";

export default function hasSystemFeatures({ systemFeatures, requiredSystemFeatureIds }) {
  if (isEmpty(systemFeatures) || isEmpty(requiredSystemFeatureIds)) {
    return false;
  }

  return every(requiredSystemFeatureIds, requiredSystemFeatureId => {
    const pId = Number(requiredSystemFeatureId);
    return find(systemFeatures, enabledSystemFeature => Number(enabledSystemFeature.id) === pId);
  });
}
