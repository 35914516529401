import { GridFilterModel } from "@mui/x-data-grid-pro";
import { isArray, isEmpty, join } from "lodash";
import map from "lodash/map";

export default function adjustPresetFilters({ filterModel }: { filterModel: GridFilterModel }) {
  const { items, quickFilterValues } = filterModel;

  const presetFilters = map(items, item => ({
    isMultipleValues: isArray(item.value),
    operator: item.operator,
    value: isArray(item.value) ? join(item.value, ",") : item.value,
    field: item.field
  }));

  if (!isEmpty(quickFilterValues)) {
    presetFilters.push({
      field: "text",
      operator: "contains",
      value: join(quickFilterValues, " "),
      isMultipleValues: false
    });
  }

  return presetFilters;
}
