import React, { useState } from "react";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import { useTheme } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import IconButton from "@mui/material/IconButton";
import { useMediaQuery } from "@mui/material";
import Step1Form from "./Step1Form";
import Step2Form from "./Step2Form";
import StepStatusBar from "./StepStatusBar";

export interface Step1 {
  values: { personalPointValue: number; personalRecruit: number } | null;
  hasError: boolean;
}
export interface Step2 {
  values: { teamPointValue: number; teamRecruit: number; acknowledgeChecked: boolean } | null;
  hasError: boolean;
}

export type AllStep = {
  [key: number]: {
    id: number;
    title: string;
  };
};

export default function SetYourGoalsButton({
  loading,
  onSubmit
}: {
  loading: boolean;
  onSubmit: (commitment: {
    personalPointValue: number;
    personalRecruit: number;
    teamPointValue: number;
    teamRecruit: number;
  }) => void;
}) {
  const theme = useTheme();
  const { t } = useTranslation();
  const isXsScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [openSetGoalsDialog, setOpenSetGoalsDialog] = useState<boolean>(false);
  const [step, setStep] = useState<number>(1);
  const [step1FromValue, setStep1FromValue] = useState<Step1>({
    values: null,
    hasError: true
  });
  const [step2FromValue, setStep2FromValue] = useState<Step2>({
    values: null,
    hasError: true
  });

  const allSteps: AllStep = {
    1: { id: 1, title: "1. Personal goals" },
    2: { id: 2, title: "2. Team goals" }
  };
  const onSetYourGoalsClick = () => {
    setOpenSetGoalsDialog(true);
  };
  const onCloseSetYourGoalsDialog = () => {
    setOpenSetGoalsDialog(false);
  };
  const onCancel = () => {
    setOpenSetGoalsDialog(false);
  };
  const onSubmitClick = () => {
    if (step === 1) {
      setStep(2);
    }
    if (step === 2) {
      if (!step1FromValue || !step2FromValue || !step1FromValue.values || !step2FromValue.values) {
        return;
      }
      const commitment = {
        personalPointValue: Number(step1FromValue.values.personalPointValue),
        personalRecruit: Number(step1FromValue.values.personalRecruit),
        teamPointValue: step2FromValue.values.teamPointValue
          ? Number(step2FromValue.values.teamPointValue)
          : 0,
        teamRecruit: step2FromValue.values.teamRecruit
          ? Number(step2FromValue.values.teamRecruit)
          : 0
      };
      onSubmit(commitment);
    }
  };
  const onStep1FormChange = ({ values, hasError }: Step1) => {
    setStep1FromValue({ values, hasError });
  };
  const onStep2FormChange = ({ values, hasError }: Step2) => {
    setStep2FromValue({ values, hasError });
  };
  const onStepBackClick = currentStep => () => {
    setStep(currentStep - 1);
  };

  return (
    <>
      <Button
        title={t("Set Goals")}
        sx={{
          height: theme.spacing(5),
          marginTop: "auto",
          marginBottom: "auto",
          marginLeft: theme.spacing(3),
          fontSize: theme.typography.pxToRem(15),
          [theme.breakpoints.down("md")]: {
            fontSize: theme.typography.pxToRem(12)
          }
        }}
        startIcon={
          <ControlPointIcon
            sx={{
              height: theme.spacing(3),
              width: theme.spacing(3)
            }}
          />
        }
        variant="contained"
        size="medium"
        color="primary"
        onClick={onSetYourGoalsClick}
        disabled={openSetGoalsDialog}
      >
        {t("Set Your Goals")}
      </Button>
      <Dialog
        fullWidth
        maxWidth="md"
        PaperProps={{
          sx: {
            backgroundColor: theme.palette.backgroundColor.light,
            borderRadius: theme.spacing(2)
          }
        }}
        open={openSetGoalsDialog}
        onClose={onCloseSetYourGoalsDialog}
      >
        <DialogTitle
          sx={{
            marginTop: theme.spacing(1.5),
            marginBottom: theme.spacing(0),
            fontFamily: "Plus Jakarta Sans",
            fontSize: theme.spacing(3),
            fontWeight: 500,
            fontStyle: "normal",
            lineHeight: theme.spacing(3.6),
            letterSpacing: theme.spacing(0.048)
          }}
        >
          {t("Setting your goals")}
        </DialogTitle>
        <DialogContent
          sx={{
            margin: theme.spacing(3),
            marginTop: theme.spacing(1.5),
            backgroundColor: theme.palette.common.white,
            borderRadius: theme.spacing(2)
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: isXsScreen ? "column" : "row",
              marginTop: theme.spacing(1),
              marginLeft: theme.spacing(1),
              paddingTop: theme.spacing(1.5),
              paddingBottom: theme.spacing(1.5)
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row"
              }}
            >
              {step > 1 && (
                <IconButton
                  sx={{
                    marginRight: isXsScreen ? theme.spacing(1) : theme.spacing(2),
                    marginTop: "auto",
                    marginBottom: "auto",
                    padding: theme.spacing(0)
                  }}
                  onClick={onStepBackClick(step)}
                >
                  <ArrowBackIosNewIcon />
                </IconButton>
              )}
              <DialogContentText
                sx={{
                  marginTop: "auto",
                  marginBottom: "auto",
                  fontFamily: "Plus Jakarta Sans",
                  fontSize: theme.spacing(2.25),
                  fontWeight: 500,
                  fontStyle: "normal",
                  lineHeight: theme.spacing(3.6),
                  letterSpacing: theme.spacing(0.0625),
                  color: theme.palette.grey[900]
                }}
              >
                {allSteps[step].title}
              </DialogContentText>
            </Box>
            <StepStatusBar
              isCompleted={
                ((step === 1 &&
                  step1FromValue &&
                  step1FromValue.values &&
                  !step1FromValue.hasError) ||
                  (step === 2 &&
                    step2FromValue &&
                    step2FromValue.values &&
                    !step2FromValue.hasError)) as boolean
              }
              step={step}
              allSteps={allSteps}
            />
          </Box>
          {step === 1 && <Step1Form {...step1FromValue.values} onChange={onStep1FormChange} />}
          {step === 2 && (
            <Step2Form
              personalPointValue={step1FromValue.values?.personalPointValue || 0}
              personalRecruit={step1FromValue.values?.personalRecruit || 0}
              {...step2FromValue.values}
              onChange={onStep2FormChange}
            />
          )}
        </DialogContent>
        <DialogActions
          sx={{
            marginRight: theme.spacing(2.5),
            marginBottom: theme.spacing(2)
          }}
        >
          <Button
            sx={{
              fontFamily: "Plus Jakarta Sans",
              fontSize: theme.spacing(2.625),
              fontWeight: 600,
              fontStyle: "normal",
              lineHeight: theme.spacing(4.2)
            }}
            variant="text"
            color="error"
            onClick={onCancel}
          >
            {t("Cancel")}
          </Button>
          <LoadingButton
            sx={{
              minWidth: theme.spacing(16)
            }}
            variant="contained"
            color="primary"
            onClick={onSubmitClick}
            autoFocus
            fullWidth={false}
            disabled={
              (step === 1 &&
                (!step1FromValue || !step1FromValue.values || step1FromValue.hasError)) ||
              (step === 2 && (!step2FromValue || !step2FromValue.values || step2FromValue.hasError))
            }
            loading={loading}
          >
            {step === 2 ? t("Submit") : t("Next")}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
