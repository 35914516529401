import isNil from "lodash/isNil";
import numeral from "numeral";

export default function formatCurrency(initCurrencyValue, { showZero = false } = {}) {
  const currencyValue =
    typeof initCurrencyValue === "number"
      ? Number(initCurrencyValue.toFixed(2))
      : initCurrencyValue;

  if (showZero) {
    if (isNil(currencyValue)) {
      return "0";
    }

    return numeral(currencyValue).format("($0,0.00)");
  }
  if (isNil(currencyValue) || currencyValue === 0) {
    return undefined;
  }

  return numeral(currencyValue).format("($0,0.00)");
}
