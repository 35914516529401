import React, { useState, MouseEvent, useRef, ChangeEvent, ReactElement, useEffect } from "react";
import { alpha, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import IconButton from "@mui/material/IconButton";
import Popper from "@mui/material/Popper";
import Button from "@mui/material/Button";
import map from "lodash/map";
import filter from "lodash/filter";
import forEach from "lodash/forEach";
import groupBy from "lodash/groupBy";
import includes from "lodash/includes";
import isEmpty from "lodash/isEmpty";
import join from "lodash/join";
import keys from "lodash/keys";
import orderBy from "lodash/orderBy";
import reduce from "lodash/reduce";
import split from "lodash/split";
import uniqBy from "lodash/uniqBy";
import CancelIcon from "@mui/icons-material/Cancel";
import ClearIcon from "@mui/icons-material/Clear";
import { useTranslation } from "react-i18next";
import DoneIcon from "@mui/icons-material/Done";
import MultipleSelectFilterLabel from "./MultipleSelectFilterLabel";

interface MultipleSelectFilterProps {
  items: {
    value: string;
    label: string;
    textColor?: string;
    backgroundColor?: string;
  }[];
  startIcon: ReactElement;
  label: string;
  values: { query: string; ids: string[] };
  filterKey: string;
  dataType: string;
  onChange: ({
    filterKey,
    value
  }: {
    filterKey: string;
    value: { query: string; operatorTypeId: string };
  }) => void;
  onDelete: () => void;
}

function mapSelectedState({
  selectedIds,
  items
}: {
  selectedIds: (string | undefined)[];
  items: { value: string; label: string }[];
}) {
  return reduce(
    items,
    (memo, item) => ({
      ...memo,
      [item.value]: includes(selectedIds, item.value)
    }),
    {}
  );
}

export default function MultipleSelectFilter({
  values,
  items: initialItems,
  startIcon,
  label,
  filterKey,
  dataType,
  onChange,
  onDelete
}: MultipleSelectFilterProps) {
  const theme = useTheme();
  const anchorRef = useRef(null);
  const [selectedState, setSelectedState] = useState<Record<string, boolean>>({});
  const { t } = useTranslation();
  const groupedItems = groupBy(initialItems, "label");

  const groupedItemsMap = reduce(
    groupedItems,
    (result, items, name) => ({ ...result, [name]: map(items, item => item?.value) }),
    {}
  );

  useEffect(() => {
    let selectedIds: (string | undefined)[] = [];
    if (values.query === null) {
      selectedIds = map(initialItems, item => item?.value);
    } else {
      selectedIds = dataType === "array" ? values.ids : split(values.query, ",");
    }
    setSelectedState(
      mapSelectedState({
        selectedIds,
        items: initialItems
      })
    );
  }, [values, initialItems]);

  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleFilterButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const handleClickAway = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };

  const handleClearSelectedState = () => {
    setSelectedState(
      mapSelectedState({
        selectedIds: [],
        items: initialItems
      })
    );
    let changeData;
    if (dataType === "array") {
      changeData = {
        filterKey,
        value: {
          ids: []
        }
      };
    } else {
      changeData = {
        filterKey,
        value: {
          query: "",
          operatorTypeId: "includes"
        }
      };
    }

    onChange(changeData);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked: isChecked, value } = event.target;
    const selectedIds = groupedItemsMap[value];
    const updatedSelectedState = { ...selectedState };
    forEach(selectedIds, id => {
      updatedSelectedState[id] = isChecked;
    });
    setSelectedState(updatedSelectedState);
    const allSelectedIds = filter(keys(updatedSelectedState), key => updatedSelectedState[key]);
    if (allSelectedIds.length === initialItems?.length) {
      let changeData;
      if (dataType === "array") {
        changeData = {
          filterKey,
          value: {
            ids: []
          }
        };
      } else {
        changeData = {
          filterKey,
          value: {
            query: "",
            operatorTypeId: "includes"
          }
        };
      }

      onChange(changeData);
    } else {
      onChange({
        filterKey,
        value: {
          query: join(
            reduce(
              updatedSelectedState,
              (memo, checked, code) => (checked ? [...memo, code] : memo),
              [] as string[]
            ),
            ","
          ),
          operatorTypeId: "includes"
        }
      });
    }
  };

  const isFilterActive = !isEmpty(values.query) || !isEmpty(values.ids);

  return (
    <>
      <Button
        ref={anchorRef}
        sx={{
          ".MuiButton-startIcon": {
            color: isFilterActive ? theme.palette.orange[50] : theme.palette.grey[50]
          },
          borderRadius: theme.spacing(12.5),
          border: `1px solid ${isFilterActive ? theme.palette.orange[50] : theme.palette.grey[90]}`,
          backgroundColor: isFilterActive ? theme.palette.orange[90] : theme.palette.grey[100],
          ":hover": {
            backgroundColor: isFilterActive ? theme.palette.orange[80] : theme.palette.grey[90],
            border: `1px solid ${isFilterActive ? theme.palette.orange[50] : theme.palette.grey[80]}`
          }
        }}
        startIcon={startIcon}
        onClick={handleFilterButtonClick}
      >
        <Typography
          variant="h8"
          sx={{
            textTransform: "capitalize",
            color: isFilterActive ? theme.palette.orange[50] : theme.palette.grey[50]
          }}
        >
          {label}
        </Typography>
        <IconButton sx={{ ml: 1.25, p: 0 }} onClick={onDelete}>
          <CancelIcon sx={{ color: theme.palette.error.main }} />
        </IconButton>
      </Button>
      {menuOpen && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <Popper open sx={{ zIndex: 99 }} anchorEl={anchorEl} placement="bottom-start">
            <FormControl
              sx={{
                p: 2.5,
                backgroundColor: theme.palette.grey[100],
                borderRadius: theme.spacing(1),
                boxShadow: `0 ${theme.spacing(0.25)} ${theme.spacing(0.75)} ${theme.spacing(0.25)} ${alpha(theme.palette.common.black, 0.15)}`
              }}
            >
              <FormLabel id="array-filter-label" sx={{ padding: theme.spacing(1.5, 0) }}>
                <Typography variant="body2" sx={{ fontWeight: 500, color: theme.palette.grey[70] }}>
                  {label}
                </Typography>
              </FormLabel>
              <FormGroup>
                <Box
                  sx={{
                    display: "flex",
                    gap: theme.spacing(1),
                    cursor: "pointer",
                    padding: theme.spacing(1.5, 2.5, 1.5, 0)
                  }}
                  onClick={handleClearSelectedState}
                >
                  <ClearIcon sx={{ height: theme.spacing(3), width: theme.spacing(3) }} />
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: 500, color: theme.palette.grey[50] }}
                  >
                    {t("None (Default)")}
                  </Typography>
                  {!isFilterActive && (
                    <DoneIcon
                      sx={{
                        height: theme.spacing(3),
                        width: theme.spacing(3),
                        color: theme.palette.orange[50]
                      }}
                    />
                  )}
                </Box>
                {map(orderBy(uniqBy(initialItems, "label"), "label", "asc"), item => (
                  <FormControlLabel
                    key={item.value}
                    sx={{ py: 1 }}
                    control={
                      <Checkbox
                        checked={selectedState[item.value]}
                        onChange={handleChange}
                        value={item.label}
                        sx={{
                          height: theme.spacing(6),
                          width: theme.spacing(6)
                        }}
                      />
                    }
                    label={<MultipleSelectFilterLabel filterKey={filterKey} item={item} />}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </Popper>
        </ClickAwayListener>
      )}
    </>
  );
}
