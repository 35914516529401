import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import keyBy from "lodash/keyBy";
import reduce from "lodash/reduce";
import omit from "lodash/omit";
import trim from "lodash/trim";
import toLower from "lodash/toLower";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import differenceBy from "lodash/differenceBy";
import TextField from "@mui/material/TextField";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import ListItem from "@mui/material/ListItem";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import FilterListIcon from "@mui/icons-material/FilterList";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import SearchIcon from "@mui/icons-material/Search";
import * as Icons from "@mui/icons-material";
import { useMediaQuery } from "@mui/material";

export type DataGridFilterModel = Record<
  string,
  { query: string; operatorTypeId: string } | { ids: string[] } | object
>;

interface AddFilterButtonProps {
  availableFilters: {
    type: string;
    filterKey: string;
    label: string;
    icon: string;
    filterOptionValues: {
      label: string;
      value: string;
    }[];
  }[];
  onSubmit: ({ filterKey }: { filterKey: string }) => void;
  filterModel: DataGridFilterModel;
}

export default function AddFilterButton({
  onSubmit,
  filterModel,
  availableFilters
}: AddFilterButtonProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const isLGDown = useMediaQuery(theme.breakpoints.down("lg"));

  const { remainingAvailableFilters } = useMemo(() => {
    const currentlyActiveFilters = map(omit(filterModel, "text"), (value, filterKey) => ({
      filterKey,
      ...value
    }));
    const remaining = differenceBy(availableFilters, currentlyActiveFilters, "filterKey");

    return {
      remainingAvailableFilters: keyBy(remaining, "filterKey")
    };
  }, [filterModel]);

  const [filterAnchor, setFilterAnchor] = React.useState<null | HTMLElement>(null);

  const [matchedFilterKeys, setMatchedFilterKeys] = useState(
    map(remainingAvailableFilters, "filterKey") || []
  );
  const [query, setQuery] = useState("");

  useEffect(() => {
    if (query) {
      return;
    }
    setMatchedFilterKeys(map(remainingAvailableFilters, "filterKey") || []);
  }, [query]);

  const createOnListItemClickHandler =
    ({ filter: clickedFilter }) =>
    () => {
      setFilterAnchor(null);
      setQuery("");
      return onSubmit({ filterKey: clickedFilter.filterKey });
    };

  const onClearSearchFieldClick = () => {
    setQuery("");

    return setMatchedFilterKeys(map(remainingAvailableFilters, "filterKey"));
  };

  const onQueryChange = event => {
    setQuery(event.target.value);
    const term = toLower(trim(event.target.value));

    const found = reduce(
      remainingAvailableFilters,
      (memo, availableFilter, filterKey) => {
        if (toLower(availableFilter.label).indexOf(term) === -1) {
          return memo;
        }
        return [...memo, filterKey];
      },
      [] as string[]
    );

    return setMatchedFilterKeys(found);
  };

  const onAddFilterButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setFilterAnchor(filterAnchor ? null : event.currentTarget);
  };
  const onCloseFilter = () => {
    setFilterAnchor(null);
    setQuery("");
  };

  return (
    <Box>
      {!isEmpty(remainingAvailableFilters) && (
        <Button
          startIcon={<FilterListIcon />}
          sx={{
            textTransform: "uppercase",
            color: theme.palette.orange[30],
            border: 0,
            ":hover": {
              border: 0,
              color: theme.palette.orange[30]
            },
            maxHeight: theme.spacing(5),
            marginTop: theme.spacing(0.2)
          }}
          onClick={onAddFilterButtonClick}
        >
          {isLGDown ? "" : t("Add Filter")}
        </Button>
      )}
      <Popover
        open={Boolean(filterAnchor)}
        anchorEl={filterAnchor}
        onClose={onCloseFilter}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        sx={{
          "& .MuiPaper-elevation": {
            width: theme.spacing(40),
            margin: theme.spacing(1, 1.5),
            borderRadius: theme.spacing(1),
            boxShadow: "0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30)"
          }
        }}
      >
        <TextField
          value={query}
          onChange={onQueryChange}
          placeholder="Search filter"
          fullWidth
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={{ width: theme.spacing(1.8), height: theme.spacing(1.8) }} />
              </InputAdornment>
            ),
            endAdornment: !isEmpty(query) ? (
              <InputAdornment position="end">
                <IconButton sx={{ width: theme.spacing(1.8), height: theme.spacing(1.8) }}>
                  <Icons.Cancel
                    sx={{ color: theme.palette.error.main }}
                    onClick={onClearSearchFieldClick}
                  />
                </IconButton>
              </InputAdornment>
            ) : null
          }}
          sx={{
            borderRadius: theme.spacing(1),
            ".MuiInputBase-root": {
              height: "100%",
              "> fieldset": { borderColor: theme.palette.grey[90] }
            },
            ".MuiInputBase-input": {
              fontSize: theme.typography.pxToRem(14),
              color: theme.palette.grey[10]
            },
            padding: theme.spacing(2.5, 2.5, 0, 2.5)
          }}
        />
        <List>
          {map(matchedFilterKeys, matchedFilterKey => {
            const matchedFilter = remainingAvailableFilters[matchedFilterKey];
            if (!matchedFilter) {
              return null;
            }
            return (
              <ListItem
                key={matchedFilter.filterKey}
                dense
                button
                onClick={createOnListItemClickHandler({ filter: matchedFilter })}
                sx={{ margin: theme.spacing(1) }}
              >
                <Typography variant="button">{matchedFilter.label}</Typography>
              </ListItem>
            );
          })}
        </List>
        {isEmpty(matchedFilterKeys) && (
          <Box sx={{ padding: theme.spacing(0, 2.5, 2.5, 2.5) }}>
            <Typography variant="body3">{t("No filters found")}</Typography>
          </Box>
        )}
      </Popover>
    </Box>
  );
}
