import React, { MouseEvent, SyntheticEvent, useMemo, useState } from "react";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import {
  Autocomplete,
  Button,
  FormLabel,
  MenuItem,
  MenuList,
  Popover,
  Popper,
  PopperProps,
  TextField,
  Typography,
  alpha,
  useTheme
} from "@mui/material";
import Box from "@mui/material/Box";
import { filter, map, startsWith, toString } from "lodash";
import { Check } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import formatNumber from "../../libs/formatter/formatNumber";

interface CustomPaginationActionsProps {
  slotProps: {
    from: number;
    to: number;
    rowsPerPageOptions: number[];
    onRowsPerPageChange: (option: number) => void;
  };
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
}

function StyledAutocompletePopper(props: PopperProps) {
  return (
    <Popper
      sx={{
        ".MuiPaper-root": {
          maxHeight: theme => theme.spacing(75),
          "> ul": {
            scrollbarWidth: "thin",
            maxHeight: theme => theme.spacing(75)
          }
        },
        ".MuiAutocomplete-option": {
          justifyContent: "flex-end !important",
          alignContent: "flex-end !important",
          fontSize: theme => theme.typography.pxToRem(16)
        }
      }}
      {...props}
    />
  );
}

export default function TablePaginationActions({
  count,
  page,
  rowsPerPage,
  onPageChange,
  slotProps
}: CustomPaginationActionsProps) {
  const { from, to, rowsPerPageOptions, onRowsPerPageChange } = slotProps;
  const { t } = useTranslation();
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [showRowsPerPageMenu, setShowsRowsPerPageMenu] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>("");

  const pageCount = Math.ceil(count / rowsPerPage);

  const pageCountOptions = useMemo(
    () => Array.from({ length: pageCount }, (_, i) => toString(i + 1)),

    [inputValue, page, pageCount]
  );

  const handleRowsPerPageButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setShowsRowsPerPageMenu(true);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setShowsRowsPerPageMenu(false);
  };

  const handleOptionClick =
    ({ option }) =>
    () => {
      if (onRowsPerPageChange) {
        onRowsPerPageChange(option);
      }
      setAnchorEl(null);
      setShowsRowsPerPageMenu(false);
    };

  const handleBackButtonClick = event => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = event => {
    onPageChange(event, page + 1);
  };

  const handlePageChange = (event: SyntheticEvent<Element, Event>, value: string) => {
    setInputValue("");
    onPageChange(null, Number(value) - 1);
  };

  const handleInputChange = event => {
    setInputValue(event.target.value);
  };

  return (
    <Box sx={{ flexShrink: 0, maxHeight: theme.spacing(6), display: "flex", flexDirection: "row" }}>
      <Button
        sx={{
          backgroundColor: showRowsPerPageMenu ? theme.palette.grey[90] : "unset",
          borderRadius: theme.spacing(1),
          width: "100% !important"
        }}
        onClick={handleRowsPerPageButtonClick}
        variant="text"
      >
        <Typography
          sx={{ color: theme.palette.grey[50], textTransform: "none" }}
          variant="body3"
        >{`${formatNumber({ amount: from, format: "0,0", showZero: true })}-${formatNumber({ amount: to, format: "0,0", showZero: true })} of ${formatNumber({ amount: count, format: "0,0", showZero: true })}`}</Typography>
      </Button>
      <Popover
        anchorEl={anchorEl}
        open={showRowsPerPageMenu}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <MenuList
          sx={{
            pt: 2,
            px: 2.5,
            pb: 0,
            borderRadius: theme.spacing(1),
            boxShadow: `0 ${theme.spacing(0.25)} ${theme.spacing(0.75)} ${theme.spacing(0.25)} ${alpha(theme.palette.common.black, 0.15)}`
          }}
        >
          <FormLabel sx={{ mt: 1 }} id="array-filter-label">
            <Typography variant="body2" sx={{ fontWeight: 500, color: theme.palette.grey[70] }}>
              Rows/page
            </Typography>
          </FormLabel>
          {map(rowsPerPageOptions, pageSizeOption => (
            <MenuItem
              key={`pageSize-${pageSizeOption}`}
              onClick={handleOptionClick({ option: pageSizeOption })}
              sx={{ py: 1, px: 0 }}
            >
              <>
                <Typography variant="body2" sx={{ fontWeight: 500, color: theme.palette.grey[50] }}>
                  {pageSizeOption}
                </Typography>
                {rowsPerPage === pageSizeOption ? (
                  <Check sx={{ ml: "auto", color: theme.palette.orange[50] }} />
                ) : null}
              </>
            </MenuItem>
          ))}
        </MenuList>
      </Popover>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0}>
        {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <Autocomplete
        openOnFocus
        autoHighlight
        filterSelectedOptions
        onChange={handlePageChange}
        filterOptions={(options, state) =>
          filter(options, option => startsWith(option, state.inputValue))
        }
        popupIcon={null}
        options={pageCountOptions}
        sx={{
          width: theme.spacing(8),
          height: theme.spacing(6),
          ".MuiInputBase-root": {
            pt: 0,
            px: "0 !important",
            alignItems: "center",
            backgroundColor: "transparent",
            ":hover": {
              backgroundColor: theme.palette.grey[95],
              ":before": { borderBottom: "none" }
            },
            ":before": { borderBottom: "none" }
          }
        }}
        noOptionsText={
          <Box sx={{ display: "flex" }}>
            <Typography
              sx={{ fontWeight: 500, color: theme.palette.grey[50] }}
              variant="body5"
              fontSize={theme.typography.pxToRem(12)}
            >
              {t("No option")}
            </Typography>
          </Box>
        }
        disableClearable
        value={toString(page + 1)}
        renderInput={params => (
          <TextField
            {...params}
            variant="filled"
            onChange={handleInputChange}
            sx={{
              width: theme.spacing(8),
              height: theme.spacing(6),
              ".MuiInputBase-input": {
                py: 0,
                fontSize: theme.typography.pxToRem(16),
                textAlign: "center"
              },
              ".MuiInputBase-root": { height: "100%" }
            }}
          />
        )}
        PopperComponent={StyledAutocompletePopper}
      />
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
      >
        {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
    </Box>
  );
}
