import keyBy from "lodash/keyBy";

export default function productValueFormatter({ config }) {
  const {
    company: { products }
  } = config;

  const hashedProducts = keyBy(products, "id");
  return value => (hashedProducts[value] ? hashedProducts[value].name : "");
}
