import keyBy from "lodash/keyBy";

export default function productTypeValueFormatter({ config }) {
  const {
    company: { productTypes }
  } = config;
  const hashedProductTypes = keyBy(productTypes, "id");

  return value => (hashedProductTypes[value] ? hashedProductTypes[value].code : "");
}
