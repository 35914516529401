import React, { useState } from "react";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import * as Icons from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import ActionButtonConfirmDialog from "./ActionButtonConfirmDialog";
import type { WorkflowConfirmInput } from "./ActionButtonConfirmDialog";
import { WorkflowAction } from "../../../generated/graphql";
import type { NormalizeItem } from "../ViewItem/ViewItem";
import type { WorkflowActionConfirm } from "../ViewItem/Item";

export type ButtonColor =
  | "inherit"
  | "primary"
  | "secondary"
  | "success"
  | "error"
  | "info"
  | "warning";
export type ButtonSize = "small" | "medium" | "large";

interface ActionButtonProps {
  action: WorkflowAction;
  isLoading: boolean;
  disabled?: boolean;
  onActionConfirmed: WorkflowActionConfirm;
  size?: ButtonSize;
  item?: NormalizeItem;
  labelSuffix?: string | null;
}

export default function ActionButton({
  action,
  isLoading,
  disabled,
  onActionConfirmed,
  size,
  item,
  labelSuffix
}: ActionButtonProps) {
  const theme = useTheme();
  const { t } = useTranslation();
  const isMdAndDown = useMediaQuery(theme.breakpoints.down("md"));
  const [isConfirming, setIsConfirming] = useState<boolean>(false);
  const isAutomationAllowed = false;

  const onActionButtonClick = event => {
    event.preventDefault();
    setIsConfirming(true);
  };

  const onCancel = () => {
    setIsConfirming(false);
  };

  const onConfirmed = ({ signature, comment }: WorkflowConfirmInput) => {
    onActionConfirmed({ action, signature, comment });
  };

  const Icon = (action.icon && Icons[action.icon]) || Icons.KeyboardArrowRight;

  let label = isAutomationAllowed ? `${action.name} [${action.id}]` : action.name;
  let altMessage = "";

  if (action.isSignatureRequired && action.isCommentRequired) {
    label = t("{{label}} (Sign & Comment)", { label });
    altMessage = t("A comment and signature are required");
  } else if (action.isSignatureRequired) {
    label = t("Sign & {{label}} ", { label });
    altMessage = t("A signature is required");
  } else if (action.isCommentRequired) {
    label = t("Comment & {{label}}", { label });
    altMessage = t("A comment is required");
  }
  if (labelSuffix) {
    label += labelSuffix;
  }

  const button = (
    <Button
      data-action-id={action.id}
      data-testid={`action-${action.name}`}
      startIcon={
        <Icon
          sx={{
            height: theme.spacing(3),
            width: theme.spacing(3),
            [theme.breakpoints.down("md")]: {
              height: theme.spacing(2.5),
              width: theme.spacing(2.5)
            }
          }}
        />
      }
      title={altMessage}
      key={action.id}
      color={action.color as ButtonColor}
      onClick={onActionButtonClick}
      variant="contained"
      disabled={disabled}
      size={size}
      fullWidth={isMdAndDown}
      sx={{
        fontSize: theme.typography.pxToRem(21),
        [theme.breakpoints.down("md")]: {
          fontSize: theme.typography.pxToRem(17)
        }
      }}
    >
      {label}
    </Button>
  );

  if (!isConfirming) {
    return button;
  }

  return (
    <>
      {button}
      <ActionButtonConfirmDialog
        item={item}
        onProceed={onConfirmed}
        onCancel={onCancel}
        action={action}
        isLoading={isLoading}
      />
    </>
  );
}
