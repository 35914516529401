import React from "react";
import { useTranslation } from "react-i18next";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import WarningIcon from "@mui/icons-material/Warning";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import Link from "@mui/material/Link";
import { FieldValidationError } from "../../generated/graphql";

export type FormErrorSummaryProps = {
  validationErrors: FieldValidationError[];
};

export default function FormErrorSummary({ validationErrors }: FormErrorSummaryProps) {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box>
      {!isEmpty(validationErrors) && (
        <Alert
          icon={<WarningIcon />}
          sx={{ borderLeft: `${theme.spacing(0.5)} solid ${theme.palette.error.main}` }}
          severity="error"
        >
          <AlertTitle>
            <Typography sx={{ color: theme.palette.error.main }} variant="h7">
              {t("{{errorCount}} {{error}}", {
                errorCount: validationErrors.length,
                error: validationErrors.length > 1 ? "Errors" : "Error"
              })}
            </Typography>
          </AlertTitle>
          {map(validationErrors, validationError => (
            <Link
              href={`#${validationError.field}`}
              sx={{ display: "flex", marginTop: theme.spacing(0.5), textDecoration: "none" }}
              key={validationError.field}
            >
              <Typography sx={{ color: theme.palette.error.main }} variant="body3">
                {t("{{message}}", { message: validationError.reason })}
              </Typography>
              <ArrowOutwardIcon
                sx={{ color: theme.palette.error.main, marginLeft: theme.spacing(0.5) }}
              />
            </Link>
          ))}
        </Alert>
      )}
    </Box>
  );
}
