import { MuiEvent } from "@mui/x-data-grid-pro";
import { MouseEvent } from "react";
import getComponentLinkAttributeAMS from "./formatter/getComponentLinkAttributeAMS";

export default function handleAMSLinkClick({
  url,
  event,
  openInNewTab = false
}: {
  url: string;
  event: MuiEvent<MouseEvent>;
  openInNewTab?: boolean;
}) {
  if (event.ctrlKey || openInNewTab) {
    window.open(
      getComponentLinkAttributeAMS({
        url
      }).href,
      "_blank"
    );
  } else {
    window.location.href = getComponentLinkAttributeAMS({
      url
    }).href;
  }
}
