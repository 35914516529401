import React, { useState } from "react";
import { SxProps, Theme, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import SaveIcon from "@mui/icons-material/Save";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import ListItem from "@mui/material/ListItem";
import { enqueueSnackbar } from "notistack";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import get from "lodash/get";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import { useParams } from "react-router";
import Box from "@mui/material/Box";
import PublishOutlinedIcon from "@mui/icons-material/PublishOutlined";
import LoadingButton from "@mui/lab/LoadingButton";
import EditIcon from "@mui/icons-material/Edit";
import Toolbar from "@mui/material/Toolbar";
import Tabs from "../../../components/Tabs";
import SessionForm from "../SessionForm/SessionForm";
import {
  FieldValidationError,
  TrainingClassSession,
  UpdateTrainingClassSessionInput,
  useGetTrainingClassSessionByIdQuery,
  useUpdateTrainingClassSessionMutation
} from "../../../../../generated/graphql";
import getGraphQLErrorsAsString from "../../../../../libs/graphql/getGraphQLErrorsAsString";
import PageLoader from "../../../../../components/PageLoader/PageLoader";
import SessionStatus from "../../../components/SessionStatus/SessionStatus";
import { useAuth } from "../../../../../contexts/auth";

export default function EditSessionButton({
  onEditCompleted,
  sessionId,
  isButton,
  isXsScreen,
  isInstructor,
  sx
}: {
  onEditCompleted: () => void;
  sessionId: string;
  isButton?: boolean;
  isXsScreen?: boolean;
  isInstructor: boolean;
  sx?: SxProps<Theme>;
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { classId: trainingClassId } = useParams();
  const { userCompanyRole } = useAuth();

  const {
    data,
    loading,
    refetch: refetchDialog
  } = useGetTrainingClassSessionByIdQuery({
    variables: {
      id: sessionId
    },
    fetchPolicy: "network-only",
    errorPolicy: "all"
  });
  const [formValues, setFormValues] = useState<{
    values: UpdateTrainingClassSessionInput;
    hasError: boolean;
  }>();
  const [openEditingDialog, setOpenEditingDialog] = useState<boolean>(false);
  const [selectedTabId, setSelectedTabId] = useState<string>(
    userCompanyRole?.isTrainingManager ? "generalInformation" : "trainersAndAssistants"
  );
  const [validationErrors, setValidationErrors] = useState<FieldValidationError[]>([]);
  const [updateTrainingClassSession, { loading: isUpdating }] =
    useUpdateTrainingClassSessionMutation();

  const trainingClassSession = get(data, "getTrainingClassSessionById") as TrainingClassSession;
  const stateCode = trainingClassSession?.status as string;

  const onFormChange = values => {
    setFormValues(values);
  };
  const onTabChange = tabId => {
    setSelectedTabId(tabId);
  };

  const onEditSessionClick = () => {
    setOpenEditingDialog(true);
  };
  const onEditSessionClose = () => {
    setFormValues({
      hasError: true,
      values: {
        endTimestamp: undefined,
        id: "",
        name: "",
        startTimestamp: undefined
      }
    });
    setOpenEditingDialog(false);
  };
  const onEditButtonClick = value => async () => {
    if (!formValues) {
      return;
    }

    try {
      const response = await updateTrainingClassSession({
        variables: {
          input: {
            ...formValues.values,
            id: sessionId,
            startTimestamp: new Date(formValues.values.startTimestamp).getTime(),
            endTimestamp: new Date(formValues.values.endTimestamp).getTime(),
            status: value.status,
            trainingClassId,
            files: map(formValues.values.files, uploadedFile => ({
              id: uploadedFile?.id,
              size: uploadedFile?.size,
              name: uploadedFile?.name,
              contentType: uploadedFile?.type,
              tmpFileUrl: uploadedFile?.pathUrl
            })),
            isInstructor,
            requirements: map(formValues.values.requirements, requirement => ({
              id: requirement?.id,
              code: requirement?.code,
              name: requirement?.name,
              value: requirement?.value,
              textValue: requirement?.textValue
            }))
          }
        }
      });
      if (response.data?.updateTrainingClassSession.result.code === 200) {
        enqueueSnackbar(
          t(`Agent Level '{{name}}' has been updated.`, {
            name: response.data?.updateTrainingClassSession.updatedTrainingClassSession?.name
          }),
          {
            variant: "success"
          }
        );
        setValidationErrors([]);
        onEditSessionClose();
        refetchDialog();
        onEditCompleted();
        return;
      }
      if (response.data?.updateTrainingClassSession.result.message) {
        enqueueSnackbar(response.data?.updateTrainingClassSession.result.message, {
          variant: "error"
        });
      }
      if (
        response.data?.updateTrainingClassSession.result.validationErrors &&
        response.data?.updateTrainingClassSession.result.validationErrors.length > 0
      ) {
        setValidationErrors(response.data?.updateTrainingClassSession.result.validationErrors);
      }
    } catch (error) {
      const errorAsString = getGraphQLErrorsAsString(error);
      const translatedError = t(`Unable to save because {{errorAsString}}`, { errorAsString });
      enqueueSnackbar(translatedError, {
        variant: "error"
      });
    }
  };

  const tabs = [
    {
      id: "trainersAndAssistants",
      title: userCompanyRole?.isTrainingManager ? t("Trainers & Assistants") : t("Assistants"),
      priority: 2
    },
    {
      id: "materials",
      title: t("Materials"),
      priority: 3
    }
  ];

  if (userCompanyRole?.isTrainingManager) {
    tabs.push({
      id: "generalInformation",
      title: t("General Information"),
      priority: 1
    });
  }

  return (
    <>
      {!isButton && (
        <ListItem
          dense
          button
          sx={{
            padding: theme.spacing(1.5),
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end"
          }}
          onClick={onEditSessionClick}
        >
          <ModeEditOutlinedIcon />
          <Typography variant="button" sx={{ marginLeft: theme.spacing(1) }}>
            {t("Edit")}
          </Typography>
        </ListItem>
      )}
      {isButton && (
        <Button
          color="primary"
          variant="contained"
          fullWidth={isXsScreen}
          startIcon={<EditIcon />}
          onClick={onEditSessionClick}
          sx={{
            ...sx
          }}
        >
          {t("Edit")}
        </Button>
      )}
      {openEditingDialog && (
        <Dialog
          open
          onClose={onEditSessionClose}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                background: theme.palette.backgroundColor.light,
                width: "100%",
                maxWidth: theme.spacing(106),
                borderRadius: theme.spacing(2.5)
              }
            }
          }}
        >
          <DialogTitle variant="h5">
            <Box sx={{ display: "flex" }}>
              <Typography variant="h5">
                {t("Editing {{type}} for {{courseName}}", {
                  type: trainingClassSession?.isBootcamp ? "Bootcamp" : "Class",
                  courseName: trainingClassSession?.name
                })}
              </Typography>
              <SessionStatus stateCode={stateCode} sx={{ marginLeft: "auto" }} />
            </Box>
          </DialogTitle>
          <DialogContent>
            <Tabs
              tabs={orderBy(tabs, "priority", "asc")}
              selectedTabId={selectedTabId}
              onTabChange={onTabChange}
              isFullWidth
              borderColor="rgba(236, 110, 9, 1)"
              sx={{
                color: theme.palette.common.black,
                fontFamily: "Plus Jakarta Sans",
                fontSize: theme.typography.pxToRem(18),
                fontWeight: 600,
                lineHeight: "160%",
                letterSpacing: theme.typography.pxToRem(0.288)
              }}
            />
            {loading ? (
              <PageLoader />
            ) : (
              <SessionForm
                session={trainingClassSession}
                isTrainingManager={userCompanyRole?.isTrainingManager as boolean}
                onChange={onFormChange}
                validationErrors={validationErrors}
                selectedTabId={selectedTabId}
                isSaving={isUpdating}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Toolbar
              disableGutters
              sx={{
                display: { xs: "flex", sm: "block" },
                paddingLeft: theme.spacing(1),
                paddingRight: theme.spacing(1),
                whiteSpace: "nowrap",
                flexWrap: "wrap"
              }}
            >
              <Button
                color="error"
                onClick={onEditSessionClose}
                sx={{
                  width: { xs: "31%", sm: "fit-content" },
                  marginRight: theme.spacing(1)
                }}
              >
                {t("Cancel")}
              </Button>
              <LoadingButton
                color="primary"
                variant={stateCode === "draft" ? "outlined" : "contained"}
                onClick={onEditButtonClick({ status: trainingClassSession?.status })}
                startIcon={<SaveIcon />}
                disabled={!formValues || formValues.hasError || isUpdating}
                loading={isUpdating}
                sx={{
                  width: { xs: stateCode !== "draft" ? "60%" : "65%", sm: "fit-content" },
                  marginRight: { xs: 0, sm: theme.spacing(1) }
                }}
              >
                {t("Save")}
              </LoadingButton>
              {stateCode === "draft" && (
                <LoadingButton
                  color="primary"
                  variant="contained"
                  onClick={onEditButtonClick({ status: "published" })}
                  startIcon={<PublishOutlinedIcon />}
                  disabled={!formValues || formValues.hasError || isUpdating}
                  loading={isUpdating}
                  sx={{
                    width: { xs: "100%", sm: "fit-content" },
                    marginTop: { xs: theme.spacing(1), sm: 0 }
                  }}
                >
                  {t("Save & Publish")}
                </LoadingButton>
              )}
            </Toolbar>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
