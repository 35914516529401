import React from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import { getDownloadURL, getStorage, ref as firebaseRef } from "firebase/storage";
import { enqueueSnackbar } from "notistack";
import getClient from "../../../../../../libs/firebase/getClient";
import downloadFile from "../../../../../../libs/formatter/downloadFile";
import FilePicker from "../../../../../../components/FilePicker/FilePicker";
import FileList from "../../../../../../components/FileList/FileList";
import { WorkflowItemFieldValueFile } from "../../../../../../generated/graphql";

export interface UploadedFile {
  url?: string;
  type: string;
  name: string;
  size: number;
  pathUrl: string;
  createdAt?: string;
}
export interface UploadingProgress {
  totalFiles: number;
  progress: number;
  uploadedFiles: number[];
}
const MAX_FILE = 5;

export default function MaterialForm({
  uploadedFiles,
  onFilesSelected,
  isUploading,
  uploadingProgress,
  onRemoved,
  isDeletable,
  isSaving
}: {
  uploadedFiles: UploadedFile[];
  onFilesSelected: (files: File[]) => void;
  isUploading: boolean;
  uploadingProgress: UploadingProgress;
  onRemoved: (removedIndex: number) => void;
  isDeletable: boolean;
  isSaving?: boolean;
}) {
  const { t } = useTranslation();
  const firebase = getClient();
  const storage = getStorage(firebase);

  const onFileRemoved = (removedFile: WorkflowItemFieldValueFile, removeIndex: number) => {
    if (!isDeletable) {
      return false;
    }
    if (onRemoved) {
      onRemoved(removeIndex);
    }
    return true;
  };
  const onDownloadFile = async uploadedFile => {
    try {
      const fileRef = firebaseRef(storage, uploadedFile?.pathUrl || uploadedFile?.fileUrl);
      const downloadURL = await getDownloadURL(fileRef);
      downloadFile({ url: downloadURL });
    } catch (error) {
      enqueueSnackbar(t("Error downloading file"), {
        variant: "error"
      });
    }
  };

  return (
    <Box>
      {uploadedFiles.length < MAX_FILE && (
        <FilePicker
          error={false}
          required={false}
          accept="application/pdf,image/*,audio/*"
          onFilesSelected={onFilesSelected}
          isUploading={isUploading}
          uploadingProgress={uploadingProgress}
          others={{ maxFiles: MAX_FILE }}
        />
      )}

      <FileList
        files={uploadedFiles}
        isEditable={!isUploading && !isSaving}
        onRemove={onFileRemoved}
        onDownload={onDownloadFile}
        showDeleteButton
      />
    </Box>
  );
}
