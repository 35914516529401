import React, { useMemo } from "react";
import Box, { BoxProps } from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { SvgIconComponent } from "@mui/icons-material";
import Helmet from "react-helmet";
import last from "lodash/last";
import isEmpty from "lodash/isEmpty";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import Breadcrumbs from "../Breadcrumb/Breadcrumb";

export type CrumbProps = {
  label: string;
  href?: string;
  Icon?: SvgIconComponent;
};
interface PageTitleProps extends BoxProps {
  name?: string;
  subTitle?: any;
  buttons?: any;
  crumbs: CrumbProps[];
  isDisplayPageName?: boolean;
}

export default function PageTitle({
  sx,
  name,
  subTitle,
  buttons,
  crumbs,
  isDisplayPageName
}: PageTitleProps) {
  const theme = useTheme();
  const isXsScreenAndDown = useMediaQuery(theme.breakpoints.down("xs"));

  const pageName = useMemo(() => {
    if (name) {
      return name;
    }
    if (isEmpty(crumbs)) {
      return name;
    }

    return last(crumbs)?.label;
  }, [name, crumbs]);

  return (
    <Box
      sx={{
        padding: theme.spacing(3.5, 0, 2.5, 0),
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        ...sx
      }}
    >
      <Helmet>
        <title>{pageName}</title>
      </Helmet>
      <Grid
        container
        sx={{
          margin: 0,
          flexDirection: "column",
          width: isXsScreenAndDown ? "100%" : "unset"
        }}
      >
        <Grid
          item
          xs={8}
          sm={7}
          md={8}
          sx={{
            display: "flex",
            flexGrow: 1,
            maxWidth: "100% !important"
          }}
        >
          {isEmpty(crumbs) && pageName && (
            <Typography variant="h6" gutterBottom>
              {pageName}
            </Typography>
          )}
          {!isEmpty(crumbs) && <Breadcrumbs items={crumbs} />}
        </Grid>
        {!isDisplayPageName && buttons && (
          <Grid
            item
            xs={12}
            sm={5}
            md={4}
            sx={{
              flexGrow: 1,
              textAlign: "right",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              "& .MuiButton-root + .MuiButton-root": {
                marginLeft: theme.spacing(0.5)
              },
              "& .MuiButtonGroup-root + button": {
                marginLeft: theme.spacing(0.5)
              },
              "& .MuiButton-outlined": {
                marginLeft: theme.spacing(0.5)
              }
            }}
          >
            {buttons}
          </Grid>
        )}
        {isDisplayPageName && pageName && (
          <Grid
            item
            sx={{
              marginTop: theme.spacing(2.5),
              display: { xs: buttons?.length > 1 ? "unset" : "flex", sm: "flex" },
              flexDirection: { xs: buttons?.length > 1 ? "unset" : "row", sm: "row" }
            }}
          >
            <Box sx={{ display: "inline-flex", gap: theme.spacing(1) }}>
              <Typography variant="h3" sx={{ marginRight: theme.spacing(1) }}>
                {pageName}
                {subTitle}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                maxHeight: theme.spacing(6.25),
                marginLeft: { xs: theme.spacing(-1), sm: "auto" },
                marginRight: theme.spacing(0),
                gap: { xs: theme.spacing(0.5), sm: theme.spacing(1.5) }
              }}
            >
              {buttons}
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
