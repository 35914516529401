import React from "react";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box, { BoxProps } from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";

interface LinearProgressWithLabelProps extends BoxProps {
  value: number;
  isShowStatus?: boolean;
  status?: string;
}

export default function LinearProgressWithLabel({
  value,
  isShowStatus,
  status,
  ...props
}: LinearProgressWithLabelProps) {
  const theme = useTheme();
  let sxTitle = {
    color: theme.palette.text.secondary
  };
  if (isShowStatus) {
    switch (status) {
      case "success":
        sxTitle = {
          color: theme.palette.success.main
        };
        break;
      case "start":
        sxTitle = {
          color: theme.palette.info.main
        };
        break;
      default:
        sxTitle = {
          color: theme.palette.primary.main
        };
        break;
    }
  }

  return (
    <>
      {isShowStatus && (
        <Box sx={sxTitle}>
          <Typography variant="body1" color="text.secondary">
            {status}
          </Typography>
        </Box>
      )}
      <Box display="flex" alignItems="center" data-value={value} {...props}>
        <Box width="100%" mr={1}>
          <LinearProgress variant="determinate" value={value} />
        </Box>
        <Box minWidth={35}>
          <Typography variant="body2" color="textSecondary">
            {`${Math.round(value)}%`}
          </Typography>
        </Box>
      </Box>
    </>
  );
}
