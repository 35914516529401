import React from "react";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import Typography from "@mui/material/Typography";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { get } from "lodash";

export default function WorkflowItemState() {
  return function renderCell(props: GridRenderCellParams) {
    const { field, row } = props;
    const state = get(row, field);
    const theme = useTheme();
    const isMdAndDown = useMediaQuery(theme.breakpoints.down("md"));
    return (
      state && (
        <Box sx={{ height: "100%", display: "flex" }}>
          <Box
            sx={{
              backgroundColor: { backgroundColor: state.backgroundColor },
              padding: isMdAndDown ? theme.spacing(0.25, 1) : theme.spacing(0.5, 1.5),
              borderRadius: theme.spacing(12.5),
              width: "min-content",
              height: "min-content",
              marginTop: "auto",
              marginBottom: "auto"
            }}
          >
            <Typography
              variant="dataGridState"
              sx={{
                color: state.textColor,
                fontWeight: 400,
                margin: theme.spacing("auto", 0),
                display: "block",
                textAlign: "center",
                width: "min-content"
              }}
            >
              {state.name}
            </Typography>
          </Box>
        </Box>
      )
    );
  };
}
