import React, { ReactNode } from "react";
import { GridRowsProp, GridSlotProps } from "@mui/x-data-grid-pro";
import { Box, Button, Typography, useTheme, useMediaQuery } from "@mui/material";
import find from "lodash/find";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";
import { OperationVariables } from "@apollo/client";
import SendAnnouncementAction from "../../../pages/University/SessionDetailPage/SendAnnouncementAction";
import RecalculatePermissionButton from "../../../pages/University/SessionDetailPage/RecalculatePermissionButton";
import { TrainingClassSessionRegisteredAgent } from "../../../generated/graphql";

type DataGridSelectedToolbarProps = GridSlotProps["toolbar"] & {
  onDeselect?: () => void;
  selectedRowIds?: string[];
  rows: GridRowsProp[];
  renderCellDataProps?: OperationVariables;
  toolbarButtons?: ReactNode;
};

export default function DataGridSelectedToolbar({
  selectedRowIds,
  onDeselect,
  rows,
  renderCellDataProps,
  toolbarButtons
}: DataGridSelectedToolbarProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMDDown = useMediaQuery(theme.breakpoints.down("md"));

  if (!selectedRowIds || isEmpty(selectedRowIds)) {
    return null;
  }
  return (
    <Box
      sx={{
        display: "flex",
        backgroundColor: theme.palette.orange[30],
        padding: theme.spacing(1.5, 5.5),
        gap: isMDDown ? theme.spacing(0.5) : theme.spacing(2.5),
        flexWrap: "wrap",
        alignItems: "center",
        width: "100vw"
      }}
    >
      <Typography variant="body4" sx={{ color: theme.palette.common.white }}>
        {t(`${selectedRowIds.length} ${selectedRowIds.length >= 2 ? "items" : "item"} selected`)}
      </Typography>
      <Button
        variant="text"
        sx={{
          color: `${theme.palette.orange[50]} !important`,
          textTransform: "none !important"
        }}
        onClick={onDeselect}
      >
        {t("Deselect")}
      </Button>
      <SendAnnouncementAction
        rows={rows}
        selectedRowIds={selectedRowIds}
        renderCellDataProps={renderCellDataProps}
      />
      {find(
        rows,
        (item: TrainingClassSessionRegisteredAgent) => item?.workflowItem?.isWorkflowOwner === true
      ) && <RecalculatePermissionButton selectedRowIds={selectedRowIds} />}
      {toolbarButtons}
    </Box>
  );
}
