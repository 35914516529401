import AgentSelectFilter from "./filterInputComponents/AgentSelect/AgentSelect";
import BooleanFilter from "./filterInputComponents/BooleanFilter";
import MultipleSelectFilter from "./filterInputComponents/MutipleSelectFilter";
import SingleSelectFilter from "./filterInputComponents/SingleSelectFilter";

export default {
  multiSelect: MultipleSelectFilter,
  singleSelect: SingleSelectFilter,
  boolean: BooleanFilter,
  agentSelect: AgentSelectFilter,
  workflowStateSelect: MultipleSelectFilter
};
