import { useTheme } from "@mui/material";

export default function getLabelAndBackgroundColorOfDTML({ value }: { value: number }) {
  const theme = useTheme();

  let labelColor;
  let backgroundColor;
  if (value <= 5) {
    labelColor = theme.palette.error.main;
  } else {
    labelColor = "#0A0B0A";
  }
  if (value <= 2) {
    backgroundColor = "#FFF6F6";
  } else if (value >= 3 && value <= 5) {
    backgroundColor = "#FFEDED";
  } else if (value >= 6 && value <= 8) {
    backgroundColor = "#FBE2CE";
  } else {
    backgroundColor = "#6BFD54";
  }
  return {
    labelColor,
    backgroundColor
  };
}
