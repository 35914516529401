import React from "react";
import Box, { BoxProps } from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import { alpha, useTheme } from "@mui/material";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import { ProductionCommitment } from "../../generated/graphql";
import formatNumber from "../../libs/formatter/formatNumber";

interface CommitmentCategoryCellProps extends BoxProps {
  item: ProductionCommitment & { color: string; name: string };
  onCommitmentCategoryClick: () => void;
  commitmentCategoryClickedIndex: string | undefined;
  index: number;
}

export default function CommitmentCategoryCell({
  item,
  onCommitmentCategoryClick,
  commitmentCategoryClickedIndex,
  index,
  ...props
}: CommitmentCategoryCellProps) {
  const theme = useTheme();
  const percentage = Math.round((item.current / item.goal) * 100);
  return (
    <Box
      {...props}
      sx={{
        display: "flex",
        flexDirection: "column",
        p: 1.5,
        height: { xs: "auto", md: "100%" },
        borderRadius: theme.spacing(1.5),
        textAlign: "left",
        cursor: "pointer",
        "&:hover": {
          backgroundColor: "rgba(10, 11, 10, 0.08)"
        },
        ...props.sx
      }}
      onClick={onCommitmentCategoryClick}
    >
      <Typography variant="h7" sx={{ color: theme.palette.grey[50] }}>
        {`${formatNumber({ amount: item.current, format: "0,0", showZero: true })}`}
        {item.goal !== 0
          ? ` of ${formatNumber({ amount: item.goal, format: "0,0", showZero: true })}`
          : ""}
      </Typography>

      <Typography
        variant="body3"
        sx={{ color: theme.palette.grey[70], fontWeight: 500, flexGrow: 1 }}
      >
        {item.name}
      </Typography>
      {item.goal !== 0 && percentage < 100 && (
        <Box sx={{ display: "flex", alignItems: "center", gap: 1.5 }}>
          <Typography
            variant="body3"
            sx={{ color: item.color, fontSize: theme.typography.pxToRem(14) }}
          >
            {`${percentage}%`}
          </Typography>
          <LinearProgress
            sx={{
              width: "100%",
              height: theme.spacing(1),
              borderRadius: theme.spacing(12.5),
              "&.MuiLinearProgress-root": {
                backgroundColor: alpha(item.color, 0.24)
              },
              "& .MuiLinearProgress-bar": {
                backgroundColor: item.color,
                borderRadius: theme.spacing(12.5)
              }
            }}
            variant="determinate"
            value={item.goal ? (item.current / item.goal) * 100 : 0}
          />
        </Box>
      )}
      {item.goal !== 0 && percentage >= 100 && (
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <VerifiedOutlinedIcon sx={{ color: theme.palette.success.main }} />
          <Typography
            variant="body3"
            sx={{ color: item.color, fontSize: theme.typography.pxToRem(14) }}
          >
            {`${percentage}% achieved`}
          </Typography>
        </Box>
      )}
      {item.goal === 0 && item.category !== "personalWrittenPointValue" && (
        <Typography
          variant="body3"
          sx={{ color: theme.palette.grey[70], fontSize: theme.typography.pxToRem(14) }}
        >
          No goal
        </Typography>
      )}
    </Box>
  );
}
