import React from "react";
import map from "lodash/map";
import first from "lodash/first";
import last from "lodash/last";

type DataPoint = {
  data: {
    x: number;
    y: number;
    isFromForecast: boolean;
  };
};
type SeriesProps = {
  data: DataPoint[];
  id: string;
  color: string;
};
export default function ChartLineWithPrediction({ series, lineGenerator, xScale, yScale }) {
  const straight = first(series) as SeriesProps;
  const dotted = last(series) as SeriesProps;
  const newDotted = {
    ...dotted,
    data: [last(straight.data)].concat(dotted.data)
  } as SeriesProps;

  return (
    <>
      <path
        key={straight.id}
        d={lineGenerator(
          map(straight.data, d => ({
            x: xScale(d.data.x),
            y: yScale(d.data.y)
          }))
        )}
        fill="none"
        stroke={straight.color}
        style={{ strokeWidth: 2 }}
      />
      <path
        key={newDotted.id}
        d={lineGenerator(
          map(newDotted.data, d => ({
            x: xScale(d.data.x),
            y: yScale(d.data.y)
          }))
        )}
        fill="none"
        stroke={newDotted.color}
        style={{ strokeDasharray: "3, 6", strokeWidth: 2 }}
      />
    </>
  );
}
