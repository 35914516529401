import React, { useMemo, useState } from "react";
import HomeIcon from "@mui/icons-material/Home";
import { useTranslation } from "react-i18next";
import SchoolIcon from "@mui/icons-material/School";
import { useNavigate, useParams } from "react-router";
import { Box, useTheme } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import filter from "lodash/filter";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import some from "lodash/some";
import map from "lodash/map";
import includes from "lodash/includes";
import FullLayout from "../../../layouts/FullLayout";
import PageTitle from "../../../components/PageTitle/PageTitle";
import {
  TrainingClassSession as TrainingClassSessionType,
  WorkflowAction,
  WorkflowItem,
  useGetRegisteredAgentsLazyQuery,
  useGetTrainingSessionInfoLazyQuery,
  useGetTrainingSessionInfoQuery,
  useExportRegisteredAgentsMutation,
  useTakeWorkflowActionForItemsMutation
} from "../../../generated/graphql";
import { useAuth } from "../../../contexts/auth";
import TrainingClassSession from "./TrainingClassSession";
import ListingDataGrid from "../../../components/ListingDataGrid/ListingDataGrid";
import PageLoader from "../../../components/PageLoader/PageLoader";
import formatShortText from "../../../libs/formatter/formatShortText";
import ActionButton from "../../Workflow/ItemAction/ActionButton";
import getGraphQLErrorsAsString from "../../../libs/graphql/getGraphQLErrorsAsString";
import { TNRG_WORKFLOW_ID } from "../../../consts";

export default function RegisteredAgentsList() {
  const { classId, sessionId } = useParams();
  const { t } = useTranslation();
  const auth = useAuth();
  const theme = useTheme();
  const navigate = useNavigate();

  const isTrainingManager = auth?.userCompanyRole?.isTrainingManager as boolean;
  const isViewAllTrainingClassAndSession = auth?.userCompanyRole
    ?.isViewAllTrainingClassAndSession as boolean;
  const [selectedRows, setSelectedRows] = useState();
  const { loading, error, data, refetch } = useGetTrainingSessionInfoQuery({
    variables: {
      orderBy: "id",
      order: "desc",
      trainingClassSessionId: sessionId as string,
      trainingClassId: classId as string,
      isNotTrainingManager: !isTrainingManager,
      isLazyQuery: false
    },
    fetchPolicy: "network-only",
    errorPolicy: "all"
  });

  const { trainingClassSession, trainingClass, workflowItems, workflowItemActions } =
    useMemo(() => {
      if (!data)
        return {
          trainingClassSession: undefined,
          trainingClass: undefined,
          workflowItems: undefined,
          workflowItemActions: undefined
        };
      return {
        trainingClassSession: data.getTrainingClassSessionById,
        trainingClass: data.getTrainingClassById,
        workflowItems: data.getRegisteredAgents?.rows
          ? map(data.getRegisteredAgents?.rows, row => row?.workflowItem)
          : undefined,
        workflowItemActions: data.getRegisteredAgents?.actions
      };
    }, [data]);

  const [takeWorkflowActionForItems, { loading: isTakingAction }] =
    useTakeWorkflowActionForItemsMutation();

  const onActionConfirmed =
    ({ itemIds }) =>
    ({
      action,
      comment,
      signature
    }: {
      action: WorkflowAction;
      comment?: string;
      signature?: string;
    }) => {
      takeWorkflowActionForItems({
        variables: {
          itemIds,
          actionId: action.id,
          comment,
          signature
        }
      })
        .then(() => {
          enqueueSnackbar(
            t('Action "{{actionName}}" has completed successfully', { actionName: action.name }),
            {
              variant: "success"
            }
          );
          refetch();
        })
        .catch(errorMessage => {
          const errorAsString = getGraphQLErrorsAsString(errorMessage);
          const translatedError = t('Unable to take action "{{actionName}}" because {{error}}', {
            actionName: action.name,
            error: errorAsString
          });
          enqueueSnackbar(translatedError, {
            variant: "error"
          });
        });
    };

  const onRowClick = ({ rowId }) => {
    if (isTrainingManager || trainingClassSession?.isInstructor) {
      navigate(
        `/processes/${TNRG_WORKFLOW_ID}/items/${rowId}/?from=training-center/university&classId=${classId}&sessionId=${sessionId}`
      );
    }
  };

  const crumbs = [
    {
      href: "/",
      label: t("Home"),
      Icon: HomeIcon
    },
    {
      label: t("Training Center"),
      Icon: SchoolIcon
    },
    {
      label: t("University"),
      Icon: SchoolIcon,
      href: `/training-center/university`
    },
    {
      label: trainingClass ? formatShortText(trainingClass.name, 5) : "",
      Icon: LocationCityIcon,
      href: `/training-center/university/training-class/${classId}/view`
    },
    {
      label: t("Session: {{name}}", {
        name: trainingClassSession ? formatShortText(trainingClassSession.name, 5) : ""
      }),
      Icon: EventAvailableIcon
    }
  ];
  if (loading) {
    return <PageLoader />;
  }
  if (error) {
    enqueueSnackbar(error.message, { variant: "error" });
    return null;
  }
  const toolbarButtons = workflowItems && workflowItemActions && (
    <>
      {map(workflowItemActions, (action: WorkflowAction) => {
        const selectedItems = filter(workflowItems, (workflowItem: WorkflowItem) =>
          includes(selectedRows, workflowItem.id)
        );
        const canTakeActionItems = filter(
          selectedItems,
          row =>
            isEmpty(get(row, "validationErrors")) &&
            some(get(row, "actions", []), (item: WorkflowAction) => item.id === action.id)
        );
        const labelSuffix =
          canTakeActionItems.length && selectedItems?.length
            ? `(${canTakeActionItems.length}/${selectedItems?.length})`
            : null;
        return (
          !isEmpty(action) &&
          !isEmpty(canTakeActionItems) && (
            <ActionButton
              action={action}
              key={action.id}
              onActionConfirmed={onActionConfirmed({
                itemIds: map(canTakeActionItems, (item: WorkflowItem) => item.id)
              })}
              isLoading={isTakingAction}
              labelSuffix={labelSuffix}
            />
          )
        );
      })}
    </>
  );
  const onRowSelectedChange = newRows => {
    setSelectedRows(newRows);
  };

  return (
    <FullLayout sx={{ overflow: "clip" }} includePadding={false}>
      <PageTitle
        crumbs={crumbs}
        sx={{ marginLeft: theme.spacing(5), marginRight: theme.spacing(5) }}
      />
      <Box
        sx={{
          margin: theme.spacing(0, 5),
          backgroundColor: theme.palette.backgroundColor.light
        }}
      >
        <TrainingClassSession
          trainingClassSession={trainingClassSession as TrainingClassSessionType}
          refetch={refetch}
          isTrainingManager={isTrainingManager}
          isViewAllTrainingClassAndSession={isViewAllTrainingClassAndSession}
        />
        <ListingDataGrid
          isEditable={false}
          useGetRowsLazyQuery={useGetTrainingSessionInfoLazyQuery}
          renderCellDataProps={{ ...data }}
          triggerRefetch={isTakingAction}
          getRowId={row => row?.workflowItemId}
          lazyQueryVariables={{
            trainingClassSessionId: sessionId as string,
            trainingClassId: classId as string,
            isNotTrainingManager: !isTrainingManager,
            isLazyQuery: true
          }}
          useGetAgentsLazyquery={useGetRegisteredAgentsLazyQuery}
          useExportRowsMutation={useExportRegisteredAgentsMutation}
          getAgentsVariables={{ trainingClassSessionId: sessionId }}
          getAgentQueryName="getRegisteredAgents"
          onRowClick={onRowClick}
          isMultiRowSelectionAllowed={
            isTrainingManager || (trainingClassSession?.isInstructor as boolean)
          }
          backgroundColor={theme.palette.grey[95]}
          toolbarButtons={toolbarButtons}
          onRowSelectedChange={onRowSelectedChange}
          query="getRegisteredAgents"
          listId="5"
        />
      </Box>
    </FullLayout>
  );
}
