import React, { useState } from "react";
import { Box, Button, useTheme, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";
import RealtimeProgressDialogForBackgroundProcess from "../../../components/RealtimeProgressDialog/RealtimeProgressDialogForBackgroundProcess";
import ConfirmDialog from "../../../components/ConfirmDialog/ConfirmDialog";
import { useRecalculateWorkflowItemPermissionsMutation } from "../../../generated/graphql";

export default function RecalculatePermissionButton({
  selectedRowIds: initSelectedRowIds
}: {
  selectedRowIds: string[];
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMDDown = useMediaQuery(theme.breakpoints.down("md"));

  const [openRecalculatePermissionDialog, setOpenRecalculatePermissionDialog] = useState(false);
  const [realtimeExecutionResult, setRealtimeExecutionResult] = useState<null | undefined | string>(
    null
  );

  const [recalculateWorkflowItemPermissions, { loading: isRecalculating }] =
    useRecalculateWorkflowItemPermissionsMutation();

  const onRecalculate = () => {
    if (isEmpty(initSelectedRowIds)) {
      return null;
    }
    return recalculateWorkflowItemPermissions({
      variables: { itemIds: initSelectedRowIds }
    }).then(response => {
      setOpenRecalculatePermissionDialog(false);
      setRealtimeExecutionResult(
        response.data?.recalculateWorkflowItemPermissions?.realtimeBackgroundProcessId
      );
    });
  };

  const onRecalculatePermissionClick = () => {
    setOpenRecalculatePermissionDialog(true);
  };
  const onCloseRecalculateDialog = () => {
    setOpenRecalculatePermissionDialog(false);
  };

  const onCloseDialog = () => {
    setRealtimeExecutionResult(null);
  };

  if (isEmpty(initSelectedRowIds)) {
    return null;
  }

  return (
    <Box sx={{ padding: theme.spacing(2, 0) }}>
      <Button
        variant="outlined"
        color="primary"
        sx={{ backgroundColor: theme.palette.common.white }}
        onClick={onRecalculatePermissionClick}
        disabled={isRecalculating || openRecalculatePermissionDialog}
      >
        {isMDDown ? t("Recalculate") : t("Recalculate Permission")}
      </Button>
      {openRecalculatePermissionDialog && (
        <ConfirmDialog
          open
          onYes={onRecalculate}
          onNo={onCloseRecalculateDialog}
          title={t("Recalculate Permission")}
          content={t("Are you sure you want to recalculate workflow item permissions?")}
          showLoading={isRecalculating}
        />
      )}
      {realtimeExecutionResult && (
        <RealtimeProgressDialogForBackgroundProcess
          onClose={onCloseDialog}
          title={t("Recalculating...")}
          realtimeBackgroundProcessId={realtimeExecutionResult}
        />
      )}
    </Box>
  );
}
