import React from "react";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import { useTheme } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import last from "lodash/last";
import getLabelAndBackgroundColorOfDTML from "../labelAndBackgroundColor/getLabelAndBackgroundColorOfDTML";

export default function TargetMarketListRatedByTrainer() {
  return function renderCell(props: GridRenderCellParams) {
    const { reviews } = props.row;

    let value;
    if (!isEmpty(reviews)) {
      const lastSponsorRatedByTrainer = get(last(reviews), "sponsorRatedByTrainer");
      const lastClientRatedByTrainer = get(last(reviews), "clientRatedByTrainer");
      value =
        props.field === "clientRatedByTrainer"
          ? lastClientRatedByTrainer
          : lastSponsorRatedByTrainer;
    }
    const theme = useTheme();
    const labelAndBackgroundColor = getLabelAndBackgroundColorOfDTML({ value });

    return (
      <Box>
        {value && (
          <Chip
            label={
              <Typography
                variant="body2"
                sx={{ fontWeight: 500 }}
                color={labelAndBackgroundColor.labelColor}
              >
                {value}
              </Typography>
            }
            size="medium"
            sx={{
              backgroundColor: labelAndBackgroundColor.backgroundColor,
              width: theme.spacing(8)
            }}
          />
        )}
      </Box>
    );
  };
}
