import React, { useState } from "react";
import MuiTablePagination, { TablePaginationProps } from "@mui/material/TablePagination";
import TablePaginationActions from "./TablePaginationActions";

interface CustomPaginationProps extends Omit<TablePaginationProps, "onRowsPerPageChange"> {
  onRowsPerPageChange: (option: number) => void;
}

export default function CustomPagination({
  rowsPerPageOptions,
  onRowsPerPageChange,
  ...props
}: CustomPaginationProps) {
  const [fromRow, setFromRow] = useState<number>(0);
  const [toRow, setToRow] = useState<number>(0);

  const handleRowsPerPageChange = (option: number) => {
    if (onRowsPerPageChange) {
      onRowsPerPageChange(option);
    }
  };

  return (
    <MuiTablePagination
      labelRowsPerPage=""
      labelDisplayedRows={({ from, to }) => {
        setFromRow(from || 0);
        setToRow(to || 0);

        return null;
      }}
      ActionsComponent={TablePaginationActions as any}
      slotProps={
        {
          actions: {
            from: fromRow,
            to: toRow,
            rowsPerPageOptions,
            onRowsPerPageChange: handleRowsPerPageChange
          },
          select: { sx: { display: "none !important" } }
        } as any
      }
      {...props}
    />
  );
}
