import {
  gridPageSelector,
  gridPageSizeSelector,
  useGridApiContext,
  useGridRootProps,
  useGridSelector
} from "@mui/x-data-grid-pro";
import React from "react";
import CustomPagination from "../../TablePagination/CustomPagination";

export default function DataGridPagination({ rowCount }: { rowCount: number }) {
  const apiRef = useGridApiContext();
  const rootProps = useGridRootProps();

  const currentPageSizeOption = useGridSelector(apiRef, gridPageSizeSelector);
  const page = useGridSelector(apiRef, gridPageSelector);

  const handlePageChange = (event, newPage) => {
    apiRef.current.setPage(newPage);
  };

  const handleRowsPerPageChange = option => {
    apiRef.current.setPageSize(option);
  };

  return (
    <CustomPagination
      rowsPerPageOptions={rootProps.pageSizeOptions}
      component="div"
      count={rowCount}
      rowsPerPage={currentPageSizeOption}
      page={page}
      onPageChange={handlePageChange}
      onRowsPerPageChange={handleRowsPerPageChange}
    />
  );
}
