import React, { ReactNode, useState } from "react";
import Button from "@mui/material/Button";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import isEmpty from "lodash/isEmpty";
import { useTheme } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import SignatureInput from "../SignatureInput/SignatureInput";

export type WorkflowConfirmDialogOnYesArgs = {
  confirmText: string;
  checkOption?: boolean;
  signature?: string;
};
interface ConfirmDialogProps {
  open: boolean;
  onNo: () => void;
  onYes: (p: WorkflowConfirmDialogOnYesArgs) => void;
  disabledButtons?: boolean | undefined;
  disabledYesButton?: boolean;
  showLoading?: boolean;
  isConfirmRequired?: boolean;
  confirmTextLabel?: string;
  confirmTextValue?: string;
  isCheckOptionRequired?: boolean;
  checkOptionLabel?: string;
  checkOptionValue?: boolean;
  title?: string | null;
  content?: ReactNode | ReactNode[];
  noLabel?: string;
  yesLabel?: string;
  agentLevelTitle?: string;
  printedName?: string;
  isSignatureRequired?: boolean;
  props?: DialogProps;
}
type TextFieldPickedProps = Pick<TextFieldProps, "value" | "error" | "helperText">;
interface Signature {
  value: string | undefined;
}
export default function WorkflowConfirmDialog({
  open,
  onYes,
  onNo,
  title,
  content,
  disabledButtons,
  disabledYesButton,
  isConfirmRequired,
  confirmTextLabel,
  confirmTextValue,
  isCheckOptionRequired,
  checkOptionLabel,
  checkOptionValue,
  noLabel = "No",
  yesLabel = "Yes",
  showLoading,
  isSignatureRequired,
  agentLevelTitle,
  printedName,
  ...props
}: ConfirmDialogProps) {
  const theme = useTheme();
  const [confirmText, setConfirmText] = useState<TextFieldPickedProps>({ value: confirmTextValue });
  const [checkOption, setCheckOption] = useState<TextFieldPickedProps>({ value: checkOptionValue });
  const [signature, setSignature] = useState<Signature>({ value: undefined });
  const onConfirmTextChange = event => {
    setConfirmText({ value: event.target.value });
  };
  const onCheckOptionChange = (event, checked) => {
    setCheckOption({ value: checked });
  };
  const onSignatureChange = value => {
    setSignature({ value });
  };
  const onNoClick = () => {
    setConfirmText({ value: undefined });
    return onNo();
  };

  const onYesClick = () =>
    onYes({
      confirmText: confirmText.value as string,
      checkOption: checkOption.value as boolean,
      signature: signature.value as string
    });

  return (
    <Dialog
      maxWidth="md"
      open={open}
      onClose={onNo}
      data-testid="confirmPopup"
      {...props}
      sx={{
        borderRadius: theme.spacing(3),
        "& .MuiDialog-paper": { borderRadius: theme.spacing(3) }
      }}
    >
      {title && (
        <DialogTitle sx={{ padding: theme.spacing(3, 3, 2, 3) }}>
          <Typography variant="h5">{title}</Typography>
        </DialogTitle>
      )}
      {content && (
        <DialogContent sx={{ padding: theme.spacing(3, 3, 2, 3) }}>
          <DialogContentText component="div">{content}</DialogContentText>
          {isSignatureRequired && (
            <SignatureInput
              data-testid="signatureInput"
              required
              fullWidth
              onChange={onSignatureChange}
              disabled={disabledButtons as boolean}
              agentLevelTitle={agentLevelTitle}
              printedName={printedName}
            />
          )}
          {isConfirmRequired && (
            <TextField
              data-testid="confirmTextField"
              margin="normal"
              autoFocus
              required
              multiline
              minRows={5}
              fullWidth
              value={confirmText.value}
              label={confirmTextLabel}
              variant="outlined"
              onChange={onConfirmTextChange}
              onKeyDown={event => event.stopPropagation()}
            />
          )}
          {isCheckOptionRequired && (
            <FormControlLabel
              control={<Checkbox checked={checkOption.value as boolean} />}
              label={checkOptionLabel}
              data-testid="isCheckOptionRequired"
              onChange={onCheckOptionChange}
            />
          )}
        </DialogContent>
      )}
      <DialogActions sx={{ padding: theme.spacing(3) }}>
        <Button
          data-testid="noButton"
          onClick={onNoClick}
          variant="text"
          color="error"
          disabled={disabledButtons}
          sx={{
            fontWeight: 600,
            fontSize: theme.typography.pxToRem(21),
            lineHeight: "160%",
            padding: theme.spacing(1, 5)
          }}
        >
          {noLabel}
        </Button>
        <LoadingButton
          data-testid="yesButton"
          onClick={onYesClick}
          color="primary"
          variant="contained"
          autoFocus
          disabled={
            disabledButtons ||
            disabledYesButton ||
            (isConfirmRequired && isEmpty(confirmText.value)) ||
            (isSignatureRequired && isEmpty(signature.value))
          }
          sx={{
            fontWeight: 600,
            fontSize: theme.typography.pxToRem(21),
            lineHeight: "160%",
            padding: theme.spacing(1, 5)
          }}
          loading={showLoading}
        >
          {yesLabel}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
