import React from "react";
import map from "lodash/map";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import type { AllStep } from "./SetYourGoalsButton";

export default function StepStatusBar({
  isCompleted,
  step: currentStep,
  allSteps
}: {
  isCompleted: boolean;
  step: number;
  allSteps: AllStep;
}) {
  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        marginTop: isXsScreen ? theme.spacing(1) : "auto",
        marginBottom: "auto",
        marginLeft: isXsScreen ? theme.spacing(0) : "auto"
      }}
    >
      {map(allSteps, step => {
        let backgroundColor = "#FBE2CE";

        if (currentStep > step.id) {
          backgroundColor = theme.palette.success.main;
        }
        if (currentStep === step.id) {
          if (isCompleted) {
            backgroundColor = theme.palette.success.main;
          } else {
            backgroundColor = theme.palette.primary.main;
          }
        }

        return (
          <Box
            sx={{
              height: theme.spacing(1),
              width: theme.spacing(10),
              backgroundColor,
              borderRadius: theme.spacing(12.5),
              marginRight: theme.spacing(0.5)
            }}
          />
        );
      })}
    </Box>
  );
}
