interface GetFileNamePartsArgs {
  name: string;
}
interface GetFileNamePartReturnValue {
  name: string;
  extension: string | undefined;
}

export default function getFileNameParts({
  name
}: GetFileNamePartsArgs): GetFileNamePartReturnValue {
  const parts = name.split(".");
  const extension = parts.pop();
  return {
    name: parts.join("."),
    extension
  };
}
