import Typography from "@mui/material/Typography";
import React from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import find from "lodash/find";
import DottedLineFaceIcon from "./DottedLineFaceIcon";
import { DataGridFilterModel } from "./DataGridFilter/AddFilterButton";

export default function CustomNoRowsOrResultsOverlay({
  filterModel,
  dataLoading
}: {
  filterModel: DataGridFilterModel;
  dataLoading: boolean;
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const dottedLineFaceIcon = DottedLineFaceIcon();

  const existingFilter = find(
    filterModel,
    (filter: { query: string; operatorTypeId: string }) => filter.query !== ""
  );

  return (
    <Box>
      {!dataLoading && (
        <Box sx={{ display: "flex", margin: theme.spacing(2.5) }}>
          <img
            style={{
              width: "60px",
              height: "auto"
            }}
            alt="dotted-line-face"
            src={dottedLineFaceIcon}
          />
          <Typography
            variant="body1"
            sx={{
              fontSize: theme.typography.pxToRem(24),
              color: "rgba(220, 54, 46, 1)",
              fontWeight: 500,
              marginTop: "auto",
              marginBottom: "auto",
              marginLeft: theme.spacing(1)
            }}
          >
            {!isEmpty(existingFilter) ? t("No items match your filter.") : t("The list is empty.")}
          </Typography>
        </Box>
      )}
    </Box>
  );
}
