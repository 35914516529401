import React, { useRef, useEffect, RefObject } from "react";
import FormControl, { FormControlProps } from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import SignatureCanvas from "react-signature-canvas";
import { useTheme } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import formatDate from "../../../libs/formatter/formatDate";

interface SignatureInputProps extends Partial<Omit<FormControlProps, "onChange">> {
  required: boolean;
  onChange: (base64PNG: string | null) => void;
  label?: string;
  fullWidth: boolean;
  disabled: boolean;
  "data-testid"?: string;
  agentLevelTitle?: string;
  printedName?: string;
}
export default function SignatureInput({
  required,
  onChange,
  label,
  fullWidth,
  agentLevelTitle,
  printedName,
  disabled,
  ...props
}: SignatureInputProps) {
  const date = new Date();
  const { t } = useTranslation();
  const sigPad: RefObject<SignatureCanvas> = useRef();
  const theme = useTheme();

  useEffect(() => {
    const currentSignatureRef = sigPad.current;
    return () => {
      if (currentSignatureRef && currentSignatureRef.off) {
        currentSignatureRef.off();
      }
    };
  }, []);

  useEffect(() => {
    if (sigPad.current) {
      if (disabled) {
        sigPad.current.off();
      } else {
        sigPad.current.on();
      }
    }
  }, [disabled, sigPad.current]);

  const onEndSigning = () => {
    const base64PNG = sigPad.current.getTrimmedCanvas().toDataURL("image/png");
    return onChange && onChange(base64PNG);
  };

  const onClearSignature = () => {
    sigPad.current.clear();
    return onChange && onChange(null);
  };

  return (
    <FormControl
      variant="outlined"
      fullWidth={fullWidth}
      required={required}
      disabled={disabled}
      {...props}
      sx={{
        gap: theme.spacing(2.5),
        padding: theme.spacing(5, 0),
        margin: theme.spacing(2.5, 0),
        backgroundColor: theme.palette.backgroundColor.light,
        borderRadius: theme.spacing(2.5)
      }}
    >
      {label && <InputLabel>{label}</InputLabel>}
      {agentLevelTitle && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: theme.spacing(1)
          }}
        >
          <Typography variant="body3" sx={{ color: theme.palette.grey[70] }}>
            {formatDate(date)}
          </Typography>
          <Typography variant="body2" sx={{ color: theme.palette.grey[50] }}>
            {agentLevelTitle}
          </Typography>
        </Box>
      )}
      <SignatureCanvas penColor={theme.palette.primary.dark} onEnd={onEndSigning} ref={sigPad} />
      {printedName && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: theme.spacing(1)
          }}
        >
          <Typography variant="body1" sx={{ color: theme.palette.grey[50], fontWeight: 700 }}>
            {printedName}
          </Typography>
        </Box>
      )}
      <Button
        variant="outlined"
        onClick={onClearSignature}
        disabled={disabled}
        fullWidth={false}
        sx={{ width: "fit-content", alignSelf: "center", maxWidth: "none" }}
      >
        {t("Create new signature")}
      </Button>
    </FormControl>
  );
}
