import React from "react";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import Typography from "@mui/material/Typography";
import last from "lodash/last";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import keyBy from "lodash/keyBy";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";

export default function TargetMarketListType({ renderCellDataProps }) {
  return function renderCell(props: GridRenderCellParams) {
    const { reviews } = props.row;

    const targetMarketListTypes = renderCellDataProps?.getTargetMarketListTypes;
    const hashedTargetMarketListTypes = keyBy(targetMarketListTypes, "code");

    let typeCode;
    if (!isEmpty(reviews)) {
      typeCode = get(last(reviews), "typeCode");
    }

    return (
      <Box>
        {typeCode && (
          <Chip
            label={
              <Typography
                variant="body5"
                sx={{ fontWeight: 500 }}
                color={hashedTargetMarketListTypes[typeCode]?.color}
              >
                {hashedTargetMarketListTypes[typeCode]?.name}
              </Typography>
            }
            size="medium"
            sx={{
              backgroundColor: hashedTargetMarketListTypes[typeCode]?.backgroundColor
            }}
          />
        )}
      </Box>
    );
  };
}
