import { PickersLocaleText, DateTimePicker } from "@mui/x-date-pickers";
import React, { ChangeEventHandler, ReactNode } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { es } from "date-fns/locale";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { SxProps, Theme } from "@mui/material";

interface DateInputProps {
  defaultValue?: Date | string;
  value?: Date | string;
  readOnly?: boolean;
  disabled?: boolean;
  format?: string;
  label?: string;
  required?: boolean;
  fullWidth?: boolean;
  onChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onPickerChange: (value: Date | null) => void;
  helperText?: ReactNode;
  error?: boolean;
  sx?: SxProps<Theme>;
}

export default function DateInput({
  defaultValue,
  value,
  readOnly,
  disabled,
  format,
  label,
  fullWidth,
  onChange,
  onPickerChange,
  helperText,
  error,
  required,
  sx
}: DateInputProps) {
  const dateValue = typeof value === "string" ? new Date(value) : value;
  const dateDefaultValue = typeof defaultValue === "string" ? new Date(defaultValue) : defaultValue;
  return (
    <LocalizationProvider localeText={es as PickersLocaleText<Date>} dateAdapter={AdapterDateFns}>
      <DateTimePicker
        sx={sx}
        label={label}
        format={format}
        readOnly={readOnly}
        value={dateValue as Date}
        disabled={disabled}
        onChange={onPickerChange}
        slotProps={{
          textField: {
            defaultValue: dateDefaultValue,
            fullWidth,
            onChange,
            helperText,
            error,
            required
          },
          actionBar: {
            actions: ["today"]
          }
        }}
      />
    </LocalizationProvider>
  );
}
