import React, { PropsWithChildren } from "react";
import Box, { BoxProps } from "@mui/material/Box";
import { useMediaQuery, useTheme } from "@mui/material";
import Header from "../components/Layouts/Header";
import ScrollToTopFab from "./ScrollToTopFab";

export type FullLayoutProps = PropsWithChildren<{ includePadding?: boolean }> & BoxProps;

export default function FullLayout({
  children,
  includePadding = true,
  sx,
  ...props
}: FullLayoutProps) {
  const theme = useTheme();
  const isTouch = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      id="fullLayout"
      {...props}
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        width: "100%",
        ...sx
      }}
    >
      <Box className="header-container">
        <Header />
      </Box>
      <Box
        sx={{
          backgroundColor: theme.palette.backgroundColor.light,
          position: "relative",
          flex: 1
        }}
      >
        <Box component="main">
          <Box
            className="main-container"
            sx={{
              p: includePadding ? { xs: 1, sm: 1.5, md: 2 } : {},
              width: "100%",
              maxWidth: includePadding ? "xl" : "none",
              margin: includePadding ? "0 auto" : "0"
            }}
          >
            {children}
          </Box>
          {!isTouch && <ScrollToTopFab scrollY={24} />}
        </Box>
      </Box>
    </Box>
  );
}
