import { PropsWithChildren } from "react";
import { includes } from "lodash";
import { getAuth } from "firebase/auth";
import { useAuth } from "../../contexts/auth";
import { SYSTEM_FEATURE_ID_REQUIRE_AGENT_MEMBERSHIP_AGREEMENT } from "../../consts";
import hasSystemFeatures from "../../libs/hasSystemFeatures";

export default function ProtectedView({ children }: PropsWithChildren) {
  const { userCompanyRole, config, signOut } = useAuth();
  const auth = getAuth();

  const hasFeatureAgentMembershipAgreement = hasSystemFeatures({
    systemFeatures: config?.company?.systemFeatures,
    requiredSystemFeatureIds: [SYSTEM_FEATURE_ID_REQUIRE_AGENT_MEMBERSHIP_AGREEMENT]
  });

  const requireReacknowledgement = process.env.REACT_APP_REQUIRE_REACKNOWLEDGEMENT === "true";

  if (!userCompanyRole) {
    if (auth.currentUser) {
      signOut();
    }

    window.location.replace(
      `${process.env.REACT_APP_AMS_UI_BASE_URL}/login?redirect=${encodeURIComponent(
        window.location.href
      )}`
    );

    return null;
  }
  if (userCompanyRole) {
    if (!userCompanyRole.isBackOfficeMember) {
      if (!userCompanyRole.isProfileCompleted && userCompanyRole.status !== "inactive") {
        window.location.replace(`${process.env.REACT_APP_AMS_UI_BASE_URL}/account/sign-up`);

        return null;
      }

      if (userCompanyRole.isProfileCompleted) {
        if (userCompanyRole.status && userCompanyRole.status === "pending") {
          window.location.replace(
            `${process.env.REACT_APP_AMS_UI_BASE_URL}/account/submitted-application/${userCompanyRole.userId}`
          );

          return null;
        }

        if (
          userCompanyRole.status &&
          includes(["inactive", "terminated", "rejected"], userCompanyRole.status)
        ) {
          window.location.replace(
            `${process.env.REACT_APP_AMS_UI_BASE_URL}/account/error/${userCompanyRole.status}`
          );

          return null;
        }

        if (userCompanyRole.status && userCompanyRole.status === "active") {
          if (hasFeatureAgentMembershipAgreement && requireReacknowledgement) {
            if (!userCompanyRole.agentExtraInfo?.isAgreementResignedAt) {
              window.location.replace(`${process.env.REACT_APP_AMS_UI_BASE_URL}/agreement-resign`);

              return null;
            }
          }
        }
      }
    }

    return children;
  }

  return null;
}
