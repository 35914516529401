export default function getCookieName({
  name,
  env,
  isDevelopment
}: {
  name: string;
  env: string;
  isDevelopment: boolean;
}) {
  if (isDevelopment || env === "production") {
    return name;
  }
  return `${env}_${name}`;
}
