import React from "react";
import { Button, useTheme } from "@mui/material";

export default function Tabs({
  tabs,
  selectedTabId,
  onTabChange,
  isFullWidth,
  sx,
  borderColor
}: {
  tabs: Array<{ id: string; title: string }>;
  selectedTabId: string;
  onTabChange: (tabId: string) => void;
  isFullWidth?: boolean;
  sx?: any;
  borderColor?: string;
}) {
  const theme = useTheme();
  if (!tabs) {
    return null;
  }

  return (
    <>
      {tabs.map(tab => (
        <Button
          sx={{
            ...sx,
            color: theme.palette.grey[50],
            borderRadius: {
              xs: 0,
              md: `${theme.spacing(1.5)} ${theme.spacing(1.5)} ${theme.spacing(0)} ${theme.spacing(0)}`
            },
            borderBottom:
              selectedTabId === tab.id
                ? `solid 3px ${borderColor || theme.palette.primary.main}`
                : null,
            width: `${isFullWidth ? `${100 / tabs.length}%` : "unset"}`
          }}
          key={tab.id}
          onClick={() => onTabChange(tab.id)}
        >
          {tab.title}
        </Button>
      ))}
    </>
  );
}
