import React from "react";
import { GridToolbarExportContainer, GridToolbarExportProps } from "@mui/x-data-grid-pro";
import { useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import LoadingButton from "@mui/lab/LoadingButton";

export default function GridToolbarExportButton({
  onExportClick,
  isExporting,
  csvOptions,
  ...props
}: GridToolbarExportProps) {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <GridToolbarExportContainer {...props}>
      <LoadingButton
        sx={{ color: theme.palette.grey[50] }}
        loading={isExporting}
        onClick={onExportClick}
        variant="text"
      >
        {t("Export to CSV")}
      </LoadingButton>
    </GridToolbarExportContainer>
  );
}
