import React, { useState, MouseEvent, useRef, ReactElement, useEffect } from "react";
import {
  ClickAwayListener,
  FormLabel,
  IconButton,
  MenuItem,
  MenuList,
  Popper,
  alpha,
  useTheme
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import CancelIcon from "@mui/icons-material/Cancel";

interface SingleSelectFilterProps {
  items: {
    value: string;
    label: string;
  }[];
  startIcon: ReactElement;
  label: string;
  values: { query: string };
  filterKey: string;
  onChange: ({
    filterKey,
    value
  }: {
    filterKey: string;
    value: { query: string; operatorTypeId: string };
  }) => void;
  onDelete: () => void;
}

export default function SingleSelectFilter({
  values,
  items,
  startIcon,
  label,
  filterKey,
  onChange,
  onDelete
}: SingleSelectFilterProps) {
  const theme = useTheme();
  const anchorRef = useRef(null);
  const [selected, setSelected] = useState<string | null>();

  useEffect(() => {
    setSelected(values.query);
  }, [values]);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleFilterButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  const handleOptionClick =
    ({ value }) =>
    () => {
      setSelected(value);
      setAnchorEl(null);

      onChange({ filterKey, value: { query: value, operatorTypeId: "is" } });
    };

  const isFilterActive = !isEmpty(values.query);
  const menuOpen = Boolean(anchorEl);

  return (
    <>
      <Button
        ref={anchorRef}
        sx={{
          ".MuiButton-startIcon": {
            color: isFilterActive ? theme.palette.orange[50] : theme.palette.grey[50]
          },
          borderRadius: theme.spacing(12.5),
          border: `1px solid ${isFilterActive ? theme.palette.orange[50] : theme.palette.grey[90]}`,
          backgroundColor: isFilterActive ? theme.palette.orange[90] : theme.palette.grey[100],
          ":hover": {
            backgroundColor: isFilterActive ? theme.palette.orange[80] : theme.palette.grey[90],
            border: `1px solid ${isFilterActive ? theme.palette.orange[50] : theme.palette.grey[80]}`
          }
        }}
        startIcon={startIcon}
        onClick={handleFilterButtonClick}
      >
        <Typography
          variant="h8"
          sx={{
            textTransform: "capitalize",
            color: isFilterActive ? theme.palette.orange[50] : theme.palette.grey[50]
          }}
        >
          {label}
        </Typography>
        {isFilterActive && (
          <IconButton sx={{ ml: 1.25, p: 0 }} onClick={onDelete}>
            <CancelIcon sx={{ color: theme.palette.error.main }} />
          </IconButton>
        )}
      </Button>
      {menuOpen && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <Popper sx={{ zIndex: 99 }} open anchorEl={anchorEl} placement="bottom-start">
            <MenuList
              sx={{
                ".Mui-selected > p": { color: theme.palette.orange[50] },
                p: 2.5,
                backgroundColor: theme.palette.grey[100],
                borderRadius: theme.spacing(1),
                boxShadow: `0 ${theme.spacing(0.25)} ${theme.spacing(0.75)} ${theme.spacing(0.25)} ${alpha(theme.palette.common.black, 0.15)}`
              }}
            >
              <FormLabel sx={{ mt: 1 }} id="array-filter-label">
                <Typography variant="body2" sx={{ fontWeight: 500, color: theme.palette.grey[70] }}>
                  {label}
                </Typography>
              </FormLabel>

              {map(items, item => (
                <MenuItem
                  selected={selected === item.value}
                  onClick={handleOptionClick({ value: item.value })}
                  sx={{ py: 1 }}
                >
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: 500, color: theme.palette.grey[50] }}
                  >
                    {item.label}
                  </Typography>
                </MenuItem>
              ))}
            </MenuList>
          </Popper>
        </ClickAwayListener>
      )}
    </>
  );
}
