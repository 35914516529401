import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import Box, { BoxProps } from "@mui/material/Box";
import SearchIcon from "@mui/icons-material/Search";
import keycode from "keycode";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material";

interface SearchInputProps extends BoxProps {
  onTextChange: (text: string) => void;
  text: string;
  placeholder: string;
  isSmallSearch?: boolean;
  isEffectImmediately?: boolean;
}

export default function SearchInput({
  onTextChange,
  text: initText,
  placeholder,
  isSmallSearch = false,
  isEffectImmediately = false,
  ...props
}: SearchInputProps) {
  const { t } = useTranslation();
  const theme = useTheme();

  const [text, setText] = useState<string>(initText);
  const onSearchChange = event => {
    setText(event.target.value);
    if (isEffectImmediately) {
      onTextChange(event.target.value);
    }
  };
  const onSearchEnter = event => {
    if (isEffectImmediately) {
      return false;
    }
    if (keycode.isEventKey(event, "enter")) {
      onTextChange(text);
    }
    return true;
  };

  const onSearchClick = () => {
    if (isEffectImmediately) {
      return false;
    }
    onTextChange(text);
    return true;
  };
  const onClearClick = () => {
    setText("");
    onTextChange("");
  };

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        ...props.sx
      }}
      {...props}
    >
      <Box
        className="searchBox-wrapper"
        sx={{
          p: isSmallSearch ? 0 : theme.spacing(1.25, 0, 1, 0),
          height: isSmallSearch ? "80%" : "100%",
          display: "flex",
          alignItems: "center",
          width: {
            xs: "100%",
            md: isSmallSearch ? "100%" : theme.spacing(45)
          },
          borderRadius: theme.spacing(1),
          border: `1px solid ${theme.palette.grey[isSmallSearch ? 90 : 60]}`,
          textAlign: "center",
          fontSize: theme.typography.pxToRem(16)
        }}
      >
        <IconButton
          type="button"
          sx={{
            p: theme.spacing(1.25)
          }}
          aria-label={t("search")}
          onClick={onSearchClick}
        >
          <SearchIcon
            sx={{
              color: theme.palette.grey[10],
              height: theme.spacing(2.5),
              width: theme.spacing(2.5)
            }}
          />
        </IconButton>
        <InputBase
          sx={{
            letterSpacing: theme.spacing(0.0625),
            ".MuiInputBase-input": {
              color: theme.palette.grey[10]
            },
            fontFamily: "Montserrat",
            fontSize: theme.typography.pxToRem(14),
            fontWeight: 400,
            lineHeight: "160%",
            color: theme.palette.grey[10]
          }}
          autoFocus
          placeholder={placeholder || t("Search")}
          onChange={onSearchChange}
          onKeyUp={onSearchEnter}
          value={text}
          fullWidth
        />
        {text !== "" && (
          <IconButton
            type="button"
            aria-label={t("clear")}
            sx={{
              p: theme.spacing(1.25)
            }}
            onClick={onClearClick}
          >
            <HighlightOffIcon
              sx={{
                color: theme.palette.grey[10],
                height: theme.spacing(2.5),
                width: theme.spacing(2.5)
              }}
            />
          </IconButton>
        )}
      </Box>
    </Box>
  );
}
