export const orange = {
  0: "#000000",
  5: "#180B01",
  10: "#2F1602",
  20: "#5E2C04",
  30: "#8E4205",
  40: "#BD5807",
  50: "#EC6E09",
  60: "#F08B3A",
  70: "#F4A86B",
  80: "#F7C59D",
  90: "#FBE2CE",
  95: "#FDF1E6",
  100: "#FFFFFF"
};

export const grey = {
  0: "#000000",
  5: "#050505",
  10: "#0A0B0A",
  20: "#141613",
  30: "#1E201D",
  40: "#282B26",
  50: "#323630",
  60: "#5B5E59",
  70: "#848683",
  80: "#ADAFAC",
  90: "#D6D7D6",
  95: "#EAEBEA",
  98: "#F7F7F7",
  99: "#FBFBFB",
  100: "#FFFFFF"
};
