import isNil from "lodash/isNil";
import numeral from "numeral";

export default function formatNumber({
  amount,
  format = "0.0,00",
  showZero = false
}: {
  showZero?: boolean;
  amount: number;
  format?: string;
}): string {
  if (showZero) {
    if (isNil(amount) || amount === 0) {
      return "0";
    }

    return numeral(amount).format(format);
  }
  if (isNil(amount) || amount === 0) {
    return "";
  }

  return numeral(amount).format(format);
}
