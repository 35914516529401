import React from "react";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import Typography from "@mui/material/Typography";
import { Avatar, Box, useTheme } from "@mui/material";
import { get, keyBy } from "lodash";

export default function UserContactCardInline({ config }) {
  return function renderCell(props: GridRenderCellParams) {
    const theme = useTheme();
    const { row, field } = props;
    const agentLevels = config?.company?.agentLevels;
    const hashedAgentLevel = keyBy(agentLevels, "id");
    const agent = get(row, field);
    const agentLevel = hashedAgentLevel[agent?.agentLevelId];
    return (
      agent && (
        <Box
          sx={{ display: "flex", gap: theme.spacing(1.5), alignItems: "center", height: "100%" }}
        >
          <Avatar
            src={agent.avatarUrl}
            sx={{ height: theme.spacing(3), width: theme.spacing(3) }}
          />
          <Typography variant="body3" sx={{ color: theme.palette.grey[10] }}>
            {agent.name}
          </Typography>
          <Typography variant="body4" sx={{ color: theme.palette.grey[70] }}>
            {agent.agentCode}
            {agentLevel && ` - ${agentLevel.level}`}
          </Typography>
        </Box>
      )
    );
  };
}
