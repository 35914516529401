import React from "react";
import RealtimeProgressDialog, { RealtimeProgressDialogProps } from "./RealtimeProgressDialog";

type RealtimeProgressDialogForBackgroundProcessProps = RealtimeProgressDialogProps & {
  realtimeBackgroundProcessId: string;
};
export default function RealtimeProgressDialogForBackgroundProcess({
  realtimeBackgroundProcessId,
  ...props
}: RealtimeProgressDialogForBackgroundProcessProps) {
  return (
    <RealtimeProgressDialog
      {...props}
      showIcon={false}
      realtimePath={`realtimeBackgroundProcess/${realtimeBackgroundProcessId}`}
    />
  );
}
