import Grid from "@mui/material/Grid";
import map from "lodash/map";
import first from "lodash/first";
import isEmpty from "lodash/isEmpty";
import React, { useState, CSSProperties } from "react";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { t } from "i18next";
import CommitmentCategoryCell from "./CommitmentCategoryCell";
import AgentInfoCell from "./AgentInfoCell";
import {
  ProductionCommitmentAgentRow,
  ProductionCommitment,
  ProductionCommitmentAgentProgress
} from "../../generated/graphql";
import PersonalCommitmentDialog from "./PersonalCommitmentDialog/PersonalCommitmentDialog";
import SetYourGoalsButton from "./SetYourGoals/SetYourGoalsButton";
import formatDate from "../../libs/formatter/formatDate";

interface CommitmentCategoryProps extends ProductionCommitment {
  color: string;
  name: string;
}

export default function PersonalCommitmentRow({
  entry,
  style,
  refetch,
  loading,
  onSetGoalSubmit
}: {
  entry: ProductionCommitmentAgentRow;
  style: CSSProperties;
  refetch: () => void;
  loading: boolean;
  onSetGoalSubmit: (commitment: {
    personalPointValue: number;
    personalRecruit: number;
    teamPointValue: number;
    teamRecruit: number;
  }) => void;
}) {
  const theme = useTheme();
  const isLgAndDown = useMediaQuery(theme.breakpoints.down("xl"));
  const isSmAndDown = useMediaQuery(theme.breakpoints.down("md"));
  const isXSScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [commitmentCategoryClickedIndex, setCommitmentCategoryClickedIndex] = useState<
    string | null
  >();

  const [isOpenCommitmentDialog, setIsCommitmentDialog] = useState<boolean>(false);
  const [selectedCommitmentCategory, setSelectedCommitmentCategory] =
    useState<CommitmentCategoryProps>();
  const categories = {
    personalWrittenPointValue: {
      name: "Personal Written PV",
      color: "#C2C600",
      viewType: "self",
      metric: "points",
      navigationLink: "/reports/production/production-breakdowns",
      tooltip: t(
        "This shows the total value of all new policies in the Production Breakdowns report, even if they were written before the current reporting period."
      ),
      filters: {
        writtenDate: {
          operatorTypeId: "between",
          query: {
            start: formatDate(new Date(2000, 0, 1), "yyyy-MM-dd"),
            end: formatDate(new Date(2024, 11, 31), "yyyy-MM-dd")
          }
        }
      }
    },
    personalPointValue: {
      name: "Personal Paid PV",
      color: "#4E9A51",
      viewType: "self",
      navigationLink: "/reports/production/production-breakdowns",
      metric: "points",
      filters: {
        issuedDate: {
          operatorTypeId: "between",
          query: {
            start: formatDate(new Date(2024, 0, 1), "yyyy-MM-dd"),
            end: formatDate(new Date(2025, 1, 28), "yyyy-MM-dd")
          }
        }
      },
      tooltip: t(
        `Here are the total points for policies in the "Paid Business" column of the Production Breakdowns report. These points are for policies written between January 1st, 2024, and December 31st, 2024, and they must be issued and paid by February 28th, 2025.`
      )
    },
    personalRecruit: {
      name: "Personal Recruit",
      color: "#CF7827",
      viewType: "self",
      navigationLink: "/reports/recruiting/all-recruits",
      metric: "points"
    },
    teamPointValue: {
      name: "Team Paid PV",
      color: "#E92376",
      viewType: "downlines",
      metric: "points",
      filters: {
        issuedDate: {
          operatorTypeId: "between",
          query: {
            start: formatDate(new Date(2024, 0, 1), "yyyy-MM-dd"),
            end: formatDate(new Date(2025, 1, 28), "yyyy-MM-dd")
          }
        }
      },
      navigationLink: "/reports/production/production-breakdowns"
    },
    teamRecruit: {
      name: "Team Recruit",
      color: "#1E95D6",
      viewType: "downlines",
      metric: "points",
      navigationLink: "/reports/recruiting/all-recruits"
    }
  };

  const onAgentInfoCellClick = () => {
    const data = first(entry.stats);
    if (data) {
      setIsCommitmentDialog(true);
      setSelectedCommitmentCategory({ ...categories[data.category], ...data });
    }
  };

  const onCommitmentCategoryClick = ({ data, index }) => {
    setCommitmentCategoryClickedIndex(index.toString());
    setIsCommitmentDialog(true);
    setSelectedCommitmentCategory(data);
  };

  const onClosePersonalCommitmentDialog = () => {
    setCommitmentCategoryClickedIndex(null);
    setIsCommitmentDialog(false);
  };

  let minWidth = "0";

  if (isLgAndDown && !isSmAndDown) {
    minWidth = "120vw";
  } else if (isSmAndDown && !isXSScreen) {
    minWidth = "200vw";
  } else if (isXSScreen) {
    minWidth = "250vw";
  }

  return (
    <>
      <Grid container sx={style} spacing={1} minWidth={minWidth} maxHeight={0}>
        <Grid item xs={2}>
          <AgentInfoCell agent={entry.agent} onAgentClick={onAgentInfoCellClick} />
        </Grid>
        {!isEmpty(entry.stats) ? (
          map(entry.stats, (item, index) => (
            <Grid key={index} item xs={2}>
              <CommitmentCategoryCell
                item={{ ...categories[item.category], ...item }}
                onCommitmentCategoryClick={() =>
                  onCommitmentCategoryClick({
                    index: index.toString(),
                    data: { ...categories[item.category], ...item }
                  })
                }
                index={index}
                commitmentCategoryClickedIndex={commitmentCategoryClickedIndex?.toString()}
              />
            </Grid>
          ))
        ) : (
          <SetYourGoalsButton loading={loading} onSubmit={onSetGoalSubmit} />
        )}
      </Grid>
      {isOpenCommitmentDialog && (
        <PersonalCommitmentDialog
          isRefreshable={entry.isRefreshable}
          refetch={refetch}
          open={isOpenCommitmentDialog}
          lastUpdated={entry.updatedAt}
          data={selectedCommitmentCategory as CommitmentCategoryProps}
          onClose={onClosePersonalCommitmentDialog}
          stats={entry.stats as [ProductionCommitment]}
          categories={categories}
          commitmentCategoryClickedIndex={commitmentCategoryClickedIndex as string}
          agent={entry.agent}
          monthlyStats={entry.monthlyStats as [ProductionCommitmentAgentProgress]}
        />
      )}
    </>
  );
}
