import React from "react";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import Typography from "@mui/material/Typography";
import truncate from "lodash/truncate";

export default function TargetMarketListEntryText({ currentUser }) {
  return function renderCell(props: GridRenderCellParams) {
    const { field, value, row } = props;

    let variant;
    let color;
    let text = truncate(value, { length: 100 });
    if (field === "name") {
      variant = "body3";
      color = "#0A0B0A";
    } else if (field === "age") {
      variant = "body3";
      color = "#323630";
    } else if (field === "note") {
      variant = "body5";
      color = "#323630";
      if (Number(currentUser.id) !== Number(row.agent.id) && !row.isNoteShared) {
        text = "";
      }
    } else if (field === "phoneNumber") {
      variant = "body5";
      color = "#0A0B0A";
      if (Number(currentUser.id) !== Number(row.agent.id) && !row.isPhoneNumberShared) {
        text = "";
      }
    } else {
      variant = "body5";
      color = "#0A0B0A";
    }

    return (
      <Typography
        variant={variant}
        sx={{
          fontWeight: field === "name" ? 700 : 400,
          whiteSpace: field === "note" ? "normal" : "unset"
        }}
        color={color}
      >
        {text}
      </Typography>
    );
  };
}
