export default [
  {
    label: "Written Point Value (Highest - Lowest)",
    value: "writtenPVCurrentDesc",
    orderBy: "personalWrittenPointValue-current",
    order: "desc"
  },
  {
    label: "Written Point Value (Lowest - Highest)",
    value: "writtenPVCurrentAsc",
    orderBy: "personalWrittenPointValue-current",
    order: "asc"
  },
  {
    label: "Paid Point Value (Highest - Lowest)",
    value: "paidPVCurrentDesc",
    orderBy: "personalPointValue-current",
    order: "desc"
  },
  {
    label: "Paid Point Value (Lowest - Highest)",
    value: "paidPVCurrentAsc",
    orderBy: "personalPointValue-current",
    order: "asc"
  },
  {
    label: "Personal Recruit (Highest - Lowest)",
    value: "personalRecruitCurrentDesc",
    orderBy: "personalRecruit-current",
    order: "desc"
  },
  {
    label: "Personal Recruit (Lowest - Highest)",
    value: "personalRecruitCurrentAsc",
    orderBy: "personalRecruit-current",
    order: "asc"
  },
  {
    label: "Team Paid PV (Highest - Lowest)",
    value: "teamPVCurrentDesc",
    orderBy: "teamPointValue-current",
    order: "desc"
  },
  {
    label: "Team Paid PV (Lowest - Highest)",
    value: "teamPVCurrentAsc",
    orderBy: "teamPointValue-current",
    order: "asc"
  },
  {
    label: "Team Recruit (Highest - Lowest)",
    value: "teamRecruitCurrentDesc",
    orderBy: "teamRecruit-current",
    order: "desc"
  },
  {
    label: "Team Recruit (Lowest - Highest)",
    value: "teamRecruitCurrentAsc",
    orderBy: "teamRecruit-current",
    order: "asc"
  },
  {
    label: "Target Paid Point Value (Highest - Lowest)",
    value: "paidPVGoalDesc",
    orderBy: "personalPointValue-goal",
    order: "desc"
  },
  {
    label: "Target Paid Point Value (Lowest - Highest)",
    value: "paidPVGoalAsc",
    orderBy: "personalPointValue-goal",
    order: "asc"
  },
  {
    label: "Target Personal Recruit (Highest - Lowest)",
    value: "personalRecruitGoalDesc",
    orderBy: "personalRecruit-goal",
    order: "desc"
  },
  {
    label: "Target Personal Recruit (Lowest - Highest)",
    value: "personalRecruitGoalAsc",
    orderBy: "personalRecruit-goal",
    order: "asc"
  },
  {
    label: "Target Team Paid PV (Highest - Lowest)",
    value: "teamPVGoalDesc",
    orderBy: "teamPointValue-goal",
    order: "desc"
  },
  {
    label: "Target Team Paid PV (Lowest - Highest)",
    value: "teamPVGoalAsc",
    orderBy: "teamPointValue-goal",
    order: "asc"
  },
  {
    label: "Target Team Recruit (Highest - Lowest)",
    value: "teamRecruitGoalDesc",
    orderBy: "teamRecruit-goal",
    order: "desc"
  },
  {
    label: "Target Team Recruit (Lowest - Highest)",
    value: "teamRecruitGoalAsc",
    orderBy: "teamRecruit-goal",
    order: "asc"
  }
];
