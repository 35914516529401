import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import React from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import { Refresh } from "@mui/icons-material";
import { formatDistanceToNow, formatDuration, intervalToDuration } from "date-fns";
import LoadingButton from "@mui/lab/LoadingButton";

export default function ProductionCommitmentDashboardHeader({
  updatedAt,
  onRefreshClick,
  loading,
  isRefreshable
}: {
  onRefreshClick: () => void;
  isRefreshable?: boolean;
  loading: boolean;
  updatedAt: Date;
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSMDown = useMediaQuery(theme.breakpoints.down("sm"));

  const countdown = formatDistanceToNow(new Date(updatedAt), {
    addSuffix: true,
    includeSeconds: true
  });

  return (
    <Box
      sx={{
        mb: 2.5,
        display: "flex",
        justifyContent: "space-between",
        flexDirection: { xs: "column", md: "row" },
        gap: 2.5
      }}
    >
      <Box>
        <Typography variant="h3">{t("2024 Production Commitment")}</Typography>
        <Box>
          <Typography variant="body3" sx={{ color: theme.palette.grey[50] }}>
            {t(
              `${formatDuration(intervalToDuration({ start: new Date(), end: new Date(2024, 11, 31) }), { delimiter: " & ", format: ["months", "days"] })} left `
            )}
          </Typography>
          <Typography
            component={isSMDown ? "div" : "span"}
            variant="body3"
            sx={{ color: theme.palette.grey[50] }}
          >
            {t("(Jan 1st, 2024 - Dec 31st, 2024)")}
          </Typography>
        </Box>
      </Box>
      {isRefreshable && (
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "row-reverse", md: "row" },
            alignItems: "center",
            justifyContent: "left",
            gap: 2.5
          }}
        >
          <Typography variant="body3" sx={{ color: theme.palette.grey[70] }}>
            {t(`Refreshed ${countdown}`)}
          </Typography>
          <LoadingButton
            size="small"
            onClick={onRefreshClick}
            startIcon={<Refresh />}
            sx={{ width: theme.spacing(22.875), px: 5 }}
            variant="outlined"
            loading={loading}
          >
            {t("Refresh")}
          </LoadingButton>
        </Box>
      )}
    </Box>
  );
}
