import each from "lodash/each";
import includes from "lodash/includes";
import reduce from "lodash/reduce";
import { useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import {
  uploadBytesResumable,
  ref as firebaseRef,
  getStorage,
  getMetadata,
  FullMetadata
} from "firebase/storage";
import { UploadingProgress, UploadingResult } from "../components/Fields/File/File";
import getClient from "../libs/firebase/getClient";
import getTmpFileName from "../libs/formatter/getTmpFileName";

interface UseUploadFilePickerProp {
  onSuccess: () => Promise<void>;
  isFileSelectedValid: (files: File[]) => boolean;
}

export default function useUploadFilePicker({
  onSuccess,
  isFileSelectedValid
}: UseUploadFilePickerProp) {
  const { t } = useTranslation();
  const firebase = getClient();
  const storage = getStorage(firebase);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [uploadingResults, setUploadingResults] = useState<UploadingResult[] | null>(null);
  const [uploadingProgress, setUploadingProgress] = useState<UploadingProgress>({
    totalFiles: 0,
    progress: 0,
    uploadedFiles: []
  });
  useEffect(() => {
    const uploadFileSuccess = async () => {
      const numberOfUploadedFiles = reduce(uploadingResults, memo => memo + 1, 0);
      if (
        isUploading &&
        uploadingProgress.progress === 100 &&
        numberOfUploadedFiles === uploadingProgress.totalFiles
      ) {
        await onSuccess();
        setIsUploading(false);
        setUploadingProgress({ progress: 0, totalFiles: 0, uploadedFiles: [] });
        setUploadingResults(null);
      }
    };
    uploadFileSuccess();
  }, [uploadingProgress, uploadingResults, isUploading]);

  const onFilesSelected = async (files: File[]) => {
    const isValid = isFileSelectedValid(files);
    if (!isValid) {
      return;
    }
    setIsUploading(true);
    let totalProgress;
    let results;
    const totalFiles = files.length;
    const totalBytes = reduce(files, (memo, file) => memo + file.size, 0);
    setUploadingProgress({ progress: 0, totalFiles, uploadedFiles: [] });
    each(files, async (file, index) => {
      const tmpFilePath = getTmpFileName({ name: file.name });

      const ref = firebaseRef(storage, tmpFilePath);

      const task = uploadBytesResumable(ref, file);
      task.on(
        "state_changed",
        snapshot => {
          totalProgress = {
            ...totalProgress,
            [tmpFilePath]: {
              bytesTransferred: snapshot.bytesTransferred
            }
          };
          const totalBytesTransferred = reduce(
            totalProgress,
            (memo, item) => memo + item.bytesTransferred,
            0
          );
          const progress = (totalBytesTransferred / totalBytes) * 100;
          setUploadingProgress(prev => ({
            progress,
            totalFiles,
            uploadedFiles:
              files[index].size === snapshot.bytesTransferred &&
              !includes(prev.uploadedFiles, index)
                ? [...prev.uploadedFiles, index]
                : prev.uploadedFiles
          }));
        },
        uploadError => {
          enqueueSnackbar(
            t("Unable to upload file: {{fileName}}, because: {{uploadError}}", {
              fileName: file.name,
              uploadError
            })
          );
        },
        async () => {
          const result: FullMetadata = await getMetadata(ref);
          results = {
            ...results,
            [index]: {
              file,
              result
            }
          };
          setUploadingResults(results);
        }
      );
    });
  };
  return {
    isUploading,
    uploadingResults,
    onFilesSelected,
    uploadingProgress
  };
}
