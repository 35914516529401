import React, { useState } from "react";
import { SxProps, Theme, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Popover from "@mui/material/Popover";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import PublishOutlinedIcon from "@mui/icons-material/PublishOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { enqueueSnackbar } from "notistack";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import { useNavigate } from "react-router";
import EditSessionButton from "./EditSessionButton/EditSessionButton";
import ConfirmDialog from "../../../../components/ConfirmDialog/ConfirmDialog";
import {
  TrainingClassSession,
  useDeleteTrainingClassSessionMutation,
  useDuplicateTrainingClassSessionMutation,
  useMarkSessionAsCompletedMutation,
  usePublishClassSessionMutation,
  useUnpublishClassSessionMutation
} from "../../../../generated/graphql";

export default function OptionButton({
  selectedTabId,
  session,
  onActionCompleted,
  onAnnouncementOpen,
  sxIcon,
  sxIconButton,
  isUsingInList = false
}: {
  selectedTabId: string;
  session: TrainingClassSession;
  onActionCompleted: () => void;
  onAnnouncementOpen?: () => void;
  sxIcon?: SxProps<Theme>;
  sxIconButton?: SxProps<Theme>;
  isUsingInList?: boolean;
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();

  const [optionAnchor, setOptionAnchor] = useState<null | HTMLElement>(null);
  const [openConfirmCompleteDialog, setOpenConfirmCompleteDialog] = useState(false);
  const [openConfirmPublishDialog, setOpenConfirmPublishDialog] = useState(false);
  const [openConfirmUnpublishDialog, setOpenConfirmUnpublishDialog] = useState(false);
  const [openConfirmDuplicateDialog, setOpenConfirmDuplicateDialog] = useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
  const [publishClassSession, { loading: isPublishing }] = usePublishClassSessionMutation();
  const [unpublishClassSession, { loading: isUnpublishing }] = useUnpublishClassSessionMutation();
  const [duplicateTrainingClassSession, { loading: isDuplicating }] =
    useDuplicateTrainingClassSessionMutation();
  const [deleteClassSession, { loading: isDeleting }] = useDeleteTrainingClassSessionMutation();
  const [markSessionAsCompleted, { loading: isCompleting }] = useMarkSessionAsCompletedMutation();

  const onPublishClick = () => {
    setOpenConfirmPublishDialog(true);
  };
  const onUnpublishClick = () => {
    setOpenConfirmUnpublishDialog(true);
  };
  const onDuplicateClick = () => {
    setOpenConfirmDuplicateDialog(true);
  };
  const onDeleteClick = () => {
    setOpenConfirmDeleteDialog(true);
  };
  const onMarkAsCompletedClick = () => {
    setOpenConfirmCompleteDialog(true);
  };
  const onConfirmPublishYes = () => {
    if (!session.id) {
      return;
    }
    publishClassSession({ variables: { id: session.id } }).then(result => {
      if (result?.data?.publishClassSession?.result?.code === 200) {
        enqueueSnackbar(
          t("Session '{{name}}' has been published", {
            name: result?.data?.publishClassSession?.updatedTrainingClassSession?.name
          }),
          { variant: "success" }
        );
        setOpenConfirmPublishDialog(false);
        onActionCompleted();
        return;
      }
      if (result?.data?.publishClassSession?.result?.message) {
        enqueueSnackbar(
          t("Can't publish session because: {{error}}", {
            error: result.data.publishClassSession.result.message
          }),
          { variant: "error" }
        );
        onActionCompleted();
      }
    });
  };
  const onConfirmUnpublishYes = () => {
    if (!session.id) {
      return;
    }
    unpublishClassSession({ variables: { id: session.id } }).then(result => {
      if (result?.data?.unpublishClassSession?.result?.code === 200) {
        enqueueSnackbar(
          t("Session '{{name}}' has been unpublished", {
            name: result?.data?.unpublishClassSession?.updatedTrainingClassSession?.name
          }),
          { variant: "success" }
        );
        setOpenConfirmPublishDialog(false);
        onActionCompleted();
        return;
      }
      if (result?.data?.unpublishClassSession?.result?.message) {
        enqueueSnackbar(
          t("Can't unpublish session because: {{error}}", {
            error: result.data.unpublishClassSession.result.message
          }),
          { variant: "error" }
        );
        onActionCompleted();
      }
    });
  };
  const onConfirmDuplicateYes = () => {
    if (!session.id) {
      return;
    }
    duplicateTrainingClassSession({ variables: { id: session.id } }).then(result => {
      if (result?.data?.duplicateTrainingClassSession?.result?.code === 200) {
        enqueueSnackbar(
          t("Session '{{name}}' has been duplicated", {
            name: result?.data?.duplicateTrainingClassSession?.updatedTrainingClassSession?.name
          }),
          { variant: "success" }
        );
        setOpenConfirmDuplicateDialog(false);
        onActionCompleted();
        return;
      }
      if (result?.data?.duplicateTrainingClassSession?.result?.message) {
        enqueueSnackbar(
          t("Can't duplicate session because: {{error}}", {
            error: result.data.duplicateTrainingClassSession.result.message
          }),
          { variant: "error" }
        );
        onActionCompleted();
      }
    });
  };
  const onConfirmDeleteYes = () => {
    if (!session.id) {
      return;
    }
    deleteClassSession({ variables: { id: session.id } }).then(result => {
      if (result?.data?.deleteTrainingClassSession?.code === 200) {
        enqueueSnackbar(t("Session has been deleted"), { variant: "success" });
        setOpenConfirmDeleteDialog(false);
        if (!isUsingInList) {
          navigate(-1);
        }
        onActionCompleted();
        return;
      }
      if (result?.data?.deleteTrainingClassSession?.message) {
        enqueueSnackbar(
          t("Can't delete session because: {{error}}", {
            error: result.data.deleteTrainingClassSession.message
          }),
          { variant: "error" }
        );
        onActionCompleted();
      }
    });
  };
  const onConfirmCompleteYes = () => {
    if (!session.id) {
      return;
    }
    markSessionAsCompleted({ variables: { id: session.id } }).then(result => {
      if (result?.data?.markSessionAsCompleted?.result?.code === 200) {
        enqueueSnackbar(
          t("Session '{{name}}' has been marked as completed", {
            name: result?.data?.markSessionAsCompleted?.updatedTrainingClassSession?.name
          }),
          { variant: "success" }
        );
        setOpenConfirmCompleteDialog(false);
        onActionCompleted();
        return;
      }
      if (result?.data?.markSessionAsCompleted?.result?.message) {
        enqueueSnackbar(
          t("Can't mark session '{{name}}' as completed because: {{error}}", {
            name: result?.data?.markSessionAsCompleted?.updatedTrainingClassSession?.name,
            error: result.data.markSessionAsCompleted.result.message
          }),
          { variant: "error" }
        );
        onActionCompleted();
      }
    });
  };
  const onConfirmPublishNo = () => {
    setOpenConfirmPublishDialog(false);
  };
  const onConfirmUnpublishNo = () => {
    setOpenConfirmUnpublishDialog(false);
  };
  const onConfirmDuplicateNo = () => {
    setOpenConfirmDuplicateDialog(false);
  };
  const onConfirmDeleteNo = () => {
    setOpenConfirmDeleteDialog(false);
  };
  const onConfirmCompleteNo = () => {
    setOpenConfirmCompleteDialog(false);
  };
  const onOptionButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setOptionAnchor(optionAnchor ? null : event.currentTarget);
  };

  const onCloseOption = () => {
    setOptionAnchor(null);
  };

  return (
    <>
      <IconButton
        onClick={onOptionButtonClick}
        sx={{
          width: theme.spacing(6),
          ...sxIconButton
        }}
      >
        <MoreVertIcon sx={sxIcon} />
      </IconButton>
      <Popover
        open={Boolean(optionAnchor)}
        anchorEl={optionAnchor}
        onClose={onCloseOption}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        sx={{
          "& .MuiPaper-elevation": {
            width: theme.spacing(30),
            borderRadius: theme.spacing(1),
            boxShadow: `0 0 10px  ${theme.palette.grey[98]}`
          }
        }}
      >
        <List>
          <EditSessionButton
            onEditCompleted={onActionCompleted}
            sessionId={session.id}
            isInstructor={session?.isInstructor as boolean}
          />
          {selectedTabId === "published" && (
            <>
              <ListItem
                dense
                button
                sx={{
                  padding: theme.spacing(1.5),
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end"
                }}
                onClick={onMarkAsCompletedClick}
              >
                <DoneOutlinedIcon />
                <Typography variant="button" sx={{ marginLeft: theme.spacing(1) }}>
                  {t("Mark as completed")}
                </Typography>
              </ListItem>
              <ListItem
                dense
                button
                sx={{
                  padding: theme.spacing(1.5),
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end"
                }}
                onClick={onUnpublishClick}
              >
                <FileDownloadIcon />
                <Typography variant="button" sx={{ marginLeft: theme.spacing(1) }}>
                  {t("Unpublish")}
                </Typography>
              </ListItem>
            </>
          )}
          {selectedTabId === "draft" && (
            <ListItem
              dense
              button
              sx={{
                padding: theme.spacing(1.5),
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end"
              }}
              onClick={onPublishClick}
            >
              <PublishOutlinedIcon />
              <Typography variant="button" sx={{ marginLeft: theme.spacing(1) }}>
                {t("Publish")}
              </Typography>
            </ListItem>
          )}
          <ListItem
            dense
            button
            sx={{
              padding: theme.spacing(1.5),
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end"
            }}
            onClick={onDuplicateClick}
          >
            <ContentCopyOutlinedIcon />
            <Typography variant="button" sx={{ marginLeft: theme.spacing(1) }}>
              {t("Duplicate")}
            </Typography>
          </ListItem>
          {onAnnouncementOpen && (
            <ListItem
              dense
              button
              sx={{
                padding: theme.spacing(1.5),
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end"
              }}
              onClick={onAnnouncementOpen}
            >
              <CampaignOutlinedIcon />
              <Typography variant="button" sx={{ marginLeft: theme.spacing(1) }}>
                {t("Announcement")}
              </Typography>
            </ListItem>
          )}
          <ListItem
            dense
            button
            sx={{
              padding: theme.spacing(1.5),
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end"
            }}
            onClick={onDeleteClick}
          >
            <DeleteOutlinedIcon sx={{ color: theme.palette.error.main }} />
            <Typography
              variant="button"
              sx={{ marginLeft: theme.spacing(1), color: theme.palette.error.main }}
            >
              {t("Delete")}
            </Typography>
          </ListItem>
        </List>
        {openConfirmDuplicateDialog && (
          <ConfirmDialog
            open
            onYes={onConfirmDuplicateYes}
            onNo={onConfirmDuplicateNo}
            title={t("Are you sure you want to duplicate session '{{name}}'?", {
              name: session.name
            })}
            yesLabel="Duplicate"
            showLoading={isDuplicating}
            disabledButtons={isDuplicating}
          />
        )}
        {openConfirmPublishDialog && (
          <ConfirmDialog
            open
            onYes={onConfirmPublishYes}
            onNo={onConfirmPublishNo}
            title={t("Are you sure you want to publish session '{{name}}'?", {
              name: session.name
            })}
            yesLabel="Publish"
            showLoading={isPublishing}
            disabledButtons={isPublishing}
          />
        )}
        {openConfirmUnpublishDialog && (
          <ConfirmDialog
            open
            onYes={onConfirmUnpublishYes}
            onNo={onConfirmUnpublishNo}
            title={t("Unpublish session")}
            yesLabel="Unpublish"
            content={t("Are you sure you want to unpublish session '{{name}}'?", {
              name: session.name
            })}
            showLoading={isUnpublishing}
            disabledButtons={isUnpublishing}
          />
        )}
        {openConfirmDeleteDialog && (
          <ConfirmDialog
            open
            onYes={onConfirmDeleteYes}
            onNo={onConfirmDeleteNo}
            title={t("Delete session")}
            yesLabel="Delete"
            content={t("Are you sure you want to delete session '{{name}}'?", {
              name: session.name
            })}
            showLoading={isDeleting}
            disabledButtons={isDeleting}
          />
        )}
        {openConfirmCompleteDialog && (
          <ConfirmDialog
            open
            onYes={onConfirmCompleteYes}
            onNo={onConfirmCompleteNo}
            title={t("Mark as complete")}
            yesLabel="Complete"
            content={t("Are you sure you want to complete session '{{name}}'?", {
              name: session.name
            })}
            showLoading={isCompleting}
            disabledButtons={isCompleting}
          />
        )}
      </Popover>
    </>
  );
}
