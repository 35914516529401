import React, { useState, MouseEvent, useRef, ReactElement, useEffect } from "react";
import { Box, ClickAwayListener, IconButton, MenuList, Popper, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import join from "lodash/join";
import isEmpty from "lodash/isEmpty";
import trim from "lodash/trim";
import CancelIcon from "@mui/icons-material/Cancel";
import { useTranslation } from "react-i18next";
import { useDebounce } from "use-debounce";
import AgentSelectItem from "../../../UserContactCard/AgentSelectItem";
import SearchInput from "./SearchInput";

interface MultipleSelectFilterProps {
  startIcon: ReactElement;
  label: string;
  values: { ids?: number[]; query?: string };
  filterKey: string;
  dataType?: string;

  onChange: ({
    filterKey,
    value
  }: {
    filterKey: string;
    value: { ids: number[] } | { query: string; operatorTypeId: string };
  }) => void;
  onDelete: () => void;
  handleGetAgents?: ({ query }: { query: string }) => UserCompanyRole[];
}

interface UserCompanyRole {
  id: number;
  agentCode: string;
  name: string;
  avatarUrl: string;
  email: string;
  agentLevel: {
    code: string;
  };
}

export default function AgentSelectFilter({
  values,
  startIcon,
  label,
  filterKey,
  onChange,
  onDelete,
  dataType,
  handleGetAgents
}: MultipleSelectFilterProps) {
  const theme = useTheme();
  const anchorRef = useRef(null);
  const [debouncedQuery, setDebouncedQuery] = useState<string>("");
  const [query] = useDebounce(debouncedQuery, 500);
  const { t } = useTranslation();
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [agents, setAgents] = useState<UserCompanyRole[]>([]);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [selectedAgentIds, setSelectedAgentIds] = useState<number[]>([]);

  const handleFilterButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };
  const handleClickAway = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };

  const isFilterActive = !isEmpty(values.ids) || !isEmpty(values.query);
  const onTextChange = (initText: string) => {
    setDebouncedQuery(initText);
  };

  const onGetAgents = async () => {
    if (handleGetAgents) {
      const agentResults = await handleGetAgents({ query: trim(query) });

      setAgents(agentResults as UserCompanyRole[]);
    }
  };
  useEffect(() => {
    onGetAgents();
  }, [query]);
  const onAgentSelected = ({ id: initId, isChecked }: { id: number; isChecked: boolean }) => {
    const id = Number(initId);
    let newSelectedAgentIds = selectedAgentIds;
    if (isChecked && !selectedAgentIds.includes(id)) {
      newSelectedAgentIds = [...selectedAgentIds, id];
    } else if (!isChecked && selectedAgentIds.includes(id)) {
      newSelectedAgentIds = selectedAgentIds.filter(selectedId => selectedId !== id);
    }
    setSelectedAgentIds(newSelectedAgentIds);
    let value;

    if (dataType === "agents") {
      value = { ids: newSelectedAgentIds };
    } else {
      value = {
        query: join(newSelectedAgentIds, ","),
        operatorTypeId: "includes"
      };
    }

    onChange({ filterKey, value });
  };
  return (
    <>
      <Button
        ref={anchorRef}
        sx={{
          ".MuiButton-startIcon": {
            color: isFilterActive ? theme.palette.orange[50] : theme.palette.grey[50]
          },
          borderRadius: theme.spacing(12.5),
          border: `1px solid ${isFilterActive ? theme.palette.orange[50] : theme.palette.grey[90]}`,
          backgroundColor: isFilterActive ? theme.palette.orange[90] : theme.palette.grey[100],
          ":hover": {
            backgroundColor: isFilterActive ? theme.palette.orange[80] : theme.palette.grey[90],
            border: `1px solid ${isFilterActive ? theme.palette.orange[50] : theme.palette.grey[80]}`
          }
        }}
        startIcon={startIcon}
        onClick={handleFilterButtonClick}
      >
        <Typography
          variant="h8"
          sx={{
            textTransform: "capitalize",
            color: isFilterActive ? theme.palette.orange[50] : theme.palette.grey[50]
          }}
        >
          {label}
        </Typography>
        <IconButton sx={{ ml: 1.25, p: 0 }} onClick={onDelete}>
          <CancelIcon sx={{ color: theme.palette.error.main }} />
        </IconButton>
      </Button>
      {menuOpen && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <Popper
            open
            sx={{
              zIndex: 99,
              backgroundColor: theme.palette.common.white,
              py: 1,
              px: 2.5
            }}
            anchorEl={anchorEl}
            placement="bottom-start"
          >
            <Typography
              variant="body2"
              sx={{
                color: theme.palette.grey[70],
                py: 1.5,
                fontWeight: 500
              }}
            >
              {label || t("Assignee")}
            </Typography>
            <MenuList
              sx={{
                ".Mui-selected > p": { color: theme.palette.orange[50] },
                borderRadius: theme.spacing(1),
                pt: 0,
                minWidth: theme.spacing(43.75)
              }}
            >
              <SearchInput
                text={query}
                isSmallSearch
                placeholder={t("Search assignee name")}
                onTextChange={onTextChange}
                sx={{
                  py: 1.5
                }}
                isEffectImmediately
              />

              <Box sx={{ maxHeight: "50vh", overflowY: "scroll" }}>
                {agents.map(agent => (
                  <AgentSelectItem
                    key={agent.id}
                    id={agent.id}
                    agentCode={agent.agentCode}
                    agentLevel={agent.agentLevel?.code}
                    agentName={agent.name || agent.email}
                    avatarUrl={agent.avatarUrl}
                    isSelected={selectedAgentIds.includes(Number(agent.id))}
                    onAgentSelected={onAgentSelected}
                  />
                ))}
              </Box>
            </MenuList>
          </Popper>
        </ClickAwayListener>
      )}
    </>
  );
}
