import React, {
  MouseEvent,
  useState,
  useEffect,
  useCallback,
  useMemo,
  JSXElementConstructor,
  ReactNode
} from "react";
import {
  DataGridPro,
  GridColDef,
  GridPinnedColumns,
  GridRowsProp,
  GridRowParams,
  GridColumnVisibilityModel,
  GridColumnOrderChangeParams,
  GridPaginationModel,
  GridSortModel,
  NoResultsOverlayPropsOverrides,
  NoRowsOverlayPropsOverrides,
  MuiEvent,
  GridPinnedColumnFields,
  GridSlotProps,
  GridCellParams,
  GridRowIdGetter
} from "@mui/x-data-grid-pro";
import Box from "@mui/material/Box";
import map from "lodash/map";
import filter from "lodash/filter";
import { cloneDeep, findIndex, isEmpty, pullAt, sortBy } from "lodash";
import { LinearProgress, alpha, useMediaQuery, useTheme } from "@mui/material";
import { OperationVariables } from "@apollo/client";
import { ListSettings } from "./setListConfiguration";
import availableGridColumnActions from "./availableGridColumnActions";
import DataGridColumnsPanel from "./DataGridColumnsPanel/DataGridColumnsPanel";
import CustomNoRowsOrResultsOverlay from "./CustomNoRowsOrResultsOverlay";
import { DataGridFilterModel } from "./DataGridFilter/AddFilterButton";
import DataGridToolbar from "./DataGridToolbar/DataGridToolbar";
import DataGridFooter from "./DataGridFooter/DataGridFooter";
import { UserCompanyRole } from "../../generated/graphql";

type CustomGridColDef = GridColDef & {
  isCustomFilter?: boolean;
};

export type ListCustomOptions = {
  columnVisibilityModel: GridColumnVisibilityModel;
  pinnedColumns: GridPinnedColumnFields;
  columnOrders: string[];
  presetId: number;
  paginationModel: GridPaginationModel;
  filterModel: DataGridFilterModel;
  sortModel: GridSortModel;
};

interface CustomDataGridProps {
  rows: GridRowsProp[];
  onRowClick?: (params: GridRowParams, event: MuiEvent<MouseEvent>) => void;
  onCellClick?: (params: GridCellParams, event: MuiEvent<MouseEvent>) => void;
  onExportClick?: () => void;
  isExporting?: boolean;
  onCreateNewPreset: ({
    presetName,
    columnVisibilityModel,
    columnOrders,
    pinnedColumns,
    filterModel,
    paginationModel,
    sortModel
  }) => void;
  onUpdatePreset: ({
    presetId,
    presetName,
    columnVisibilityModel,
    columnOrders,
    pinnedColumns,
    filterModel,
    paginationModel,
    sortModel
  }) => void;
  onDeletePreset: ({ presetId }: { presetId: string }) => void;
  onSettingsChange: ({
    filterModel,
    selectedPresetId,
    columnVisibilityModel,
    columnOrders,
    pinnedColumns,
    paginationModel,
    sortModel
  }: {
    filterModel: DataGridFilterModel;
    pinnedColumns: GridPinnedColumns;
    columnVisibilityModel: GridColumnVisibilityModel;
    paginationModel: GridPaginationModel;
    selectedPresetId: number;
    columnOrders: string[];
    sortModel: GridSortModel;
  }) => void;
  listSettings: ListSettings;
  totalRowCount: number;
  loading: boolean;
  dataLoading: boolean;
  settings: ListCustomOptions;
  isMultiRowSelectionAllowed?: boolean;
  disableRowSelectionOnClick?: boolean;
  backgroundColor: string | undefined;
  handleGetAgents?: ({ query }: { query: string }) => Promise<UserCompanyRole[]>;
  getRowId?: GridRowIdGetter<GridRowsProp>;
  renderCellDataProps?: OperationVariables;
  toolbarButtons?: ReactNode;
  onRowSelectedChange?: any;
}

export default function DataGrid({
  listSettings,
  settings,
  onRowClick,
  onCellClick,
  loading,
  dataLoading,
  onSettingsChange,
  onCreateNewPreset,
  onUpdatePreset,
  onDeletePreset,
  onExportClick,
  isExporting,
  rows,
  totalRowCount,
  isMultiRowSelectionAllowed = true,
  disableRowSelectionOnClick = false,
  backgroundColor,
  handleGetAgents,
  getRowId,
  renderCellDataProps,
  toolbarButtons,
  onRowSelectedChange
}: CustomDataGridProps) {
  const theme = useTheme();
  const isMDDown = useMediaQuery(theme.breakpoints.down("md"));
  const isLGDown = useMediaQuery(theme.breakpoints.down("lg"));

  const [columnButtonEl, setColumnButtonEl] = useState<HTMLElement | null>(null);

  const [pinnedColumns, setPinnedColumns] = useState<GridPinnedColumnFields>();
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<Record<string, boolean>>();
  const [columnOrders, setColumnOrders] = useState<string[] | undefined>([]);
  const [rowCount, setRowCount] = useState<number>(totalRowCount || 0);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 20
  });
  const [filterModel, setFilterModel] = useState<DataGridFilterModel>({});
  const [sortModel, setSortModel] = useState<GridSortModel>();
  const [selectedPresetId, setSelectedPresetId] = useState<number | undefined>();
  const [rowSelected, setRowSelected] = useState<string[]>([]);

  const triggerSettingsChange = useCallback(
    changes => {
      const newSettings = {
        filterModel,
        pinnedColumns,
        columnVisibilityModel,
        columnOrders,
        selectedPresetId,
        paginationModel,
        sortModel,
        ...changes
      };

      onSettingsChange(newSettings);
    },
    [
      onSettingsChange,
      selectedPresetId,
      filterModel,
      pinnedColumns,
      columnVisibilityModel,
      columnOrders,
      paginationModel,
      sortModel
    ]
  );

  useEffect(() => {
    const {
      columnVisibilityModel: initialColumnVisibilityModel,
      pinnedColumns: initialPinnedColumns,
      presetId,
      filterModel: filterModelSettings,
      paginationModel: initialPaginationModel,
      columnOrders: initialColumnOrders,
      sortModel: initialSortModel
    } = settings;

    setPinnedColumns(initialPinnedColumns);
    setColumnVisibilityModel(initialColumnVisibilityModel);
    setSelectedPresetId(presetId);
    setColumnOrders(initialColumnOrders);
    setPaginationModel(initialPaginationModel);
    setFilterModel(filterModelSettings as DataGridFilterModel);
    setSortModel(initialSortModel as GridSortModel);
  }, [settings]);

  useEffect(() => {
    setRowCount(prevRowCountState =>
      totalRowCount !== undefined ? totalRowCount : prevRowCountState
    );
  }, [totalRowCount, setRowCount]);

  const handleActionClick = ({ action, rowId }: { action: string; rowId: string | number }) => {
    // eslint-disable-next-line no-console
    console.log(`Action Clicked: ${action}, rowId: ${rowId}`);
  };

  const columns = useMemo(() => {
    const listColumns = cloneDeep(listSettings?.columns);
    const columnsWithActions = isEmpty(listSettings.listActions)
      ? filter(listColumns, listColumn => listColumn.type !== "actions")
      : map(listColumns, column => {
          if (column.type !== "actions") {
            return column;
          }

          return {
            field: "actions",
            type: "actions",
            getActions: (params: GridRowParams) =>
              map(listSettings.listActions, listAction => {
                const GridAction = availableGridColumnActions[listAction.action];

                return <GridAction onActionClick={handleActionClick} rowId={params.id} />;
              })
          };
        });

    const orderedColumns = sortBy(
      map(columnsWithActions, column => ({
        ...column,
        order: findIndex(columnOrders, value => value === column.field)
      })),
      "order"
    );

    return orderedColumns as GridColDef[];
  }, [columnOrders]);

  const getTogglableColumns = (availableColumns: CustomGridColDef[]) =>
    map(
      filter(availableColumns, column => !column.isCustomFilter),
      "field"
    );
  const handleColumnOrderChange = (params: GridColumnOrderChangeParams) => {
    const { column, oldIndex, targetIndex } = params;

    const oldIndexWithoutCheckbox = isMultiRowSelectionAllowed ? oldIndex - 1 : oldIndex;
    const targetIndexWithoutCheckbox = isMultiRowSelectionAllowed ? targetIndex - 1 : targetIndex;

    const allColumns = map(columns, "field");

    if (targetIndexWithoutCheckbox > oldIndexWithoutCheckbox) {
      allColumns.splice(targetIndexWithoutCheckbox + 1, 0, column.field);
      pullAt(allColumns, [oldIndexWithoutCheckbox]);
    } else {
      allColumns.splice(targetIndexWithoutCheckbox, 0, column.field);
      pullAt(allColumns, [oldIndexWithoutCheckbox + 1]);
    }

    setColumnOrders(allColumns);
    setSelectedPresetId(undefined);

    triggerSettingsChange({ columnOrders: allColumns, selectedPresetId: "" });
  };

  const handlePinnedColumnsChange = (updatedPinnedColumns: GridPinnedColumnFields) => {
    setPinnedColumns(updatedPinnedColumns);
    setSelectedPresetId(undefined);

    triggerSettingsChange({ pinnedColumns: updatedPinnedColumns, selectedPresetId: "" });
  };

  const handleColumnVisibilityModelChange = (
    updatedColumnVisibilityModel: GridColumnVisibilityModel
  ) => {
    setColumnVisibilityModel(updatedColumnVisibilityModel);
    setSelectedPresetId(undefined);

    triggerSettingsChange({
      columnVisibilityModel: updatedColumnVisibilityModel,
      selectedPresetId: ""
    });
  };

  const handlePaginationModelChange = (model: GridPaginationModel) => {
    const { pageSize } = model;
    setPaginationModel(model);
    const changes = { paginationModel: model, selectedPresetId };

    const currentPaginationModel = paginationModel;

    if (pageSize !== currentPaginationModel?.pageSize) {
      setSelectedPresetId(undefined);
      changes.selectedPresetId = undefined;
    }
    triggerSettingsChange(changes);
  };

  const handleSortModelChange = (model: GridSortModel) => {
    setSortModel(model);
    setSelectedPresetId(undefined);

    triggerSettingsChange({ sortModel: model, selectedPresetId: "" });
  };

  const handlePresetChange = ({ selectedPresetId: selectedId }) => {
    if (selectedId !== selectedPresetId) {
      setSelectedPresetId(selectedId);
      setPinnedColumns({});
      setColumnVisibilityModel({});
      setColumnOrders([]);
      setFilterModel({});
      setSortModel([]);
      setPaginationModel({ page: 0, pageSize: 20 });

      triggerSettingsChange({
        selectedPresetId: selectedId,
        columnVisibilityModel: {},
        pinnedColumns: {},
        columnOrders: [],
        paginationModel: { page: 0, pageSize: 20 },
        sortModel: []
      });
    }
  };

  const handleFilterChange = (model: DataGridFilterModel) => {
    setFilterModel(model);
    setSelectedPresetId(undefined);
    const currentPaginationModel = paginationModel;
    setPaginationModel({ ...currentPaginationModel, page: 0 });

    triggerSettingsChange({
      filterModel: model,
      selectedPresetId: "",
      paginationModel: { ...currentPaginationModel, page: 0 }
    });
  };

  const handleCreatePreset = ({ name }: { name: string }) =>
    onCreateNewPreset({
      presetName: name,
      filterModel,
      columnVisibilityModel,
      columnOrders,
      pinnedColumns,
      paginationModel,
      sortModel
    });

  const handleUpdatePreset = ({ name, presetId }: { name: string; presetId: string }) =>
    onUpdatePreset({
      presetName: name,
      presetId,
      filterModel,
      columnVisibilityModel,
      columnOrders,
      pinnedColumns,
      paginationModel,
      sortModel
    });

  const handleDeletePreset = ({ presetId }: { presetId: string }) => {
    onDeletePreset({ presetId });
  };

  const handleDeselect = () => {
    setRowSelected([]);
  };

  const onRowSelectionChange = newSelectedRow => {
    setRowSelected(newSelectedRow);
    if (onRowSelectedChange) {
      onRowSelectedChange(newSelectedRow);
    }
  };

  return (
    <Box sx={{ width: "100%", mt: 5 }}>
      <Box sx={{ flexGrow: 1 }}>
        <DataGridPro
          sx={{
            borderRadius: 5,
            boxShadow: `0 0 ${theme.spacing(5)} 0 ${alpha(theme.palette.common.black, 0.04)}`,
            borderStyle: "none",
            ".MuiDataGrid-toolbarContainer": {
              px: 2.5,
              py: 1.5
            },
            ".MuiDataGrid-main": {
              width: { xs: "unset", md: "100%" }
            },
            ".MuiDataGrid-main > div > div:nth-child(2)": {
              maxHeight: `${theme.spacing(100)} !important`
            },
            ".highlight-cell": {
              background: "#FBE2CE"
            },
            ".highlight-header": {
              background: `linear-gradient(0deg, rgba(10, 11, 10, 0.08), rgba(10, 11, 10, 0.08)), ${theme.palette.orange[90]} !important`
            },
            "& .MuiDataGrid-row:hover": {
              cursor: "pointer"
            },
            ".MuiDataGrid-overlayWrapper": {
              height: "auto !important"
            },
            ".MuiDataGrid-overlayWrapperInner": {
              height: "auto !important"
            },
            ".MuiDataGrid-withBorderColor": {
              borderRadius: 0,
              borderColor: theme.palette.grey[80]
            },
            ".MuiDataGrid-footerContainer": {
              display: {
                xs: isEmpty(rowSelected) ? "flex" : "block",
                md: isEmpty(rowSelected) ? "none" : "flex"
              },
              position: "sticky",
              bottom: 0,
              backgroundColor: "white",
              width: "100vw",
              ml: "calc(50% - 50vw)",
              zIndex: 10
            },
            ".MuiDataGrid-columnHeader": {
              background: backgroundColor || theme.palette.orange[90]
            },
            ".MuiDataGrid-row": {
              background: theme.palette.common.white
            },
            ".MuiDataGrid-pinnedColumnHeaders": {
              paddingRight: theme.spacing(0, "!important")
            },
            ".MuiDataGrid-pinnedColumnHeaders .MuiDataGrid-columnHeader": {
              paddingRight: theme.spacing(0, "!important"),
              background: "#D9DAD9"
            },
            ".MuiDataGrid-pinnedColumns .MuiDataGrid-row": {
              background: theme.palette.backgroundColor.light
            },
            ".MuiTablePagination-root": {
              marginLeft: "auto",
              color: theme.palette.grey[50],
              fontFamily: "Montserrat",
              fontSize: theme.typography.pxToRem(18),
              fontWeight: 500,
              lineHeight: "160%",
              letterSpacing: theme.typography.pxToRem(0.288)
            },
            ".MuiDataGrid-columnHeaderTitle": {
              fontFamily: "Plus Jakarta Sans",
              fontSize: theme.typography.pxToRem(18),
              fontWeight: 500,
              lineHeight: "160%",
              letterSpacing: theme.typography.pxToRem(0.288),
              color: theme.palette.orange[10]
            },
            ".MuiButton-text": {
              textTransform: "uppercase",
              color: theme.palette.orange[30]
            },
            ".MuiButtonBase-root": {
              fontWeight: 600,
              fontSize: theme.typography.pxToRem(18)
            },
            ".MuiPaper-root": { borderRadius: theme.spacing(2.5) },
            ".MuiDataGrid-cell": {
              alignContent: "center"
            }
          }}
          rows={rows || []}
          rowCount={rowCount}
          columns={columns}
          autosizeOnMount
          loading={loading}
          checkboxSelection={isMultiRowSelectionAllowed}
          disableRowSelectionOnClick={disableRowSelectionOnClick}
          onRowClick={onRowClick}
          onCellClick={onCellClick}
          getRowId={getRowId}
          rowSelectionModel={onRowSelectedChange ? rowSelected : rowSelected}
          onRowSelectionModelChange={onRowSelectionChange}
          paginationMode="server"
          paginationModel={paginationModel}
          sortModel={sortModel}
          localeText={{
            columnsManagementSearchTitle: "Find column",
            toolbarColumns: isLGDown ? "" : "Columns",
            toolbarExport: isLGDown ? "" : "Export",
            toolbarDensity: isLGDown ? "" : "Density"
          }}
          filterMode="server"
          sortingMode="server"
          disableColumnFilter
          pageSizeOptions={[20, 40, 60, 80, 100]}
          disableDensitySelector={!listSettings.isDensitySelectionEnabled}
          pagination
          slots={{
            noRowsOverlay: CustomNoRowsOrResultsOverlay as JSXElementConstructor<
              GridSlotProps["noRowsOverlay"]
            >,
            noResultsOverlay: CustomNoRowsOrResultsOverlay as JSXElementConstructor<
              GridSlotProps["noResultsOverlay"]
            >,
            loadingOverlay: LinearProgress as JSXElementConstructor<
              GridSlotProps["loadingOverlay"]
            >,
            toolbar: DataGridToolbar as JSXElementConstructor<GridSlotProps["toolbar"]>,
            columnsPanel: DataGridColumnsPanel as JSXElementConstructor<
              GridSlotProps["columnsPanel"]
            >,
            footer: DataGridFooter as JSXElementConstructor<GridSlotProps["footer"]>
          }}
          slotProps={{
            basePopper: {
              sx: {
                ".MuiPaper-root": {
                  borderRadius: theme.spacing(2.5)
                }
              }
            },
            footer: {
              selectedRowIds: rowSelected,
              rows,
              renderCellDataProps,
              rowCount,
              toolbarButtons,
              onDeselect: handleDeselect
            },
            toolbar: {
              setColumnButtonEl,
              listSettings,
              filterModel,
              selectedPresetId,
              rowCount,
              onPresetChange: handlePresetChange,
              onFilterChange: handleFilterChange,
              onCreatePreset: handleCreatePreset,
              onUpdatePreset: handleUpdatePreset,
              onDeletePreset: handleDeletePreset,
              onExportClick,
              isExporting,
              handleGetAgents
            },
            columnsManagement: {
              getTogglableColumns
            },
            columnsPanel: {
              anchorEl: columnButtonEl
            },
            noRowsOverlay: { filterModel, dataLoading } as NoRowsOverlayPropsOverrides,
            noResultsOverlay: { filterModel, dataLoading } as NoResultsOverlayPropsOverrides
          }}
          columnVisibilityModel={columnVisibilityModel}
          pinnedColumns={isMDDown ? {} : pinnedColumns}
          onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
          onPinnedColumnsChange={handlePinnedColumnsChange}
          onColumnOrderChange={handleColumnOrderChange}
          onPaginationModelChange={handlePaginationModelChange}
          onSortModelChange={handleSortModelChange}
        />
      </Box>
    </Box>
  );
}
