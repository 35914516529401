import React, { ChangeEvent } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useMediaQuery, useTheme } from "@mui/material";
import CustomSwitch from "./CustomSwitch";

export default function CustomToggleButton({
  disabled,
  checked,
  onChange,
  label,
  isMarginLeftAuto
}: {
  disabled?: boolean;
  checked: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  label?: string;
  isMarginLeftAuto?: boolean;
}) {
  const theme = useTheme();
  const isXSScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center"
      }}
    >
      <Typography variant="body2" sx={{ fontWeight: 500 }}>
        {label}
      </Typography>
      <CustomSwitch
        disabled={disabled}
        checked={checked}
        onChange={onChange}
        isXSScreen={isXSScreen}
        isMarginLeftAuto={isMarginLeftAuto}
      />
    </Box>
  );
}
