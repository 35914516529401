import React, { ReactNode } from "react";
import { GridFooterContainer, GridRowsProp, GridSlotProps } from "@mui/x-data-grid-pro";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import { OperationVariables } from "@apollo/client";
import DataGridPagination from "../DataGridPagination/DataGridPagination";
import DataGridSelectedToolbar from "../DataGridToolbar/DataGridSelectedToolbar";

type DataGridFooterProps = GridSlotProps["toolbar"] & {
  onDeselect?: () => void;
  selectedRowIds?: string[];
  rows: GridRowsProp[];
  renderCellDataProps?: OperationVariables;
  toolbarButtons?: ReactNode;
};

export default function DataGridFooter({
  selectedRowIds,
  rows,
  rowCount,
  onDeselect,
  renderCellDataProps,
  toolbarButtons
}: DataGridFooterProps) {
  const theme = useTheme();
  const isMDDown = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <GridFooterContainer>
      {!isEmpty(selectedRowIds) && (
        <DataGridSelectedToolbar
          rows={rows}
          selectedRowIds={selectedRowIds}
          onDeselect={onDeselect}
          renderCellDataProps={renderCellDataProps}
          toolbarButtons={toolbarButtons}
        />
      )}
      {isMDDown ? <DataGridPagination rowCount={rowCount} /> : null}
    </GridFooterContainer>
  );
}
