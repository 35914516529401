import { setContext } from "@apollo/client/link/context";

export default function createAuthLink() {
  return setContext((_, { headers }) => {
    const headersToReturn = {
      ...headers,
      "x-timezone": Intl.DateTimeFormat().resolvedOptions().timeZone
    };
    if (process.env.REACT_APP_DEFAULT_COMPANY_ID) {
      headersToReturn["x-company-id"] = process.env.REACT_APP_DEFAULT_COMPANY_ID;
    }
    return {
      headers: headersToReturn
    };
  });
}
