import isString from "lodash/isString";
import { format as fmt, isValid } from "date-fns";

export default function formatDate(
  dateValue: Date | string | null | undefined,
  format = "MM/dd/yyyy"
) {
  if (!dateValue) {
    return undefined;
  }
  const date = isString(dateValue) ? new Date(dateValue) : dateValue;

  if (!isValid(date)) {
    return undefined;
  }
  return fmt(date, format);
}
