import React from "react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import map from "lodash/map";
import { useTheme } from "@mui/material/styles";
import { filesize } from "filesize";
import { useMediaQuery } from "@mui/material";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { WorkflowItemFieldValueFile } from "../../generated/graphql";
import EllipsisText from "../EllipsisText/EllipsisText";

interface FileListProps {
  files: WorkflowItemFieldValueFile[];
  onRemove?: (removeFile: WorkflowItemFieldValueFile, removeIndex: number) => void;
  isEditable: boolean;
  showDownloadButton?: boolean;
  showDeleteButton?: boolean;
  onDownload?: (file: any, index: number) => void;
}

export default function FileList({
  files,
  onRemove,
  isEditable,
  showDownloadButton,
  showDeleteButton,
  onDownload
}: FileListProps) {
  const theme = useTheme();
  const isXlAndDown = useMediaQuery(theme.breakpoints.down("xl"));

  const onFileRemove =
    ({ file, index }) =>
    event => {
      event.stopPropagation();
      if (!isEditable) {
        return;
      }
      if (onRemove) {
        onRemove(file, index);
      }
    };

  const onFileDownload =
    ({ file, index }) =>
    event => {
      event.stopPropagation();
      if (onDownload) {
        onDownload(file, index);
      }
    };
  return (
    <Box
      data-testid="fileList"
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        flexDirection: "column",
        gap: theme.spacing(1)
      }}
    >
      {map(files, (file, index) => (
        <Box
          sx={{
            display: "inline-flex",
            justifyContent: "flex-start",
            alignItems: "center",
            marginTop: theme.spacing(1)
          }}
          key={file.name}
          data-testid={`fileListItem-${index}`}
        >
          <Box
            onClick={onDownload ? onFileDownload({ file, index }) : undefined}
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row"
            }}
          >
            <EllipsisText
              maxLine={1}
              sx={{
                color: theme.palette.orange[30],
                fontWeight: 500,
                wordBreak: "break-all",
                ":hover": {
                  textDecoration: "underline",
                  cursor: "pointer"
                }
              }}
              text={file.name}
              variant="body1"
            />
            <Typography
              data-testid={`fileListItemText-${index}`}
              sx={{
                display: "inline-block",
                whiteSpace: "nowrap",
                color: theme.palette.grey[70],
                marginLeft: theme.spacing(isXlAndDown ? 1 : 2.5)
              }}
              variant="body2"
            >
              {filesize(file.size)}
            </Typography>
            {showDownloadButton && (
              <IconButton onClick={onFileDownload({ file, index })}>
                <FileDownloadOutlinedIcon />
              </IconButton>
            )}
            {showDeleteButton && (
              <Box>
                <IconButton
                  edge="end"
                  className="removeButton"
                  data-testid={`fileListItemRemoveButton-${index}`}
                  onClick={onFileRemove({ file, index })}
                  disabled={!isEditable}
                >
                  <DeleteOutlineIcon
                    sx={{
                      color: isEditable ? theme.palette.orange[30] : theme.palette.grey[70]
                    }}
                  />
                </IconButton>
              </Box>
            )}
          </Box>
        </Box>
      ))}
    </Box>
  );
}
