import React, { useMemo } from "react";
import MUIBreadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";
import type { CrumbProps } from "../PageTitle/PageTitle";

type BreadcrumbsProps = {
  items: CrumbProps[];
};

export default function Breadcrumbs({ items }: BreadcrumbsProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const crumbs: CrumbProps[] = useMemo(() => {
    if (isMobile) {
      return [items[items.length - 1]];
    }
    return items;
  }, [isMobile, items]);

  return (
    <MUIBreadcrumbs aria-label="breadcrumb" separator="›">
      {crumbs.map((item, index) => {
        if (index < crumbs.length - 1) {
          if (item.href) {
            return (
              <Typography
                key={item.label}
                color="inherit"
                to={item.href}
                component={Link}
                variant="body3"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  textDecoration: "none",
                  "&:hover": {
                    textDecoration: "underline"
                  },
                  alignItems: "center",
                  color: theme.palette.orange[30]
                }}
              >
                {item.Icon && (
                  <item.Icon sx={{ marginRight: 0.5, fontSize: theme.typography.pxToRem(20) }} />
                )}
                {item.label}
              </Typography>
            );
          }
          return (
            <Typography
              key={item.label}
              variant="body3"
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                color: theme.palette.common.black
              }}
            >
              {item.Icon && (
                <item.Icon
                  sx={{
                    marginRight: 0.5,
                    fontSize: theme.typography.pxToRem(20)
                  }}
                />
              )}
              {item.label}
            </Typography>
          );
        }
        return (
          <Typography
            key={item.label}
            variant="body3"
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              color: theme.palette.grey[70]
            }}
          >
            {item.Icon && (
              <item.Icon
                sx={{
                  marginRight: 0.5,
                  fontSize: theme.typography.pxToRem(20)
                }}
              />
            )}
            {item.label}
          </Typography>
        );
      })}
    </MUIBreadcrumbs>
  );
}
