import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { SnackbarProvider } from "notistack";
import Router from "./router/Router";
import createTheme from "./createTheme";
import { useAuth } from "./contexts/auth";

export default function Root() {
  const { config } = useAuth();

  const theme = createTheme({ company: config?.company });
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarProvider
        maxSnack={5}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        autoHideDuration={2000}
      >
        <Router />
      </SnackbarProvider>
    </ThemeProvider>
  );
}
