import React from "react";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import SaveIcon from "@mui/icons-material/Save";
import { useTranslation } from "react-i18next";

export default function GridColumnActionSave({
  onActionClick,
  rowId
}: {
  onActionClick: ({ action, rowId }: { action: string; rowId: string | number }) => void;
  rowId: string | number;
}) {
  const { t } = useTranslation();

  return (
    <GridActionsCellItem
      icon={<SaveIcon />}
      label={t("Save")}
      sx={{
        color: "primary.main"
      }}
      onClick={() => onActionClick({ action: "save", rowId })}
    />
  );
}
