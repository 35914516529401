import React, { ChangeEvent, useState } from "react";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import { Button, List, ListItem, Typography, useMediaQuery, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";
import isEmpty from "lodash/isEmpty";
import includes from "lodash/includes";
import map from "lodash/map";
import join from "lodash/join";
import EditIcon from "@mui/icons-material/Edit";
import { OverridableTextFieldProps } from "../../../../../../types/mui";
import DateTimeInput from "../../../../../../components/DateTimeInput/DateTimeInput";
import TextFieldAndMarkdownPreview from "../../../../../../components/TextFieldAndMarkdownPreview/TextFieldAndMarkdownPreview";
import CustomToggleButton from "../../../../../../components/CustomToggleButton/CustomToggleButton";
import RequirementsDialog from "../RequirementsDialog/RequirementsDialog";
import {
  TrainingClassRequirementInput,
  TrainingClassSessionRequirement
} from "../../../../../../generated/graphql";

interface DateProps {
  helperText?: string;
  error?: boolean;
  value: Date;
}
export default function GeneralInformationForm({
  name,
  description,
  startTimestamp,
  endTimestamp,
  areClassRequirementsIgnored,
  isHomeworkSubmissionAllowed,
  isOpenForEnrollment,
  requirements,
  onNameChange,
  onDescriptionChange,
  onStartTimestampChange,
  onEndTimestampChange,
  onAreClassRequirementsIgnoredChange,
  onIsHomeworkSubmissionAllowedChange,
  onIsOpenForEnrollmentChange,
  onRequirementChange,
  hasEnrolledItem
}: {
  name: OverridableTextFieldProps;
  description: OverridableTextFieldProps;
  startTimestamp: DateProps;
  endTimestamp: DateProps;
  areClassRequirementsIgnored: boolean;
  isHomeworkSubmissionAllowed: boolean;
  isOpenForEnrollment: boolean;
  requirements?: TrainingClassSessionRequirement[];
  onNameChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onDescriptionChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onStartTimestampChange: (dateValue: any) => void;
  onEndTimestampChange: (dateValue: any) => void;
  onAreClassRequirementsIgnoredChange: (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  onIsHomeworkSubmissionAllowedChange: (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  onIsOpenForEnrollmentChange: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  onRequirementChange?: (values: TrainingClassRequirementInput[]) => void;
  hasEnrolledItem?: boolean;
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const isXSScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [isSettingRequirements, setIsSettingRequirements] = useState(false);

  const onSetRequiremetnsClick = () => {
    setIsSettingRequirements(true);
  };
  const onRequirementsDialogClose = () => {
    setIsSettingRequirements(false);
  };
  const onRequirementFormChange = (values: TrainingClassRequirementInput[]) => {
    if (onRequirementChange) {
      onRequirementChange(values);
    }
    setIsSettingRequirements(false);
  };

  return (
    <Box>
      <TextField
        id="name"
        margin="normal"
        label={t("Session Name")}
        autoFocus
        fullWidth
        required
        variant="outlined"
        onChange={onNameChange}
        inputProps={{ "data-testid": "name" }}
        {...name}
      />
      <Box sx={{ marginTop: theme.spacing(isXSScreen ? 0 : 2) }}>
        <TextFieldAndMarkdownPreview
          label={t("Description")}
          text={description}
          onTextChange={onDescriptionChange}
        />
      </Box>
      <Box sx={{ marginTop: theme.spacing(isXSScreen ? 3 : 3.5) }}>
        <Grid container spacing={1.5}>
          <Grid item xs={12} md={6}>
            <DateTimeInput
              label={t("Start Date & Time")}
              fullWidth
              onChange={onStartTimestampChange}
              onPickerChange={onStartTimestampChange}
              format="MM/dd/yyyy hh:mm aa"
              {...startTimestamp}
              sx={{
                marginTop: theme.spacing(1),
                "& .MuiInputBase-root.Mui-disabled": {
                  color: theme.palette.grey[10]
                }
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DateTimeInput
              label={t("End Date & Time")}
              fullWidth
              onChange={onEndTimestampChange}
              onPickerChange={onEndTimestampChange}
              format="MM/dd/yyyy hh:mm aa"
              {...endTimestamp}
              sx={{
                marginTop: theme.spacing(isXSScreen ? 0 : 1),
                "& .MuiInputBase-root.Mui-disabled": {
                  color: theme.palette.grey[10]
                }
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          marginTop: theme.spacing(2.5)
        }}
      >
        <CustomToggleButton
          disabled={hasEnrolledItem}
          checked={isHomeworkSubmissionAllowed}
          onChange={onIsHomeworkSubmissionAllowedChange}
          label={t("Attendees must submit homework")}
          isMarginLeftAuto
        />
      </Box>
      <Box
        sx={{
          marginTop: theme.spacing(2.5)
        }}
      >
        <CustomToggleButton
          checked={isOpenForEnrollment}
          onChange={onIsOpenForEnrollmentChange}
          label={t("Open for enrollment")}
          isMarginLeftAuto
        />
      </Box>
      <Box
        sx={{
          marginTop: theme.spacing(2.5)
        }}
      >
        <CustomToggleButton
          disabled={hasEnrolledItem}
          checked={!areClassRequirementsIgnored}
          onChange={onAreClassRequirementsIgnoredChange}
          label={t("Attendees must meet all course requirements to enroll")}
          isMarginLeftAuto
        />
      </Box>
      {!areClassRequirementsIgnored && (
        <Box sx={{ marginTop: theme.spacing(0) }}>
          {isEmpty(requirements) && !hasEnrolledItem && (
            <Typography
              variant="body3"
              sx={{
                width: "fit-content",
                userSelect: "none",
                font: "Poppins",
                fontWeight: 500,
                color: theme.palette.orange[30],
                ":hover": { textDecoration: "underline" }
              }}
              onClick={onSetRequiremetnsClick}
            >
              {t("Set requirements")}
            </Typography>
          )}
          {!isEmpty(requirements) && (
            <Box sx={{ display: "flex", flexDirection: "row", marginTop: theme.spacing(1) }}>
              <Grid item xs={8} md={8}>
                <Typography
                  variant="body3"
                  sx={{ font: "Poppins", color: theme.palette.orange[30], fontWeight: 500 }}
                >
                  {t("{{numberOfRequirements}} {{requirementText}}", {
                    numberOfRequirements: requirements?.length,
                    requirementText:
                      requirements && requirements?.length > 1 ? "Requirements" : "Requirement"
                  })}
                </Typography>
                <List>
                  {map(requirements, requirement => {
                    if (
                      includes(
                        ["submittedExchangeSales", "fieldTrainingAppointments", "exchangeSales"],
                        requirement.code
                      )
                    ) {
                      return (
                        <ListItem
                          sx={{
                            "::before": {
                              content: "'\u2022'",
                              marginLeft: theme.spacing(0.75),
                              color: theme.palette.orange[30],
                              fontSize: theme.spacing(3.75)
                            },
                            margin: theme.spacing(0),
                            padding: theme.spacing(0),
                            gap: theme.spacing(0.75)
                          }}
                        >
                          <Typography
                            variant="body3"
                            sx={{
                              font: "Poppins",
                              fontWeight: 500,
                              color: theme.palette.orange[30]
                            }}
                          >
                            {`${requirement.name}: ${requirement.value}`}
                          </Typography>
                        </ListItem>
                      );
                    }
                    if (includes(["completedClass", "appointed"], requirement.code)) {
                      return (
                        <ListItem
                          sx={{
                            "::before": {
                              content: "'\u2022'",
                              marginLeft: theme.spacing(0.75),
                              color: theme.palette.orange[30],
                              fontSize: theme.spacing(3.75)
                            },
                            margin: theme.spacing(0),
                            padding: theme.spacing(0),
                            gap: theme.spacing(0.75)
                          }}
                        >
                          <Typography
                            variant="body3"
                            sx={{
                              font: "Poppins",
                              fontWeight: 500,
                              color: theme.palette.orange[30]
                            }}
                          >
                            {`${requirement.name}: ${join(requirement?.textValue, ",")}`}
                          </Typography>
                        </ListItem>
                      );
                    }
                    return (
                      <ListItem
                        sx={{
                          "::before": {
                            content: "'\u2022'",
                            marginLeft: theme.spacing(0.75),
                            color: theme.palette.orange[30],
                            fontSize: theme.spacing(3.75)
                          },
                          margin: theme.spacing(0),
                          padding: theme.spacing(0),
                          gap: theme.spacing(0.75)
                        }}
                      >
                        <Typography
                          variant="body3"
                          sx={{
                            font: "Poppins",
                            fontWeight: 500,
                            color: theme.palette.orange[30]
                          }}
                        >
                          {requirement.name}
                        </Typography>
                      </ListItem>
                    );
                  })}
                </List>
              </Grid>
              <Grid item xs={4} md={4} sx={{ display: "flex" }}>
                <Button
                  disabled={hasEnrolledItem}
                  variant="outlined"
                  size="small"
                  sx={{
                    marginTop: "auto",
                    marginLeft: "auto",
                    width: theme.spacing(20),
                    font: "Poppins",
                    color: theme.palette.orange[40],
                    fontWeight: 500
                  }}
                  startIcon={<EditIcon />}
                  onClick={onSetRequiremetnsClick}
                >
                  {t("Edit")}
                </Button>
              </Grid>
            </Box>
          )}
        </Box>
      )}
      {isSettingRequirements && (
        <RequirementsDialog
          disabled={hasEnrolledItem}
          requirements={requirements}
          onClose={onRequirementsDialogClose}
          onRequirementChange={onRequirementFormChange}
        />
      )}
    </Box>
  );
}
