import React from "react";
import { Box, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";

interface MultipleSelectFilterLabelProps {
  item: {
    value: string;
    label: string;
    textColor?: string;
    backgroundColor?: string;
  };
  filterKey: string;
}

export default function MultipleSelectFilterLabel({
  item,
  filterKey
}: MultipleSelectFilterLabelProps) {
  const theme = useTheme();

  if (filterKey === "states") {
    return (
      <Box
        sx={{
          backgroundColor: item.backgroundColor,
          padding: theme.spacing(0.5, 1.5),
          borderRadius: theme.spacing(12.5)
        }}
      >
        <Typography
          variant="dataGridState"
          sx={{
            color: item.textColor,
            fontWeight: 400,
            margin: theme.spacing("auto", 0),
            display: "block",
            textAlign: "center"
          }}
        >
          {item.label}
        </Typography>
      </Box>
    );
  }
  return (
    <Typography variant="body2" sx={{ fontWeight: 500, color: theme.palette.grey[50] }}>
      {item.label}
    </Typography>
  );
}
