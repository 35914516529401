import React, { ReactNode, useState } from "react";
import Button from "@mui/material/Button";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import isEmpty from "lodash/isEmpty";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
import { useTheme } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

export type WorkflowConfirmDialogOnYesArgs = {
  confirmText: string;
  checkOption?: boolean;
  signature?: string;
};

interface ConfirmDialogProps {
  open: boolean;
  onNo: () => void;
  onYes: (p: WorkflowConfirmDialogOnYesArgs) => void;
  disabledButtons?: boolean | undefined;
  disabledYesButton?: boolean;
  showLoading?: boolean;
  isConfirmRequired?: boolean;
  confirmTextLabel?: string;
  confirmTextValue?: string;
  isCheckOptionRequired?: boolean;
  checkOptionLabel?: string;
  checkOptionValue?: boolean;
  title?: string | null;
  content?: ReactNode | ReactNode[];
  noLabel?: string;
  yesLabel?: string;
  props?: DialogProps;
}
type TextFieldPickedProps = Pick<TextFieldProps, "value" | "error" | "helperText">;

export default function ConfirmDialog({
  open,
  onYes,
  onNo,
  title,
  content,
  disabledButtons,
  disabledYesButton,
  isConfirmRequired,
  confirmTextLabel,
  confirmTextValue,
  isCheckOptionRequired,
  checkOptionLabel,
  checkOptionValue,
  noLabel = "No",
  yesLabel = "Yes",
  showLoading,
  ...props
}: ConfirmDialogProps) {
  const theme = useTheme();
  const [confirmText, setConfirmText] = useState<TextFieldPickedProps>({ value: confirmTextValue });
  const [checkOption, setCheckOption] = useState<TextFieldPickedProps>({ value: checkOptionValue });

  const onConfirmTextChange = event => {
    setConfirmText({ value: event.target.value });
  };
  const onCheckOptionChange = (event, checked) => {
    setCheckOption({ value: checked });
  };

  const onNoClick = () => {
    setConfirmText({ value: undefined });
    return onNo();
  };

  const onYesClick = () =>
    onYes({ confirmText: confirmText.value as string, checkOption: checkOption.value as boolean });

  return (
    <Dialog open={open} onClose={onNo} data-testid="confirmPopup" {...props}>
      {title && <DialogTitle>{title}</DialogTitle>}
      {content && (
        <DialogContent>
          <DialogContentText component="div">{content}</DialogContentText>
          {isConfirmRequired && (
            <TextField
              data-testid="confirmTextField"
              margin="normal"
              autoFocus
              required
              multiline
              maxRows={3}
              fullWidth
              value={confirmText.value}
              label={confirmTextLabel}
              variant="outlined"
              onChange={onConfirmTextChange}
              onKeyDown={event => event.stopPropagation()}
            />
          )}
          {isCheckOptionRequired && (
            <FormControlLabel
              control={<Checkbox checked={checkOption.value as boolean} />}
              label={checkOptionLabel}
              data-testid="isCheckOptionRequired"
              onChange={onCheckOptionChange}
            />
          )}
          {showLoading && (
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: theme.spacing(1)
              }}
            >
              <CircularProgress size={theme.spacing(4)} />
            </span>
          )}
        </DialogContent>
      )}
      <DialogActions>
        <Button
          data-testid="yesButton"
          onClick={onYesClick}
          startIcon={<CheckIcon color="inherit" />}
          color="primary"
          autoFocus
          disabled={
            disabledButtons ||
            disabledYesButton ||
            (isConfirmRequired && isEmpty(confirmText.value))
          }
        >
          {yesLabel}
        </Button>
        <Button
          data-testid="noButton"
          onClick={onNoClick}
          startIcon={<CloseIcon color="inherit" />}
          color="secondary"
          disabled={disabledButtons}
        >
          {noLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
