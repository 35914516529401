import React, { lazy } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Login from "../pages/Login/Login";
import ProtectedView from "../components/ProtectedView/ProtectedView";
import PolicyListingPage from "../pages/Policy/PolicyListing/PolicyListingPage";
import ProductionCommitment2024DashboardPage from "../pages/ProductionCommitment2024DashboardPage/ProductionCommitment2024DashboardPage";
import RegisteredAgentsList from "../pages/University/SessionDetailPage/RegisteredAgentsList";
import PageLoader from "../components/PageLoader/PageLoader";
import PerformancePromotionPage from "../pages/Report/2023PerformancePromotion/2023PerformancePromotionPage";

const HomePage = lazy(() => import("../pages/Home/Home"));
const UniversityPage = lazy(() => import("../pages/University/UniversityPage/University"));
const UniversityAttendees = lazy(
  () => import("../pages/University/UniversityAttendees/UniversityAttendees")
);
const ClassAttendees = lazy(() => import("../pages/University/ClassAttendees/ClassAttendees"));
const TrainingClassPage = lazy(
  () => import("../pages/University/TrainingClassDetailPage/TrainingClassDetailPage")
);
const VideosPage = lazy(() => import("../pages/Video/Videos/Videos"));
const VideoDetailPage = lazy(() => import("../pages/Video/VideoDetail/VideoDetail"));
const VimeoConfigPage = lazy(() => import("../pages/Settings/VimeoConfig/VimeoConfigPage"));
const ToolboxPage = lazy(() => import("../pages/Settings/Toolbox/Toolbox"));
const ViewItemPage = lazy(() => import("../pages/Workflow/ViewItem/ViewItem"));
const NewItemPage = lazy(() => import("../pages/Workflow/NewItem/NewItem"));
const SettingHistory = lazy(() => import("../pages/Settings/SettingHistory/SettingHistory"));
const EmailConfiguration = lazy(
  () => import("../pages/Settings/Company/EmailConfiguration/EmailConfiguration")
);
const GeneralInformation = lazy(
  () => import("../pages/Settings/Company/GeneralInformation/GeneralInformation")
);
const AgentLevelList = lazy(
  () => import("../pages/Settings/Lists/AgentLevel/AgentLevelList/AgentLevelList")
);
const AgentLevelEdit = lazy(
  () => import("../pages/Settings/Lists/AgentLevel/AgentLevelEdit/AgentLevelEdit")
);
const AgentLevelNew = lazy(
  () => import("../pages/Settings/Lists/AgentLevel/AgentLevelNew/AgentLevelNew")
);
const Branding = lazy(() => import("../pages/Settings/Company/Branding/Branding"));
const ClickUpSettingsPage = lazy(() => import("../pages/Settings/Integration/ClickUp/ClickUp"));
const TargetMarketList = lazy(() => import("../pages/TargetMarketList/TargetMarketList"));
const SharedTargetMarketList = lazy(
  () => import("../pages/SharedTargetMarketList/SharedTargetMarketList")
);
const CompanyHierarchyPage = lazy(() => import("../pages/Settings/Directory/Hierarchy/Hierarchy"));
const CheckInKioskPage = lazy(() => import("../pages/Event/CheckInKiosk/CheckInKiosk"));
const CheckOutKioskPage = lazy(() => import("../pages/Event/CheckOutKiosk/CheckOutKiosk"));
export default function AppRouter() {
  const router = createBrowserRouter(
    [
      {
        path: "/",
        element: (
          <ProtectedView>
            <HomePage />
          </ProtectedView>
        )
      },
      {
        path: "/login",
        element: <Login />
      },
      {
        path: "/training-center/university",
        element: (
          <ProtectedView>
            <UniversityPage />
          </ProtectedView>
        )
      },
      {
        path: "/training-center/university/attendees",
        element: (
          <ProtectedView>
            <UniversityAttendees />
          </ProtectedView>
        )
      },
      {
        path: "/training-center/university/training-class/:id/attendees",
        element: (
          <ProtectedView>
            <ClassAttendees />
          </ProtectedView>
        )
      },
      {
        path: "/training-center/university/training-class/:classId/view",
        element: (
          <ProtectedView>
            <TrainingClassPage />
          </ProtectedView>
        )
      },
      {
        path: "/training-center/videos",
        element: (
          <ProtectedView>
            <VideosPage />
          </ProtectedView>
        )
      },
      {
        path: "/training-center/videos/:id",
        element: (
          <ProtectedView>
            <VideoDetailPage />
          </ProtectedView>
        )
      },
      {
        path: "/settings/integration/vimeo",
        element: (
          <ProtectedView>
            <VimeoConfigPage />
          </ProtectedView>
        )
      },
      {
        path: "/settings/toolbox",
        element: (
          <ProtectedView>
            <ToolboxPage />
          </ProtectedView>
        )
      },
      {
        path: "/settings/history",
        element: (
          <ProtectedView>
            <SettingHistory />
          </ProtectedView>
        )
      },
      {
        path: "/settings/company/branding",
        element: (
          <ProtectedView>
            <Branding />
          </ProtectedView>
        )
      },
      {
        path: "/settings/company/email-configuration",
        element: (
          <ProtectedView>
            <EmailConfiguration />
          </ProtectedView>
        )
      },
      {
        path: "/settings/company/general-information",
        element: (
          <ProtectedView>
            <GeneralInformation />
          </ProtectedView>
        )
      },
      {
        path: "/settings/lists/agent-levels",
        element: (
          <ProtectedView>
            <AgentLevelList />
          </ProtectedView>
        )
      },
      {
        path: "/settings/lists/agent-levels/New",
        element: (
          <ProtectedView>
            <AgentLevelNew />
          </ProtectedView>
        )
      },
      {
        path: "/settings/lists/agent-levels/:id",
        element: (
          <ProtectedView>
            <AgentLevelEdit />
          </ProtectedView>
        )
      },
      {
        path: "/processes/:workflowId/items/:itemId",
        element: (
          <ProtectedView>
            <ViewItemPage />
          </ProtectedView>
        )
      },
      {
        path: "/processes/new-item/select-process",
        element: (
          <ProtectedView>
            <NewItemPage />
          </ProtectedView>
        )
      },
      {
        path: "/policies",
        element: (
          <ProtectedView>
            <PolicyListingPage />
          </ProtectedView>
        )
      },
      {
        path: "/2024-production-commitment",
        element: (
          <ProtectedView>
            <ProductionCommitment2024DashboardPage />
          </ProtectedView>
        )
      },
      {
        path: "/digital-target-market-lists/view/:agentId",
        element: (
          <ProtectedView>
            <TargetMarketList />
          </ProtectedView>
        )
      },
      {
        path: "/digital-target-market-lists",
        element: (
          <ProtectedView>
            <SharedTargetMarketList />
          </ProtectedView>
        )
      },
      {
        path: "/training-center/university/class/:classId/session/:sessionId",
        element: (
          <ProtectedView>
            <RegisteredAgentsList />
          </ProtectedView>
        )
      },
      {
        path: "/reports/production/2023-performance-promotion",
        element: (
          <ProtectedView>
            <PerformancePromotionPage />
          </ProtectedView>
        )
      },
      {
        path: "/settings/integration/click-up",
        element: (
          <ProtectedView>
            <ClickUpSettingsPage />
          </ProtectedView>
        )
      },
      {
        path: "/settings/directory/hierarchy",
        element: (
          <ProtectedView>
            <CompanyHierarchyPage />
          </ProtectedView>
        )
      },
      {
        path: "/events/:eventId/event-sessions/:sessionId/check-in-kiosk",
        element: <CheckInKioskPage />
      },
      {
        path: "/events/:eventId/event-sessions/:sessionId/check-out-kiosk",
        element: <CheckOutKioskPage />
      }
    ],
    {
      basename: process.env.PUBLIC_URL
    }
  );

  return <RouterProvider router={router} fallbackElement={<PageLoader />} />;
}
