import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import map from "lodash/map";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import RefreshIcon from "@mui/icons-material/Refresh";
import { enqueueSnackbar } from "notistack";
import { formatDistanceToNow } from "date-fns";
import isEmpty from "lodash/isEmpty";
import { IconButton, useMediaQuery } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import includes from "lodash/includes";
import LoadingButton from "@mui/lab/LoadingButton";
import CommitmentCategoryCell from "../CommitmentCategoryCell";
import ChartTitle from "./ChartTitle";
import PersonalCommitmentLineChart from "./PersonalCommitmentLineChart";
import {
  ProductionCommitment,
  ProductionCommitmentAgentProgress,
  UserCompanyRole,
  useCalculate2024ProductionCommitmentStatsMutation
} from "../../../generated/graphql";
import getGraphQLErrorsAsString from "../../../libs/graphql/getGraphQLErrorsAsString";
import handleAMSLinkClick from "../../../libs/handleAMSLinkClick";
import { useAuth } from "../../../contexts/auth";

type CategoriesProps = Record<
  string,
  {
    name: string;
    color: string;
    filters?: Record<string, string | number | object>;
    navigationLink: string;
  }
>;

interface PersonalCommitmentDialogProps {
  open: boolean;
  onClose: () => void;
  refetch: () => void;
  data: ProductionCommitment & { color: string | undefined; name: string | undefined };
  categories: CategoriesProps;
  lastUpdated: number;
  stats: [ProductionCommitment];
  monthlyStats: [ProductionCommitmentAgentProgress];
  agent: UserCompanyRole;
  commitmentCategoryClickedIndex: string;
  isRefreshable: boolean;
}
export default function PersonalCommitmentDialog({
  open,
  onClose,
  refetch,
  data,
  categories,
  commitmentCategoryClickedIndex: initialCommitmentClickedIndex,
  stats,
  lastUpdated,
  monthlyStats,
  isRefreshable,
  agent
}: PersonalCommitmentDialogProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { userCompanyRole } = useAuth();
  const [item, setItem] = useState(data);
  const [commitmentCategoryClickedIndex, setCommitmentCategoryClickedIndex] = useState<string>(
    initialCommitmentClickedIndex
  );
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));

  const countdown = formatDistanceToNow(new Date(lastUpdated), {
    addSuffix: true,
    includeSeconds: true
  });

  const onItemClick = ({ selectedItem }) => {
    setItem(selectedItem);
  };
  const onCommitmentCategoryClick = ({ index }) => {
    setCommitmentCategoryClickedIndex(index.toString());
  };
  const [calculate2024ProductionCommitmentStats, { loading: recalculateLoading }] =
    useCalculate2024ProductionCommitmentStatsMutation();

  const handleRefreshClick = () =>
    calculate2024ProductionCommitmentStats({ variables: { agentId: agent.id } })
      .then(() => {
        enqueueSnackbar(t(`The list has been refreshed successfully`), {
          variant: "success"
        });
        refetch();
      })
      .catch(error => {
        const errorAsString = getGraphQLErrorsAsString(error);
        const translatedError = t("Unable to refresh list preset because {{error}}", {
          error: errorAsString
        });
        enqueueSnackbar(translatedError, {
          variant: "error"
        });
      });

  const handleViewDetailsClick = ({ event, item: categoryItem }) => {
    const filters = {
      ...categoryItem.filters
    };

    if (includes(["personalRecruit", "teamRecruit"], categoryItem.category)) {
      filters.dateApplied = {
        operatorTypeId: "between",
        query: {
          start: new Date(2024, 0, 1),
          end: new Date(2024, 11, 31)
        }
      };
    }

    if (!userCompanyRole?.isAgent) {
      filters.agents = { ids: [agent.id] };
    }

    const params = {
      filters,
      viewType: categoryItem.viewType,
      metric: categoryItem.metric
    };

    handleAMSLinkClick({
      url: `${categoryItem.navigationLink}/?filter=${window.btoa(JSON.stringify(params.filters))}&viewType=${params.viewType}&metric=${params.metric}`,
      event,
      openInNewTab: true
    });
  };

  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: theme.spacing(2.5),
          backgroundColor: theme.palette.common.white
        }
      }}
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="xl"
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", md: "row" },
          backgroundColor: theme.palette.common.white,
          gap: 1
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <Typography component="span" variant="h5">
            {t("{{agentCode}} {{agentName}}'s Commitment", {
              agentCode: isMdDown ? null : agent.agentCode,
              agentName: agent.name
            })}
          </Typography>
          {!isMdDown ? null : (
            <IconButton onClick={onClose}>
              <ClearIcon
                sx={{
                  color: theme.palette.common.black,
                  [theme.breakpoints.down("sm")]: {
                    height: theme.spacing(3),
                    width: theme.spacing(3)
                  }
                }}
              />
            </IconButton>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: { xs: "row-reverse", md: "row" },
            justifyContent: "left",
            gap: 2.5
          }}
        >
          {isRefreshable && (
            <>
              <Typography variant="body3" sx={{ color: theme.palette.grey[70] }}>
                {t(`Refreshed ${countdown}`)}
              </Typography>
              <LoadingButton
                size="small"
                sx={{ width: theme.spacing(15) }}
                variant="outlined"
                disabled={recalculateLoading}
                onClick={handleRefreshClick}
                startIcon={<RefreshIcon />}
                loading={recalculateLoading}
              >
                {t("Refresh")}
              </LoadingButton>
            </>
          )}

          {isMdDown ? null : (
            <IconButton onClick={onClose}>
              <ClearIcon
                sx={{
                  color: theme.palette.common.black,
                  [theme.breakpoints.down("sm")]: {
                    height: theme.spacing(3),
                    width: theme.spacing(3)
                  }
                }}
              />
            </IconButton>
          )}
        </Box>
      </DialogTitle>
      <DialogContent
        sx={{
          px: { xs: 2.5, md: 3 },
          backgroundColor: theme.palette.common.white,
          maxHeight: theme.spacing(100)
        }}
      >
        <Box sx={{ minWidth: { xs: "100vh", md: "100%" } }}>
          <Grid spacing={1} container>
            {map(stats, (commitment, index) => (
              <Grid
                key={index}
                item
                onClick={() =>
                  onItemClick({
                    selectedItem: { ...categories[commitment.category], ...commitment }
                  })
                }
                xs={2.4}
                // md={2.4}
              >
                <CommitmentCategoryCell
                  sx={{
                    backgroundColor:
                      index.toString() === commitmentCategoryClickedIndex
                        ? theme.palette.orange[90]
                        : "none"
                  }}
                  onCommitmentCategoryClick={() =>
                    onCommitmentCategoryClick({
                      index: index.toString()
                    })
                  }
                  index={index}
                  commitmentCategoryClickedIndex={commitmentCategoryClickedIndex?.toString()}
                  item={{ ...categories[commitment.category], ...commitment }}
                />
              </Grid>
            ))}
          </Grid>

          <ChartTitle
            agent={agent}
            onViewDetailsClick={handleViewDetailsClick}
            item={
              item as ProductionCommitment & {
                color: string | undefined;
                name: string | undefined;
                tooltip: string | undefined;
                filters?: Record<string, string | number>;
                navigationLink: string;
              }
            }
          />
          {!isEmpty(monthlyStats) && (
            <Box sx={{ height: theme.spacing(66) }}>
              <PersonalCommitmentLineChart
                agent={agent}
                goal={item.goal}
                data={monthlyStats}
                selectedMetric={
                  item as ProductionCommitment & {
                    color: string | undefined;
                    name: string | undefined;
                    filters?: Record<string, string | number | object>;
                    navigationLink: string;
                    metric: string;
                    viewType: string;
                  }
                }
              />
            </Box>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
}
