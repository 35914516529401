import React from "react";
import Typography from "@mui/material/Typography";
import keyBy from "lodash/keyBy";
import Chip from "@mui/material/Chip";
import { useTheme, SxProps, Theme } from "@mui/material";

export default function SessionStatus({
  stateCode,
  sx
}: {
  stateCode: string;
  sx?: SxProps<Theme>;
}) {
  const theme = useTheme();

  const sessionStatuses = [
    {
      id: "published",
      name: "Published",
      color: theme.palette.common.white,
      backgroundColor: "rgb(78,154,81)"
    },
    {
      id: "completed",
      name: "Completed",
      color: theme.palette.common.white,
      backgroundColor: "rgba(106, 113, 96, 1)"
    },
    {
      id: "draft",
      name: "Draft",
      color: "rgba(10, 11, 10, 1)",
      backgroundColor: "rgba(249, 253, 83, 1)"
    }
  ];
  const hashedSessionStatuses = keyBy(sessionStatuses, "id");

  return (
    <Chip
      label={
        <Typography variant="body3" color={hashedSessionStatuses[stateCode]?.color}>
          {hashedSessionStatuses[stateCode]?.name}
        </Typography>
      }
      sx={{
        ...sx,
        backgroundColor: hashedSessionStatuses[stateCode]?.backgroundColor,
        marginTop: theme.spacing(0.3),
        padding: theme.spacing(2, 0.5),
        borderRadius: theme.spacing(12.5)
      }}
    />
  );
}
