import React, { MouseEvent, useState } from "react";
import { GridRowModel, MuiEvent } from "@mui/x-data-grid-pro";
import { useTranslation } from "react-i18next";
import HomeIcon from "@mui/icons-material/Home";
import AssessmentIcon from "@mui/icons-material/Assessment";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import { enqueueSnackbar } from "notistack";
import LoadingButton from "@mui/lab/LoadingButton";
import { endOfDay, startOfDay } from "date-fns";
import getComponentLinkAttributeAMS from "../../../libs/formatter/getComponentLinkAttributeAMS";
import ListingDataGrid from "../../../components/ListingDataGrid/ListingDataGrid";
import FullLayout from "../../../layouts/FullLayout";
import {
  useGetPerformancePromotionReportLazyQuery,
  useCalculatePerformancePromotionStatsMutation,
  useExportPerformancePromotionReportMutation
} from "../../../generated/graphql";
import PageTitle from "../../../components/PageTitle/PageTitle";
import handleAMSLinkClick from "../../../libs/handleAMSLinkClick";
import getGraphQLErrorsAsString from "../../../libs/graphql/getGraphQLErrorsAsString";

const monthPeriods = {
  periodFrom0715to0914PointValue: {
    startDate: startOfDay(new Date(2023, 6, 15)),
    endDate: endOfDay(new Date(2023, 8, 14))
  },
  periodFrom0815to1014PointValue: {
    startDate: startOfDay(new Date(2023, 7, 15)),
    endDate: endOfDay(new Date(2023, 9, 14))
  },
  periodFrom0915to1114PointValue: {
    startDate: startOfDay(new Date(2023, 8, 15)),
    endDate: endOfDay(new Date(2023, 10, 14))
  },
  periodFrom1015to1214PointValue: {
    startDate: startOfDay(new Date(2023, 9, 15)),
    endDate: endOfDay(new Date(2023, 11, 14))
  },
  periodFrom0715to1014PointValue: {
    startDate: startOfDay(new Date(2023, 6, 15)),
    endDate: endOfDay(new Date(2023, 9, 14))
  },
  periodFrom0815to1114PointValue: {
    startDate: startOfDay(new Date(2023, 7, 15)),
    endDate: endOfDay(new Date(2023, 10, 14))
  },
  periodFrom0915to1214PointValue: {
    startDate: startOfDay(new Date(2023, 8, 15)),
    endDate: endOfDay(new Date(2023, 11, 14))
  }
};

export default function PerformancePromotionPage() {
  const { t } = useTranslation();

  const [triggerRefetch, setTriggerRefetch] = useState<boolean>(false);

  const [calculatePerformancePromotionStats, { loading: recalculateLoading }] =
    useCalculatePerformancePromotionStatsMutation();
  const crumbs = [
    {
      href: "/",
      label: t("Home"),
      Icon: HomeIcon
    },
    {
      href: getComponentLinkAttributeAMS({
        url: "/reports"
      }).href,
      label: t("Reports"),
      Icon: AssessmentIcon
    },
    {
      label: t("Production"),
      Icon: AssessmentIcon
    },
    {
      label: t("2023 Performance Promotion"),
      Icon: EmojiEventsIcon
    }
  ];

  const handleRecalculate = async () => {
    try {
      const response = await calculatePerformancePromotionStats();

      if (response.data?.calculatePerformancePromotionStats?.code === 200) {
        enqueueSnackbar(t("Report data has been recalculated successfully"), {
          variant: "success"
        });

        return setTriggerRefetch(!triggerRefetch);
      }
      return enqueueSnackbar(
        t("Can't recalculate because: {{error}}", {
          error: response?.data?.calculatePerformancePromotionStats?.message
        }),
        { variant: "error" }
      );
    } catch (error) {
      return enqueueSnackbar(
        t("Can't recalculate because: {{error}}", { error: getGraphQLErrorsAsString(error) }),
        { variant: "error" }
      );
    }
  };

  const onCellClick = ({
    row,
    field,
    event
  }: {
    row: GridRowModel;
    field: string;
    event: MuiEvent<MouseEvent>;
  }) => {
    const policyFilters = {
      agents: {
        ids: [row.userAgent.id]
      },
      writtenDate: {
        operatorTypeId: "between",
        query: {
          start: new Date(2023, 6, 15),
          end: new Date(2023, 11, 14)
        }
      },
      policyStates: {
        ids: ["delivered", "paid"]
      },
      isExchangeSale: {
        query: "false"
      },
      isFieldTrainingSale: {
        query: "false"
      },
      fromPerformancePromotionReport: {
        query: "true"
      }
    };

    const monthPeriod = monthPeriods[field];

    if (monthPeriod) {
      policyFilters.writtenDate = {
        operatorTypeId: "between",
        query: {
          start: monthPeriod.startDate,
          end: monthPeriod.endDate
        }
      };
    }

    handleAMSLinkClick({
      url: `/policies/all/?filter=${window.btoa(JSON.stringify(policyFilters))}&isReporting=true`,
      event,
      openInNewTab: true
    });
  };

  return (
    <FullLayout
      sx={{
        pl: { xs: 2.5, md: 5 },
        pr: { xs: 0, md: 5 },
        overflow: "clip",
        ".header-container": {
          pr: { xs: 2.5, md: 0 }
        }
      }}
      includePadding={false}
    >
      <PageTitle
        isDisplayPageName
        sx={{
          pr: { xs: 2.5, md: 0 }
        }}
        crumbs={crumbs}
        buttons={
          <LoadingButton
            loading={recalculateLoading}
            onClick={handleRecalculate}
            variant="contained"
            startIcon={<RotateRightIcon />}
          >
            {t("Recalculate")}
          </LoadingButton>
        }
      />

      <ListingDataGrid
        triggerRefetch={triggerRefetch}
        useGetRowsLazyQuery={useGetPerformancePromotionReportLazyQuery}
        useExportRowsMutation={useExportPerformancePromotionReportMutation}
        onCellClick={onCellClick}
        query="getPerformancePromotionReport"
        listId="4"
      />
    </FullLayout>
  );
}
