import React, { createContext, useContext, useEffect, useState } from "react";
import {
  getAuth,
  GoogleAuthProvider,
  onIdTokenChanged,
  signInWithCustomToken,
  signInWithPopup,
  signOut as signUserOut
} from "firebase/auth";
import {
  User,
  Config,
  UserCompanyRole,
  useGenerateCustomTokenMutation,
  useGetConfigurationQuery
} from "../generated/graphql";
import getClient from "../libs/firebase/getClient";
import PageLoader from "../components/PageLoader/PageLoader";
import getConfigInFirebaseRemoteConfig from "../libs/getConfigInFirebaseRemoteConfig";
import getCompanyConfigInFirestore from "../libs/getCompanyConfigInFirestore";

export type AuthContextValue = {
  user?: User;
  userCompanyRole?: UserCompanyRole;
  config?: Config;
  signOut: () => Promise<void>;
  signIn: () => void;
};
const AuthContext = createContext<AuthContextValue>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  signOut: () => Promise.resolve(),
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  signIn: () => {}
});

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [loading, setLoading] = useState(true);
  const [firestoreLoading, setFirestoreLoading] = useState(true);
  const [value, setValue] = useState<AuthContextValue | null>(null);
  const provider = new GoogleAuthProvider();
  const auth = getAuth();
  const { loading: configurationLoading, data } = useGetConfigurationQuery({
    fetchPolicy: "no-cache"
  });
  const [generateCustomToken] = useGenerateCustomTokenMutation({
    fetchPolicy: "no-cache"
  });
  const signOut = () => signUserOut(getAuth());

  const signIn = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const credential = GoogleAuthProvider.credentialFromResult(result);
      if (credential?.idToken) {
        window.location.reload();
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  useEffect(() => {
    setLoading(true);
    getClient();

    return onIdTokenChanged(getAuth(), async signedUser => {
      if (!signedUser && data?.getSession?.user) {
        try {
          const customTokenData = await generateCustomToken();
          const customToken = customTokenData.data?.generateCustomToken;
          if (customToken) {
            await signInWithCustomToken(auth, customToken);
          }
          // eslint-disable-next-line no-empty
        } catch (error) {}
      }
      setLoading(false);
    });
  }, [data]);

  useEffect(() => {
    const handle = setInterval(
      async () => {
        const user = getAuth().currentUser;
        if (user) {
          await user.getIdToken(true);
        }
      },
      15 * 60 * 1000
    );
    return () => clearInterval(handle);
  }, []);

  useEffect(() => {
    const getConfig = async () => {
      setFirestoreLoading(true);
      if (data) {
        const { configData, domainMapping } = await getConfigInFirebaseRemoteConfig();
        const configCompany = await getCompanyConfigInFirestore({
          path: `companies/${domainMapping.id}/company`
        });
        setValue({
          user: data?.getSession?.user,
          userCompanyRole: data?.getSession?.userCompanyRole,
          config: { ...configData, company: configCompany } as Config,
          loading,
          signOut,
          signIn
        } as AuthContextValue);
        setFirestoreLoading(false);
      }
    };
    getConfig();
  }, [data]);

  if (firestoreLoading || loading || configurationLoading || !data) {
    return <PageLoader center />;
  }

  if (!value) {
    return null;
  }
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export const useAuth = () => useContext(AuthContext);
