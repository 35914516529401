import map from "lodash/map";
import sumBy from "lodash/sumBy";
import sortBy from "lodash/sortBy";
import keyBy from "lodash/keyBy";
import split from "lodash/split";
import { ProductionCommitmentAgentRow, UserCompanyRole } from "../../generated/graphql";
import sortOptions from "../ProductionCommitmentSortButton/sortOptions";

export default function applyOrder({
  items: initialItems,
  userCompanyRole,
  sortMethod
}: {
  items: ProductionCommitmentAgentRow[];
  sortMethod: string;
  userCompanyRole?: UserCompanyRole;
}) {
  const hashedSortOptions = keyBy(sortOptions, "value");

  if (!sortMethod) {
    const items = map(initialItems, item => {
      const categoryStats = item.stats;

      if (item.agent.agentCode === userCompanyRole?.agentCode) {
        return { ...item, sortValue: Infinity };
      }

      const sortValue =
        (sumBy(categoryStats, categoryStat => categoryStat.current) /
          sumBy(categoryStats, categoryStat => categoryStat.goal)) *
        100;

      return { ...item, sortValue };
    });

    return sortBy(items, item => item.sortValue * -1);
  }

  const { orderBy, order } = hashedSortOptions[sortMethod];

  const [orderCategory, valueType] = split(orderBy, "-");

  const items = map(initialItems, item => {
    const categoryStats = keyBy(item.stats, "category");

    if (item.agent.agentCode === userCompanyRole?.agentCode) {
      return { ...item, sortValue: order === "asc" ? -1 : Infinity };
    }

    const sortValue = categoryStats[orderCategory][valueType] as number;

    return { ...item, sortValue };
  });

  const sortedItems =
    order === "asc"
      ? sortBy(items, item => item.sortValue)
      : sortBy(items, item => item.sortValue * -1);

  return sortedItems;
}
