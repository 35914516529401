import React from "react";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import Box from "@mui/material/Box";

export default function AgentLevelBooleanType() {
  return function renderCell(props: GridRenderCellParams) {
    const { value } = props;
    return <Box>{value ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}</Box>;
  };
}
