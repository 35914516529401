import { Theme } from "@mui/material/styles";
import createSharedTheme from "./theme/createSharedTheme";

export default function createTheme({ company }) {
  const bodyFont = "Montserrat";
  const headingFont = "Plus Jakarta Sans";
  function getOverrideCustomTheme(theme: Theme) {
    return {
      typography: {
        h3: {
          lineHeight: "150%"
        },
        h4: {
          lineHeight: "150%",
          letterSpacing: theme.typography.pxToRem(0.512),
          [theme.breakpoints.down("sm")]: {
            letterSpacing: theme.typography.pxToRem(0.24),
            fontSize: theme.typography.pxToRem(15),
            lineHeight: "160%"
          }
        },
        h5: {
          letterSpacing: theme.typography.pxToRem(0.384),
          lineHeight: "160%"
        },
        h6: {
          fontFamily: bodyFont,
          lineHeight: "160%",
          letterSpacing: theme.typography.pxToRem(0.336),
          [theme.breakpoints.down("sm")]: {
            letterSpacing: theme.typography.pxToRem(0.192),
            fontSize: theme.typography.pxToRem(12)
          }
        },
        h7: {
          [theme.breakpoints.down("sm")]: {
            fontSize: theme.typography.pxToRem(15)
          }
        },
        body1: {
          lineHeight: "160%",
          [theme.breakpoints.down("sm")]: {
            letterSpacing: theme.typography.pxToRem(0.24),
            fontSize: theme.typography.pxToRem(15)
          }
        },
        body2: {
          letterSpacing: theme.typography.pxToRem(0.288),
          [theme.breakpoints.down("sm")]: {
            letterSpacing: theme.typography.pxToRem(0.192),
            fontSize: theme.typography.pxToRem(12)
          }
        },
        body3: {
          letterSpacing: theme.typography.pxToRem(0.256)
        },
        body4: {
          lineHeight: "160%"
        },
        body5: {
          [theme.breakpoints.down("sm")]: {
            fontSize: theme.typography.pxToRem(12)
          }
        },
        button: {
          [theme.breakpoints.down("sm")]: {
            fontSize: theme.typography.pxToRem(15),
            lineHeight: "160%",
            letterSpacing: theme.typography.pxToRem(0.288)
          }
        },
        subtitle3: {
          [theme.breakpoints.down("sm")]: {
            fontSize: theme.typography.pxToRem(15)
          }
        },
        dataGridState: {
          fontSize: theme.typography.pxToRem(12),
          lineHeight: "160%",
          letterSpacing: theme.typography.pxToRem(0.192),
          [theme.breakpoints.down("sm")]: {
            fontSize: theme.typography.pxToRem(10)
          }
        }
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              paddingTop: theme.spacing(1),
              paddingBottom: theme.spacing(1),
              lineHeight: "160%",
              [theme.breakpoints.down("sm")]: {
                padding: theme.spacing(0.5, 1.73925),
                borderRadius: theme.spacing(8.696375),
                fontSize: theme.typography.pxToRem(15)
              }
            }
          }
        },
        MuiSvgIcon: {
          styleOverrides: {
            root: {
              [theme.breakpoints.down("sm")]: {
                height: theme.spacing(2.5),
                width: theme.spacing(2.5)
              }
            }
          }
        },
        MuiTextField: {
          styleOverrides: {
            root: {
              ".MuiFormHelperText-root": {
                fontFamily: "Montserrat",
                fontSize: theme.spacing(1.5),
                fontWeight: 400,
                fontStyle: "normal",
                lineHeight: theme.spacing(2.4),
                letterSpacing: theme.spacing(0.0625)
              }
            }
          }
        },
        MuiAlert: {
          styleOverrides: {
            root: {
              backgroundColor: "#FBE2CE",
              ".MuiTypography-root": {
                color: "#2F1602",
                fontFamily: "Montserrat",
                fontSize: theme.spacing(2),
                fontWeight: 400,
                fontStyle: "normal",
                lineHeight: theme.spacing(3.2),
                letterSpacing: theme.spacing(0.0625)
              }
            }
          }
        }
      }
    };
  }

  return createSharedTheme({
    headingFont,
    bodyFont,
    getOverrideCustomTheme,
    company
  });
}
