import React, { ReactNode, useEffect, useRef, useState } from "react";
import Typography, { TypographyProps } from "@mui/material/Typography";
import { useTheme } from "@mui/material";
import omit from "lodash/omit";

interface EllipsisTextProps extends TypographyProps {
  text: ReactNode;
  maxLine?: number;
}

export default function EllipsisText({ text, maxLine = 2, ...props }: EllipsisTextProps) {
  const theme = useTheme();
  const typographyRef = useRef<HTMLDivElement>(null);

  const [showEllipsis, setShowEllipsis] = useState<boolean>(false);

  useEffect(() => {
    const truncateText = () => {
      const typographyElement = typographyRef.current;
      if (typographyElement) {
        const style = window.getComputedStyle(typographyElement);
        const lineHeight = parseFloat(style.lineHeight);
        const fontSize = parseFloat(style.fontSize);
        const maxHeight = lineHeight * maxLine;
        typographyElement.style.paddingRight = theme.typography.pxToRem(fontSize - 8);
        typographyElement.style.maxHeight = theme.typography.pxToRem(maxHeight);

        if (typographyElement.clientHeight !== typographyElement.scrollHeight) {
          setShowEllipsis(true);
        } else {
          setShowEllipsis(false);
        }
      }
    };
    truncateText();
  }, [maxLine, typographyRef.current]);

  return (
    <Typography
      ref={typographyRef}
      sx={{
        overflow: "hidden",
        display: "block",
        wordBreak: "break-all",
        position: "relative",
        "&::after": {
          content: showEllipsis ? '"..."' : '""',
          position: "absolute",
          bottom: 0,
          right: 0
        },
        ...props.sx
      }}
      {...omit(props, "sx")}
    >
      {text}
    </Typography>
  );
}
