import { fetchAndActivate, getRemoteConfig, getValue } from "firebase/remote-config";
import extractBaseDomain from "./extractBaseDomain";
import getClient from "./firebase/getClient";

export default async function getConfigInFirebaseRemoteConfig() {
  const client = getClient();
  const remoteConfig = getRemoteConfig(client);
  const domain =
    window.location.hostname === "localhost"
      ? "iaaforlife"
      : extractBaseDomain(window.location.host);

  await fetchAndActivate(remoteConfig);

  const configData = getValue(remoteConfig, "config").asString();
  const domainMappingData = getValue(remoteConfig, domain).asString();
  return {
    configData: JSON.parse(configData),
    domainMapping: JSON.parse(domainMappingData)
  };
}
