import React from "react";
import {
  Avatar,
  AvatarGroup,
  Box,
  Popover,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { UserCompanyRole } from "../../../generated/graphql";

export default function TrainingClassSessionAssistants({
  assistants,
  isSmallSize = false
}: {
  assistants?: UserCompanyRole[] | undefined | null;
  isSmallSize?: boolean;
}) {
  const theme = useTheme();
  const { t } = useTranslation();
  const isMdScreenAndDown = useMediaQuery(theme.breakpoints.down("lg"));
  const [assistantAnchor, setAssistantAnchor] = React.useState<null | HTMLElement>(null);

  const onAssistantClick = (event: React.MouseEvent<HTMLElement>) => {
    setAssistantAnchor(assistantAnchor ? null : event.currentTarget);
  };

  const onAssistantClose = () => {
    setAssistantAnchor(null);
  };

  return (
    <Box
      sx={{
        padding: isSmallSize ? 0 : theme.spacing(2.5),
        backgroundColor: isSmallSize ? "unset" : theme.palette.backgroundColor.light,
        borderRadius: theme.spacing(1.5),
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(1.5),
        width: "100%",
        height: "100%"
      }}
    >
      <Typography variant={isSmallSize ? "body2" : "body1"} sx={{ color: theme.palette.grey[70] }}>
        {t("Assistant ({{count}})", {
          count: assistants?.length
        })}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: theme.spacing(1.5),
          alignItems: "center"
        }}
      >
        <AvatarGroup
          max={isMdScreenAndDown ? 5 : 10}
          sx={{
            "& .MuiAvatar-root": {
              width: theme.spacing(isSmallSize ? 3 : 5),
              height: theme.spacing(isSmallSize ? 3 : 5),
              fontSize: theme.typography.pxToRem(isSmallSize ? 15 : 20)
            },
            ":hover": {
              cursor: "pointer"
            }
          }}
          onClick={onAssistantClick}
        >
          {assistants?.map(
            assistant =>
              assistant &&
              assistant.avatarUrl && <Avatar key={assistant.id} src={assistant.avatarUrl} />
          )}
        </AvatarGroup>
      </Box>
      <Popover
        open={Boolean(assistantAnchor)}
        anchorEl={assistantAnchor}
        onClose={onAssistantClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        sx={{
          "& .MuiPaper-elevation": {
            minWidth: theme.spacing(40),
            margin: theme.spacing(1, 0),
            borderRadius: theme.spacing(1),
            boxShadow: "0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30)"
          }
        }}
      >
        <Box>
          {assistants?.map(assistant => (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                padding: theme.spacing(1.5, 2.5),
                gap: theme.spacing(1)
              }}
              key={assistant.id}
            >
              <Avatar alt={`avatar of ${assistant.name}`} src={assistant.avatarUrl as string} />
              <Typography variant="body2" sx={{ color: theme.palette.grey[50] }}>
                {assistant.name}
              </Typography>
              <Typography variant="body5" sx={{ color: theme.palette.grey[70] }}>
                {assistant.agentCode}
              </Typography>
            </Box>
          ))}
        </Box>
      </Popover>
    </Box>
  );
}
