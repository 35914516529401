import Promise from "bluebird";
import Box from "@mui/material/Box";
import React, { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TextFieldProps, useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import WarningIcon from "@mui/icons-material/Warning";
import filter from "lodash/filter";
import includes from "lodash/includes";
import isEmpty from "lodash/isEmpty";
import trim from "lodash/trim";
import { isLength } from "validator";
import toLower from "lodash/toLower";
import {
  ref as firebaseRef,
  getStorage,
  getDownloadURL,
  uploadBytes,
  UploadResult
} from "firebase/storage";
import { enqueueSnackbar } from "notistack";
import html2canvas from "html2canvas";
import Bowser from "bowser";
import map from "lodash/map";
import { useAuth } from "../../contexts/auth";
import { TaskCategory, useReportIssueMutation } from "../../generated/graphql";
import FilePicker from "../FilePicker/FilePicker";
import type { UploadingResult } from "../Fields/File/File";
import getClient from "../../libs/firebase/getClient";
import upload from "../../libs/upload";
import getGraphQLErrorsAsString from "../../libs/graphql/getGraphQLErrorsAsString";
import IssueReportImageItem from "./IssueReportImageItem/IssueReportImageItem";
import useUploadFilePicker from "../../hooks/useUploadFilePicker";

export interface UploadedFile {
  url?: string;
  type: string;
  name: string;
  size: number;
  pathUrl: string;
  createdAt?: string;
}

type CategoryTextFieldProps = Pick<TextFieldProps, "error" | "helperText"> & {
  value: TaskCategory | null;
};

interface IssueReportModalProps {
  open: boolean;
  onClose: () => void;
}
const MAX_FILE = 5;
export default function IssueReportModal({ open, onClose }: IssueReportModalProps) {
  const theme = useTheme();
  const { t } = useTranslation();
  const firebase = getClient();
  const auth = useAuth();
  const { config } = auth;
  const storage = getStorage(firebase);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>("");
  const [screenShortFile, setScreenShortFile] = useState<UploadedFile | undefined>();
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
  const subjectHelperText = t(
    "Provide explanation about the request (between 10 to 1,000 characters). The more you describe, the quicker we will respond."
  );
  const [subject, setSubject] = useState<Pick<TextFieldProps, "error" | "helperText" | "value">>({
    helperText: subjectHelperText,
    value: ""
  });
  const [category, setCategory] = useState<CategoryTextFieldProps>({
    value: null
  });
  const [reportIssue, { loading: isReporting }] = useReportIssueMutation();
  const isFileSelectedValid = (files: File[]) => {
    if (files.length + uploadedFiles.length > MAX_FILE) {
      enqueueSnackbar(
        t("The maximum number of files supported is {{maxFile}}", {
          maxFile: MAX_FILE
        }),
        {
          variant: "error"
        }
      );
      return false;
    }
    return true;
  };

  const { isUploading, onFilesSelected, uploadingResults, uploadingProgress } = useUploadFilePicker(
    {
      onSuccess: async () => {
        if (!isEmpty(uploadingResults) && uploadingResults !== null) {
          const mappedUploadingResult = await Promise.map(
            Object.keys(uploadingResults),
            async (key: string) => {
              const uploadingResult: UploadingResult = uploadingResults[key];
              const fileRef = firebaseRef(storage, uploadingResult.result.fullPath);
              const url = await getDownloadURL(fileRef);
              return {
                name: uploadingResult.file.name,
                type: uploadingResult.file.type,
                url,
                size: uploadingResult.file.size,
                pathUrl: uploadingResult.result.fullPath,
                createdAt: uploadingResult.result.timeCreated
              } as UploadedFile;
            }
          );
          setUploadedFiles([...uploadedFiles, ...mappedUploadingResult]);
        }
      },
      isFileSelectedValid
    }
  );
  const categories = useMemo(() => {
    const lowerInputValue = toLower(inputValue);
    let initCategories = auth?.userCompanyRole?.isBackOfficeMember
      ? auth?.config?.company?.taskCategories
      : filter(auth?.config?.company?.taskCategories, { isPublicVisible: true });

    if (auth?.userCompanyRole?.isBackOfficeMember && lowerInputValue) {
      initCategories = filter(
        auth?.config?.company?.taskCategories,
        taskCategory =>
          includes(toLower(taskCategory?.name), lowerInputValue) ||
          includes(toLower(taskCategory?.description as string), lowerInputValue)
      );
    } else if (auth?.userCompanyRole?.isBackOfficeMember && !lowerInputValue) {
      initCategories = auth?.config?.company?.taskCategories;
    } else if (!auth?.userCompanyRole?.isBackOfficeMember && lowerInputValue) {
      initCategories = filter(
        auth?.config?.company?.taskCategories,
        taskCategory =>
          taskCategory?.isPublicVisible === true &&
          (includes(toLower(taskCategory?.name), lowerInputValue) ||
            includes(toLower(taskCategory?.description as string), lowerInputValue))
      );
    } else {
      initCategories = filter(auth?.config?.company?.taskCategories, { isPublicVisible: true });
    }
    return initCategories;
  }, [inputValue, auth]);

  const isSubjectValid = value => !isEmpty(value) && isLength(trim(value), { min: 10, max: 1000 });
  const onSubjectChange = event => {
    const error = !isSubjectValid(event.target.value);
    setSubject({
      value: event.target.value,
      error,
      helperText: error
        ? t("You must provide the subject between 10 to 1000 characters!")
        : subjectHelperText
    });
  };

  const isCategoryValid = newCategory => !isEmpty(newCategory);
  const onCategoryChange = value => {
    setInputValue("");
    const error = !isCategoryValid(value);
    setCategory({
      value,
      error,
      helperText: error ? t("The Category is required!") : undefined
    });
  };
  const onACChange = (event, newValue) => {
    if (newValue) {
      onCategoryChange(newValue);
    }
  };
  const onInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
    if (newInputValue === "") {
      setCategory({
        value: null,
        error: true,
        helperText: t("The Category is required!")
      });
    }
  };
  useEffect(() => {
    const getCanvas = async () => {
      const tmpFilePath = `tmp/${Math.random().toString(36).substr(2, 5)}.png`;
      const canvas: HTMLCanvasElement = await html2canvas(
        document.getElementById("root") as HTMLElement,
        { useCORS: true, imageTimeout: 600000 }
      );
      canvas.toBlob(async blob => {
        const storageRef = firebaseRef(storage, tmpFilePath);
        const initScreenShortFile: UploadResult = await uploadBytes(storageRef, blob as Blob);
        const url = await getDownloadURL(storageRef);

        setScreenShortFile({
          name: initScreenShortFile.metadata.name,
          pathUrl: initScreenShortFile.metadata.fullPath,
          size: initScreenShortFile.metadata.size,
          type: initScreenShortFile.metadata.contentType as string,
          createdAt: initScreenShortFile.metadata.timeCreated,
          url
        });
      });
    };
    getCanvas();
  }, []);

  const onFileRemoved = (removeIndex: number) => {
    const updatedItems = filter(uploadedFiles, (uploadedFile, index) => index !== removeIndex);
    setUploadedFiles(updatedItems);
  };

  const onSubmit = async () => {
    if (!screenShortFile) {
      enqueueSnackbar(t("Please wait a moment while AMS capture the screenshot"), {
        variant: "info"
      });
      return;
    }
    setIsLoading(true);
    const attachFilePath = `tmp/${Date.now()}`;
    await Promise.map(uploadedFiles, async uploadedFile => {
      if (uploadedFile) {
        const uploaded = await upload({
          file: uploadedFile,
          to: attachFilePath
        });
        if (!uploaded) {
          enqueueSnackbar(t("Unable to upload image!"), {
            variant: "error"
          });
        }
      }
    });
    const browserInfo = Bowser.parse(window.navigator.userAgent);
    const currentUrl = window.location.href.replace(window.location.origin, "");
    const technicalInfo = {
      Version: config?.version,
      BrowserInfo: browserInfo
    };
    await reportIssue({
      variables: {
        technicalInfo,
        currentUrl,
        subject: subject.value as string,
        categoryId: category?.value?.id as string,
        screenshot: {
          tmpFileUrl: screenShortFile?.pathUrl as string,
          name: screenShortFile?.name as string,
          size: screenShortFile?.size,
          contentType: screenShortFile?.type as string,
          createdAt: new Date(screenShortFile?.createdAt as string)
        },
        uploadedFiles: map(uploadedFiles, uploadedFile => ({
          size: uploadedFile.size,
          name: uploadedFile.name,
          contentType: uploadedFile.type,
          tmpFileUrl: uploadedFile.pathUrl,
          createdAt: new Date(uploadedFile.createdAt as string)
        }))
      }
    })
      .then(() => {
        setIsLoading(false);
        enqueueSnackbar(
          t(
            "Request '{{subject}}' has been received. We will get back to you as soon as possible!",
            {
              subject: subject.value
            }
          ),
          {
            variant: "success"
          }
        );
        onClose();
      })
      .catch(error => {
        setIsLoading(false);
        const errorAsString = getGraphQLErrorsAsString(error);
        const translatedError = t(
          "Unable to request help because {{error}}. Please try again later!",
          { error: errorAsString }
        );
        enqueueSnackbar(translatedError, {
          variant: "error"
        });
      });
  };
  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: theme.spacing(2.5),
          backgroundColor: theme.palette.backgroundColor.light
        }
      }}
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "space-between",
          backgroundColor: theme.palette.backgroundColor.light,
          padding: theme.spacing(3),
          gap: theme.spacing(2.5)
        }}
      >
        <Typography variant="h5" sx={{ color: theme.palette.grey[10] }}>
          {t("Request Help")}
        </Typography>
        <Box
          sx={{
            backgroundColor: theme.palette.orange[90],
            borderRadius: theme.spacing(1.5),
            gap: theme.spacing(1),
            display: "flex",
            padding: theme.spacing(1.5)
          }}
        >
          <WarningIcon sx={{ color: "#DC362E" }} />
          <Typography variant="body3" sx={{ color: theme.palette.grey[50] }}>
            {t(
              "AMS has already collected the technical details of your browser and operating system, as well as the screenshot from where you requested help."
            )}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent
        sx={{
          backgroundColor: theme.palette.common.white,
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing(2.5),
          padding: theme.spacing(2.5, "!important"),
          margin: theme.spacing(0, 3),
          borderRadius: theme.spacing(1.5)
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: theme.spacing(1.5) }}>
          <Typography variant="body3" sx={{ color: theme.palette.grey[10] }}>
            {t(
              "Please describe your problems as clear as possible so that we can help you effectively."
            )}
          </Typography>
          <TextField
            label="Describe your problems"
            required
            multiline
            minRows={3}
            autoFocus
            onChange={onSubjectChange}
            {...subject}
          />
        </Box>
        <Autocomplete
          getOptionLabel={option => option?.name as string}
          isOptionEqualToValue={(option, initValue) => option?.id === initValue?.id}
          filterOptions={x => x}
          options={categories as TaskCategory[]}
          autoComplete
          includeInputInList
          filterSelectedOptions
          value={category?.value}
          fullWidth
          onChange={onACChange}
          onInputChange={onInputChange}
          noOptionsText={t("Type something to search")}
          renderInput={params => (
            <TextField
              label={t("Request Type")}
              variant="outlined"
              required
              error={category?.error}
              helperText={category?.helperText}
              {...params}
            />
          )}
          renderOption={(props, option) => (
            <li {...props}>
              <Box
                sx={{
                  display: "flex",
                  padding: theme.spacing(0.5, 1),
                  flexDirection: "column"
                }}
              >
                <Typography
                  variant="h7"
                  sx={{
                    flexBasis: "auto",
                    flexGrow: 2,
                    whiteSpace: "nowrap",
                    color: theme.palette.grey[50]
                  }}
                >
                  {option?.name}
                </Typography>
                <Typography
                  variant="body4"
                  sx={{ flexBasis: "auto", flexGrow: 1, color: theme.palette.grey[70] }}
                >
                  {option?.description}
                </Typography>
              </Box>
            </li>
          )}
        />
        <Box sx={{ display: "flex", flexDirection: "column", gap: theme.spacing(1.5) }}>
          <Typography variant="body3" sx={{ color: theme.palette.grey[10] }}>
            {t(
              "AMS has captured the screen from where you requested help. You can add more files related to this problem to help us understand the problems better."
            )}
          </Typography>
          {uploadedFiles.length < MAX_FILE && (
            <FilePicker
              error={false}
              required={false}
              accept="application/pdf,image/*,audio/*"
              onFilesSelected={onFilesSelected}
              isUploading={isUploading}
              uploadingProgress={uploadingProgress}
              others={{ maxFiles: MAX_FILE }}
            />
          )}
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: theme.spacing(2)
            }}
          >
            {screenShortFile ? (
              <IssueReportImageItem uploadedFile={screenShortFile} isDeletable={false} />
            ) : (
              <IssueReportImageItem isLoading isDeletable={false} />
            )}
            {uploadedFiles.map((uploadedFile, index) => (
              <IssueReportImageItem
                uploadedFile={uploadedFile}
                onRemoved={onFileRemoved}
                isDeletable
                index={index}
              />
            ))}
          </Box>
        </Box>
      </DialogContent>

      <DialogActions
        sx={{
          padding: theme.spacing(2.5, 3, 3, 3),
          backgroundColor: theme.palette.backgroundColor.light
        }}
      >
        <Button
          onClick={onClose}
          color="error"
          variant="text"
          disabled={isLoading || isReporting || isUploading}
        >
          {t("Cancel")}
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={onSubmit}
          disabled={
            isLoading ||
            isReporting ||
            isUploading ||
            !isSubjectValid(subject.value) ||
            !isCategoryValid(category.value)
          }
        >
          {t("Submit")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
