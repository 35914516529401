import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import React from "react";
import { useDropzone, DropzoneProps } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import reduce from "lodash/reduce";
import { useMediaQuery } from "@mui/material";
import { isEmpty } from "lodash";
import { enqueueSnackbar } from "notistack";
import type { UploadingProgress } from "../Fields/File/File";

const CloudIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="49" viewBox="0 0 48 49" fill="none">
    <g clipPath="url(#clip0_747_1363)">
      <path
        d="M38.7083 20.58C37.3483 13.68 31.2883 8.5 24.0083 8.5C18.2283 8.5 13.2083 11.78 10.7083 16.58C4.6883 17.22 0.00830078 22.32 0.00830078 28.5C0.00830078 35.12 5.3883 40.5 12.0083 40.5H38.0083C43.5283 40.5 48.0083 36.02 48.0083 30.5C48.0083 25.22 43.9083 20.94 38.7083 20.58ZM38.0083 36.5H12.0083C7.5883 36.5 4.0083 32.92 4.0083 28.5C4.0083 24.4 7.0683 20.98 11.1283 20.56L13.2683 20.34L14.2683 18.44C16.1683 14.78 19.8883 12.5 24.0083 12.5C29.2483 12.5 33.7683 16.22 34.7883 21.36L35.3883 24.36L38.4483 24.58C41.5683 24.78 44.0083 27.4 44.0083 30.5C44.0083 33.8 41.3083 36.5 38.0083 36.5ZM16.0083 26.5H21.1083V32.5H26.9083V26.5H32.0083L24.0083 18.5L16.0083 26.5Z"
        fill="#2F1602"
      />
    </g>
    <defs>
      <clipPath id="clip0_747_1363">
        <rect width="48" height="48" fill="white" transform="translate(0.00830078 0.5)" />
      </clipPath>
    </defs>
  </svg>
);

const MSCloundIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
    <g clipPath="url(#clip0_1651_1792)">
      <path
        d="M18.6251 9.97387C17.9943 6.77361 15.1836 4.37109 11.8071 4.37109C9.12633 4.37109 6.79802 5.89238 5.63851 8.11865C2.84639 8.41548 0.675781 10.7809 0.675781 13.6472C0.675781 16.7176 3.17106 19.2129 6.24145 19.2129H18.3004C20.8606 19.2129 22.9385 17.135 22.9385 14.5748C22.9385 12.1259 21.0369 10.1408 18.6251 9.97387ZM18.3004 17.3577H6.24145C4.19143 17.3577 2.53101 15.6972 2.53101 13.6472C2.53101 11.7456 3.95025 10.1594 5.83331 9.9646L6.82585 9.86256L7.28966 8.98133C8.17089 7.2838 9.89625 6.22632 11.8071 6.22632C14.2375 6.22632 16.3339 7.95168 16.807 10.3356L17.0852 11.7271L18.5045 11.8291C19.9516 11.9219 21.0833 13.137 21.0833 14.5748C21.0833 16.1054 19.831 17.3577 18.3004 17.3577ZM8.09668 12.7196H10.4621V15.5024H13.1522V12.7196H15.5176L11.8071 9.00916L8.09668 12.7196Z"
        fill="#2F1602"
      />
    </g>
    <defs>
      <clipPath id="clip0_1651_1792">
        <rect width="24" height="24" fill="white" transform="translate(0.402344 0.638672)" />
      </clipPath>
    </defs>
  </svg>
);

interface FilePickerProps {
  onFilesSelected: (files: File[]) => Promise<void> | void;
  label?: string;
  fileProps?: { "data-testid": string };
  required: boolean;
  error?: boolean;
  accept: string;
  others?: DropzoneProps;
  isUploading?: boolean;
  isImportant?: boolean;
  uploadingProgress?: UploadingProgress;
}

const ACCEPT = {
  "application/pdf": [".pdf"],
  "image/*": [".bmp", ".gif", ".jpeg", ".jpg", ".png", ".svg", ".tiff", ".webp"],
  "application/vnd.openxmlformatsofficedocument.spreadsheetml.sheet": [".xlsx"],
  "application/excel": [".xls"],
  "application/vnd.msexcel": [".xls"],
  "application/xexcel": [".xls"],
  "application/xmsexcel": [".xls"],
  "text/csv": [".csv"],
  "application/xml": [".xml"],
  "text/xml": [".xml"],
  "application/vnd.openxmlformatsofficedocument.wordprocessingml.document": [".docx"],
  "application/msword": [".doc"],
  "audio/*": [
    ".aac",
    ".mp3",
    ".ogg",
    ".wav",
    ".flac",
    ".m4a",
    ".wma",
    ".amr",
    ".mid",
    ".midi",
    ".opus",
    ".weba",
    ".mp4",
    ".MP4"
  ]
};
export default function FilePicker({
  onFilesSelected,
  label,
  fileProps,
  required,
  error,
  accept,
  isUploading,
  uploadingProgress,
  isImportant,
  ...others
}: FilePickerProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMdAndDown = useMediaQuery(theme.breakpoints.down("md"));
  const mappingAccept = () =>
    reduce(
      accept.split(","),
      (keep, type) => {
        if (ACCEPT[type]) {
          return { ...keep, [type]: ACCEPT[type] };
        }
        return keep;
      },
      {}
    );

  const onDrop = acceptedFiles => {
    if (isEmpty(acceptedFiles)) {
      const acceptFile = reduce(
        accept.split(","),
        (keep: string[], type: string) => {
          if (ACCEPT[type]) {
            return [...keep, ...ACCEPT[type]];
          }
          return keep;
        },
        []
      );
      return enqueueSnackbar(
        t(
          `Invalid file extension. Please ensure you're uploading files with valid file extensions. Accepted types: ${acceptFile.join(", ")}`
        ),
        {
          variant: "error"
        }
      );
    }

    return onFilesSelected(acceptedFiles);
  };
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    ...others,
    disabled: isUploading,
    accept: mappingAccept()
  });

  const helperText = label || t("Drag and drop or click here to choose and upload file(s)");
  return (
    <Box
      data-testid="filePicker"
      {...getRootProps()}
      sx={{
        position: "relative",
        border: `1px dashed ${theme.palette.grey[60]}`,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: theme.spacing(2.5, 0),
        borderRadius: theme.spacing(1),
        cursor: isUploading ? "context-menu" : "pointer",
        backgroundColor: isImportant ? theme.palette.orange[90] : "transparent",
        zIndex: 1,
        height: "100%"
      }}
    >
      {isUploading ? (
        <LinearProgress
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            borderRadius: theme.spacing(1),
            zIndex: -1,
            "&.MuiLinearProgress-root": {
              backgroundColor: "transparent"
            },
            "& .MuiLinearProgress-bar": {
              backgroundColor: theme.palette.orange[90]
            }
          }}
          variant="determinate"
          value={uploadingProgress?.progress}
        />
      ) : null}
      <input {...getInputProps()} {...fileProps} />
      {isMdAndDown ? MSCloundIcon : CloudIcon}
      <Typography
        variant="body2"
        data-testid="filePickerHelperText"
        sx={{ color: theme.palette.orange[10], textAlign: "center" }}
      >
        {isUploading
          ? `Uploading ${uploadingProgress?.uploadedFiles?.length}/${uploadingProgress?.totalFiles}...`
          : helperText}
      </Typography>
    </Box>
  );
}
