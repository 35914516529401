import React from "react";
import { useTheme } from "@mui/material";
import { ref as firebaseRef, getStorage, getDownloadURL } from "firebase/storage";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { TrainingClassSessionAnnouncementFile } from "../../../../generated/graphql";
import getClient from "../../../../libs/firebase/getClient";
import downloadFile from "../../../../libs/formatter/downloadFile";
import EllipsisText from "../../../../components/EllipsisText/EllipsisText";

export default function AnnouncementFileItem({
  file
}: {
  file: TrainingClassSessionAnnouncementFile;
}) {
  const theme = useTheme();
  const firebase = getClient();
  const storage = getStorage(firebase);
  const { t } = useTranslation();

  const onDownloadFile = async () => {
    try {
      const fileRef = firebaseRef(storage, file.fileUrl);
      const downloadURL = await getDownloadURL(fileRef);
      downloadFile({ url: downloadURL });
    } catch (error) {
      enqueueSnackbar(t("Error downloading file"), {
        variant: "error"
      });
    }
  };
  return (
    <EllipsisText
      text={file.name}
      variant="body2"
      sx={{ color: theme.palette.orange[30], fontWeight: 500, cursor: "pointer" }}
      props={{ onClick: onDownloadFile }}
      maxLine={1}
    />
  );
}
