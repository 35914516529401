import React from "react";
import Button from "@mui/material/Button";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { useObject } from "react-firebase-hooks/database";
import { getDatabase, ref } from "firebase/database";
import get from "lodash/get";
import meanBy from "lodash/meanBy";
import LinearProgressWithLabel from "../LinearProgressWithLabel/LinearProgressWithLabel";
import StatusWithIcon from "../StatusWithIcon/StatusWithIcon";

interface RealTimeProgress {
  progress: number;
  status: string;
  updatedAt: number;
}
export type RealtimeProgressDialogProps = Omit<DialogProps, "open"> & {
  onClose?: ({ status }: { status: string }) => void;
  title: string;
  realtimePath?: string;
  onProgress?: ({
    status,
    value,
    updatedAt
  }: {
    status: string;
    value: number;
    updatedAt: number;
  }) => void;
  showIcon?: boolean;
  isMultipleProcesses?: boolean;
};
function RealtimeProgressDialog({
  onClose,
  title,
  realtimePath,
  onProgress,
  showIcon = true,
  isMultipleProcesses,
  ...props
}: RealtimeProgressDialogProps) {
  const [snapshot] = useObject(ref(getDatabase(), realtimePath));
  const { t } = useTranslation();
  let value = 0;
  let status;
  let updatedAt;
  if (snapshot) {
    const val = snapshot.val();

    if (isMultipleProcesses) {
      const values: RealTimeProgress[] = get(val, "progress", []);
      value = meanBy(values, item => item && item.progress) || 0;
    } else {
      value = get(val, "progress", 0);
    }

    status = get(val, "status");
    updatedAt = get(val, "updatedAt");

    if (onProgress) {
      onProgress({ status, value, updatedAt });
    }
  }

  const onCloseClick = () => {
    if (onClose) {
      onClose({ status });
    }
  };

  return (
    <Dialog open fullWidth onClose={onClose} {...props}>
      <DialogTitle>{title}</DialogTitle>

      <DialogContent>
        <DialogContentText>
          {showIcon && (
            <>
              <StatusWithIcon status={status} />
              <LinearProgressWithLabel value={value} />
            </>
          )}
          {!showIcon && <LinearProgressWithLabel status={status} isShowStatus value={value} />}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          data-testid="closeButton"
          onClick={onCloseClick}
          startIcon={<CloseIcon color="inherit" />}
        >
          {t("Close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default RealtimeProgressDialog;
