import React from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { useMediaQuery } from "@mui/material";
import { WorkflowState } from "../../generated/graphql";

type WorkflowItemStateProps = Partial<WorkflowState>;
export default function WorkflowItemState({
  name,
  textColor,
  backgroundColor
}: WorkflowItemStateProps) {
  const theme = useTheme();
  const isMdAndDown = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box
      sx={{
        backgroundColor: { backgroundColor },
        padding: isMdAndDown ? theme.spacing(0.347875, 1.043625) : theme.spacing(0.5, 1.5),
        borderRadius: theme.spacing(12.5)
      }}
    >
      <Typography
        variant="state"
        sx={{
          color: textColor,
          fontWeight: 400,
          margin: theme.spacing("auto", 0),
          display: "block",
          textAlign: "center"
        }}
      >
        {name}
      </Typography>
    </Box>
  );
}
