import React from "react";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import last from "lodash/last";
import { useTranslation } from "react-i18next";
import keyBy from "lodash/keyBy";

export default function TargetMarketListEntryReviewedBy({ config }) {
  return function renderCell(props: GridRenderCellParams) {
    const { reviews } = props.row;
    const theme = useTheme();
    const { t } = useTranslation();
    const {
      company: { agentLevels }
    } = config;
    const hashedAgentLevels = keyBy(agentLevels, "id");

    let reviewer;
    if (!isEmpty(reviews)) {
      reviewer = get(last(reviews), "reviewer");
    }

    return (
      <Box>
        {reviewer && (
          <Box sx={{ display: "grid" }}>
            <Typography variant="body5" color={theme.palette.grey[10]}>
              {reviewer.name}
            </Typography>
            <Typography
              variant="body5"
              sx={{ fontSize: theme.typography.pxToRem(12) }}
              color={theme.palette.grey[70]}
            >
              {t(`${reviewer.agentCode} - ${hashedAgentLevels[reviewer.agentLevelId].level}`)}
            </Typography>
          </Box>
        )}
      </Box>
    );
  };
}
