import CancelIcon from "@mui/icons-material/Cancel";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField, { StandardTextFieldProps } from "@mui/material/TextField";
import { useTheme } from "@mui/material/styles";
import isEmpty from "lodash/isEmpty";
import React, { useState, ChangeEvent, KeyboardEvent, useEffect } from "react";
import { DataGridFilterModel } from "../DataGridFilter/AddFilterButton";

interface SearchFieldProps extends StandardTextFieldProps {
  filterModel: DataGridFilterModel;
  onQueryChange: ({ value }: { value: string }) => void;
}

export default function SearchField({
  filterModel,
  onQueryChange,
  sx,
  ...props
}: SearchFieldProps) {
  const theme = useTheme();
  const [textQuery, setTextQuery] = useState<string>(
    (filterModel as Record<string, { query: string; operatorTypeId: string }>)?.text?.query
  );

  const handleTextQueryChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTextQuery(event.target.value);
  };

  const handleTextQueryInput = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      onQueryChange({ value: textQuery });
    }
  };

  const handleTextQueryClear = () => {
    setTextQuery("");

    onQueryChange({ value: "" });
  };

  useEffect(() => {
    setTextQuery(
      (filterModel as Record<string, { query: string; operatorTypeId: string }>)?.text?.query
    );
  }, [filterModel]);

  return (
    <TextField
      value={textQuery}
      onKeyUp={handleTextQueryInput}
      onChange={handleTextQueryChange}
      placeholder="Search"
      size="small"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon sx={{ width: theme.spacing(1.8), height: theme.spacing(1.8) }} />
          </InputAdornment>
        ),
        endAdornment: !isEmpty(textQuery) ? (
          <InputAdornment position="end">
            <IconButton sx={{ width: theme.spacing(1.8), height: theme.spacing(1.8) }}>
              <CancelIcon sx={{ color: theme.palette.error.main }} onClick={handleTextQueryClear} />
            </IconButton>
          </InputAdornment>
        ) : null
      }}
      sx={{
        borderRadius: theme.spacing(1),
        width: theme.spacing(30),
        ".MuiInputBase-root": {
          height: "100%",
          "> fieldset": { borderColor: theme.palette.grey[90] }
        },
        ".MuiInputBase-input": {
          fontSize: theme.typography.pxToRem(14),
          color: theme.palette.grey[10]
        },
        maxHeight: theme.spacing(5),
        ...sx
      }}
      {...props}
    />
  );
}
