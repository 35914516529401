import React from "react";
import { useTranslation } from "react-i18next";
import keyBy from "lodash/keyBy";
import WorkflowConfirmDialog, {
  WorkflowConfirmDialogOnYesArgs
} from "../WorkflowConfirmDialog/WorkflowConfirmDialog";
import { WorkflowAction } from "../../../generated/graphql";
import type { NormalizeItem } from "../ViewItem/ViewItem";
import { useAuth } from "../../../contexts/auth";

export type WorkflowConfirmInput = { signature?: string; comment?: string };
export type WorkflowConfirm = (arg0: WorkflowConfirmInput) => void;

interface ActionButtonConfirmDialogProps {
  action: WorkflowAction;
  onProceed: WorkflowConfirm;
  onCancel: () => void;
  isLoading: boolean;
  item?: NormalizeItem;
}

export default function ActionButtonConfirmDialog({
  action,
  onProceed,
  onCancel,
  isLoading,
  item,
  ...props
}: ActionButtonConfirmDialogProps) {
  const { t } = useTranslation();
  const { config, userCompanyRole } = useAuth();

  const hashedAgentLevel = keyBy(config?.company?.agentLevels, "id");
  const agentLevel = userCompanyRole?.agentLevelId
    ? hashedAgentLevel[userCompanyRole?.agentLevelId]
    : undefined;

  const onProceedClick = ({ confirmText, signature }: WorkflowConfirmDialogOnYesArgs) => {
    const updates: { comment?: string; signature?: string | undefined } = {};
    if (action.isSignatureRequired && action.isCommentRequired) {
      updates.comment = confirmText;
      updates.signature = signature;
    } else if (action.isSignatureRequired) {
      updates.signature = signature;
    } else if (action.isCommentRequired) {
      updates.comment = confirmText;
    }

    onProceed(updates);
  };

  let content = t(`Are you sure you want to take action "{{actionName}}"?`, {
    actionName: action.name
  });
  if (action.isSignatureRequired && action.isCommentRequired) {
    content = t("Please provide your signature and a comment below and click on Proceed.");
  } else if (action.isSignatureRequired) {
    content = t("Please provide your signature below and click on Proceed.");
  } else if (action.isCommentRequired) {
    content = t("Please provide your comment below and click on Proceed.");
  }
  return (
    <WorkflowConfirmDialog
      open
      onNo={onCancel}
      onYes={onProceedClick}
      disabledButtons={isLoading}
      title={t(`Action: {{name}}`, { name: action.name })}
      content={action.prompt || content}
      noLabel={t("Cancel")}
      yesLabel={t("Proceed")}
      isConfirmRequired={action.isCommentRequired as boolean}
      showLoading={isLoading}
      confirmTextLabel={t("Comment")}
      isSignatureRequired={action.isSignatureRequired as boolean}
      printedName={userCompanyRole?.name as string}
      agentLevelTitle={agentLevel?.title}
      {...props}
    />
  );
}
