import { getStorage, ref, uploadString, uploadBytes, getMetadata } from "firebase/storage";
import type { UploadedFile } from "../components/IssueReportModal/IssueReportModal";

export default async function upload({
  file,
  to
}: {
  file: File | UploadedFile | Blob;
  to: string;
}) {
  const storage = getStorage();
  const storageRef = ref(storage, to);

  let uploadTask;
  if (typeof file !== "string") {
    uploadTask = uploadBytes(storageRef, file as Blob);
  } else {
    uploadTask = uploadString(storageRef, file);
  }

  await uploadTask;

  return getMetadata(storageRef);
}
