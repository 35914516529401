import Promise from "bluebird";
import Box from "@mui/material/Box";
import React, { ChangeEvent, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { TextFieldProps, useMediaQuery, useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import filter from "lodash/filter";
import isEmpty from "lodash/isEmpty";
import trim from "lodash/trim";
import { isLength } from "validator";
import { ref as firebaseRef, getStorage, getDownloadURL } from "firebase/storage";
import { enqueueSnackbar } from "notistack";
import { get, map, compact } from "lodash";
import { useParams } from "react-router";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import { GridRowsProp } from "@mui/x-data-grid-pro";
import uniq from "lodash/uniq";
import getClient from "../../../../libs/firebase/getClient";
import { useSendEmailToTrainingClassAttendeesMutation } from "../../../../generated/graphql";
import { UploadingResult } from "../../../../components/Fields/File/File";
import useUploadFilePicker from "../../../../hooks/useUploadFilePicker";
import upload from "../../../../libs/upload";
import FilePicker from "../../../../components/FilePicker/FilePicker";
import IssueReportImageItem from "../../../../components/IssueReportModal/IssueReportImageItem/IssueReportImageItem";
import getGraphQLErrorsAsString from "../../../../libs/graphql/getGraphQLErrorsAsString";
import { useAuth } from "../../../../contexts/auth";
import TextFieldAndMarkdownPreview from "../../../../components/TextFieldAndMarkdownPreview/TextFieldAndMarkdownPreview";
import CustomToggleButton from "../../../../components/CustomToggleButton/CustomToggleButton";

export interface UploadedFile {
  url?: string;
  type: string;
  name: string;
  size: number;
  pathUrl: string;
  createdAt?: string;
}

interface SendAnnouncementModalProps {
  open: boolean;
  onClose: () => void;
  selectedAttendeeIds: string[];
  rows: GridRowsProp[];
  onSend: (realtimeExecutionResultId: string) => void;
  trainerIds: string[];
  assistantIds: string[];
}
const MAX_FILE = 5;
export default function SendAnnouncementModal({
  open,
  onClose,
  selectedAttendeeIds,
  onSend,
  rows,
  trainerIds,
  assistantIds
}: SendAnnouncementModalProps) {
  const theme = useTheme();
  const { t } = useTranslation();
  const firebase = getClient();
  const config = useAuth();
  const storage = getStorage(firebase);
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
  const { classId: trainingClassId, sessionId: trainingClassSessionId } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [subject, setSubject] = useState<Pick<TextFieldProps, "error" | "helperText" | "value">>({
    value: ""
  });
  const selectedRows = rows.filter(row =>
    selectedAttendeeIds.includes(get(row, "workflowItemId", "") as string)
  );
  const recipientIds = uniq(
    compact(selectedRows.map(selectedRow => get(selectedRow, "agent.id", "")))
  );
  const [isCopyMe, setIsCopyMe] = useState<boolean>(true);
  const [isCopyTrainers, setIsCopyTrainers] = useState<boolean>(true);
  const [isCopyAssistants, setIsCopyAssistants] = useState<boolean>(true);
  const [announcement, setAnnouncement] = useState<
    Pick<TextFieldProps, "error" | "helperText" | "value">
  >({
    value: ""
  });
  const attendeeIds: string[] = useMemo(() => {
    let initAttendeeIds: string[] = recipientIds;
    if (isCopyMe && config?.userCompanyRole?.id) {
      initAttendeeIds.push(config?.userCompanyRole?.id);
    }
    if (isCopyTrainers) {
      initAttendeeIds = initAttendeeIds.concat(trainerIds);
    }
    if (isCopyAssistants) {
      initAttendeeIds = initAttendeeIds.concat(assistantIds);
    }
    return uniq(initAttendeeIds);
  }, [trainerIds, assistantIds, isCopyMe, config, isCopyTrainers, isCopyAssistants]);

  const [sendEmailToTrainingClassAttendees, { loading: isReporting }] =
    useSendEmailToTrainingClassAttendeesMutation();
  const isFileSelectedValid = (files: File[]) => {
    if (files.length + uploadedFiles.length > MAX_FILE) {
      enqueueSnackbar(
        t("The maximum number of files supported is {{maxFile}}", {
          maxFile: MAX_FILE
        }),
        {
          variant: "error"
        }
      );
      return false;
    }
    return true;
  };

  const { isUploading, onFilesSelected, uploadingResults, uploadingProgress } = useUploadFilePicker(
    {
      onSuccess: async () => {
        if (!isEmpty(uploadingResults) && uploadingResults !== null) {
          const mappedUploadingResult = await Promise.map(
            Object.keys(uploadingResults),
            async (key: string) => {
              const uploadingResult: UploadingResult = uploadingResults[key];
              const fileRef = firebaseRef(storage, uploadingResult.result.fullPath);
              const url = await getDownloadURL(fileRef);
              return {
                name: uploadingResult.file.name,
                type: uploadingResult.file.type,
                url,
                size: uploadingResult.file.size,
                pathUrl: uploadingResult.result.fullPath,
                createdAt: uploadingResult.result.timeCreated
              } as UploadedFile;
            }
          );
          setUploadedFiles([...uploadedFiles, ...mappedUploadingResult]);
        }
      },
      isFileSelectedValid
    }
  );

  const isSubjectValid = value => !isEmpty(value) && isLength(trim(value), { min: 10, max: 255 });
  const isAnnouncementValid = value =>
    !isEmpty(value) && isLength(trim(value), { min: 10, max: 1000 });

  const onSubjectChange = event => {
    const error = !isSubjectValid(event.target.value);
    setSubject({
      value: event.target.value,
      error,
      helperText: error ? t("You must provide the subject between 10 to 255 characters!") : ""
    });
  };

  const onAnnouncementChange = event => {
    const error = !isAnnouncementValid(event.target.value);
    setAnnouncement({
      value: event.target.value,
      error,
      helperText: error ? t("You must provide the subject between 10 to 1000 characters!") : ""
    });
  };

  const onIsCopyMeChange = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setIsCopyMe(checked);
  };
  const onIsCopyTrainersChange = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setIsCopyTrainers(checked);
  };
  const onIsCopyAssistantsChange = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setIsCopyAssistants(checked);
  };

  const onFileRemoved = (removeIndex: number) => {
    const updatedItems = filter(uploadedFiles, (uploadedFile, index) => index !== removeIndex);
    setUploadedFiles(updatedItems);
  };

  const onSubmit = async () => {
    setIsLoading(true);
    const attachFilePath = `tmp/${Date.now()}`;
    await Promise.map(uploadedFiles, async uploadedFile => {
      if (uploadedFile) {
        const uploaded = await upload({
          file: uploadedFile,
          to: attachFilePath
        });
        if (!uploaded) {
          enqueueSnackbar(t("Unable to upload image!"), {
            variant: "error"
          });
        }
      }
    });
    return sendEmailToTrainingClassAttendees({
      variables: {
        subject: subject.value as string,
        body: announcement.value as string,
        attachFiles: map(uploadedFiles, uploadedFile => ({
          size: uploadedFile.size,
          name: uploadedFile.name,
          contentType: uploadedFile.type,
          tmpFileUrl: uploadedFile.pathUrl,
          createdAt: new Date(uploadedFile.createdAt as string)
        })),
        isCopyMe,
        isCopyTrainers,
        isCopyAssistants,
        recipientIds,
        trainingClassId: trainingClassId as string,
        trainingClassSessionId: trainingClassSessionId as string
      }
    })
      .then(response => {
        setIsLoading(false);
        onSend(
          response.data?.sendEmailToTrainingClassAttendees?.realtimeBackgroundProcessId as string
        );
        enqueueSnackbar(
          t(
            "Request '{{subject}}' has been received. We will get back to you as soon as possible!",
            {
              subject: subject.value
            }
          ),
          {
            variant: "success"
          }
        );
        onClose();
      })
      .catch(error => {
        setIsLoading(false);
        const errorAsString = getGraphQLErrorsAsString(error);
        const translatedError = t(
          "Unable to request help because {{error}}. Please try again later!",
          { error: errorAsString }
        );
        enqueueSnackbar(translatedError, {
          variant: "error"
        });
      });
  };

  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: theme.spacing(2.5),
          backgroundColor: theme.palette.backgroundColor.light
        }
      }}
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          backgroundColor: theme.palette.backgroundColor.light,
          padding: theme.spacing(2.5),
          gap: theme.spacing(1)
        }}
      >
        <Typography variant="h5" sx={{ color: theme.palette.grey[10] }}>
          {t("Send Announcement")}
        </Typography>
      </DialogTitle>
      <DialogContent
        sx={{
          backgroundColor: theme.palette.common.white,
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing(2.5),
          padding: theme.spacing(2.5, "!important"),
          margin: theme.spacing(0, 2.5),
          borderRadius: theme.spacing(1.5)
        }}
      >
        <TextField label="Subject" required multiline onChange={onSubjectChange} {...subject} />
        <TextFieldAndMarkdownPreview
          label={t("Write your announcement")}
          text={announcement}
          onTextChange={onAnnouncementChange}
          minRows={4}
        />
        <Box sx={{ display: "flex", flexDirection: "column", gap: theme.spacing(2.5) }}>
          {uploadedFiles.length < MAX_FILE && (
            <FilePicker
              error={false}
              required={false}
              accept="application/pdf,image/*,audio/*"
              onFilesSelected={onFilesSelected}
              isUploading={isUploading}
              uploadingProgress={uploadingProgress}
              others={{ maxFiles: MAX_FILE }}
            />
          )}
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: theme.spacing(2)
            }}
          >
            {uploadedFiles.map((uploadedFile, index) => (
              <IssueReportImageItem
                uploadedFile={uploadedFile}
                onRemoved={onFileRemoved}
                isDeletable
                index={index}
                key={uploadedFile.pathUrl}
              />
            ))}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: theme.spacing(isSmDown ? 2.5 : 5),
            marginTop: theme.spacing(1),
            flexWrap: "wrap",
            flexDirection: isSmDown ? "column" : "row"
          }}
        >
          <CustomToggleButton checked={isCopyMe} onChange={onIsCopyMeChange} label={t("Copy Me")} />
          <CustomToggleButton
            checked={isCopyTrainers}
            onChange={onIsCopyTrainersChange}
            label={t("Copy Trainers")}
          />
          <CustomToggleButton
            checked={isCopyAssistants}
            onChange={onIsCopyAssistantsChange}
            label={t("Copy Assistants")}
          />
        </Box>
      </DialogContent>

      <DialogActions
        sx={{
          padding: theme.spacing(2.5, 3, 3, 3),
          backgroundColor: theme.palette.backgroundColor.light,
          display: "flex",
          gap: theme.spacing(2.5),
          flexWrap: "wrap",
          justifyContent: isSmDown ? "center" : "flex-end"
        }}
      >
        <Typography variant="body4" sx={{ color: theme.palette.grey[70] }}>
          {t("Send to {{count}} attendees", { count: attendeeIds.length })}
        </Typography>
        <Box sx={{ display: "flex", gap: theme.spacing(2.5) }}>
          <Button color="error" onClick={onClose}>
            {t("Cancel")}
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={onSubmit}
            startIcon={<CampaignOutlinedIcon />}
            disabled={
              isLoading ||
              isReporting ||
              isUploading ||
              !isSubjectValid(subject.value) ||
              !isAnnouncementValid(announcement.value)
            }
          >
            {t("Announce")}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
