import React from "react";
import { Box, Grid, useTheme } from "@mui/material";
import {
  TrainingClassSession as TrainingClassSessionType,
  UserCompanyRole
} from "../../../generated/graphql";
import TrainingClassSessionTitle from "./TrainingClassSessionTitle";
import TrainingClassSessionInfo from "./TrainingClassSessionInfo";
import TrainingClassSessionInstructors from "./TrainingClassSessionInstructors";
import TrainingClassSessionAssistants from "./TrainingClassSessionAssistants";

export default function TrainingClassSession({
  trainingClassSession,
  refetch,
  isTrainingManager,
  isViewAllTrainingClassAndSession
}: {
  trainingClassSession: TrainingClassSessionType;
  refetch: () => void;
  isTrainingManager: boolean;
  isViewAllTrainingClassAndSession: boolean;
}) {
  const theme = useTheme();
  return (
    <Box>
      <TrainingClassSessionTitle
        trainingClassSession={trainingClassSession}
        refetch={refetch}
        isTrainingManager={isTrainingManager}
        isViewAllTrainingClassAndSession={isViewAllTrainingClassAndSession}
      />
      <Grid
        container
        spacing={2.5}
        sx={{
          backgroundColor: theme.palette.background.paper,
          padding: theme.spacing(2.5),
          borderRadius: theme.spacing(2.5),
          marginTop: theme.spacing(2)
        }}
      >
        <Grid item xs={12} sm={12} md={6}>
          <TrainingClassSessionInfo trainingClassSession={trainingClassSession} />
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <TrainingClassSessionInstructors
            fontSize={theme.spacing(2.625)}
            instructors={trainingClassSession.instructors as UserCompanyRole[]}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <TrainingClassSessionAssistants
            assistants={trainingClassSession.assistants as UserCompanyRole[]}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
