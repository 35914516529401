import Box from "@mui/material/Box";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import MovieIcon from "@mui/icons-material/Movie";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { ref as firebaseRef, getStorage, getDownloadURL } from "firebase/storage";
import { enqueueSnackbar } from "notistack";
import type { UploadedFile } from "../IssueReportModal";
import getClient from "../../../libs/firebase/getClient";
import PageLoader from "../../PageLoader/PageLoader";
import downloadFile from "../../../libs/formatter/downloadFile";
import EllipsisText from "../../EllipsisText/EllipsisText";

interface IssueReportImageItemProps {
  uploadedFile?: UploadedFile;
  onRemoved?: (removeIndex: number) => void;
  isDeletable: boolean;
  index?: number;
  isLoading?: boolean;
}

export default function IssueReportImageItem({
  uploadedFile,
  onRemoved,
  isDeletable,
  index,
  isLoading
}: IssueReportImageItemProps) {
  const theme = useTheme();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const firebase = getClient();
  const storage = getStorage(firebase);

  const handleImagePopupClick = event => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleImagePopupClose = () => {
    setAnchorEl(null);
  };

  const onFileRemoved = (removeIndex: number) => {
    if (!isDeletable) {
      return false;
    }
    if (onRemoved) {
      onRemoved(removeIndex);
    }
    handleImagePopupClose();
    return true;
  };
  const onDownloadFile = async () => {
    try {
      const fileRef = firebaseRef(storage, uploadedFile?.pathUrl);
      const downloadURL = await getDownloadURL(fileRef);
      downloadFile({ url: downloadURL });
    } catch (error) {
      enqueueSnackbar(t("Error downloading file"), {
        variant: "success"
      });
    }
  };
  return (
    <Box
      sx={{
        width: theme.typography.pxToRem(180),
        cursor: "pointer",
        display: "flex",
        flexDirection: "column",
        flex: "1 1 25%",
        maxWidth: "calc(25% - 12px)"
      }}
    >
      <Box
        onClick={onDownloadFile}
        sx={{
          position: "relative",
          borderRadius: theme.spacing(1, 1, 0, 0),
          border: `1px solid ${theme.palette.grey[90]}`,
          height: theme.typography.pxToRem(120),
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "start",
          ...(uploadedFile?.type.startsWith("image/") && {
            backgroundImage: `url(${uploadedFile?.url})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat"
          }),
          ...((uploadedFile?.type === "application/pdf" ||
            uploadedFile?.type.startsWith("video") ||
            isLoading) && {
            backgroundColor: theme.palette.grey[70],
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat"
          })
        }}
      >
        {isDeletable && (
          <IconButton sx={{ padding: theme.spacing(1.5) }} onClick={handleImagePopupClick}>
            <MoreVertIcon sx={{ color: theme.palette.common.white }} />
          </IconButton>
        )}

        {uploadedFile?.type === "application/pdf" && (
          <PictureAsPdfIcon
            sx={{
              height: theme.spacing(6),
              width: theme.spacing(6),
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              color: theme.palette.common.white
            }}
          />
        )}
        {uploadedFile?.type.startsWith("video") && (
          <MovieIcon
            sx={{
              height: theme.spacing(6),
              width: theme.spacing(6),
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              color: theme.palette.common.white
            }}
          />
        )}
        {isLoading && <PageLoader />}
      </Box>
      <Box
        sx={{
          borderRadius: theme.spacing(0, 0, 1, 1),
          backgroundColor: theme.palette.common.white,
          border: `0.753px solid ${theme.palette.grey[90]}`,
          borderTop: "0",
          padding: theme.spacing(1, 1.5),
          flexGrow: 1,
          alignItems: "center",
          display: "flex"
        }}
      >
        {uploadedFile?.name && (
          <EllipsisText
            text={uploadedFile?.name}
            variant="body5"
            sx={{ color: theme.palette.orange[10] }}
          />
        )}
        {isLoading && !uploadedFile && (
          <Typography variant="body5">{t("Taking screenshot...")}</Typography>
        )}
      </Box>
      {isDeletable && (
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleImagePopupClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left"
          }}
        >
          <List id="popup-menu">
            <ListItemButton onClick={index !== undefined ? () => onFileRemoved(index) : undefined}>
              <ListItemIcon>
                <DeleteIcon />
              </ListItemIcon>
              <ListItemText primary={t("Delete")} />
            </ListItemButton>
          </List>
        </Popover>
      )}
    </Box>
  );
}
