import React, { useState, ElementType } from "react";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import isFloat from "validator/lib/isFloat";
import isNumneric from "validator/lib/isNumeric";
import { InputBaseComponentProps } from "@mui/material";
import { OverridableTextFieldProps } from "../../../types/mui";
import NumberFormat from "../../NumberFormat/NumberFormat";

export default function Step1Form({
  personalPointValue: initPersonalPaidPointValue,
  personalRecruit: initPersonalSuccessfulRecruit,
  onChange
}: {
  personalPointValue?: number;
  personalRecruit?: number;
  onChange: ({
    values: { personalPointValue, personalRecruit },
    hasError
  }: {
    values: { personalPointValue: number; personalRecruit: number };
    hasError: boolean;
  }) => void;
}) {
  const theme = useTheme();
  const { t } = useTranslation();

  const [personalPointValue, setPersonalPointValue] = useState<OverridableTextFieldProps>({
    value: initPersonalPaidPointValue || null,
    helperText: t(
      "How many point value of the committed “Written Point Value” will be paid by IAA? (This is the Paid Business value in the Production Breakdowns report)"
    )
  });
  const [personalRecruit, setPersonalRecruit] = useState<OverridableTextFieldProps>({
    value: initPersonalSuccessfulRecruit || null,
    helperText: t("How many new agents you want to recruit in 2024?")
  });

  const isPointValueValid = value => value && isFloat(value) && /^[+]?\d+([.]\d+)?$/.test(value);
  const isPersonalRecruitValid = value => value && isNumneric(value) && /^[0-9]*$/.test(value);

  const triggerChange = changes => {
    const values = {
      personalPointValue: personalPointValue.value,
      personalRecruit: personalRecruit.value,
      ...changes
    };

    onChange({
      values,
      hasError:
        !isPointValueValid(values.personalPointValue) ||
        !isPersonalRecruitValid(values.personalRecruit)
    });
  };

  const onPointValueChange = event => {
    const error = !isPointValueValid(event.target.value);

    setPersonalPointValue({
      value: event.target.value,
      error,
      helperText: error
        ? t("Invalid Personal Paid Point value")
        : t(
            "How many point value of the committed “Written Point Value” will be paid by IAA? (This is the Paid Business value in the Production Breakdowns report)"
          )
    });
    triggerChange({ personalPointValue: event.target.value });
  };

  const onPersonalRecruitChange = event => {
    const error = !isPersonalRecruitValid(event.target.value);

    setPersonalRecruit({
      value: event.target.value,
      error,
      helperText: error
        ? t("Invalid Personal Recruit")
        : t("How many new agents you want to recruit in 2024?")
    });
    triggerChange({ personalRecruit: event.target.value });
  };

  return (
    <>
      <TextField
        id="personalPaidPointValue"
        margin="dense"
        variant="outlined"
        label={t("Personal Paid Point Value")}
        fullWidth
        required
        onChange={onPointValueChange}
        InputProps={{
          inputProps: { decimalScale: 0 },
          inputComponent: NumberFormat as ElementType<InputBaseComponentProps>
        }}
        sx={{
          marginTop: theme.spacing(2.5)
        }}
        {...personalPointValue}
        autoFocus
      />

      <TextField
        id="personalSuccessfulRecruit"
        margin="dense"
        variant="outlined"
        label={t("Personal Recruit")}
        fullWidth
        required
        onChange={onPersonalRecruitChange}
        InputProps={{
          inputProps: { decimalScale: 0 },
          inputComponent: NumberFormat as ElementType<InputBaseComponentProps>
        }}
        sx={{ marginTop: theme.spacing(2.5) }}
        {...personalRecruit}
      />

      <Alert
        icon={<WarningAmberIcon sx={{ marginTop: theme.spacing(0.25) }} />}
        severity="error"
        sx={{
          marginTop: theme.spacing(2),
          padding: `${theme.spacing(0)} ${theme.spacing(2)}`,
          width: "fit-content",
          borderRadius: `${theme.spacing(1.25)}`
        }}
      >
        <Typography>{t("Commitments can not be changed after submitting.")}</Typography>
      </Alert>
    </>
  );
}
