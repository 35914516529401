import React from "react";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import Typography from "@mui/material/Typography";
import { Box, useTheme } from "@mui/material";
import get from "lodash/get";
import formatDate from "date-fns/format";
import { useTranslation } from "react-i18next";

export default function universityAttendeesText() {
  return function renderCell(props: GridRenderCellParams) {
    const theme = useTheme();
    const { t } = useTranslation();
    const { row, field } = props;

    let variant;
    let color = theme.palette.grey[10];
    let fontWeight;
    let component;

    if (field === "trainingClass.name") {
      variant = "body3";
      color = theme.palette.grey[10] as string;
      component = get(row, field, "");
    } else if (field === "trainingClassSession.name") {
      variant = "body3";
      color = theme.palette.grey[50] as string;
      component = get(row, field, "");
    } else if (field === "trainingClassSession.sessionDateTime") {
      variant = "body3";
      color = theme.palette.grey[50] as string;
      component = (
        <Typography variant="body3" sx={{ color: theme.palette.grey[50] }}>
          {get(row, "trainingClassSession.startTimestamp") &&
            get(row, "trainingClassSession.endTimestamp") &&
            t("{{startDate}} - {{endDate}}", {
              startDate: formatDate(
                new Date(get(row, "trainingClassSession.startTimestamp")),
                " h aaa, MMM do yyyy"
              ),
              endDate: formatDate(
                new Date(get(row, "trainingClassSession.endTimestamp")),
                " h aaa, MMM do yyyy"
              )
            })}
        </Typography>
      );
    } else if (field === "trainingChecklistItem.graduatedAt") {
      variant = "body3";
      color = theme.palette.grey[50] as string;
      component = get(row, field) && (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="body3" sx={{ color: theme.palette.grey[50] }}>
            {formatDate(get(row, field), "MMM dd, yyyy")}
          </Typography>

          <Typography variant="body4" sx={{ color: theme.palette.grey[70] }}>
            {formatDate(get(row, field), "h:mm a")}
          </Typography>
        </Box>
      );
    }
    return (
      <Typography
        variant={variant}
        sx={{
          fontWeight,
          whiteSpace: field === "note" ? "normal" : "unset"
        }}
        color={color}
        fontFamily="Montserrat"
      >
        {component}
      </Typography>
    );
  };
}
