import React, { useEffect, useState } from "react";
import debounce from "lodash/debounce";
import { useTheme } from "@mui/material";
import Fab from "@mui/material/Fab";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

export default function ScrollToTopFab({
  top = 0,
  scrollY = 76,
  debounceInterval = 100,
  show: initialShow = false
}: {
  top?: number;
  scrollY?: number;
  debounceInterval?: number;
  show?: boolean;
}) {
  const theme = useTheme();
  const [show, setShow] = useState<boolean>(initialShow);

  const checkScrollTop = debounce(() => {
    setShow(window.scrollY > scrollY);
  }, debounceInterval);

  useEffect(() => {
    window.addEventListener("scroll", checkScrollTop);
    return () => window.removeEventListener("scroll", checkScrollTop);
  }, [checkScrollTop]);

  const onClick = () => {
    setShow(false);
    window.scrollTo({ top, behavior: "smooth" });
  };

  if (!show) {
    return null;
  }

  return (
    <Fab
      size="medium"
      aria-label="move to top"
      sx={{
        color: "white",
        backgroundColor: theme.palette.primary.main,
        borderRadius: 0,
        position: "fixed",
        bottom: theme.spacing(12),
        right: theme.spacing(5)
      }}
      onClick={onClick}
    >
      <KeyboardArrowUpIcon />
    </Fab>
  );
}
