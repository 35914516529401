import React, { ChangeEvent, useState } from "react";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import { SxProps, Theme, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MarkdownPreview from "@uiw/react-markdown-preview";
import Button from "@mui/material/Button";
import CallMadeIcon from "@mui/icons-material/CallMade";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { OverridableTextFieldProps } from "../../types/mui";

export default function TextFieldAndMarkdownPreview({
  label,
  text,
  onTextChange,
  disabled,
  sx,
  minRows = 2
}: {
  label: string;
  text: OverridableTextFieldProps;
  onTextChange: (event: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  sx?: SxProps<Theme>;
  minRows?: number;
}) {
  const { t } = useTranslation();
  const theme = useTheme();

  const [isPreviewMode, setIsPreviewMode] = useState<boolean>(false);

  const onTurnOnPreviewMode = () => {
    setIsPreviewMode(true);
  };
  const onTurnOffPreviewMode = () => {
    setIsPreviewMode(false);
  };

  return (
    <Box sx={{ position: "relative" }}>
      {!isPreviewMode && (
        <>
          <TextField
            margin="none"
            label={label}
            fullWidth
            multiline
            minRows={minRows}
            variant="outlined"
            disabled={disabled}
            onChange={onTextChange}
            sx={{
              ...sx,
              ".MuiInputBase-root": {
                paddingBottom: theme.spacing(7),
                fontFamily: "Montserrat",
                fontStyle: "normal",
                lineHeight: "160%",
                fontWeight: 400,
                letterSpacing: theme.spacing(0.036),
                fontSize: theme.typography.pxToRem(21)
              }
            }}
            {...text}
          />
          <Box
            sx={{
              display: "flex",
              position: "absolute",
              bottom: theme.spacing(text.error ? 3 : 0),
              right: 0
            }}
          >
            <IconButton
              sx={{ marginLeft: "auto", marginTop: "auto" }}
              href="https://www.markdownguide.org/cheat-sheet/"
              target="_blank"
            >
              <CallMadeIcon />
            </IconButton>
            <Button
              sx={{ marginTop: "auto" }}
              variant="text"
              color="inherit"
              startIcon={<VisibilityIcon />}
              onClick={onTurnOnPreviewMode}
            >
              {t("Preview Mode")}
            </Button>
          </Box>
        </>
      )}
      {isPreviewMode && (
        <>
          <Typography variant="h6">{label}</Typography>
          <MarkdownPreview
            style={{
              marginBottom: theme.spacing(1),
              backgroundColor: "transparent",
              color: theme.palette.common.black,
              fontFamily: "Montserrat",
              fontStyle: "normal",
              lineHeight: "160%",
              fontWeight: 400,
              letterSpacing: theme.spacing(0.036),
              fontSize: theme.typography.pxToRem(21)
            }}
            source={(text.value as string) || ""}
          />
          <Box sx={{ display: "flex" }}>
            <IconButton
              sx={{ marginLeft: "auto", marginTop: "auto" }}
              href="https://www.markdownguide.org/cheat-sheet/"
              target="_blank"
            >
              <CallMadeIcon />
            </IconButton>
            <Button
              sx={{ marginTop: "auto" }}
              variant="text"
              color="inherit"
              startIcon={<ModeEditOutlineOutlinedIcon />}
              onClick={onTurnOffPreviewMode}
            >
              {t("Edit Mode")}
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
}
