import { styled } from "@mui/material/styles";
import Switch, { SwitchProps } from "@mui/material/Switch";

interface CustomSwitchProps extends SwitchProps {
  isXSScreen?: boolean;
  isMarginLeftAuto?: boolean;
}

const CustomSwitch = styled(Switch)<CustomSwitchProps>(
  ({ theme, isXSScreen, isMarginLeftAuto }) => ({
    display: "flex",
    overflow: "unset",
    height: theme.spacing(4),
    width: theme.spacing(6.5),
    marginLeft: isXSScreen || isMarginLeftAuto ? "auto" : theme.spacing(1.5),
    padding: theme.spacing(0),
    ".MuiButtonBase-root": {
      padding: theme.spacing(0),
      paddingLeft: theme.spacing(0.5),
      paddingTop: theme.spacing(0.5)
    },
    ".MuiSwitch-input": {
      left: 0,
      width: theme.spacing(6.5),
      height: theme.spacing(4)
    },
    ".MuiSwitch-thumb": {
      boxShadow: "none",
      height: theme.spacing(3),
      width: theme.spacing(3)
    },
    ".MuiSwitch-track": {
      height: theme.spacing(4),
      width: theme.spacing(6.5),
      borderRadius: theme.spacing(12.5)
    }
  })
);

export default CustomSwitch;
