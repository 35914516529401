import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import SortIcon from "@mui/icons-material/Sort";
import { useTranslation } from "react-i18next";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Popover from "@mui/material/Popover";
import List from "@mui/material/List";

interface SearchInputProps {
  onSortChange: (p: { orderBy: string; order: string }) => void;
}

export default function OrderPopup({ onSortChange }: SearchInputProps) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSortOptionClick =
    ({ orderBy, order }) =>
    () => {
      onSortChange({ orderBy, order });
      handleClose();
    };

  const items = [
    {
      text: t("Newest First"),
      orderBy: "createdAt",
      order: "asc"
    },
    {
      text: t("Oldest First"),
      orderBy: "createdAt",
      order: "desc"
    },
    {
      text: t("A ➝ Z"),
      orderBy: "title",
      order: "asc"
    },
    {
      text: t("Z ➝ A"),
      orderBy: "title",
      order: "desc"
    }
  ];

  return (
    <Box>
      <IconButton
        sx={{
          p: theme => theme.spacing(1.25)
        }}
        aria-label="sort"
        onClick={handleClick}
      >
        <SortIcon
          sx={{
            color: theme => theme.palette.common.black,
            height: theme => theme.spacing(6),
            width: theme => theme.spacing(6)
          }}
        />
      </IconButton>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
      >
        <List id="popup-menu">
          {items.map(item => (
            <ListItem
              button
              onClick={onSortOptionClick({ orderBy: item.orderBy, order: item.order })}
              key={item.text}
            >
              <ListItemText primary={item.text} />
            </ListItem>
          ))}
        </List>
      </Popover>
    </Box>
  );
}
