import Box from "@mui/material/Box";
import React from "react";
import { Avatar, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import MarkdownPreview from "@uiw/react-markdown-preview";
import { TrainingClassSessionAnnouncement } from "../../../../generated/graphql";
import AnnouncementFileItem from "./AnnouncementFileItem";
import formatDate from "../../../../libs/formatter/formatDate";
import EllipsisText from "../../../../components/EllipsisText/EllipsisText";

export default function AnnouncementItem({
  announcement
}: {
  announcement: TrainingClassSessionAnnouncement;
}) {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.common.white,
        padding: theme.spacing(2.5),
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(2.5),
        borderRadius: theme.spacing(1.5),
        boxShadow: `0 0 10px  ${theme.palette.grey[98]}`
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            maxWidth: { xs: "50%", sm: "70%", md: "80%" }
          }}
        >
          <EllipsisText
            maxLine={1}
            sx={{
              color: theme.palette.grey[10]
            }}
            text={announcement.subject}
            variant="h7"
          />
          <Typography variant="body4" sx={{ color: theme.palette.grey[70] }}>
            {formatDate(new Date(announcement.createdAt), "MMM d, h a")}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: theme.spacing(0.5) }}>
          <Typography
            variant="body4"
            sx={{
              color: theme.palette.grey[70],
              display: "flex",
              justifyContent: "flex-end"
            }}
          >
            {t("Sent to {{count}} attendees", {
              count: announcement.announcementReceivers?.length
            })}
          </Typography>
          <Box sx={{ display: "flex", gap: theme.spacing(1.5) }}>
            <Typography variant="body4" sx={{ color: theme.palette.grey[70] }}>
              {t("By")}
            </Typography>
            <Box sx={{ display: "flex", gap: theme.spacing(1.5), alignItems: "center" }}>
              <Avatar
                sx={{ height: theme.spacing(3), width: theme.spacing(3) }}
                src={announcement.createdBy.avatarUrl}
              />
              <Box sx={{ display: "flex", gap: theme.spacing(1) }}>
                <Typography variant="body4" sx={{ color: theme.palette.grey[10] }}>
                  {announcement.createdBy.name}
                </Typography>
                {announcement.createdBy.agentCode && (
                  <Typography variant="body5" sx={{ color: theme.palette.grey[70] }}>
                    {t("- {{agentCode}}", { agentCode: announcement.createdBy.agentCode })}
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <MarkdownPreview
        source={announcement.body || ""}
        style={{
          color: theme.palette.grey[10],
          lineHeight: "160%",
          fontSize: theme.typography.pxToRem(18),
          fontWeight: 400,
          letterSpacing: theme.typography.pxToRem(0.288),
          backgroundColor: "transparent",
          [theme.breakpoints.down("sm")]: {
            letterSpacing: theme.typography.pxToRem(0.192),
            fontSize: theme.typography.pxToRem(12)
          }
        }}
      />
      <Box sx={{ display: "flex", gap: theme.spacing(2.5), flexWrap: "wrap" }}>
        {announcement.announcementFiles?.map(file => (
          <AnnouncementFileItem key={file.id} file={file} />
        ))}
      </Box>
    </Box>
  );
}
