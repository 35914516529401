import { InputBaseComponentProps } from "@mui/material";
import React, { forwardRef } from "react";
import { NumericFormat, NumericFormatProps } from "react-number-format";

type CustomProps = Omit<InputBaseComponentProps, "onChange"> & {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
};

const NumberFormat = forwardRef<NumericFormatProps, CustomProps>((props, ref) => {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator=","
      decimalSeparator="."
      valueIsNumericString
    />
  );
});

export default NumberFormat;
