import getTLD from "./libs/getTLD";

export const LANGUAGE_ID_ENGLISH = "en";
export const LANGUAGE_NAME_ENGLISH = "English";
export const LANGUAGE_ID_VIETNAMESE = "vi";
export const LANGUAGE_NAME_VIETNAMESE = "Vietnamese";
export const LANGUAGE_ALL = [
  { id: LANGUAGE_ID_ENGLISH, name: LANGUAGE_NAME_ENGLISH },
  { id: LANGUAGE_ID_VIETNAMESE, name: LANGUAGE_NAME_VIETNAMESE }
];

export const SYSTEM_FEATURE_ID_REQUIRE_AGENT_MEMBERSHIP_AGREEMENT = 11;
export const SYSTEM_FEATURE_ID_AGENT_LEVELS = 20;
export const TNRG_WORKFLOW_ID = 3;
export const SYSTEM_FEATURE_ID_CLICK_UP_INTEGRATION = 2;
export const firebaseConfig = {
  apiKey: "AIzaSyD1KpmtsngV10WsOdNWJKSFEZxICg9E-j4",
  authDomain: "jupiter-ams-development.firebaseapp.com",
  databaseURL: "https://jupiter-ams-development-default-rtdb.firebaseio.com",
  projectId: "jupiter-ams-development",
  storageBucket: "jupiter-ams-development.appspot.com",
  messagingSenderId: "55727517742",
  appId: "1:55727517742:web:ec8c0dbac9953f4c8f2fbf",
  measurementId: "G-5S92DJHCNH"
};

export const DEFAULT_COOKIE_OPTIONS = {
  sameSite: "strict" as "strict" | "lax" | "none" | undefined,
  path: "/",
  domain: getTLD(window.location.hostname)
};
