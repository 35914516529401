import React from "react";
import { Avatar, AvatarGroup, Box, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { UserCompanyRole } from "../../../generated/graphql";
import EllipsisText from "../../../components/EllipsisText/EllipsisText";

export default function TrainingClassSessionInstructors({
  instructors,
  fontSize,
  isSmallSize = false,
  isXsScreen
}: {
  instructors?: UserCompanyRole[] | undefined | null;
  fontSize?: string;
  isSmallSize?: boolean;
  isXsScreen?: boolean;
}) {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        padding: isSmallSize ? 0 : theme.spacing(2.5),
        backgroundColor: isSmallSize ? "unset" : theme.palette.backgroundColor.light,
        borderRadius: theme.spacing(1.5),
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(1.5),
        width: "100%",
        height: "100%",
        alignItems: "flex-start"
      }}
    >
      <Typography variant={isSmallSize ? "body2" : "body1"} sx={{ color: theme.palette.grey[70] }}>
        {t("Trainer ({{count}})", {
          count: instructors?.length
        })}
      </Typography>
      {!isXsScreen &&
        instructors?.map(instructor => (
          <Box
            sx={{ display: "flex", gap: theme.spacing(1.5), alignItems: "center" }}
            key={instructor.id}
          >
            {instructor?.avatarUrl && (
              <Avatar
                sx={{
                  width: theme.spacing(isSmallSize ? 3 : 5),
                  height: theme.spacing(isSmallSize ? 3 : 5)
                }}
                src={instructor?.avatarUrl}
              />
            )}
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <EllipsisText
                text={instructor?.name as string}
                variant={isSmallSize ? "body2" : "body1"}
                sx={{ fontWeight: 500, color: theme.palette.grey[50] }}
                maxLine={1}
                props={{ fontSize }}
              />

              <Typography
                variant={isSmallSize ? "body3" : "body1"}
                sx={{
                  fontWeight: 500,
                  color: theme.palette.grey[70]
                }}
              >
                {instructor?.agentCode}
              </Typography>
            </Box>
          </Box>
        ))}
      {isXsScreen && (
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: theme.spacing(1.5),
            alignItems: "center"
          }}
        >
          <AvatarGroup max={isXsScreen ? 5 : 10}>
            {instructors?.map(
              instructor =>
                instructor &&
                instructor.avatarUrl && (
                  <Avatar
                    key={instructor.id}
                    sx={{
                      width: theme.spacing(isSmallSize ? 3 : 5),
                      height: theme.spacing(isSmallSize ? 3 : 5)
                    }}
                    src={instructor.avatarUrl}
                  />
                )
            )}
          </AvatarGroup>
        </Box>
      )}
    </Box>
  );
}
