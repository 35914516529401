const formatShortText = (shortText: string, limitWord = 10, limitText = 20): string => {
  if (!shortText) {
    return "";
  }
  const words = shortText.split(" ");
  return words.length < limitWord && shortText.length < limitText
    ? shortText
    : `${words.slice(0, limitWord).join(" ")}...`;
};

export default formatShortText;
