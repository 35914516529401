import React, { ChangeEvent, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import trim from "lodash/trim";
import concat from "lodash/concat";
import isEmpty from "lodash/isEmpty";
import isLength from "validator/lib/isLength";
import Box from "@mui/material/Box";
import { isBefore, isValid, nextSaturday } from "date-fns";
import { useTheme } from "@mui/material";
import without from "lodash/without";
import map from "lodash/map";
import { enqueueSnackbar } from "notistack";
import Promise from "bluebird";
import { getDownloadURL, getStorage, ref as firebaseRef } from "firebase/storage";
import filter from "lodash/filter";
import keyBy from "lodash/keyBy";
import {
  CreateTrainingClassSessionInput,
  FieldValidationError,
  TrainingClassSession,
  TrainingClassSessionRequirement,
  UpdateTrainingClassSessionInput
} from "../../../../../generated/graphql";
import { OverridableTextFieldProps } from "../../../../../types/mui";
import FormErrorSummary from "../../../../../components/FormErrorSummary/FormErrorSummary";
import GeneralInformationForm from "./GeneralInformationForm/GeneralInformationForm";
import TrainerAndAssistantForm from "./TrainerAndAssistantForm/TrainerAndAssistantForm";
import useUploadFilePicker from "../../../../../hooks/useUploadFilePicker";
import { UploadingResult } from "../../../../../components/Fields/File/File";
import getClient from "../../../../../libs/firebase/getClient";
import MaterialForm from "./MaterialForm/MaterialForm";
import { useAuth } from "../../../../../contexts/auth";

export interface UploadedFile {
  id: string;
  url?: string;
  type: string;
  name: string;
  size: number;
  pathUrl: string;
  createdAt?: string;
  contentType: string;
  tmpFileUrl: string;
}

interface DateProps {
  helperText?: string;
  error?: boolean;
  value: Date;
}

export type TrainingClassSessionFormOnChangeArgs = {
  values: CreateTrainingClassSessionInput | UpdateTrainingClassSessionInput;
  hasError: boolean;
};

const isNameValid = value => value && isLength(value, { min: 3, max: 255 });
const isStartTimestampValid = (value, endTimestamp) =>
  isValid(value) && isBefore(value, endTimestamp);
const isEndTimestampValid = (value, startTimestamp) =>
  isValid(value) && isBefore(startTimestamp, value);
const MAX_FILE = 5;

export default function SessionForm({
  session,
  onChange,
  validationErrors: backendValidationErrors,
  selectedTabId,
  isSaving,
  isTrainingManager,
  isCreate
}: {
  session?:
    | CreateTrainingClassSessionInput
    | UpdateTrainingClassSessionInput
    | TrainingClassSession;
  onChange: (args: TrainingClassSessionFormOnChangeArgs) => void;
  validationErrors: FieldValidationError[];
  selectedTabId: string;
  isSaving?: boolean;
  isTrainingManager: boolean;
  isCreate?: boolean;
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const firebase = getClient();
  const storage = getStorage(firebase);
  const {
    config: {
      company: { universityRequirements }
    }
  } = useAuth();
  const hashedUniversityRequirements = keyBy(universityRequirements, "code");

  const [name, setName] = useState<OverridableTextFieldProps>({
    value: session?.name || ""
  });
  const [description, setDescription] = useState<OverridableTextFieldProps>({
    value: session?.description || ""
  });
  const [startTimestamp, setStartTimestamp] = useState<DateProps>({
    value:
      (session?.startTimestamp && new Date(session.startTimestamp)) ||
      nextSaturday(new Date(new Date().setHours(8, 0, 0)))
  });

  const [endTimestamp, setEndTimestamp] = useState<DateProps>({
    value:
      (session?.endTimestamp && new Date(session.endTimestamp)) ||
      nextSaturday(new Date(new Date().setHours(17, 0, 0))).setDate(
        nextSaturday(new Date()).getDate() + 1
      )
  });
  const [areClassRequirementsIgnored, setAreClassRequirementsIgnored] = useState<boolean>(
    session?.areClassRequirementsIgnored as boolean
  );
  const [isHomeworkSubmissionAllowed, setIsHomeworkSubmissionAllowed] = useState<boolean>(
    session?.isHomeworkSubmissionAllowed as boolean
  );
  const [isOpenForEnrollment, setIsOpenForEnrollment] = useState<boolean>(
    isCreate ? true : (session?.isOpenForEnrollment as boolean)
  );
  const [requirements, setRequirements] = useState<any>(
    map(session?.requirements, (requirement: TrainingClassSessionRequirement) => ({
      ...requirement,
      id: hashedUniversityRequirements[requirement.code]?.id
    })) || []
  );
  const [instructorIds, setInstructorIds] = useState(map(session?.instructors, "id") || []);
  const [assistantIds, setAssistantIds] = useState(map(session?.assistants, "id") || []);
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>(session?.files || []);

  const triggerChange = (
    changes: Partial<CreateTrainingClassSessionInput | UpdateTrainingClassSessionInput>
  ) => {
    const values = {
      name: name.value,
      description: description.value,
      startTimestamp: startTimestamp.value,
      endTimestamp: endTimestamp.value,
      areClassRequirementsIgnored,
      isHomeworkSubmissionAllowed,
      isOpenForEnrollment,
      instructorIds,
      assistantIds,
      files: uploadedFiles,
      requirements,
      ...changes
    };

    return onChange({
      values,
      hasError:
        !isNameValid(values.name) ||
        !isStartTimestampValid(values.startTimestamp, values.endTimestamp) ||
        !isEndTimestampValid(values.endTimestamp, values.startTimestamp) ||
        (!values.areClassRequirementsIgnored && isEmpty(values.requirements))
    });
  };

  const { validationErrors } = useMemo(() => {
    let allErrors: FieldValidationError[] = [];

    if (name.error) {
      allErrors.push({
        field: "name",
        code: "INVALID",
        reason: "Session name is required"
      });
    }

    if (!isEmpty(backendValidationErrors)) {
      allErrors = concat(allErrors, backendValidationErrors);
    }
    return {
      validationErrors: allErrors
    };
  }, [name, backendValidationErrors]);

  const onNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    const error = !isNameValid(value);
    setName({
      value,
      error,
      helperText: error
        ? t("Session name must be in between {{min}} and {{max}} characters!", { min: 3, max: 255 })
        : ""
    });
    triggerChange({ name: value });
  };

  const onDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDescription({ value: event.target.value });
    triggerChange({ description: trim(event.target.value) });
  };

  const onStartTimestampChange = dateValue => {
    const error = !isStartTimestampValid(dateValue, endTimestamp.value);
    setStartTimestamp({
      value: dateValue,
      error,
      helperText: error ? t("Start date & time is invalid") : ""
    });
    const endTimestampsError = !isEndTimestampValid(endTimestamp.value, dateValue);
    setEndTimestamp({
      value: endTimestamp.value,
      error: endTimestampsError,
      helperText: endTimestampsError ? t("End date & time is invalid") : ""
    });
    triggerChange({ startTimestamp: dateValue, endTimestamp: endTimestamp.value });
  };
  const onEndTimestampChange = dateValue => {
    const error = !isEndTimestampValid(dateValue, startTimestamp.value);
    setEndTimestamp({
      value: dateValue,
      error,
      helperText: error ? t("End date & time is invalid") : ""
    });

    const startTimestampsError = !isStartTimestampValid(startTimestamp.value, dateValue);
    setStartTimestamp({
      value: startTimestamp.value,
      error: startTimestampsError,
      helperText: startTimestampsError ? t("Start date & time is invalid") : ""
    });
    triggerChange({ endTimestamp: dateValue, startTimestamp: startTimestamp.value });
  };

  const onAreClassRequirementsIgnoredChange = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setAreClassRequirementsIgnored(!checked);
    triggerChange({ areClassRequirementsIgnored: !checked });
  };

  const onIsHomeworkSubmissionAllowedChange = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setIsHomeworkSubmissionAllowed(checked);
    triggerChange({ isHomeworkSubmissionAllowed: checked });
  };
  const onIsOpenForEnrollmentChange = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setIsOpenForEnrollment(checked);
    triggerChange({ isOpenForEnrollment: checked });
  };
  const onRequirementChange = values => {
    setRequirements(values);
    triggerChange({ requirements: values });
  };

  const onAddInstructorId = instructorId => {
    setInstructorIds(instructorIds.concat(instructorId));
    triggerChange({ instructorIds: instructorIds.concat(instructorId) });
  };

  const onRemoveInstructorId = instructorId => {
    setInstructorIds(without(instructorIds, instructorId));
    triggerChange({ instructorIds: without(instructorIds, instructorId) });
  };

  const onAddAssistantId = assistantId => {
    setAssistantIds(assistantIds.concat(assistantId));
    triggerChange({ assistantIds: assistantIds.concat(assistantId) });
  };
  const onRemoveAssistantId = assistantId => {
    setAssistantIds(without(assistantIds, assistantId));
    triggerChange({ assistantIds: without(assistantIds, assistantId) });
  };
  const isFileSelectedValid = (files: File[]) => {
    if (files.length + uploadedFiles.length > MAX_FILE) {
      enqueueSnackbar(
        t("The maximum number of files supported is {{maxFile}}", {
          maxFile: MAX_FILE
        }),
        {
          variant: "error"
        }
      );
      return false;
    }
    return true;
  };

  const { isUploading, onFilesSelected, uploadingResults, uploadingProgress } = useUploadFilePicker(
    {
      onSuccess: async () => {
        if (!isEmpty(uploadingResults) && uploadingResults !== null) {
          const mappedUploadingResult = await Promise.map(
            Object.keys(uploadingResults),
            async (key: string) => {
              const uploadingResult: UploadingResult = uploadingResults[key];
              const fileRef = firebaseRef(storage, uploadingResult.result.fullPath);
              const url = await getDownloadURL(fileRef);
              return {
                name: uploadingResult.file.name,
                type: uploadingResult.file.type,
                url,
                size: uploadingResult.file.size,
                pathUrl: uploadingResult.result.fullPath,
                createdAt: uploadingResult.result.timeCreated
              } as UploadedFile;
            }
          );
          setUploadedFiles([...uploadedFiles, ...mappedUploadingResult]);
          triggerChange({ files: [...uploadedFiles, ...mappedUploadingResult] });
        }
      },
      isFileSelectedValid
    }
  );

  const onFileRemoved = (removeIndex: number) => {
    const updatedItems = filter(uploadedFiles, (uploadedFile, index) => index !== removeIndex);
    setUploadedFiles(updatedItems);
    triggerChange({ files: updatedItems });
  };

  return (
    <Box
      sx={{
        background: theme.palette.common.white,
        padding: theme.spacing(1, 2.5, 2.5, 2.5),
        marginTop: theme.spacing(2.5),
        borderRadius: theme.spacing(1.5)
      }}
    >
      {selectedTabId === "generalInformation" && (
        <GeneralInformationForm
          name={name}
          description={description}
          startTimestamp={startTimestamp}
          endTimestamp={endTimestamp}
          areClassRequirementsIgnored={areClassRequirementsIgnored}
          isHomeworkSubmissionAllowed={isHomeworkSubmissionAllowed}
          isOpenForEnrollment={isOpenForEnrollment}
          requirements={requirements}
          onNameChange={onNameChange}
          onDescriptionChange={onDescriptionChange}
          onStartTimestampChange={onStartTimestampChange}
          onEndTimestampChange={onEndTimestampChange}
          onAreClassRequirementsIgnoredChange={onAreClassRequirementsIgnoredChange}
          onIsHomeworkSubmissionAllowedChange={onIsHomeworkSubmissionAllowedChange}
          onIsOpenForEnrollmentChange={onIsOpenForEnrollmentChange}
          onRequirementChange={onRequirementChange}
          hasEnrolledItem={session?.hasEnrolledItem as boolean}
        />
      )}
      {selectedTabId === "trainersAndAssistants" && (
        <TrainerAndAssistantForm
          isTrainingManager={isTrainingManager}
          instructorIds={instructorIds}
          onAddInstructorId={onAddInstructorId}
          onRemoveInstructorId={onRemoveInstructorId}
          assistantIds={assistantIds}
          onAddAssistantId={onAddAssistantId}
          onRemoveAssistantId={onRemoveAssistantId}
        />
      )}
      {selectedTabId === "materials" && (
        <MaterialForm
          uploadedFiles={uploadedFiles}
          onFilesSelected={onFilesSelected}
          isUploading={isUploading}
          uploadingProgress={uploadingProgress}
          onRemoved={onFileRemoved}
          isSaving={isSaving}
          isDeletable
        />
      )}
      <FormErrorSummary validationErrors={validationErrors} />
    </Box>
  );
}
