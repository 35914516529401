import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import React from "react";
import { Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { UserCompanyRole } from "../../generated/graphql";
import { useAuth } from "../../contexts/auth";
import EllipsisText from "../EllipsisText/EllipsisText";

export default function AgentInfoCell({
  agent,
  onAgentClick
}: {
  agent: UserCompanyRole;
  onAgentClick: () => void;
}) {
  const { t } = useTranslation();
  const { userCompanyRole } = useAuth();
  const theme = useTheme();

  const isCurrentUser = userCompanyRole && userCompanyRole.id === agent.id;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: 2.5,
        borderRadius: theme.spacing(1.5),
        height: { xs: "auto", md: "100%" },
        cursor: "pointer",
        padding: theme.spacing(1.5),
        "&:hover": {
          backgroundColor: "rgba(10, 11, 10, 0.08)"
        },
        backgroundColor: isCurrentUser ? theme.palette.orange[90] : "none"
      }}
      onClick={onAgentClick}
    >
      <Avatar
        sx={{
          width: { xs: theme.spacing(5), lg: theme.spacing(8) },
          height: { xs: theme.spacing(5), lg: theme.spacing(8) },
          marginTop: { xs: theme.spacing(2.5), md: "auto" },
          marginBottom: { xs: theme.spacing(2.5), md: "auto" }
        }}
        src={agent.avatarUrl as string}
      />
      <Box
        sx={{
          marginTop: { xs: theme.spacing(1.5), md: "auto" },
          marginBottom: { xs: theme.spacing(1.5), md: "auto" },
          textAlign: "left"
        }}
      >
        <EllipsisText
          text={isCurrentUser ? "You" : agent.name}
          maxLine={1}
          variant="body1"
          sx={{
            fontWeight: 500,
            fontSize: { sm: theme.typography.pxToRem(18), lg: theme.typography.pxToRem(24) }
          }}
        />
        <Typography variant="body3" sx={{ color: theme.palette.grey[70] }}>
          {t(`${agent.agentCode} - ${agent.agentLevel?.level}`)}
        </Typography>
      </Box>
    </Box>
  );
}
