import { collection, getDocs, getFirestore } from "firebase/firestore";
import reduce from "lodash/reduce";

export default async function getCompanyConfigInFirestore({ path }) {
  const firestore = getFirestore();
  const firestoreCollection = collection(firestore, path);
  const firestoreSnapshot = await getDocs(firestoreCollection);

  return reduce(
    firestoreSnapshot.docs,
    (keep, doc) => {
      if (doc.id === "flatten") {
        return { ...keep, ...doc.data().data };
      }
      keep[doc.id] = doc.data().data;
      return keep;
    },
    {}
  );
}
