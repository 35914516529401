import React, { useState } from "react";
import { Box, Button, useMediaQuery, useTheme } from "@mui/material";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import { useTranslation } from "react-i18next";
import LoadingButton from "@mui/lab/LoadingButton";
import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router";
import {
  TrainingClassSession as TrainingClassSessionType,
  useCreateTrainingClassRegistrationWorkflowItemMutation,
  WorkflowItem
} from "../../../generated/graphql";
import WorkflowItemState from "../../../components/State/WorkflowItemState";
import AnnouncementListModel from "./AnnouncementListModel/AnnouncementListModel";
import OptionButton from "../TrainingClassDetailPage/SessionListSection/OptionButton";
import SessionStatus from "../components/SessionStatus/SessionStatus";
import getGraphQLErrorsAsString from "../../../libs/graphql/getGraphQLErrorsAsString";
import EllipsisText from "../../../components/EllipsisText/EllipsisText";
import EditSessionButton from "../TrainingClassDetailPage/SessionListSection/EditSessionButton/EditSessionButton";

export default function TrainingClassSessionTitle({
  trainingClassSession,
  refetch,
  isTrainingManager,
  isViewAllTrainingClassAndSession
}: {
  trainingClassSession: TrainingClassSessionType;
  refetch: () => void;
  isTrainingManager: boolean;
  isViewAllTrainingClassAndSession: boolean;
}) {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));
  const isMdScreenAndDown = useMediaQuery(theme.breakpoints.down("lg"));

  const stateCode = trainingClassSession?.status as string;

  const [createTrainingClassRegistrationWorkflowItem, { loading: creating }] =
    useCreateTrainingClassRegistrationWorkflowItemMutation();

  const [openAnnouncement, setSendAnnouncement] = useState(false);
  const onAnnouncementOpen = () => {
    setSendAnnouncement(true);
  };

  const onAnnouncementClose = () => {
    setSendAnnouncement(false);
  };

  const onAddAttendeeClick = () => {
    createTrainingClassRegistrationWorkflowItem({
      variables: {
        trainingClassId: trainingClassSession.trainingClassId as string,
        classSessionId: trainingClassSession.id as string
      }
    })
      .then(response => {
        const {
          id: itemId,
          code,
          workflowId
        } = response.data?.createTrainingClassRegistrationWorkflowItem as WorkflowItem;
        enqueueSnackbar(
          t("{{code}} has been created", {
            code
          }),
          {
            variant: "success"
          }
        );
        refetch();
        return navigate(
          `/processes/${workflowId}/items/${itemId}/?from=training-center/university/training-class/${trainingClassSession.trainingClassId}/view`
        );
      })
      .catch(enrollError => {
        const errorAsString = getGraphQLErrorsAsString(enrollError);
        enqueueSnackbar(errorAsString, {
          variant: "error"
        });
      });
  };

  return (
    <Box sx={{ marginBottom: theme.spacing(2.75) }}>
      {isMdScreenAndDown && (
        <Box>
          <EllipsisText
            maxLine={isSmDown ? 2 : 1}
            text={
              <>
                {isSmDown && (isTrainingManager || isViewAllTrainingClassAndSession) && (
                  <SessionStatus stateCode={stateCode} sx={{ marginRight: theme.spacing(1) }} />
                )}
                {trainingClassSession.name}
              </>
            }
            variant="h3"
          />
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: isSmDown ? "column" : "row",
          gap: theme.spacing(isSmDown ? 2.5 : 0)
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: theme.spacing(2.5),
            alignItems: isSmDown ? "start" : "center",
            marginTop: isMdScreenAndDown ? theme.spacing(1) : 0,
            flexGrow: 1,
            flexBasis: 1,
            flexDirection: isSmDown ? "row-reverse" : "row"
          }}
        >
          {!isMdScreenAndDown && (
            <Box>
              <EllipsisText
                maxLine={isSmDown ? 2 : 1}
                text={
                  <>
                    {isSmDown && (isTrainingManager || isViewAllTrainingClassAndSession) && (
                      <SessionStatus stateCode={stateCode} sx={{ marginRight: theme.spacing(1) }} />
                    )}
                    {trainingClassSession.name}
                  </>
                }
                variant="h3"
              />
            </Box>
          )}

          {!isTrainingManager &&
            !isViewAllTrainingClassAndSession &&
            trainingClassSession.registeredWorkflowItem?.state && (
              <WorkflowItemState {...trainingClassSession.registeredWorkflowItem?.state} />
            )}
          {!isSmDown && (isTrainingManager || isViewAllTrainingClassAndSession) && (
            <SessionStatus stateCode={stateCode} sx={{ marginRight: theme.spacing(1) }} />
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: theme.spacing(isSmDown ? 1 : 2.5),
            flex: "0 1 auto",
            marginTop: isMdScreenAndDown ? theme.spacing(1) : 0,
            flexDirection: isSmDown ? "row-reverse" : "row",
            width: isSmDown ? "100%" : "unset"
          }}
        >
          {isTrainingManager && (
            <LoadingButton
              variant="contained"
              startIcon={<PersonAddOutlinedIcon />}
              onClick={onAddAttendeeClick}
              loading={creating}
              fullWidth={isSmDown}
              sx={{
                padding: theme.spacing(1, 5),
                lineHeight: "200%"
              }}
            >
              {t("Add Attendee")}
            </LoadingButton>
          )}

          {!isSmDown && (
            <Button
              variant="outlined"
              startIcon={<CampaignOutlinedIcon />}
              onClick={onAnnouncementOpen}
            >
              {t("Announcement")}
            </Button>
          )}
          {openAnnouncement && (
            <AnnouncementListModel
              onClose={onAnnouncementClose}
              open={openAnnouncement}
              trainingClassSessionId={trainingClassSession.id}
              trainingClassId={trainingClassSession.trainingClassId as string}
            />
          )}
          {isTrainingManager && (
            <OptionButton
              selectedTabId={stateCode}
              onActionCompleted={refetch}
              session={trainingClassSession}
              onAnnouncementOpen={onAnnouncementOpen}
              sxIconButton={{
                border: `1px solid ${theme.palette.grey[90]}`
              }}
              sxIcon={{
                [theme.breakpoints.down("sm")]: {
                  height: theme.spacing(3),
                  width: theme.spacing(3)
                },
                color: theme.palette.grey[50]
              }}
            />
          )}
          {trainingClassSession?.isInstructor && (
            <EditSessionButton
              onEditCompleted={refetch}
              sessionId={trainingClassSession?.id}
              isButton
              isInstructor={trainingClassSession?.isInstructor as boolean}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
}
