import { GridColumnVisibilityModel, GridPinnedColumns } from "@mui/x-data-grid-pro";
import { findIndex, includes, reduce } from "lodash";

export default function adjustPresetColumns({
  columnVisibilityModel,
  pinnedColumns,
  columnOrders
}: {
  pinnedColumns: GridPinnedColumns;
  columnVisibilityModel: GridColumnVisibilityModel;
  columnOrders: string[];
}) {
  return reduce(
    columnVisibilityModel,
    (
      memo: { field: string; isPinnedOnLeft: boolean; isPinnedOnRight: boolean }[],
      isVisible,
      key
    ) => {
      const column = {
        isVisible,
        field: key,
        isPinnedOnLeft: includes(pinnedColumns.left, key),
        isPinnedOnRight: includes(pinnedColumns.right, key),
        order: findIndex(columnOrders, value => value === key)
      };

      memo.push(column);

      return memo;
    },
    []
  );
}
