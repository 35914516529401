import policyStatusRenderer from "./cellRenderers/policyStatus";
import targetMarketListTypeRenderer from "./cellRenderers/targetMarketListType";
import targetMarketListRatedByAgentRenderer from "./cellRenderers/targetMarketListRatedByAgent";
import targetMarketListRatedByTrainerRenderer from "./cellRenderers/targetMarketListRatedByTrainer";
import targetMarketListProfileRenderer from "./cellRenderers/targetMarketListProfile";
import targetMarketListTextRenderer from "./cellRenderers/targetMarketListEntryText";
import targetMarketListEntryReviewedByRenderer from "./cellRenderers/targetMarketListEntryReviewedBy";
import targetMarketListEntryLastContactRenderer from "./cellRenderers/targetMarketListEntryLastContact";
import TrainingClassSessionText from "./cellRenderers/TrainingClassSessionText";
import UserContactCardInline from "./cellRenderers/UserContactCardInline";
import WorkflowItemState from "./cellRenderers/WorkflowItemState";
import universityAttendeesText from "./cellRenderers/universityAttendeesText";
import AgentLevelBooleanTypeRenderer from "./cellRenderers/AgentLevelBooleanType";
import UserRoleCellRenderer from "./cellRenderers/userRoles";

export default {
  policyStatus: policyStatusRenderer,
  targetMarketListType: targetMarketListTypeRenderer,
  targetMarketListRatedByAgent: targetMarketListRatedByAgentRenderer,
  targetMarketListRatedByTrainer: targetMarketListRatedByTrainerRenderer,
  targetMarketListProfile: targetMarketListProfileRenderer,
  targetMarketListEntryText: targetMarketListTextRenderer,
  targetMarketListEntryReviewedBy: targetMarketListEntryReviewedByRenderer,
  targetMarketListEntryLastContact: targetMarketListEntryLastContactRenderer,
  trainingClassSessionText: TrainingClassSessionText,
  userContactCardInline: UserContactCardInline,
  workflowItemState: WorkflowItemState,
  universityAttendeesText,
  agentLevelBooleanType: AgentLevelBooleanTypeRenderer,
  userRoles: UserRoleCellRenderer
};
