import React from "react";
import DescriptionIcon from "@mui/icons-material/Description";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import CancelIcon from "@mui/icons-material/Cancel";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import FindReplaceIcon from "@mui/icons-material/FindReplace";
import MarkunreadMailboxIcon from "@mui/icons-material/MarkunreadMailbox";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import Chip from "@mui/material/Chip";
import get from "lodash/get";
import keyBy from "lodash/keyBy";

interface PolicyState {
  code: string;
  name: string;
  color: string;
  backgroundColor: string;
}

export const STATUS = {
  draft: {
    code: "draft",
    name: "Draft",
    icon: DescriptionIcon
  },
  paid: {
    code: "paid",
    name: "Paid By IAA",
    icon: MonetizationOnIcon
  },
  "canceled-by-client": {
    code: "canceled-by-client",
    name: "Client Canceled",
    icon: CancelIcon
  },
  "rejected-by-client": {
    code: "rejected-by-client",
    name: "Client Rejected",
    icon: SentimentVeryDissatisfiedIcon
  },
  "declined-by-carrier": {
    code: "declined-by-client",
    name: "Client Declined",
    icon: CancelIcon
  },
  "terminated-by-carrier": {
    code: "terminated-by-carrier",
    name: "Terminated by Carrier",
    icon: CancelIcon
  },
  "pending-dr-approval": {
    code: "pending-dr-approval",
    name: "Pending DR Approval",
    icon: FindReplaceIcon
  },
  delivered: {
    code: "delivered",
    name: "Delivered",
    icon: MarkunreadMailboxIcon
  },
  closed: {
    code: "closed",
    name: "Closed",
    icon: CancelPresentationIcon
  }
};

export default function PolicyStatus({
  status,
  dense = false,
  isIconOnly = false,
  policyStates
}: {
  status: string;
  dense?: boolean;
  isIconOnly?: boolean;
  policyStates: PolicyState[];
}) {
  const hashedPolicyStates = keyBy(policyStates, "code");
  const foundStatus = get(hashedPolicyStates, status) || { name: "Draft" };
  const StatusIcon =
    get(foundStatus, "icon") ||
    get(STATUS, [foundStatus.code, "icon"]) ||
    get(STATUS, ["draft", "icon"]);

  return isIconOnly ? (
    <StatusIcon sx={{ color: foundStatus.color }} />
  ) : (
    <Chip
      data-testid="policyStatus"
      sx={{
        display: "flex",
        justifyContent: "left",
        flexWrap: "nowrap",
        "& > *": {
          margin: dense ? 0.25 : 0.5
        },
        "& > .MuiChip-label": {
          pl: dense ? 0.25 : 0.5,
          pr: dense ? 1 : 2
        },
        backgroundColor: foundStatus.backgroundColor,
        color: foundStatus.color
      }}
      icon={<StatusIcon color={foundStatus.color} />}
      label={foundStatus.name}
      variant="outlined"
    />
  );
}
