import React, { useEffect, useState } from "react";
import { ResponsiveLine } from "@nivo/line";
import {
  parse,
  format,
  addMonths,
  endOfYear,
  differenceInCalendarMonths,
  startOfMonth,
  endOfMonth
} from "date-fns";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { useMediaQuery, useTheme } from "@mui/material";
import find from "lodash/find";
import includes from "lodash/includes";
import { isNaN, isNil } from "lodash";
import {
  ProductionCommitment,
  ProductionCommitmentAgentProgress,
  UserCompanyRole
} from "../../../generated/graphql";
import { useAuth } from "../../../contexts/auth";
import handleAMSLinkClick from "../../../libs/handleAMSLinkClick";
import formatDate from "../../../libs/formatter/formatDate";
import formatNumber from "../../../libs/formatter/formatNumber";
import ChartLineWithPrediction from "./ChartLineWithPrediction";

type PersonalCommitmentLineChartProps = {
  data: ProductionCommitmentAgentProgress[];
  goal: number;
  agent: UserCompanyRole;
  selectedMetric: ProductionCommitment & {
    color: string | undefined;
    name: string | undefined;
    filters?: Record<string, string | number | object>;
    navigationLink: string;
    metric: string;
    viewType: string;
  };
};

// Least Squares Regression
const squaresRegression = (data, metric, monthsAhead) => {
  const n = data.length;
  if (n < 5) {
    return 0;
  }
  const sumX = data.reduce((acc, item, index) => acc + (index + 1), 0);
  const sumY = data.reduce((acc, item) => acc + item[metric], 0);
  const sumXY = data.reduce((acc, item, index) => acc + (index + 1) * item[metric], 0);
  const sumX2 = data.reduce((acc, item, index) => acc + (index + 1) ** 2, 0);

  const averageX = sumX / n;
  const averageY = sumY / n;
  const slope = (sumXY - n * averageX * averageY) / (sumX2 - n * averageX ** 2);
  const intercept = averageY - slope * averageX;

  const forecastedValue = intercept + slope * (n + monthsAhead);
  if (isNaN(forecastedValue) || forecastedValue < 0) {
    return 0;
  }
  if (metric === "personalRecruit" || metric === "teamRecruit") {
    return Math.round(forecastedValue);
  }
  return forecastedValue;
};

const forecastFutureMonths = (data, selectedMetric) => {
  const lastKnownMonth = data[data.length - 1].monthKey;
  const currentDate = parse(lastKnownMonth, "yyyyMM", new Date());

  const endOfYearDate = endOfYear(currentDate);

  return Array.from({ length: 12 }, (_, i) => {
    const futureMonth = addMonths(currentDate, i + 1);
    const futureMonthKey = format(futureMonth, "yyyyMM");

    return futureMonth > endOfYearDate
      ? null
      : {
          monthKey: futureMonthKey,
          [selectedMetric.category]: squaresRegression(
            data,
            selectedMetric.category,
            differenceInCalendarMonths(futureMonth, currentDate)
          )
        };
  }).filter(item => !isNil(item));
};

function formatShorthandNumber({ value }: { value: number }) {
  return value >= 1000
    ? `${value / 1000}k`
    : formatNumber({ amount: value, format: "0,0", showZero: true });
}

interface AchievedGoalPoint {
  x: number;
  y: number;
  isFromForecast: boolean;
}

export default function PersonalCommitmentLineChart({
  data,
  selectedMetric,
  agent,
  goal
}: PersonalCommitmentLineChartProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { userCompanyRole } = useAuth();
  const [achievedGoalPoint, setAchievedGoalPoint] = useState<AchievedGoalPoint | null>(null);

  const isSMDown = useMediaQuery(theme.breakpoints.down("sm"));

  const [metricChanged, setMetricChanged] = useState<boolean>(false);
  const formatMonth = dateString => {
    const parsedDate = parse(dateString, "yyyyMM", new Date());
    return format(parsedDate, "MMM");
  };

  const chartData: AchievedGoalPoint[] = data.map(item => ({
    x: Number(item.monthKey),
    y: item[selectedMetric.category],
    isFromForecast: false
  }));

  const forecastedData = forecastFutureMonths(data, selectedMetric);
  const modifiedForecastedData = forecastedData.map(item => ({
    x: Number(item?.monthKey),
    y: data.length < 5 ? null : item?.[selectedMetric?.category],
    isFromForecast: true
  }));

  chartData.push(...modifiedForecastedData);

  useEffect(() => {
    setMetricChanged(true);
    setAchievedGoalPoint(null);
  }, [selectedMetric]);

  useEffect(() => {
    if (goal > 0 && metricChanged && !achievedGoalPoint) {
      const firstAchievedGoalPoint = find(chartData, item => item.y > goal);
      if (firstAchievedGoalPoint) {
        setAchievedGoalPoint(firstAchievedGoalPoint);
      }
    }
  }, [chartData, goal]);

  const formatYAxisForRecruit = value => {
    if (Number.isInteger(value)) {
      return value;
    }
    return "";
  };

  const onClick = (point, event) => {
    if (userCompanyRole?.isAgent && userCompanyRole.id !== agent.id) {
      return false;
    }
    const date = parse(point.data.x, "yyyyMM", new Date());
    const startDate = startOfMonth(date);
    const endDate = endOfMonth(date);

    const filters = {
      ...selectedMetric.filters
    };

    if (includes(["personalRecruit", "teamRecruit"], selectedMetric.category)) {
      filters.dateApplied = {
        operatorTypeId: "between",
        query: {
          start: startDate,
          end: endDate
        }
      };
    }

    if (selectedMetric.category === "personalWrittenPointValue") {
      filters.writtenDate = {
        operatorTypeId: "between",
        query: {
          start: formatDate(new Date(2000, 0, 1), "yyyy-MM-dd"),
          end: formatDate(endDate, "yyyy-MM-dd")
        }
      };
    }

    if (includes(["personalPointValue", "teamPointValue"], selectedMetric.category)) {
      filters.issuedDate = {
        operatorTypeId: "between",
        query: {
          start: formatDate(startDate, "yyyy-MM-dd"),
          end: formatDate(endDate, "yyyy-MM-dd")
        }
      };
    }

    if (!userCompanyRole?.isAgent) {
      filters.agents = { ids: [agent.id] };
    }

    const params = {
      filters,
      viewType: selectedMetric.viewType,
      metric: selectedMetric.metric
    };

    return handleAMSLinkClick({
      url: `${selectedMetric.navigationLink}/?filter=${window.btoa(JSON.stringify(params.filters))}&viewType=${params.viewType}&metric=${params.metric}`,
      event,
      openInNewTab: true
    });
  };
  return (
    <>
      {achievedGoalPoint && (
        <Box
          sx={{
            marginTop: theme.spacing(2.5),
            borderRadius: theme.spacing(1),
            backgroundColor: theme.palette.orange[90],
            padding: theme.spacing(1, 2)
          }}
        >
          <Typography sx={{ fontWeight: 400 }} variant="h7">
            {t(
              "With this performance, this agent can reach their {{ metricName }} goal in {{ goalReachedTime }}",
              {
                metricName: selectedMetric.name,
                goalReachedTime: format(
                  parse(achievedGoalPoint.x.toString(), "yyyyMM", new Date()),
                  "MMMM yyyy"
                )
              }
            )}
          </Typography>
        </Box>
      )}
      <Box sx={{ height: "100%", borderRadius: theme.spacing(1.5), mt: 2.5 }}>
        <ResponsiveLine
          data={[
            {
              id: "actual",
              data: chartData.filter(d => !d.isFromForecast)
            },
            {
              id: "forecasted",
              data: chartData.filter(d => d.isFromForecast)
            }
          ]}
          margin={{ top: isSMDown ? 22 : 50, right: 60, bottom: 50, left: isSMDown ? 80 : 100 }}
          xScale={{ type: "point" }}
          yScale={{
            type: "linear",
            min: 0,
            max: "auto",
            reverse: false
          }}
          theme={{
            background: theme.palette.backgroundColor.light,
            axis: {
              ticks: {
                text: {
                  fontSize: isSMDown ? theme.typography.pxToRem(14) : theme.typography.pxToRem(18),
                  fontFamily: "Montserrat",
                  fill: theme.palette.grey[70]
                }
              }
            }
          }}
          yFormat={
            selectedMetric.category === "personalRecruit" ||
            selectedMetric.category === "teamRecruit"
              ? ""
              : ">-,.2d"
          }
          xFormat={value => formatMonth(value)}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 0,
            tickPadding: 17,
            format: value => formatMonth(value)
          }}
          axisLeft={{
            tickValues: 5,
            tickSize: 0,
            tickPadding: isSMDown ? 26 : 40,
            tickRotation: 0,
            legendOffset: -60,
            legendPosition: "middle",
            format: e =>
              selectedMetric.category === "personalRecruit" ||
              selectedMetric.category === "teamRecruit"
                ? formatYAxisForRecruit(e)
                : formatShorthandNumber({ value: e })
          }}
          // eslint-disable-next-line react/no-unstable-nested-components
          tooltip={({ point }) => (
            <div
              style={{
                padding: theme.spacing(0.8),
                border: `${theme.spacing(0.125)} solid ${theme.palette.grey[200]}`,
                boxShadow: theme.shadows[2],
                color: theme.palette.common.black,
                backgroundColor: theme.palette.getContrastText(theme.palette.common.black)
              }}
            >
              <span>{`${point.data.xFormatted}: `}</span>
              <strong>{`${point.data.yFormatted}`}</strong>
            </div>
          )}
          defs={[
            {
              id: "gradientA",
              type: "linearGradient",
              colors: [
                { offset: 0, color: selectedMetric.color, opacity: 1 },
                { offset: 100, color: selectedMetric.color, opacity: 0.2 }
              ]
            },
            {
              id: "transparent",
              type: "linearGradient",
              colors: [{ offset: 0, color: "transparent", opacity: 1 }]
            }
          ]}
          fill={[
            { match: { id: "actual" }, id: "gradientA" },
            { match: { id: "forecasted" }, id: "transparent" }
          ]}
          enableGridX={false}
          enableGridY={false}
          pointSize={6}
          useMesh
          colors={selectedMetric.color}
          enableArea
          areaBaselineValue={0}
          crosshairType="x"
          lineWidth={4}
          layers={[
            "grid",
            "markers",
            "axes",
            "areas",
            ChartLineWithPrediction,
            "points",
            "slices",
            "mesh",
            "legends"
          ]}
          onClick={onClick}
        />
      </Box>
    </>
  );
}
