import { useMediaQuery, useTheme, alpha } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React, { useMemo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FixedSizeList } from "react-window";
import filter from "lodash/filter";
import toLower from "lodash/toLower";
import find from "lodash/find";
import trim from "lodash/trim";
import isEmpty from "lodash/isEmpty";
import PersonalCommitmentRow from "./PersonalCommitmentRow";
import { ProductionCommitmentAgentRow, UserCompanyRole } from "../../generated/graphql";
import SearchInput from "../SearchInput/SearchInput";
import { useAuth } from "../../contexts/auth";
import ProductionCommitmentSortButton from "../ProductionCommitmentSortButton/ProductionCommitmentSortButton";
import applyOrder from "./applyOrder";

export default function PersonalCommitmentList({
  stats,
  refetch,
  loading,
  onSetGoalSubmit
}: {
  stats: ProductionCommitmentAgentRow[];
  refetch: () => void;
  loading: boolean;
  onSetGoalSubmit: (commitment: {
    personalPointValue: number;
    personalRecruit: number;
    teamPointValue: number;
    teamRecruit: number;
  }) => void;
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { userCompanyRole } = useAuth();
  const isMDDown = useMediaQuery(theme.breakpoints.down("md"));
  const [query, setQuery] = useState("");
  const [sortMethod, setSortMethod] = useState<string>("writtenPVCurrentDesc");
  const [listHeight, setListHeight] = useState(window.innerHeight - 50);

  useEffect(() => {
    const handleResize = () => {
      setListHeight(window.innerHeight - 50);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const dashboard = useMemo(() => {
    let data = stats;

    if (userCompanyRole && userCompanyRole.isAgent) {
      const myCommitment = find(
        data,
        item => item?.agent?.agentCode === userCompanyRole?.agentCode
      );

      if (!myCommitment) {
        const agentRow = {
          id: userCompanyRole?.id,
          agentCode: userCompanyRole?.agentCode,
          name: userCompanyRole?.name,
          avatarUrl: userCompanyRole?.avatarUrl,
          agentLevel: userCompanyRole?.agentLevel
        } as UserCompanyRole;

        data = [
          {
            agent: agentRow,
            stats: [],
            isRefreshable: false
          } as ProductionCommitmentAgentRow
        ].concat(data);
      }
    }

    const sortedItems = applyOrder({ userCompanyRole, items: data, sortMethod });

    if (query) {
      return filter(sortedItems, item => {
        if (item.agent.name && item.agent.agentCode) {
          return (
            toLower(item.agent.name).indexOf(trim(toLower(query))) !== -1 ||
            toLower(item.agent.agentCode).indexOf(trim(toLower(query))) !== -1
          );
        }
        return null;
      }) as ProductionCommitmentAgentRow[];
    }

    return sortedItems;
  }, [stats, query, sortMethod]);

  const onFilterValueChange = newFilterValue => {
    setQuery(newFilterValue?.text?.query || "");
  };

  const handleSortChange = ({ value }: { value: string }) => {
    setSortMethod(value);
  };

  let rowHeight = 130;

  if (isMDDown) {
    rowHeight = 105;
  }

  return (
    <Box
      sx={{
        mt: 5,
        p: 2.5,
        borderRadius: theme.spacing(2.5),
        boxShadow: `0 0 ${theme.spacing(5)} 0 ${alpha(theme.palette.common.black, 0.04)}`
      }}
    >
      <Box
        sx={{
          display: { xs: "inline-table", md: "flex" },
          height: theme.spacing(5)
        }}
      >
        <Typography variant="h5">{t("Personal Commitment")}</Typography>
        <Box
          sx={{
            marginLeft: "auto",
            display: "flex",
            flexDirection: { xs: "column-reverse", md: "row" },
            gap: 2.5
          }}
        >
          <ProductionCommitmentSortButton
            onSortChange={handleSortChange}
            sortMethod={sortMethod}
            sx={{ alignSelf: "flex-start", height: "100%" }}
          />
          <SearchInput
            sx={{ ".searchBox-wrapper": { height: "100%" } }}
            placeholder={t("Search agent")}
            isSortable={false}
            isSmallSearch
            onFilterValueChange={onFilterValueChange}
            filterValues={null}
          />
        </Box>
      </Box>
      <Box
        sx={{
          mt: { xs: 2.5, md: 1.5 },
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          textAlign: "center"
        }}
      >
        {!isEmpty(dashboard) ? (
          <FixedSizeList
            width="100%"
            itemData={dashboard}
            itemCount={dashboard.length}
            itemSize={rowHeight}
            height={listHeight}
          >
            {({ index, style, data }) => (
              <PersonalCommitmentRow
                refetch={refetch}
                loading={loading}
                style={style}
                entry={data[index]}
                onSetGoalSubmit={onSetGoalSubmit}
              />
            )}
          </FixedSizeList>
        ) : (
          <Typography sx={{ textAlign: "center" }} variant="body3">
            {t("No items found or the list is empty.")}
          </Typography>
        )}
      </Box>
    </Box>
  );
}
