import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  GridSlotProps,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector
} from "@mui/x-data-grid-pro";
import ViewWeekOutlinedIcon from "@mui/icons-material/ViewWeekOutlined";
import trim from "lodash/trim";
import Box from "@mui/material/Box";
import omit from "lodash/omit";
import pick from "lodash/pick";
import { ClickAwayListener, IconButton, useMediaQuery, useTheme } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import isEmpty from "lodash/isEmpty";
import GridToolbarPresetButton from "./GridToolbarPresetButton/GridToolbarPresetButton";
import SearchField from "./SearchField";
import AddFilterButton, { DataGridFilterModel } from "../DataGridFilter/AddFilterButton";
import ActiveFilters from "../DataGridFilter/ActiveFilters";
import DataGridPagination from "../DataGridPagination/DataGridPagination";
import GridToolbarExportButton from "./GridToolbarExportButton/GridToolbarExportButton";

type DataGridToolbarProps = GridSlotProps["toolbar"] & {
  selectedPresetId?: string;
  listSettings: {
    isPresetEnabled: boolean;
    isDensitySelectionEnabled: boolean;
    isExportFunctionEnabled: boolean;
    userPresets: { id: string; name: string; isDefault: boolean }[];
    rowCount: number;
    // TODO: Add type for filters
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    filters: any;
  };
  onPresetChange: ({ selectedPresetId }: { selectedPresetId: string }) => void;
  onCreatePreset: ({ name }: { name: string }) => void;
  onUpdatePreset: ({ name, presetId }: { name: string; presetId: string }) => void;
  onDeletePreset: ({ presetId }: { presetId: string }) => void;
  filterModel: DataGridFilterModel;
  onFilterChange: (filterModel: DataGridFilterModel) => void;
  setColumnButtonEl: Dispatch<SetStateAction<HTMLElement | null>>;
};

export default function DataGridToolbar({
  selectedPresetId,
  onPresetChange,
  onFilterChange,
  onCreatePreset,
  onUpdatePreset,
  onDeletePreset,
  onExportClick,
  isExporting,
  listSettings,
  filterModel: initialFilterModel,
  setColumnButtonEl,
  handleGetAgents,
  rowCount
}: DataGridToolbarProps) {
  const [filterModel, setFilterModel] = useState<DataGridFilterModel>(initialFilterModel);
  const [showMobileSearchField, setShowMobileSearchField] = useState<boolean>(false);
  const theme = useTheme();
  const isLGDown = useMediaQuery(theme.breakpoints.down("lg"));
  const isMDDown = useMediaQuery(theme.breakpoints.down("md"));
  const isSMDown = useMediaQuery(theme.breakpoints.down("sm"));

  const handleSearchFieldChange = ({ value }: { value: string }) => {
    const changes = { text: { query: trim(value), operatorTypeId: "contains" } };
    const updates = { ...filterModel, ...changes } as DataGridFilterModel;

    setFilterModel(updates);
    onFilterChange(updates);
  };

  const handleFilterValueChange = ({ filterKey, value }) => {
    const updates = { ...filterModel, [filterKey]: value } as DataGridFilterModel;

    setFilterModel(updates);
    onFilterChange(updates);
  };

  const onAddFiltersDialogSubmit = ({ filterKey }: { filterKey: string }) => {
    const changes = { [filterKey]: { query: null, operatorTypeId: "includes" } };
    const updates = { ...filterModel, ...changes } as DataGridFilterModel;

    setFilterModel(updates);
    onFilterChange(updates);
  };

  const handleRemoveFilter = ({ filterKey }) => {
    const updates = omit(filterModel, filterKey);

    setFilterModel(updates);
    onFilterChange(updates);
  };

  const handleClearAllFilter = () => {
    const updates = pick(filterModel, "text");

    setFilterModel(updates);
    onFilterChange(updates);
  };

  const onShowMobileSearchFieldClick = () => {
    setShowMobileSearchField(true);
  };

  const handleExportClick = () => {
    onExportClick();
    // apiRef.current.exportDataAsCsv();
  };

  useEffect(() => {
    setFilterModel(initialFilterModel);
  }, [initialFilterModel]);

  return (
    <Box
      sx={{
        ".MuiDataGrid-toolbarContainer": {
          px: { xs: 1.5, sm: 2.5 }
        }
      }}
    >
      <GridToolbarContainer
        sx={{
          justifyContent: { xs: "start", md: "center" },
          ".MuiButton-text": {
            width: isLGDown ? theme.spacing(6) : "unset",
            minWidth: "unset",
            px: { xs: 0, lg: 2.5 }
          }
        }}
      >
        {isSMDown ? (
          <IconButton
            sx={{
              width: theme.spacing(6),
              background:
                showMobileSearchField ||
                !isEmpty(
                  (filterModel as Record<string, { query: string; operatorTypeId: string }>)?.text
                    ?.query
                )
                  ? theme.palette.orange[90]
                  : "none"
            }}
            onClick={onShowMobileSearchFieldClick}
          >
            <SearchIcon sx={{ fontSize: theme.spacing(2.25), color: theme.palette.orange[30] }} />
          </IconButton>
        ) : (
          <SearchField filterModel={filterModel} onQueryChange={handleSearchFieldChange} />
        )}
        <GridToolbarColumnsButton
          ref={setColumnButtonEl}
          slotProps={{
            button: {
              startIcon: <ViewWeekOutlinedIcon />
            }
          }}
        />
        {!isEmpty(listSettings.filters) ? (
          <AddFilterButton
            filterModel={filterModel}
            onSubmit={onAddFiltersDialogSubmit}
            availableFilters={listSettings.filters}
          />
        ) : null}
        <GridToolbarDensitySelector />
        {listSettings.isExportFunctionEnabled ? (
          <GridToolbarExportButton onExportClick={handleExportClick} isExporting={isExporting} />
        ) : null}
        {listSettings.isPresetEnabled ? (
          <GridToolbarPresetButton
            onPresetChange={onPresetChange}
            selectedPresetId={selectedPresetId}
            availablePresets={listSettings.userPresets}
            onCreatePreset={onCreatePreset}
            onUpdatePreset={onUpdatePreset}
            onDeletePreset={onDeletePreset}
          />
        ) : null}
        {isMDDown ? null : <DataGridPagination rowCount={rowCount} />}
      </GridToolbarContainer>

      {isSMDown && showMobileSearchField && (
        <ClickAwayListener onClickAway={() => setShowMobileSearchField(false)}>
          <Box sx={{ px: { xs: 1.5, md: 2.5 }, pb: 1 }}>
            <SearchField
              sx={{ width: "100%" }}
              filterModel={filterModel}
              onQueryChange={handleSearchFieldChange}
            />
          </Box>
        </ClickAwayListener>
      )}
      <ActiveFilters
        onFilterRemove={handleRemoveFilter}
        filterModel={filterModel}
        onChange={handleFilterValueChange}
        availableFilters={listSettings.filters}
        handleGetAgents={handleGetAgents}
        onFilterClearAll={handleClearAllFilter}
      />
    </Box>
  );
}
