interface DownloadFileArgs {
  url: string;
  forceOpen?: boolean;
}
export default function downloadFile({ url, forceOpen = true }: DownloadFileArgs) {
  const save = document.createElement("a");
  save.target = "_blank";
  if (forceOpen) {
    const parsedURL = new URL(url);
    const params = new URLSearchParams(parsedURL.search);
    params.set("download", "false");
    parsedURL.search = `?${params.toString()}`;
    save.href = parsedURL.toString();
  } else {
    save.href = url;
  }
  save.click();
}
