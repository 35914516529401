import React from "react";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import { useTheme } from "@mui/material";
import keyBy from "lodash/keyBy";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import { useTranslation } from "react-i18next";

const MAX_PROFILE_DISPLAY_COUNT = 2;
export default function TargetMarketListProfile({ renderCellDataProps }) {
  return function renderCell(props: GridRenderCellParams) {
    const { value: profiles } = props;
    if (isEmpty(profiles)) {
      return null;
    }
    const theme = useTheme();
    const { t } = useTranslation();
    const targetMarketListProfiles = renderCellDataProps?.getTargetMarketListProfiles;
    const hashedTargetMarketListProfiles = keyBy(targetMarketListProfiles, "code");

    const firstAndSecondProfiles = profiles.slice(0, MAX_PROFILE_DISPLAY_COUNT);

    return (
      <Box>
        {map(firstAndSecondProfiles, item => (
          <Chip
            key={item.id}
            label={
              <Typography
                variant="body5"
                sx={{ fontSize: theme.typography.pxToRem(12), color: theme.palette.grey[10] }}
              >
                {hashedTargetMarketListProfiles[item.targetMarketListProfileCode]?.name}
              </Typography>
            }
            size="medium"
            sx={{
              backgroundColor: theme.palette.common.white,
              marginLeft: theme.spacing(0.5),
              border: `1px solid ${theme.palette.grey[80]}`
            }}
          />
        ))}
        {profiles.length > MAX_PROFILE_DISPLAY_COUNT && (
          <Typography
            variant="body5"
            sx={{ marginLeft: theme.spacing(1), color: theme.palette.grey[10] }}
          >
            {t(`+${profiles.length - MAX_PROFILE_DISPLAY_COUNT}`)}
          </Typography>
        )}
      </Box>
    );
  };
}
