import React from "react";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import PolicyStatus from "../../PolicyStatus";

export default function PolicyStatusCellRenderer({ config }) {
  const {
    company: { policyStates }
  } = config;

  return function renderCell(props: GridRenderCellParams) {
    const { value } = props;

    return <PolicyStatus status={value} policyStates={policyStates} />;
  };
}
