import React from "react";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material";
import formatDate from "../../../libs/formatter/formatDate";

export default function TargetMarketListEntryLastContact({ currentUser }) {
  return function renderCell(props: GridRenderCellParams) {
    const { value, row } = props;
    const theme = useTheme();

    return (
      <Typography variant="body5" color={theme.palette.grey[10]}>
        {Number(currentUser.id) === Number(row.agent.id) || row.isLastContactShared
          ? formatDate(value, "MMM dd, yyyy")
          : ""}
      </Typography>
    );
  };
}
