import React, { useState, ReactElement, useEffect, useRef, MouseEvent } from "react";
import { useTheme } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CancelIcon from "@mui/icons-material/Cancel";
import Popover from "@mui/material/Popover";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";

interface SingleSelectFilterProps {
  startIcon: ReactElement;
  label: string;
  values: { query: string };
  filterKey: string;
  onChange: ({
    filterKey,
    value
  }: {
    filterKey: string;
    value: { query: string; operatorTypeId: string };
  }) => void;
  onDelete: () => void;
}

export default function BooleanFilter({
  values,
  startIcon,
  label,
  filterKey,
  onChange,
  onDelete
}: SingleSelectFilterProps) {
  const theme = useTheme();
  const [isActive, setIsActive] = useState<boolean>(false);
  const anchorRef = useRef(null);
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  useEffect(() => {
    setIsActive(values.query === "true");
  }, [values]);

  const handleFilterButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onOnOrOffClick = () => {
    setIsActive(!isActive);
    setAnchorEl(null);
    onChange({ filterKey, value: { query: `${!isActive}`, operatorTypeId: "is" } });
  };

  let booleanLabel;
  if (isActive) {
    booleanLabel = `${label}: On`;
  } else if (!isActive) {
    booleanLabel = `${label}: Off`;
  }

  return (
    <>
      <Button
        ref={anchorRef}
        sx={{
          ".MuiButton-startIcon": {
            color: isActive ? theme.palette.orange[50] : theme.palette.grey[50]
          },
          borderRadius: theme.spacing(12.5),
          border: `1px solid ${isActive ? theme.palette.orange[50] : theme.palette.grey[90]}`,
          backgroundColor: isActive ? theme.palette.orange[90] : theme.palette.grey[100],
          ":hover": {
            backgroundColor: isActive ? theme.palette.orange[80] : theme.palette.grey[90],
            border: `1px solid ${isActive ? theme.palette.orange[50] : theme.palette.grey[80]}`
          }
        }}
        startIcon={startIcon}
        onClick={handleFilterButtonClick}
      >
        <Typography
          variant="h8"
          sx={{
            textTransform: "capitalize",
            color: isActive ? theme.palette.orange[50] : theme.palette.grey[50]
          }}
        >
          {booleanLabel}
        </Typography>
        <IconButton sx={{ ml: 1.25, p: 0 }} onClick={onDelete}>
          <CancelIcon sx={{ color: theme.palette.error.main }} />
        </IconButton>
      </Button>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        sx={{
          "& .MuiPaper-elevation": {
            width: theme.spacing(40),
            margin: theme.spacing(1, 0),
            borderRadius: theme.spacing(1),
            boxShadow: "0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30)"
          }
        }}
      >
        <Box sx={{ padding: theme.spacing(1.5, 2.5) }}>
          <Typography variant="h7" sx={{ color: theme.palette.grey[70] }}>
            {label}
          </Typography>
        </Box>
        <Box
          sx={{
            padding: theme.spacing(1.5, 2.5),
            cursor: "pointer",
            backgroundColor: isActive ? theme.palette.orange[90] : theme.palette.grey[100]
          }}
          onClick={onOnOrOffClick}
        >
          <Typography variant="body2" sx={{ color: theme.palette.grey[50], fontWeight: 500 }}>
            {t("On")}
          </Typography>
        </Box>
        <Box
          sx={{
            padding: theme.spacing(1.5, 2.5),
            cursor: "pointer",
            backgroundColor: !isActive ? theme.palette.orange[90] : theme.palette.grey[100]
          }}
          onClick={onOnOrOffClick}
        >
          <Typography variant="body2" sx={{ color: theme.palette.grey[50], fontWeight: 500 }}>
            {t("Off")}
          </Typography>
        </Box>
      </Popover>
    </>
  );
}
