export default function getTLD(hostname: string) {
  if (hostname.startsWith("localhost")) {
    return hostname;
  }
  const hostnameParts = hostname.split(/\./);
  if (hostnameParts[0] === "v2") {
    const [, ...remainingParts] = hostnameParts;
    return remainingParts.join(".");
  }
  return hostname;
}
