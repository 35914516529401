import filter from "lodash/filter";
import keyBy from "lodash/keyBy";
import map from "lodash/map";
import reduce from "lodash/reduce";
import sortBy from "lodash/sortBy";

export default function getPresetSettings({ preset, availableColumns }) {
  const hashedColumns = keyBy(availableColumns, "id");
  const {
    presetColumns,
    // presetFilters,
    // textQuery,
    order,
    orderBy
  } = preset;

  const hashedPresetColumns = keyBy(presetColumns, "column.field");

  const columnOrdersSettings = map(sortBy(presetColumns, "order"), "column.field");

  const formattedColumnVisibilityModel = reduce(
    hashedColumns,
    (memo, column) => {
      memo[column.field] =
        !!hashedPresetColumns[column.field] && hashedPresetColumns[column.field].isVisible;

      return memo;
    },
    {}
  );

  const pinnedOnLeftColumns = filter(presetColumns, presetColumn => presetColumn.isPinnedOnLeft);
  const pinnedOnRightColumns = filter(presetColumns, presetColumn => presetColumn.isPinnedOnRight);

  const pinnedColumnsSettings = {
    left: reduce(
      pinnedOnLeftColumns,
      (memo, column) =>
        hashedColumns[column.columnId] ? memo.concat(hashedColumns[column.columnId].field) : memo,
      []
    ),
    right: reduce(
      pinnedOnRightColumns,
      (memo, column) =>
        hashedColumns[column.columnId] ? memo.concat(hashedColumns[column.columnId].field) : memo,
      []
    )
  };

  return {
    columnVisibilityModel: formattedColumnVisibilityModel,
    columnOrders: columnOrdersSettings,
    pinnedColumns: pinnedColumnsSettings,
    currentPresetId: preset.id,
    sortModel: orderBy && order ? [{ field: orderBy, sort: order }] : [],
    paginationModel: {
      page: preset.page || 0,
      pageSize: preset.pageSize || preset.pageSize
    },
    // TODO: rework preset filters
    filterModel: {}
  };
}
