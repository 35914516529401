import React, { useEffect, useState } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { format } from "date-fns/esm";
import { formatDistanceToNow } from "date-fns";
import MarkdownPreview from "@uiw/react-markdown-preview";
import { useTranslation } from "react-i18next";
import truncate from "lodash/truncate";
import useMediaQuery from "@mui/material/useMediaQuery";
import { TrainingClassSession as TrainingClassSessionType } from "../../../generated/graphql";

export default function TrainingClassSessionInfo({
  trainingClassSession
}: {
  trainingClassSession: TrainingClassSessionType;
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [isSeeMore, setIsSeeMore] = useState<boolean>(false);
  const [showSeeMoreButton, setShowSeeMoreButton] = useState<boolean>(false);
  const [description, setDescription] = useState<string>();

  const isLgScreen = useMediaQuery(theme.breakpoints.down("xl"));
  const isMdScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const isSmScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isXsScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    let length = 380;
    setShowSeeMoreButton(trainingClassSession.description?.length >= 380);

    if (isSeeMore) {
      return setDescription(trainingClassSession.description || "");
    }
    if (isLgScreen) {
      setShowSeeMoreButton(trainingClassSession.description?.length >= 200);
      length = 200;
    }
    if (isMdScreen) {
      setShowSeeMoreButton(trainingClassSession.description?.length >= 120);
      length = 120;
    }
    if (isSmScreen) {
      setShowSeeMoreButton(trainingClassSession.description?.length >= 180);
      length = 180;
    }
    if (isXsScreen) {
      setShowSeeMoreButton(trainingClassSession.description?.length >= 80);
      length = 80;
    }
    return setDescription(
      (trainingClassSession.description &&
        (truncate(trainingClassSession.description, { length }) as string)) ||
        ""
    );
  }, [isSeeMore, isLgScreen, isMdScreen, isSmScreen, isXsScreen, trainingClassSession.description]);

  const onSeeMoreClick = () => {
    setIsSeeMore(true);
  };

  const onSeeLessClick = () => {
    setIsSeeMore(false);
  };
  return (
    <Box
      sx={{
        padding: theme.spacing(2.5),
        backgroundColor: theme.palette.backgroundColor.light,
        borderRadius: theme.spacing(1.5),
        flexDirection: "column",
        gap: theme.spacing(2.5),
        width: "100%",
        height: "100%",
        alignItems: "flex-start"
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: theme.spacing(2.5)
        }}
      >
        <Box
          sx={{
            padding: theme.spacing(1, 2.5),
            backgroundColor: theme.palette.background.paper,
            borderRadius: theme.spacing(0.5),
            display: "flex",
            alignItems: "center",
            gap: theme.spacing(1.5)
          }}
        >
          <CalendarMonthIcon />
          <Typography variant="body2" sx={{ color: theme.palette.grey[50], fontWeight: 500 }}>
            {`${format(trainingClassSession.startTimestamp || 0, "MMM d, h a")} - ${format(trainingClassSession.endTimestamp || 0, "MMM d, h a")}`}
          </Typography>
        </Box>
        <Typography variant="body2" sx={{ color: theme.palette.grey[70] }}>
          {formatDistanceToNow(trainingClassSession.startTimestamp, {
            addSuffix: true
          })}
        </Typography>
      </Box>
      <MarkdownPreview
        source={description}
        style={{
          backgroundColor: theme.palette.backgroundColor.light,
          color: theme.palette.grey[50],
          marginTop: theme.spacing(1.5),
          fontFamily: "Montserrat",
          fontSize: theme.typography.pxToRem(18),
          fontWeight: 400,
          lineHeight: "160%",
          letterSpacing: theme.typography.pxToRem(0.336)
        }}
      />
      {showSeeMoreButton && trainingClassSession.description && !isSeeMore && (
        <Typography
          variant="button"
          color={theme.palette.orange[30]}
          onClick={onSeeMoreClick}
          sx={{ cursor: "pointer" }}
        >
          {t("See more")}
        </Typography>
      )}
      {trainingClassSession.description && isSeeMore && (
        <Typography
          variant="button"
          color={theme.palette.orange[30]}
          onClick={onSeeLessClick}
          sx={{ cursor: "pointer" }}
        >
          {t("See less")}
        </Typography>
      )}
    </Box>
  );
}
