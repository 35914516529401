import { ApolloError } from "@apollo/client";
import map from "lodash/map";
import result from "lodash/result";
import isEmpty from "lodash/isEmpty";
import compact from "lodash/compact";
import isNil from "lodash/isNil";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function getGraphQLErrorsAsString(err: any): string {
  const error = err as ApolloError;
  if (isNil(error)) {
    return "";
  }

  if (!isEmpty(error.graphQLErrors)) {
    return compact(
      map(error.graphQLErrors, ({ message, extensions }) => {
        if (!isEmpty(message)) {
          return message;
        }

        if (extensions && extensions.code === "FORBIDDEN") {
          return "permission denied";
        }
        return undefined;
      })
    ).join(", ");
  }

  return error.message || result(error, "toString");
}
