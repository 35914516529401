import map from "lodash/map";
import { Config, ListColumn, ListFilter, UserCompanyRole } from "../../generated/graphql";
import { defaultCellRenderers, defaultFormatters, defaultValueGetter } from "../DataGrid";

export default function getColumnFormats({
  columns,
  config,
  currentUser,
  renderCellDataProps
}: {
  columns: ListColumn[];
  filters: ListFilter[];
  config: Config;
  currentUser: UserCompanyRole;
  renderCellDataProps: Record<string, any>;
}) {
  return map(columns, column => {
    const formatter = column.valueFormatter ? defaultFormatters[column.valueFormatter] : null;

    const renderer = column.cellRenderer ? defaultCellRenderers[column.cellRenderer] : null;
    const valueGetter = column.valueGetter ? defaultValueGetter : null;
    return {
      ...column,
      valueGetter: valueGetter && valueGetter({ path: column.valueGetter as string }),
      valueFormatter: formatter && formatter({ config }),
      renderCell: renderer && renderer({ config, currentUser, renderCellDataProps })
    };
  });
}
