import React from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import { Button, Tooltip, alpha, useTheme, useMediaQuery } from "@mui/material";
import Typography from "@mui/material/Typography";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import Grid from "@mui/material/Grid";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { ProductionCommitment, UserCompanyRole } from "../../../generated/graphql";
import { useAuth } from "../../../contexts/auth";
import formatNumber from "../../../libs/formatter/formatNumber";

export type ProductionCommitmentCategoryItemProps = {
  item: ProductionCommitment & {
    color: string | undefined;
    name: string | undefined;
    tooltip: string | undefined;
    filters?: Record<string, string | number>;
    navigationLink: string;
  };
  agent: UserCompanyRole;
  onViewDetailsClick: ({ event, item }) => void;
};

export default function ChartTitle({
  item,
  agent,
  onViewDetailsClick
}: ProductionCommitmentCategoryItemProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { userCompanyRole } = useAuth();

  const isSMUp = useMediaQuery(theme.breakpoints.up("sm"));

  const onViewClick = ({ event, item: categoryItem }) => {
    onViewDetailsClick({ event, item: categoryItem });
  };

  return (
    <Box
      sx={{
        mt: 5
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          mb: 2.5
        }}
      >
        <Typography
          sx={{
            display: "flex",
            justifyContent: "center",
            color: theme.palette.grey[10]
          }}
          variant="h7"
        >
          {t("{{chartTitle}}", {
            chartTitle: item.name?.replace("PV", "Point Value")
          })}
          {item.tooltip ? (
            <Tooltip
              enterTouchDelay={0}
              componentsProps={{
                tooltip: {
                  sx: {
                    backgroundColor: theme.palette.common.white,
                    color: theme.palette.grey[50],
                    padding: 2.5,
                    borderRadius: 1,
                    boxShadow: `0 0 ${theme.spacing(5)} 0 ${alpha(theme.palette.common.black, 0.04)}`
                  }
                }
              }}
              title={
                <Typography variant="body3" sx={{ color: theme.palette.grey[50] }}>
                  {item.tooltip}
                </Typography>
              }
              placement={isSMUp ? "right-start" : "bottom"}
            >
              <InfoOutlined
                sx={{ width: theme.spacing(2), height: theme.spacing(2), ml: 1, my: "auto" }}
              />
            </Tooltip>
          ) : null}
        </Typography>
      </Box>
      <Grid
        sx={{
          backgroundColor: { xs: "unset", sm: theme.palette.backgroundColor.light },
          padding: { xs: 0, sm: theme.spacing(1.5, 1) },
          borderRadius: { xs: 0, sm: theme.spacing(1) }
        }}
        container
        rowSpacing={1}
        justifyContent="space-between"
      >
        {isSMUp ? (
          <Grid item>
            <Box
              sx={{
                display: "flex",
                alignItems: "center"
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: theme.spacing(7)
                }}
              >
                <Typography
                  sx={{ marginRight: theme.spacing(1.5), color: item.color }}
                  variant="h5"
                >
                  {formatNumber({ amount: item.current, format: "0,0", showZero: true })}
                </Typography>
                {item.category === "personalRecruit" || item.category === "teamRecruit" ? (
                  <Typography
                    sx={{
                      marginRight: theme.spacing(1.5),
                      color: theme.palette.grey[50],
                      fontWeight: 400
                    }}
                    variant="h7"
                  >
                    {t("Recruited")}
                  </Typography>
                ) : (
                  <Typography
                    sx={{
                      marginRight: theme.spacing(1.5),
                      color: theme.palette.grey[50],
                      fontWeight: 400
                    }}
                    variant="h7"
                  >
                    {t("Points Earned")}
                  </Typography>
                )}
              </Box>
              {item.goal !== 0 ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: theme.spacing(7)
                  }}
                >
                  <Typography sx={{ marginRight: theme.spacing(1.5) }} variant="h5">
                    {formatNumber({ amount: item.goal, format: "0,0", showZero: true })}
                  </Typography>
                  <Typography
                    sx={{
                      marginRight: theme.spacing(1.5),
                      color: theme.palette.grey[50],
                      fontWeight: 400
                    }}
                    variant="h7"
                  >
                    {t("Goal")}
                  </Typography>
                </Box>
              ) : null}
              {item.goal !== 0 ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center"
                  }}
                >
                  <Typography sx={{ marginRight: theme.spacing(1.5) }} variant="h5">
                    {t("{{percentage}}%", {
                      percentage: item.goal !== 0 ? Math.round((item.current / item.goal) * 100) : 0
                    })}
                  </Typography>
                  <Typography
                    sx={{
                      marginRight: theme.spacing(1.5),
                      color: theme.palette.grey[50],
                      fontWeight: 400
                    }}
                    variant="h7"
                  >
                    {t("Target reached")}
                  </Typography>
                </Box>
              ) : null}
            </Box>
          </Grid>
        ) : null}

        <Grid item>
          {!userCompanyRole?.isAgent || userCompanyRole.id === agent.id ? (
            <Button
              onClick={event => onViewClick({ item, event })}
              variant="text"
              sx={{
                display: "flex",
                padding: { xs: 0 },
                gap: 1,
                alignItems: "center",
                cursor: "pointer"
              }}
            >
              {item.category === "personalRecruit" || item.category === "teamRecruit" ? (
                <Typography sx={{ color: theme.palette.orange[30] }} variant="h7">
                  {t("View Recruit List")}
                </Typography>
              ) : (
                <Typography sx={{ color: theme.palette.orange[30] }} variant="h7">
                  {t("View Production Breakdowns")}
                </Typography>
              )}
              <ArrowOutwardIcon
                sx={{
                  color: theme.palette.orange[30]
                }}
              />
            </Button>
          ) : null}
        </Grid>
      </Grid>
    </Box>
  );
}
