import snakeCase from "lodash/snakeCase";
import getFileNameParts from "./getFileNameParts";

interface GetTmpFileNameArgs {
  name: string;
}
export default function getTmpFileName({ name: fileName }: GetTmpFileNameArgs): string {
  const { name, extension } = getFileNameParts({ name: fileName });
  return `tmp/${Date.now()}_${snakeCase(name)}.${extension}`;
}
